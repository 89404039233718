import React from 'react';
import { Svg, ClipPath, Defs, G, Rect } from 'react-native-svg';

import { Color } from '~/components/color';

type BubbleLinesProps = { fill: Color };

export const BubbleLines = (props: BubbleLinesProps) => {
    const { fill } = props;
    const numberOfLines = 9;
    const height = 24;
    const width = 200;

    return (
        <Svg width={width} height={height}>
            <Defs>
                <ClipPath id="linesclip">
                    <Rect x="0" y={-1 * height} width={width} height={2 * height} rx="16" />
                </ClipPath>
            </Defs>
            <G clipPath="url(#linesclip)">
                {[...Array(numberOfLines)].map((_, i) => {
                    return (
                        <Rect
                            key={`line${i}`}
                            x={width - i * (numberOfLines + 3) - numberOfLines}
                            y="0"
                            width={numberOfLines - i + 1}
                            height={height}
                            fill={fill}
                        />
                    );
                })}
            </G>
        </Svg>
    );
};
