import { Flex } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import Svg, { ClipPath, Defs, G, Rect } from 'react-native-svg';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Badge } from '~/components/badge';
import { Color } from '~/components/color';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';

type UserJoinedMessageProps = {
    imageUri: string;
    name: string;
    title: string;
    timestamp: string;
};

const UserJoinedContainer = styled.View`
    flex-direction: row;
    border-radius: 15px;
    margin-bottom: 5px;
    align-items: center;
    background-color: ${Color.BACKGROUND_DEFAULT};
    flex: 1 1 auto;
`;

const LinesContainer = styled.View`
    flex: 0 0 26px;
    align-self: flex-start;
`;

export const UserJoinedLines = () => {
    const numberOfLines = 9;
    const height = numberOfLines * (numberOfLines + 2);

    return (
        <Svg height={height}>
            <Defs>
                <ClipPath id="linesclip">
                    <Rect x="0" y="0" width="20" height={height} rx="15" />
                </ClipPath>
            </Defs>
            <G clipPath="url(#linesclip)">
                {[...Array(numberOfLines)].map((_, i) => {
                    return (
                        <Rect
                            key={`line${i}`}
                            x="0"
                            y={i * (numberOfLines + 2)}
                            width="13"
                            height={numberOfLines - i}
                            fill={Color.BACKGROUND_TERTIARY}
                        />
                    );
                })}
            </G>
        </Svg>
    );
};

const UserAvatarSection = styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 15px;
`;

export const UserJoinedMessage = (props: UserJoinedMessageProps) => {
    const { name, title, imageUri } = props;
    return (
        <UserJoinedContainer style={Shadow.styles.primary}>
            <LinesContainer>
                <UserJoinedLines />
            </LinesContainer>
            <Flex py="10px" direction="column" grow="1">
                <Text.INPUT_FIELD_TITLE
                    style={{ color: Color.TEXT_SECONDARY }}
                    after={Spacing.SMALL}
                    before={Spacing.SMALL}
                >
                    <FormattedMessage id="chat-message.joined_chat" />
                </Text.INPUT_FIELD_TITLE>
                <Text.HEADER_3 after={Spacing.TINY}>{name}</Text.HEADER_3>
                <Text.PARAGRAPH_1 after={Spacing.HUGE}>{title}</Text.PARAGRAPH_1>
            </Flex>
            <UserAvatarSection>
                <Avatar size="regular" imageUri={imageUri} />
            </UserAvatarSection>
        </UserJoinedContainer>
    );
};

export const SystemMessageContainer = styled.View`
    padding-vertical: 12px;
    padding-horizontal: 18px;
    flex-direction: column;
    border-radius: 18px;
    margin-bottom: 5px;
    align-items: center;
    background-color: ${Color.BACKGROUND_PRIMARY};
`;

type UserLeftMessageProps = {
    name: string;
    title: string;
};

export const UserLeftMessage = (props: UserLeftMessageProps) => {
    const { name, title } = props;

    return (
        <SystemMessageContainer>
            <Text.CAPTION after={Spacing.TINY}>
                {name}, {title}
            </Text.CAPTION>
            <Text.CAPTION>
                <FormattedMessage id="chat-message.left_chat" />
            </Text.CAPTION>
        </SystemMessageContainer>
    );
};

type TextOnlyMessageProps = {
    children: React.ReactNode;
};

export const TextOnlyMessage = (props: TextOnlyMessageProps) => {
    const { children } = props;

    return (
        <SystemMessageContainer>
            <Text.CAPTION>{children}</Text.CAPTION>
        </SystemMessageContainer>
    );
};

type TimestampBasedMessageProps = {
    timestamp: string;
};

export const ChatClosedMessage = (props: TimestampBasedMessageProps) => {
    const { timestamp } = props;

    return (
        <TextOnlyMessage>
            <FormattedMessage id="chat-message.chat_closed" values={{ timestamp }} />
        </TextOnlyMessage>
    );
};

export const ChatReopenedMessage = (props: TimestampBasedMessageProps) => {
    const { timestamp } = props;

    return (
        <TextOnlyMessage>
            <FormattedMessage id="chat-message.chat_reopened" values={{ timestamp }} />
        </TextOnlyMessage>
    );
};

export const ChatEscalatedMessage = (props: TimestampBasedMessageProps) => {
    const { timestamp } = props;

    return (
        <TextOnlyMessage>
            <FormattedMessage id="chat-message.chat_escalated" values={{ timestamp }} />
        </TextOnlyMessage>
    );
};

export type NewMessagesProps = {
    badge: string;
};

export const NewMessages = (props: NewMessagesProps) => {
    const { badge } = props;
    return (
        <SystemMessageContainer>
            <View style={{ flexDirection: 'row' }}>
                <Text.CAPTION style={{ color: Color.TEXT_SECONDARY }}>
                    <FormattedMessage id="chat-message.new_messages" />
                </Text.CAPTION>
                <View style={{ marginLeft: Spacing.SMALL }}>
                    <Badge badge={badge} />
                </View>
            </View>
        </SystemMessageContainer>
    );
};

const DateChangeContainer = styled.View`
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const DateChangeTextContainer = styled.View`
    border-radius: 30px;
    border-width: 1px;
    padding-vertical: 6px;
    padding-horizontal: 18px
    border-color: ${Color.LINE_DEFAULT};
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const DateDivider = styled.View`
    background-color: ${Color.LINE_DEFAULT};
    width: 100%;
    height: 1px;
    position: absolute;
`;

type DateChangeMessageProps = {
    date: Date;
};

export const DateChangeMessage = (props: DateChangeMessageProps) => {
    const { date } = props;
    const { formatDate } = useIntl();

    return (
        <DateChangeContainer>
            <DateDivider />
            <DateChangeTextContainer>
                <Text.CAPTION>
                    {formatDate(date, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric'
                    }).toUpperCase()}
                </Text.CAPTION>
            </DateChangeTextContainer>
        </DateChangeContainer>
    );
};
