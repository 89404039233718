import Constants from 'expo-constants';

import { AppConfig } from '~/contexts/app-config';

const expoConfig = Constants.expoConfig;

export const config: AppConfig = {
    development: {
        /**
         * Determines the current host IP address from Expo configuration's host URI.
         *
         * You also need to add the current host IP address to allowed_origins_public_api list in backend config.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        host: `${(expoConfig as any)?.hostUri?.split(':')[0] ?? 'localhost'}:50001`,
        http: 'http',
        ws: 'ws'
    },
    staging: {
        host: 'my.staging.heltti.fi',
        http: 'https',
        ws: 'wss',
        sentry: {
            dsn: 'https://d0395514a4b34f2ba31b5f853f31ef2b@o441371.ingest.sentry.io/6361191'
        }
    },
    production: {
        host: 'my.heltti.fi',
        http: 'https',
        ws: 'wss',
        sentry: {
            dsn: 'https://d0395514a4b34f2ba31b5f853f31ef2b@o441371.ingest.sentry.io/6361191'
        }
    }
};
