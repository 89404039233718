import React, { useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Loader } from '~/components/loader';

import { UrlImage } from './url-image';

type ScaledImageProps = {
    uri: string;
    headers?: { [index: string]: string };
    additionalStyles?: object;
};

const LoaderContainer = styled.View`
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
`;

// Scales image with unknown dimensions to fit container width
export const ScaledImage = (props: ScaledImageProps) => {
    const { additionalStyles = {}, uri, headers = {} } = props;
    const [loading, setLoading] = useState(true);

    const [aspectRatio, setAspectRatio] = useState<number>(1);

    const handleLoad = (width: number, height: number) => {
        if (height === 0) {
            setAspectRatio(1);
        } else {
            setAspectRatio((width * 1.0) / height);
        }
        setLoading(false);
    };

    const handleLoadEnd = () => {
        setLoading(false);
    };

    return (
        <View>
            <UrlImage
                headers={headers}
                onLoad={handleLoad}
                onLoadEnd={handleLoadEnd}
                additionalStyles={additionalStyles}
                uri={uri}
                aspectRatio={aspectRatio}
            />
            {loading && (
                <LoaderContainer>
                    <Loader size="large" />
                </LoaderContainer>
            )}
        </View>
    );
};
