import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ArrowSwipeUpIcon: FunctionComponent<IconProps> = ({ fill, size = 24 }) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M13.0008 5.00079L13.0015 11.0019L13.0015 11.1609C13.0017 16.4082 8.74766 20.6618 3.50042 20.6612V20.6612"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                    // @ts-ignore
                    tabIndex="-1"
                />
                <Path
                    d="M18 8L13 4L8 8"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                    strokeLinecap="round"
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
