import React, { ReactElement } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { RadioButtonProps } from './radio-button';

type RadioGroupProps = ViewProps & {
    selectedIndex: number;
    horizontal?: true;
    onChange: (index: number) => void;
    children?: RadioButtonElement | RadioButtonElement[];
};

type RadioButtonElement = ReactElement<RadioButtonProps>;

export const RadioGroup = (props: RadioGroupProps) => {
    const { selectedIndex, onChange, children = [], horizontal, style } = props;

    const radioButtons = React.Children.map(children, (element: RadioButtonElement, index: number) =>
        React.cloneElement(element, {
            key: index,
            style: [{ marginTop: 3, marginBottom: 3 }, element.props.style],
            selected: selectedIndex === index,
            onPress: () => {
                onChange(index);
            }
        })
    );
    return <View style={[style, horizontal ? styles.horizontal : styles.vertical]}>{radioButtons}</View>;
};

const styles = StyleSheet.create({
    horizontal: {
        flexDirection: 'row'
    },
    vertical: {
        flexDirection: 'column'
    }
});

RadioGroup.displayName = 'RadioGroup';
