/*
 * Non-secure storage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

export enum AuthStorageKey {
    USER_PROFILE_LAST_REVIEWED = 'user-profile-reviewed'
}

export const getUserProfileLastReviewed = async (identity: string): Promise<Date | null> => {
    const result = await AsyncStorage.getItem(`${AuthStorageKey.USER_PROFILE_LAST_REVIEWED}:${identity}`);
    return result ? new Date(result) : null;
};

export const setUserProfileLastReviewed = (identity: string, reviewed: Date) => {
    return AsyncStorage.setItem(`${AuthStorageKey.USER_PROFILE_LAST_REVIEWED}:${identity}`, reviewed.toISOString());
};

export const clearUserProfileLastReviewed = () => AsyncStorage.removeItem(AuthStorageKey.USER_PROFILE_LAST_REVIEWED);
