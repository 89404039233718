import React, { ComponentType } from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';

import { Text } from '../text';

const SystemMessageContainer = styled.View`
    background-color: ${Color.BACKGROUND_PRIMARY};
    border-radius: 16px;
    padding-vertical: 8px;
    padding-horizontal: 16px;
    margin: auto;
`;

export type SystemMessageProps = {
    label: string;
    icon?: ComponentType<IconProps>;
};

export function SystemMessage(props: SystemMessageProps) {
    const { icon: Icon, label } = props;
    return (
        <SystemMessageContainer>
            {Icon && <Icon />}
            <Text.CAPTION>{label}</Text.CAPTION>
        </SystemMessageContainer>
    );
}
