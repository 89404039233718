import { MasonryFlashList } from '@shopify/flash-list';
import { Box, Flex, useBreakpointValue } from 'native-base';
import React, { ReactNode } from 'react';

import { NoContentContainer } from '~/components/no-content-container';
import { Spacing } from '~/components/spacing';
import { SystemMessage } from '~/components/system-message';

type Props<T> = {
    items: readonly T[];
    renderItem: (item: T) => ReactNode;
    noContentMessage?: string;
    customColumnCount?: number;
    minCardHeight?: number;
    minCardWidth?: number;
};

export const MasonryList = <T,>(props: Props<T>) => {
    const { items, noContentMessage, renderItem, customColumnCount, minCardHeight = 250, minCardWidth = 353 } = props;

    const breakpointColumnCount = useBreakpointValue({
        base: 1,
        md: 2,
        lg: 3
    });

    const calculateColumnsCount = (): number => {
        if (customColumnCount && customColumnCount < 4) {
            return customColumnCount;
        } else {
            return breakpointColumnCount;
        }
    };

    const columnsCount = calculateColumnsCount();

    const estimateListHeight = (): number => {
        return (items.length * minCardHeight) / columnsCount;
    };

    const estimateListWidth = (): number => {
        return columnsCount > 1 ? minCardWidth * columnsCount : minCardWidth * 2;
    };

    const estimateItemSize = (): number => {
        return columnsCount > 1 ? minCardWidth * columnsCount : minCardHeight * items.length;
    };

    return (
        <MasonryFlashList
            key={`${columnsCount}`}
            keyExtractor={(_item, index) => `${index}`}
            scrollEnabled={false}
            data={items}
            estimatedItemSize={estimateItemSize() === 0 ? estimateItemSize() : minCardHeight}
            estimatedListSize={{
                height: estimateListHeight() === 0 ? estimateItemSize() : minCardHeight,
                width: estimateListWidth() === 0 ? estimateItemSize() : minCardWidth
            }}
            numColumns={columnsCount}
            renderItem={({ item }) => <Box m="2">{renderItem(item)}</Box>}
            ListEmptyComponent={
                <Flex>
                    <NoContentContainer>
                        <Spacing.Vertical.SMALL />
                        <SystemMessage label={noContentMessage ?? '-'} />
                    </NoContentContainer>
                </Flex>
            }
        />
    );
};
