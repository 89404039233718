import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useAnalytics } from '~/contexts/analytics';
import { AppError } from '~/error';

export type Attachment = {
    uri: string;
    name: string;
    file?: File;
};

export const useAttachment = () => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const errorHandler = useErrorHandler();
    const { track } = useAnalytics();

    const addToAttachments = (attachmentToAdd: Attachment) => {
        if (!attachments.find(attachment => attachment.uri === attachmentToAdd.uri)) {
            setAttachments(previousAttachments => [...previousAttachments, attachmentToAdd]);
        }
    };

    const removeAttachment = (attachmentToRemove: Attachment) => {
        setAttachments(attachments.filter(attachment => attachment.uri !== attachmentToRemove.uri));
    };

    const clearAttachments = () => {
        setAttachments([]);
    };

    const uploadSuccess = (result: DocumentPicker.DocumentPickerResult) => {
        if (result.canceled) {
            // TODO: user cancelled, now what?
        } else {
            // TODO: Only support one attachment currently
            const attachment = result.assets[0];
            addToAttachments({ uri: attachment.uri, name: attachment.name, file: attachment.file });
        }
    };

    const uploadError = (err: unknown) => {
        if (err instanceof Error) {
            errorHandler(
                new AppError(err, 'error.chat-attachment-upload-failed', {
                    onClose: () => {},
                    name: 'error-overlay.go_back'
                })
            );
        } else {
            errorHandler(err);
        }
    };

    const onDocument = () => {
        DocumentPicker.getDocumentAsync().then(uploadSuccess, uploadError);
    };

    const imageSuccess = (result: ImagePicker.ImagePickerResult) => {
        if (!result.canceled) {
            const uri = result.assets[0].uri;
            const name = uri.substring(uri.lastIndexOf('/') + 1);
            addToAttachments({ uri, name });
        }
    };

    const handleRequestCameraPermission = async () => {
        let { status } = await ImagePicker.getCameraPermissionsAsync();

        if (status === 'undetermined') {
            const permission = await ImagePicker.requestCameraPermissionsAsync();
            status = permission.status;
            track({ type: 'permission-camera', action: status === 'granted' ? 'granted' : 'denied' });
        }
        return status === 'granted';
    };

    const handleRequestMediaLibraryPermission = async () => {
        let { status } = await ImagePicker.getMediaLibraryPermissionsAsync();

        if (status === 'undetermined') {
            const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();
            status = permission.status;

            track({ type: 'permission-media-library', action: status === 'granted' ? 'granted' : 'denied' });
        }

        return status === 'granted';
    };

    const onImage = async () => {
        if (await handleRequestMediaLibraryPermission()) {
            ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All
            }).then(imageSuccess, uploadError);
        }
    };

    const onCamera = async () => {
        if (await handleRequestCameraPermission()) {
            ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images
            }).then(imageSuccess, uploadError);
        }
    };

    const onVideoCamera = async () => {
        if (await handleRequestCameraPermission()) {
            ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Videos
            }).then(imageSuccess, uploadError);
        }
    };

    const result = {
        attachments,
        removeAttachment,
        clearAttachments,
        onVideoCamera,
        onCamera,
        onImage,
        onDocument
    };

    return result;
};
