import { useNavigation } from '@react-navigation/native';
import { Box, useBreakpointValue } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { OvalButton } from '~/components/button';
import { Color } from '~/components/color';
import { CalendarIcon, FiltersIcon } from '~/components/icon';
import { InputSelectDate } from '~/components/input';
import { SegmentedControl } from '~/components/segmented-control';
import { Spacing } from '~/components/spacing';
import { AppointmentsSelection, AppointmentTimeOfDayValues } from '~/contexts/appointments-selection';
import { AppMessageId, useIntl } from '~/contexts/intl';
import { useBreakpoints } from '~/hooks/breakpoints';
import { AppointmentDay } from '~/types';
import { isNative } from '~/utils';
import { toISODateString } from '~/utils/date';

import { AppointmentsContentContextDescription } from './appointments-content-context-description';
import { AppointmentsNavigationProp } from './appointments.web';

const AppointmentsContentHeaderContainer = styled.View`
    padding: 0px ${Spacing.MEDIUM}px;
    border-bottom-width: 1px;
    border-color: ${Color.LINE_DEFAULT};
`;

const AppointmentsFiltersContainer = styled.View`
    flex-direction: row;
    min-height: 104px;
`;

const AppointmentsContentHeaderItems = styled.View`
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`;
const AppointmentsContentHeaderActions = styled.View`
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
`;

const AppointmentDaySelectorContainer = styled.View`
    padding-top: 24px;
    margin-horizontal: -${Spacing.MEDIUM}px;
`;

export type AppointmentContentProps = {
    selection: AppointmentsSelection;
    loading: boolean;
    appointmentDays?: AppointmentDay[];
    onFetchMoreAppointmentDays?: () => void;
};

export const AppointmentsContentHeader: React.FC<AppointmentContentProps> = props => {
    const { selection, loading, appointmentDays, onFetchMoreAppointmentDays } = props;
    const {
        calendarReservationAccessId,
        region,
        location,
        appointmentDaysRange,
        date,
        setDate,
        timeOfDay,
        setTimeOfDay,
        filters
    } = selection;
    const { navigate } = useNavigation<AppointmentsNavigationProp>();
    const { formatMessage } = useIntl();
    const { mobile } = useBreakpoints();
    const isDesktop: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const handleOpenFilters = () => {
        navigate('appointments-filters', {
            calendarReservationAccessId,
            filters,
            remote: region === undefined
        });
    };

    const handleOpenCalendar = () => {
        navigate('appointments-calendar', {
            calendarReservationAccessId,
            appointmentDaysRange,
            date,
            regionId: region?.id,
            locationId: location?.id,
            timeOfDay,
            filters
        });
    };

    return (
        <AppointmentsContentHeaderContainer style={{ borderBottomWidth: !isDesktop ? 0 : 1 }}>
            <AppointmentsFiltersContainer>
                <AppointmentsContentHeaderItems>
                    <AppointmentsContentContextDescription />
                    <Box pb={mobile ? 6 : 0}>
                        <SegmentedControl
                            values={AppointmentTimeOfDayValues.map(f =>
                                formatMessage(`appointments.${f}` as AppMessageId)
                            )}
                            selectedIndex={AppointmentTimeOfDayValues.findIndex(i => i === timeOfDay)}
                            onSetSelectedIndex={index => {
                                setTimeOfDay(AppointmentTimeOfDayValues[index]);
                            }}
                        />
                    </Box>
                </AppointmentsContentHeaderItems>
                {isNative() && (
                    <AppointmentsContentHeaderActions>
                        <OvalButton
                            size="small"
                            icon={FiltersIcon}
                            disabled={!appointmentDays}
                            disabledStateRendered={false}
                            onPress={handleOpenFilters}
                        />
                        <OvalButton
                            size="small"
                            icon={CalendarIcon}
                            disabled={!appointmentDays}
                            disabledStateRendered={false}
                            onPress={handleOpenCalendar}
                        />
                    </AppointmentsContentHeaderActions>
                )}
            </AppointmentsFiltersContainer>
            {isNative() && (
                <AppointmentDaySelectorContainer>
                    {!loading && appointmentDays?.length ? (
                        <View style={{ marginBottom: 24 }}>
                            <InputSelectDate
                                scrollableOptionCount={1}
                                options={
                                    appointmentDays?.map(appointmentDay => toISODateString(appointmentDay.date)) ?? []
                                }
                                selectedOption={date}
                                onSelectOption={setDate}
                                onEndReached={onFetchMoreAppointmentDays}
                            />
                        </View>
                    ) : null}
                </AppointmentDaySelectorContainer>
            )}
        </AppointmentsContentHeaderContainer>
    );
};
