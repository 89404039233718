import { useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react';
import { Linking } from 'react-native';

import { useLinking } from '~/contexts/linking';
import { useInteractionTrigger } from '~/hooks/interaction-trigger';

export const useRichLink = () => {
    const { getInteractionHandlesFromURL, triggerInteraction } = useInteractionTrigger();
    const { options } = useLinking();
    const navigateTo = useLinkTo();

    const getValidUrl = useCallback((link: string) => {
        let url;
        try {
            // this works if there is valid protocol defined, can be custom one or just http/https
            url = new URL(link);
        } catch (_) {
            // new URL will fail if protocol is not defined with link.
            // Thus, if we get here, lets try adding protocol
            url = new URL(link.startsWith('http') ? link : 'https://' + link);
        }
        return url;
    }, []);

    return useCallback(
        async (linkUrl: string) => {
            const handles = getInteractionHandlesFromURL(linkUrl);

            if (handles) {
                const [handle, actionHandle] = handles;

                await triggerInteraction(handle, actionHandle);
            } else {
                const url = getValidUrl(linkUrl);

                if (options.prefixes.includes(`${url.protocol}//${url.host}`)) {
                    navigateTo(url.pathname);
                } else {
                    await Linking.openURL(url.toString());
                }
            }
        },
        [getInteractionHandlesFromURL, getValidUrl, navigateTo, options.prefixes, triggerInteraction]
    );
};
