import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Circle, Path, Svg } from 'react-native-svg';

import { Color } from '~/components/color';
import { Maybe } from '~/types';

const sizes = {
    small: { width: 16, height: 16 },
    medium: { width: 24, height: 24 }
};

export type LogoProviderProps = {
    provider?: Maybe<string>;
    disabled?: boolean;
    size?: keyof typeof sizes;
    style?: StyleProp<ViewStyle>;
};

const LogoProviderHeltti = ({ size = 'small', style, disabled }: LogoProviderProps) => {
    return (
        <View style={[{ ...sizes[size] }, style]}>
            <Svg width="100%" height="100%" viewBox="0 0 16 16" fill="none">
                <Path
                    d="M4.34811 0.0989563C3.24203 0.116984 2.36087 1.00487 2.38174 2.07755C2.38406 2.23755 2.40724 2.39529 2.44898 2.54403C2.29362 2.51022 2.13362 2.4922 1.96666 2.49445C0.862896 2.51473 -0.0182631 3.40036 0.000287613 4.47529C0.0165195 5.3767 0.658838 6.12262 1.51913 6.32994C1.80666 6.41783 2.1313 6.45614 2.50464 6.44938C3.06116 6.44036 5.74869 6.4336 5.96203 6.4291C6.22637 6.42459 6.33072 6.39529 6.43507 6.28938C6.53246 6.19022 6.56724 6.08656 6.56261 5.82741C6.56029 5.72149 6.55101 3.03529 6.54174 2.51022C6.53014 1.85445 6.33072 1.26403 5.94116 0.822337C5.56551 0.371632 4.99043 0.0876887 4.34811 0.0989563Z"
                    fill={disabled ? Color.ICON_DISABLED : '#E74011'}
                />
                <Path
                    d="M11.668 0.0989563C12.7741 0.116984 13.6553 1.00487 13.6344 2.07755C13.6321 2.23755 13.6089 2.39529 13.5672 2.54403C13.7225 2.51022 13.8825 2.4922 14.0495 2.49445C15.1556 2.51248 16.0367 3.40036 16.0158 4.47304C15.9996 5.37445 15.3573 6.12037 14.497 6.32769C14.2095 6.41558 13.8848 6.45389 13.5115 6.44713C12.955 6.43811 10.2674 6.43135 10.0541 6.42684C9.78976 6.42234 9.68541 6.39304 9.58107 6.28713C9.48367 6.18797 9.44889 6.08431 9.45353 5.82515C9.45585 5.71924 9.46512 3.03304 9.4744 2.50797C9.48599 1.8522 9.68541 1.26177 10.075 0.820083C10.4506 0.371632 11.0257 0.0876887 11.668 0.0989563Z"
                    fill={disabled ? Color.ICON_DISABLED : '#E74011'}
                />
                <Path
                    d="M4.34811 15.9728C3.24203 15.9547 2.36087 15.0668 2.38174 13.9942C2.38406 13.8342 2.40724 13.6764 2.44898 13.5277C2.29362 13.5615 2.13362 13.5795 1.96666 13.5773C0.862896 13.557 -0.0182631 12.6714 0.000287613 11.5964C0.0165195 10.695 0.658838 9.9491 1.51913 9.74178C1.80666 9.65389 2.1313 9.61558 2.50464 9.62234C3.06116 9.63135 5.74869 9.63812 5.96203 9.64262C6.22637 9.64713 6.33072 9.67643 6.43507 9.78234C6.53246 9.8815 6.56724 9.98516 6.56261 10.2443C6.56029 10.3502 6.55101 13.0364 6.54174 13.5615C6.53014 14.2173 6.33072 14.8077 5.94116 15.2494C5.56551 15.7001 4.99043 15.984 4.34811 15.9728Z"
                    fill={disabled ? Color.ICON_DISABLED : '#E74011'}
                />
                <Path
                    d="M11.668 15.973C12.7741 15.9549 13.6553 15.067 13.6344 13.9944C13.6321 13.8344 13.6089 13.6766 13.5672 13.5279C13.7225 13.5617 13.8825 13.5797 14.0495 13.5775C15.1556 13.5594 16.0367 12.6715 16.0158 11.5989C15.9996 10.6975 15.3573 9.95154 14.497 9.74422C14.2095 9.65633 13.8848 9.61802 13.5115 9.62478C12.955 9.6338 10.2674 9.64056 10.0541 9.64506C9.78976 9.64957 9.68541 9.67887 9.58107 9.78478C9.48367 9.88394 9.44889 9.9876 9.45353 10.2468C9.45585 10.3527 9.46512 13.0389 9.4744 13.5639C9.48599 14.2197 9.68541 14.8101 10.075 15.2518C10.4506 15.7003 11.0257 15.9842 11.668 15.973Z"
                    fill={disabled ? Color.ICON_DISABLED : '#E74011'}
                />
            </Svg>
        </View>
    );
};

const LogoProviderAava = ({ size = 'small', style, disabled }: LogoProviderProps) => {
    return (
        <View style={[{ ...sizes[size] }, style]}>
            <Svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                <Circle cx="11.6855" cy="4.90961" r="3.77143" fill={disabled ? Color.ICON_DISABLED : '#8ED8F8'} />
                <Circle cx="4.77143" cy="11.8237" r="3.77143" fill={disabled ? Color.ICON_DISABLED : '#C12E91'} />
                <Circle cx="18.914" cy="11.8237" r="3.77143" fill={disabled ? Color.ICON_DISABLED : '#1C9AD6'} />
                <Circle cx="11.6855" cy="19.3666" r="3.77143" fill={disabled ? Color.ICON_DISABLED : '#9FC93B'} />
            </Svg>
        </View>
    );
};

export function LogoProvider(props: LogoProviderProps) {
    switch (props.provider) {
        case 'HELTTI':
            return <LogoProviderHeltti {...props} />;
        case 'AAVA':
            return <LogoProviderAava {...props} />;
    }
    return null;
}
