import { isBefore, subMonths } from 'date-fns';
import { useMemo } from 'react';

import { useAuth } from '~/contexts/auth';
import { usePushNotifications } from '~/contexts/push-notifications';
import { useUserProfile } from '~/contexts/user-profile';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';
import { findNextItem } from '~/utils/array';
import { isNative } from '~/utils/platform';

type OnboardingTopic = keyof Omit<OnboardingNavigatorParamList, 'profile-edit'>;

export const useOnboardingTopics = () => {
    const { biometric, pin } = useAuth();
    const { status } = usePushNotifications();
    const { termsAccepted, userProfileReviewed } = useUserProfile();

    const onboardingTopics = useMemo(() => {
        const topics: OnboardingTopic[] = [];

        if (!pin.enabled && pin.available) {
            topics.push('pin-setup-intro');
        }

        if (biometric.available === true && biometric.enabled === null) {
            topics.push('biometric-auth');
        }

        if (status !== 'granted' && isNative()) {
            topics.push('push-notifications');
        }

        if (!termsAccepted) {
            topics.push('communication-permissions');
        }

        if (userProfileReviewed === null || isBefore(userProfileReviewed, subMonths(new Date(), 6))) {
            topics.push('profile-check');
        }

        return topics;
    }, [
        biometric.available,
        biometric.enabled,
        pin.available,
        pin.enabled,
        status,
        termsAccepted,
        userProfileReviewed
    ]);

    return {
        topics: onboardingTopics,
        nextTopic: (current: OnboardingTopic) => findNextItem(onboardingTopics ?? [], current) ?? 'completed'
    };
};
