import { useMemo } from 'react';

import { useLoadingQuery } from '~/hooks/loading-query';
import { ChatDraftQueryDocument } from '~/types';

export const useChatMessageDraft = (chatId: ID) => {
    const { data, loadingInitial } = useLoadingQuery(ChatDraftQueryDocument, {
        variables: { chatId }
    });

    const draftMessage = data?.root?.chat?.draftMessage ?? '';

    return useMemo(
        () => ({
            loading: loadingInitial,
            draftMessage
        }),
        [loadingInitial, draftMessage]
    );
};
