import { encode, decode } from 'base-64';

export const idToGlobalId = (id: ID): GlobalID => {
    const match = decode(id).match(
        /(.*Node):(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b|[0-9]+)/
    );
    if (!match) {
        throw new Error(`Invalid id ${decode(id)}`);
    }
    const [, type, uuidOrNumericId] = match;

    return [/^-?\d+$/.test(uuidOrNumericId) ? Number.parseInt(uuidOrNumericId, 10) : uuidOrNumericId, type];
};

export const globalIdToID = (globalId: GlobalID): ID => encode(`${globalId[1]}:${globalId[0]}`);
