/**
 * See https://emailregex.com/
 */
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

/**
 * Validate email with regex
 *
 * @param email:        The email predicate
 * @param allowEmpty:   Whether null or empty string should be considered a valid email
 */
export const isEmailValid = (email: string | null, allowEmpty: boolean = false): boolean => {
    if (!email?.length) {
        return allowEmpty;
    }

    return email.match(EMAIL_REGEX) !== null;
};
