import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { ErrorModal } from '~/components/error';
import { Text } from '~/components/text';
import { AuthActionResult, useAuth } from '~/contexts/auth';

import { EIdentVerificationState } from './e-ident-types';

export function EIdentWeb() {
    const { remote } = useAuth();
    const [verificationState, setVerificationState] = useState<EIdentVerificationState>(
        EIdentVerificationState.INITIALISING
    );
    // TODO
    useEffect(() => {
        (async () => {
            const url = await remote.eidentInit();
            if (url) {
                setVerificationState(EIdentVerificationState.READY);
                window.location.href = url;
            } else {
                setVerificationState(EIdentVerificationState.SERVICE_NOT_AVAILABLE);
            }
        })();
    }, [remote]);

    return (
        <>
            {verificationState === EIdentVerificationState.INITIALISING && <ActivityIndicator />}
            {verificationState === EIdentVerificationState.SERVICE_NOT_AVAILABLE && <Text.H2>Not available</Text.H2>}
        </>
    );
}

type Props = {
    onVerificationCompleted: (result: AuthActionResult) => void;
};

export function EIdentWebVerify(props: Props) {
    const { onVerificationCompleted } = props;
    const { remote } = useAuth();

    // TODO
    useEffect(() => {
        (async () => {
            const url = window.location.href;
            const urlParams = url.slice(url.indexOf('?') + 1);

            const result = await remote.eidentVerify(urlParams);
            onVerificationCompleted(result);
        })();
    }, [onVerificationCompleted, remote]);

    return (
        <>
            <ActivityIndicator />
            <Text.P1>E-ident verification in progress!</Text.P1>
        </>
    );
}

type EIdentWebErrorProps = {
    onRetry: () => void;
};

export const EIdentWebError: React.FC<EIdentWebErrorProps> = ({ onRetry }) => {
    return <ErrorModal errorType="auth" onRetry={onRetry} />;
};
