import React from 'react';
import { SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { Markdown } from '~/components/markdown';
import { useIntl } from '~/contexts/intl';
import { MemberActionType } from '~/types';

import { Button } from '../button';
import { Color } from '../color';
import { Shadow } from '../shadow';
import { Text } from '../text';

export type CallToActionProps = {
    type: MemberActionType;
    description: string;
    descriptionShort: string;
    title: string;
    onPress: () => void;
    kicker?: string;
    image?: React.ComponentType<SvgProps>;
};

export function CallToAction(props: CallToActionProps) {
    const { title, type, description, onPress } = props;
    const { formatMessage } = useIntl();

    return (
        <Container style={Shadow.styles.primary}>
            <Text.HEADER_3 style={{ color: Color.HEADER }}>{title}</Text.HEADER_3>
            <Markdown contentContainerStyle={{ flexGrow: 1 }}>{description}</Markdown>
            <Button
                label={formatMessage('new-contact.action.type', { type })}
                type="primary"
                shape="rounded"
                size="regular"
                onPress={onPress}
            />
        </Container>
    );
}

const Container = styled.View`
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
    flex-grow: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;
