import React from 'react';

import { FormCheckGroupInput, FormNumericTextInput, FormRadioGroupInput, FormTextInput } from '~/components/form';
import { Slider } from '~/components/slider';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { QuestionResponse, QuestionnaireQuestionWithResponse } from '~/hooks/questionnaire-response';
import { assertNever } from '~/utils';

export type QuestionnaireQuestionProps = {
    onChange: (questionId: ID, responseValue: QuestionResponse) => void;
} & QuestionnaireQuestionWithResponse;

export const QuestionnaireQuestion: React.FC<QuestionnaireQuestionProps> = props => {
    const { question, questionType, responseValue, onChange } = props;

    const onResponseChange = <T extends string | number | number[]>(newValue: T): T => {
        onChange(question.id, typeof newValue === 'number' ? [newValue] : newValue);

        return newValue;
    };

    const renderQuestionInput = () => {
        switch (questionType) {
            case 'RADIO':
                return (
                    <FormRadioGroupInput
                        initialValue={responseValue}
                        options={question.options!.map(option => ({ option: option.value, name: option.title }))}
                        onSetValue={onResponseChange}
                        caption={question.description ?? undefined}
                    />
                );
            case 'SELECT':
                return (
                    <FormCheckGroupInput
                        initialValue={responseValue}
                        options={question.options!.map(option => ({ option: option.value, name: option.title }))}
                        onSetValue={onResponseChange}
                        caption={question.description ?? undefined}
                    />
                );
            case 'TEXT_SHORT':
                return (
                    <FormTextInput
                        initialValue={responseValue}
                        onSetValue={onResponseChange}
                        caption={question.description ?? undefined}
                    />
                );
            case 'INT':
                return (
                    <FormNumericTextInput
                        initialValue={responseValue}
                        onSetValue={onResponseChange}
                        caption={question.description ?? undefined}
                    />
                );
            case 'TEXT_LONG':
                return (
                    <FormTextInput
                        initialValue={responseValue}
                        onSetValue={onResponseChange}
                        caption={question.description ?? undefined}
                        multiline
                    />
                );
            case 'SLIDER': {
                const options = question.options;
                const { value: leftValue, title: leftTitle } = options![0];
                const { value: rightValue, title: rightTitle } = options![options!.length - 1]!;

                return (
                    <Slider
                        minValue={leftValue}
                        maxValue={rightValue}
                        initialValue={responseValue ?? Math.ceil((rightValue - leftValue) / 2)}
                        minValueDescription={leftTitle}
                        maxValueDescription={rightTitle}
                        onChange={onResponseChange}
                    />
                );
            }
            default:
                return assertNever(questionType);
        }
    };

    return (
        <>
            <Text.SUBTITLE_1 before={Spacing.MEDIUM} after={Spacing.MEDIUM}>
                {question.title}
            </Text.SUBTITLE_1>

            {renderQuestionInput()}
        </>
    );
};
