// There is a bug in react navigator that it doesn't properly decode url parameters after refreshing
// page. https://github.com/react-navigation/react-navigation/issues/11506 Until the bug is fixed,
// this function can be used to fix the issue.
export function decodeURLParam(param: string): string;
export function decodeURLParam(param: string | undefined): string | undefined;
export function decodeURLParam(param: string | undefined): string | undefined {
    if (param === undefined) {
        return undefined;
    }
    if (param === 'undefined') {
        return undefined;
    }
    return decodeURIComponent(param);
}
