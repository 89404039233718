import React from 'react';
import { View } from 'react-native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type NotificationProps = {
    message: string;
};

export function NotificationSystemMessage(props: NotificationProps) {
    return (
        <View
            style={{
                paddingHorizontal: 16,
                paddingVertical: 10,
                borderRadius: 16,
                backgroundColor: Color.BACKGROUND_PRIMARY
            }}
        >
            <Text.CAPTION style={{ color: Color.TEXT_SECONDARY }}>{props.message}</Text.CAPTION>
        </View>
    );
}
