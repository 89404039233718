import { Platform, StyleSheet } from 'react-native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

// eslint-disable-next-line import/no-default-export
export default StyleSheet.create({
    placeholder: {
        borderWidth: 0.5,
        borderColor: Color.LINE_DEFAULT
    },
    error: {
        alignSelf: 'stretch',
        padding: 16,
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export const standard = StyleSheet.create({
    button: {
        marginVertical: 12
    },
    video: {
        marginVertical: 16,
        borderRadius: 8,
        overflow: 'hidden'
    },
    body: {
        ...Text.PARAGRAPH_1_STYLES,
        alignSelf: 'stretch',
        flexShrink: 1
    },
    paragraph: {
        marginVertical: 8
    },
    hr: {
        backgroundColor: Color.LINE_DEFAULT,
        height: 1,
        marginVertical: 8
    },
    blockquote: {
        borderColor: Color.BACKGROUND_TERTIARY,
        borderLeftWidth: 4,
        marginLeft: 0,
        borderRadius: 0,
        paddingLeft: 8,
        paddingHorizontal: 5
    },
    code_inline: {
        padding: 4,
        backgroundColor: Color.BACKGROUND_PRIMARY,
        ...Platform.select({
            ios: {
                fontFamily: 'Courier'
            },
            android: {
                fontFamily: 'monospace'
            },
            web: {
                fontFamily: 'monospace'
            }
        })
    },
    code_block: {
        fontSize: 14,
        borderWidth: 1,
        borderColor: Color.LINE_DEFAULT,
        borderRadius: 4,
        backgroundColor: Color.BACKGROUND_PRIMARY,
        marginVertical: 6,
        padding: 8,
        ...Platform.select({
            ios: {
                fontFamily: 'Courier'
            },
            android: {
                fontFamily: 'monospace'
            },
            web: {
                fontFamily: 'monospace'
            }
        })
    },
    fence: {
        fontSize: 14,
        borderWidth: 1,
        borderColor: Color.LINE_DEFAULT,
        borderRadius: 4,
        backgroundColor: Color.BACKGROUND_PRIMARY,
        marginVertical: 6,
        padding: 8,
        ...Platform.select({
            ios: {
                fontFamily: 'Courier'
            },
            android: {
                fontFamily: 'monospace'
            },
            web: {
                fontFamily: 'monospace'
            }
        })
    },
    strong: Text.PARAGRAPH_3_STYLES,
    heading1: { ...Text.HEADER_1_STYLES, marginTop: 16, marginBottom: 8 },
    heading2: { ...Text.HEADER_2_STYLES, marginTop: 16, marginBottom: 8 },
    heading3: { ...Text.HEADER_3_STYLES, marginTop: 16, marginBottom: 8 },
    heading4: { ...Text.HEADER_4_STYLES, marginTop: 16, marginBottom: 8 },
    heading5: { ...Text.HEADER_5_STYLES, marginTop: 16, marginBottom: 8 },
    heading6: { ...Text.HEADER_6_STYLES, marginTop: 16, marginBottom: 8 },
    table: {
        borderWidth: 1,
        borderColor: Color.LINE_DEFAULT,
        borderRadius: 4
    },
    thead: {
        backgroundColor: Color.BACKGROUND_PRIMARY
    },
    tbody: {},
    th: {
        flex: 1,
        padding: 8
    },
    tr: {
        borderBottomWidth: 1,
        borderColor: Color.LINE_DEFAULT,
        flexDirection: 'row'
    },
    td: {
        flex: 1,
        padding: 8
    },
    link: { textDecorationLine: 'underline' },
    list_item: {
        flexDirection: 'row',
        marginVertical: 0
    },
    ordered_list_icon: {
        marginHorizontal: 4,
        minWidth: 16
    },
    ordered_list_content: {
        flexShrink: 1
    },
    bullet_list_icon: {
        fontWeight: '800',
        lineHeight: 17, // Vertically aligns with text with this line height
        minWidth: 8,
        marginHorizontal: 8,
        marginVertical: 12
    },
    bullet_list_content: {
        flexShrink: 1
    }
});

export const compact = StyleSheet.create({
    body: {
        flexShrink: 1
    },
    paragraph: {
        ...Text.CAPTION_STYLES,
        marginVertical: 3
    },
    ordered_list_icon: {
        ...Text.CAPTION_STYLES,
        marginHorizontal: 4,
        marginVertical: 3
    },
    bullet_list_icon: {
        ...Text.CAPTION_STYLES,
        minWidth: 18,
        marginHorizontal: 4,
        marginVertical: 3
    }
});

export const lead = StyleSheet.create({
    paragraph: {
        ...Text.LEAD_2_STYLES,
        marginVertical: 10
    },
    ordered_list_icon: {
        ...Text.LEAD_2_STYLES,
        marginVertical: 10
    },
    bullet_list_icon: {
        ...Text.LEAD_2_STYLES,
        marginVertical: 10
    }
});
