import { Flex } from 'native-base';
import React, { PropsWithChildren, useCallback } from 'react';
import SelectDropdown from 'react-native-select-dropdown';

import { Text } from '~/components/text';

import { Color } from '../color';
import { ChevronDownIcon } from '../icon/chevron-down';
import { StyleProp, ViewStyle } from 'react-native';

type DefaultFormSelectRowProps = {
    firstRow: boolean;
} & PropsWithChildren;

export const DefaultFormSelectRow: React.FC<DefaultFormSelectRowProps> = props => {
    const { children, firstRow } = props;

    const borderStyle = {
        borderTopWidth: 1,
        borderColor: Color.LINE_DEFAULT
    };

    return (
        <Flex
            direction="row"
            flexGrow={1}
            pt="16px"
            pb="16px"
            ml="16px"
            textAlign="left"
            style={firstRow ? {} : borderStyle}
        >
            {children}
        </Flex>
    );
};

type FormSelectProps = {
    disabled?: boolean;
    defaultValue?: string;
    options: string[];
    onSelect?: (item: string) => void;
    rowRenderer?: (value: string, index: number) => React.ReactNode;
};

export const FormSelect: React.FC<FormSelectProps> = props => {
    const { defaultValue, disabled, options, onSelect, rowRenderer } = props;

    const onSelectHandler = useCallback(
        (selectedItem: (typeof options)[0], index: number) => {
            if (onSelect) {
                onSelect(selectedItem);
            }
        },
        [onSelect]
    );

    const focused = false;

    let borderColor;

    if (disabled) {
        borderColor = Color.LINE_DISABLED;
    } else if (focused) {
        borderColor = Color.FOCUS_DEFAULT;
    } else {
        borderColor = Color.LINE_DEFAULT;
    }

    const selectDropDownMenuButtonStyle: StyleProp<ViewStyle> = {
        backgroundColor: `${Color.BACKGROUND_DEFAULT}`,
        borderColor,
        borderRadius: 8,
        borderWidth: 2,
        width: '100%'
    };

    const selectDropdownMenuRowStyle: StyleProp<ViewStyle> = {
        backgroundColor: '#fff',
        borderBottomWidth: 0
    };

    const defaultButtonRenderer = (selectedItem: string, index: number): React.ReactNode => {
        return <Text.BUTTON_LABEL>{selectedItem}</Text.BUTTON_LABEL>;
    };

    const defaultRowRenderer = (value: string, index: number): React.ReactNode => {
        return (
            <DefaultFormSelectRow firstRow={index === 0}>
                <Text.BUTTON_LABEL>{value}</Text.BUTTON_LABEL>
            </DefaultFormSelectRow>
        );
    };

    return (
        <SelectDropdown
            data={options}
            defaultValue={defaultValue}
            disabled={disabled}
            dropdownOverlayColor="transparent"
            buttonStyle={selectDropDownMenuButtonStyle}
            rowStyle={selectDropdownMenuRowStyle}
            renderCustomizedButtonChild={defaultButtonRenderer}
            renderCustomizedRowChild={rowRenderer ?? defaultRowRenderer}
            renderDropdownIcon={() => {
                return <ChevronDownIcon />;
            }}
            onSelect={onSelectHandler}
            buttonTextAfterSelection={(_selectedOption, index) => {
                // text represented after item is selected
                // if data array is an array of objects then return selectedItem.property to render after item is selected
                return _selectedOption;
            }}
            rowTextForSelection={(option, index) => {
                // text represented for each item in dropdown
                // if data array is an array of objects then return item.property to represent item in dropdown
                return option;
            }}
        />
    );
};
