import React from 'react';
import { Ellipse, Mask } from 'react-native-svg';

import { CenteredEllipticMaskProps } from './centered-elliptic-mask';

export const CenteredEllipticMask = (props: CenteredEllipticMaskProps) => {
    const { maskId } = props;

    return (
        <Mask id={maskId} maskContentUnits="objectBoundingBox">
            <Ellipse cx={0.708} cy={0} rx={0.477} ry={0.516} rotation={45} fill="#ffffff" />
        </Mask>
    );
};
