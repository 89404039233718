import React from 'react';
import { View } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export function TabHomeIcon({ fill, size = 32 }: IconProps) {
    return (
        <View style={{ width: size, height: size }}>
            <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.9788 2.46694C16.4153 1.9797 15.5797 1.97978 15.0163 2.46712L2.19251 13.5603C2.13633 13.6089 2.11706 13.6528 2.10904 13.6881C2.09959 13.7297 2.10155 13.7823 2.1218 13.8366C2.14205 13.891 2.17497 13.932 2.20934 13.9573C2.2385 13.9788 2.28179 13.9994 2.35607 13.9994H4.00042C4.26567 13.9994 4.52005 14.1047 4.70759 14.2923C4.89514 14.4799 5.00047 14.7343 5.00042 14.9995L4.99793 27.9958C4.99772 29.1006 5.89321 29.9962 6.99793 29.9962H24.9979C26.1024 29.9962 26.9977 29.101 26.9979 27.9966L27.0004 14.9992C27.0005 14.447 27.4482 13.9994 28.0004 13.9994H29.6439C29.7181 13.9994 29.7614 13.9788 29.7906 13.9573C29.825 13.932 29.8579 13.891 29.8781 13.8366C29.8984 13.7822 29.9003 13.7296 29.8909 13.688C29.8829 13.6527 29.8636 13.6089 29.8074 13.5603L16.9788 2.46694ZM13.7078 0.954528C15.0224 -0.182604 16.9722 -0.182778 18.287 0.95412L31.1156 12.0474C32.692 13.4106 31.7279 15.9994 29.6439 15.9994H29.0002L28.9979 27.997C28.9975 30.2058 27.2068 31.9962 24.9979 31.9962H6.99793C4.78849 31.9962 2.99751 30.2049 2.99793 27.9955L3.00023 15.9994H2.35607C0.272208 15.9994 -0.691963 13.411 0.884055 12.0477L13.7078 0.954528Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 22.6629C22 23.9516 20.9553 24.9962 19.6666 24.9962H13.5C11.0147 24.9962 8.99998 22.9815 8.99998 20.4962C8.99998 18.1715 10.7629 16.2584 13.0248 16.021C13.2622 13.7591 15.1752 11.9962 17.5 11.9962C19.9853 11.9962 22 14.0109 22 16.4962V22.6629ZM19.6666 22.9962C19.8507 22.9962 20 22.847 20 22.6629V16.4962C20 15.1155 18.8807 13.9962 17.5 13.9962C16.1193 13.9962 15 15.1155 15 16.4962V17.9962H13.5C12.1193 17.9962 11 19.1155 11 20.4962C11 21.8769 12.1193 22.9962 13.5 22.9962H19.6666Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
            </Svg>
        </View>
    );
}
