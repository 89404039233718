export type KeyValue<T> = {
    key: string;
    value: T;
};

export function valueForKey<T>(values: readonly KeyValue<T>[] | null, key: string): T | null {
    if (values) {
        const i = values?.findIndex(({ key: currKey, value }) => currKey === key);
        if (i >= 0) {
            return values?.[i].value;
        }
    }
    return null;
}
