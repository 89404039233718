import React from 'react';
import { View } from 'react-native';

import { Spacing } from '~/components/spacing';

import { TextButton } from '../button/text-button';

export type HeaderDownloadReferralButtonProps = {
    label: string;
    onPress: () => void;
    isWeb: boolean;
};

export function HeaderDownloadReferralButton(props: Readonly<HeaderDownloadReferralButtonProps>) {
    const { label, onPress, isWeb } = props;

    const buttonStyle = isWeb
        ? { paddingRight: Spacing.GINORMOUS, paddingTop: Spacing.SMALL }
        : { paddingRight: Spacing.GIGANTIC, paddingBottom: Spacing.MEDIUM };

    return (
        <View style={buttonStyle}>
            <TextButton label={label} onPress={onPress} />
        </View>
    );
}

HeaderDownloadReferralButton.displayName = 'HeaderDownloadReferralButton';
