import { useMemo } from 'react';

import { useLoadingQuery } from '~/hooks/loading-query';
import { CallToActionsQueryDocument, CtaContextType } from '~/types';

export const useCallToActions = (context: CtaContextType) => {
    const { data, error, loadingInitial, loading, refetch } = useLoadingQuery(CallToActionsQueryDocument, {
        variables: { context }
    });

    const callToActions = useMemo(() => data?.root?.callToActions ?? [], [data?.root?.callToActions]);

    return useMemo(
        () => ({
            callToActions,
            error,
            loadingInitial,
            loading,
            refetch
        }),
        [callToActions, error, loadingInitial, loading, refetch]
    );
};
