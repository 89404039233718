import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp } from '@react-navigation/native';
import React, { useCallback, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { TextInput } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { FormEmailInput, FormGroup, FormPhoneNumberInput, FormRadioGroupInput, FormTextInput } from '~/components/form';
import { ButtonForwardIcon } from '~/components/icon';
import { KeyboardShift } from '~/components/keyboard-shift';
import { EllipticHeaderScreenActions, ScreenContainer } from '~/components/screen';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { useAuth } from '~/contexts/auth';
import { AppLocale, useIntl } from '~/contexts/intl';
import { AppError } from '~/error';
import { useDynamicHeaderColor } from '~/hooks/dynamic-header-color/dynamic-header-color';
import { useRoute } from '~/hooks/route/route';
import { UnauthenticatedNavigatorParamList } from '~/navigator/app-navigator';
import { LanguageCode } from '~/types';
import { getTokenPayload } from '~/utils/access-token';

type AuthEmailSignupFormRouteProp = RouteProp<UnauthenticatedNavigatorParamList, 'email-signup-form'>;

const ContentContainer = styled.View`
    flex: 1;
    margin: 8px 16px 8px;
`;

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    language: AppLocale;
};

export function AuthEmailSignupForm() {
    const { remote } = useAuth();
    const { params } = useRoute<AuthEmailSignupFormRouteProp>();
    const { formatMessage, locale } = useIntl();
    const headerHeight = useHeaderHeight();
    const { bottom } = useSafeAreaInsets();
    const handleError = useErrorHandler();

    const firstNameRef = useRef<TextInput>(null);
    const lastNameRef = useRef<TextInput>(null);
    const phoneRef = useRef<TextInput>(null);

    const formValues = useRef<FormValues>({
        firstName: '',
        lastName: '',
        email: getTokenPayload(params.token).value,
        phone: '',
        language: locale
    });
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { onScroll } = useDynamicHeaderColor({
        range: 40,
        color: {
            from: Color.BACKGROUND_DEFAULT,
            to: Color.BACKGROUND_DEFAULT
        },
        title: formatMessage('auth-email-signup-form.title')
    });

    const handleValueChanged = useCallback(() => {
        const { firstName, lastName, phone } = formValues.current;

        if (firstName && lastName && phone) {
            if (!readyToSubmit) {
                setReadyToSubmit(true);
            }
        } else if (readyToSubmit) {
            setReadyToSubmit(false);
        }
    }, [readyToSubmit]);

    const onSubmit = useCallback(() => {
        if (!readyToSubmit || loading) {
            return;
        }

        setLoading(true);

        const { firstName, lastName, phone, language } = formValues.current;

        remote
            .signUpWithEmail(params.token, firstName, lastName, phone, language)
            .then(result => {
                if (result.success) {
                    result.commit();
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    handleError(
                        new AppError(error, 'auth-email-signup-form.error.description', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    handleError(error);
                }
            })
            .finally(() => setLoading(false));
    }, [handleError, loading, params.token, readyToSubmit, remote]);

    return (
        <ScreenContainer bottomInset={bottom}>
            <KeyboardShift headerHeight={headerHeight}>
                <ContentScrollView
                    bounces
                    showsVerticalScrollIndicator={false}
                    onScroll={onScroll}
                    scrollEventThrottle={16}
                >
                    <ContentContainer>
                        <FormGroup>
                            <FormTextInput
                                ref={firstNameRef}
                                caption={formatMessage('profile.first-name')}
                                initialValue={formValues.current.firstName}
                                textContentType="givenName"
                                autoComplete="name"
                                returnKeyType="next"
                                onSubmitEditing={() => lastNameRef.current?.focus()}
                                onSetValue={value => {
                                    formValues.current.firstName = value;
                                    handleValueChanged();
                                    return value;
                                }}
                            />
                            <FormTextInput
                                ref={lastNameRef}
                                caption={formatMessage('profile.last-name')}
                                initialValue={formValues.current.lastName}
                                textContentType="givenName"
                                autoComplete="name"
                                returnKeyType="next"
                                onSubmitEditing={() => phoneRef.current?.focus()}
                                onSetValue={value => {
                                    formValues.current.lastName = value;
                                    handleValueChanged();
                                    return value;
                                }}
                            />
                        </FormGroup>

                        <FormGroup caption={formatMessage('profile.contact-details')}>
                            <FormEmailInput
                                disabled
                                caption={formatMessage('profile.email')}
                                initialValue={formValues.current.email}
                                returnKeyType="next"
                                onSubmitEditing={() => phoneRef.current?.focus()}
                                onSetValue={value => {
                                    formValues.current.email = value;
                                    handleValueChanged();
                                    return value;
                                }}
                            />
                            <FormPhoneNumberInput
                                ref={phoneRef}
                                caption={formatMessage('profile.phone')}
                                initialValue={formValues.current.phone}
                                returnKeyType="done"
                                onSetValue={value => {
                                    formValues.current.phone = value;
                                    handleValueChanged();
                                    return value;
                                }}
                            />
                        </FormGroup>

                        <FormGroup caption={formatMessage('profile.language')}>
                            <FormRadioGroupInput<LanguageCode>
                                initialValue={formValues.current.language}
                                options={[
                                    { option: 'FI', name: formatMessage('profile.language.fi') },
                                    { option: 'EN', name: formatMessage('profile.language.en') }
                                ]}
                                onSetValue={value => {
                                    formValues.current.language = value;
                                    handleValueChanged();
                                    return value;
                                }}
                            />
                        </FormGroup>
                    </ContentContainer>
                </ContentScrollView>
            </KeyboardShift>

            <EllipticHeaderScreenActions
                topBorder
                actionOrActions={
                    <Button
                        fillContainer
                        alignCenter
                        type="primary"
                        onPress={onSubmit}
                        label={formatMessage('auth-email-signup-form.submit')}
                        rightIcon={ButtonForwardIcon}
                        disabled={!readyToSubmit || loading}
                        loading={loading}
                    />
                }
            />
        </ScreenContainer>
    );
}
