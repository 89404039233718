import React, { useCallback } from 'react';

import { Loader } from '~/components/loader';
import { useIntl } from '~/contexts/intl';
import { useRegions } from '~/hooks/regions';
import { Region } from '~/types';

import { FormSelect } from './form-select';

type RegionSelectInputProps = {
    initialRegion?: Region;
    onSelect: (region?: Region) => void;
};

export const RegionSelect: React.FC<RegionSelectInputProps> = props => {
    const { initialRegion, onSelect } = props;
    const { error, loading, regions } = useRegions();
    const { formatMessage } = useIntl();

    const onlyRemoteAppointments = formatMessage('appointments.select-region.remote-only');

    const onSelectCallback = useCallback(
        (regionName: string) => {
            if (onSelect) {
                if (regionName === onlyRemoteAppointments) {
                    onSelect();
                } else {
                    const region = regions?.find(_region => _region.name === regionName);

                    if (region) {
                        onSelect(region);
                    }
                }
            }
        },
        [onSelect, onlyRemoteAppointments, regions]
    );

    if (loading) {
        return <Loader size="small" />;
    }

    if (error) {
        throw new Error(error.message); // TODO: better error handling
    }

    const options = (regions ?? []).map((region: Region) => region.name);
    options.unshift(onlyRemoteAppointments);

    return (
        <FormSelect
            defaultValue={initialRegion?.name ?? onlyRemoteAppointments}
            options={options}
            onSelect={onSelectCallback}
        />
    );
};
