import { FieldMergeFunction, InMemoryCacheConfig } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { appointmentDaysTypePolicy } from '~/hooks/appointment-days';

const simpleMerge: FieldMergeFunction = (existing, incoming, { mergeObjects }) => {
    return mergeObjects(existing, incoming);
};

export const cacheConfig: InMemoryCacheConfig = {
    possibleTypes: {
        ChatAttachment: ['ChatMessageAttachmentNode', 'ChatMessageIdAttachmentNode', 'ChatMessageInteractionNode']
    },
    typePolicies: {
        Query: {
            fields: {
                root: {
                    merge: simpleMerge
                }
            }
        },
        ChatNode: {
            fields: {
                messages: relayStylePagination(['first']),
                messageInteractions: {
                    merge: false
                },
                notifications: {
                    merge: false
                }
            }
        },
        ChatMessageNode: {
            fields: {
                attachmentsV2: {
                    merge: false
                }
            }
        },
        // Temporary fix to 'missing field chat' error from apollo when receiving subscription confirmation
        // message until https://github.com/apollographql/apollo-client/issues/8677 is fixed.
        Subscription: {
            fields: {
                chat: {
                    read(chat) {
                        return chat ?? null;
                    }
                }
            }
        },
        CalendarReservationAccessNode: {
            merge: simpleMerge,
            fields: {
                appointmentDays: appointmentDaysTypePolicy
            }
        }
    }
};
