import { useLayout } from '@react-native-community/hooks';
import { Flex } from 'native-base';
import React from 'react';

import { Color } from '~/components/color';

import { EmbeddedYouTubeVideoProps } from './youtube-utils';

export const EmbeddedYouTubeVideo: React.FC<EmbeddedYouTubeVideoProps> = props => {
    const {
        attributes: { v }
    } = props;

    const { onLayout, width } = useLayout();

    return (
        <Flex
            borderWidth={1}
            borderColor={Color.LINE_DEFAULT}
            my={4}
            borderRadius={2}
            overflow="hidden"
            onLayout={onLayout}
        >
            <iframe
                height={(width / 16) * 9}
                src={`https://www.youtube.com/embed/${v}`}
                title={v}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Flex>
    );
};
