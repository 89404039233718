import { useDimensions } from '@react-native-community/hooks';
import * as Linking from 'expo-linking';
import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { Pressable } from 'react-native';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { TextButton } from '~/components/button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { EllipticMaskView } from '~/components/elliptic-mask/';
import { InfoIcon, ReloadIcon } from '~/components/icon';
import { ScreenContainer } from '~/components/screen';
import { Shadow } from '~/components/shadow';
import { FontFamily, Text } from '~/components/text';
import { useAnalyticsEventEffect } from '~/contexts/analytics';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { isWeb } from '~/utils/platform';

import { Spacing } from '../spacing';

import ConnectionErrorImage from './assets/connection-error.svg';
import GenericErrorImage from './assets/generic-error.svg';

export type ErrorType = 'connection' | 'generic' | 'unrecoverable' | 'auth';

const MobileHeader = styled.View<{
    deviceWidth: number;
    errorType: 'connection' | 'generic' | 'unrecoverable' | 'auth';
}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: ${({ errorType }) =>
        errorType === 'connection' ? Color.SUBMARINE : Color.BRUTAL_BRUISE_EXTRA_LIGHT};
    min-width: ${({ deviceWidth }) => deviceWidth}px;
`;

const WebHeader = styled.View<{ errorType: ErrorType }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: ${({ errorType }) =>
        errorType === 'connection' ? Color.SUBMARINE : Color.BRUTAL_BRUISE_EXTRA_LIGHT};
    width: 100%;
`;

const MobileUpperContent = styled.View`
    flex-grow: 3;
    padding: 0;
`;

const WebUpperContent = styled.View`
    flex-grow: 3;
    padding: 0;
    min-height: 300;
`;

const UpperContentWrapper = (props: PropsWithChildren) => {
    if (isWeb()) {
        return <WebUpperContent>{props.children}</WebUpperContent>;
    } else {
        return <MobileUpperContent>{props.children}</MobileUpperContent>;
    }
};

const LowerContent = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    flex-grow: 3;
`;

const MobileImageContent = styled.View<{
    deviceWidth?: number;
    errorType: ErrorType;
}>`
    align-items: center;
    background-color: ${({ errorType }) =>
        errorType === 'connection' ? Color.SUBMARINE : Color.BRUTAL_BRUISE_EXTRA_LIGHT};
    min-width: ${({ deviceWidth }) => deviceWidth}px;
    padding-bottom: 39px;
`;

const WebImageContent = styled.View<{
    headerIcon?: boolean;
    errorType: ErrorType;
}>`
    align-items: center;
    background-color: ${({ errorType }) =>
        errorType === 'connection' ? Color.SUBMARINE : Color.BRUTAL_BRUISE_EXTRA_LIGHT};
    width: 100%;
    padding-top: ${({ headerIcon }) => (headerIcon ? '39px' : 0)};
`;

const ImageContentWrapper = (
    props: PropsWithChildren & {
        deviceWidth?: number;
        headerIcon?: boolean;
        errorType: ErrorType;
    }
) => {
    if (isWeb()) {
        return (
            <WebImageContent errorType={props.errorType} headerIcon={props.headerIcon}>
                {props.children}
            </WebImageContent>
        );
    } else {
        return (
            <MobileImageContent deviceWidth={props.deviceWidth} errorType={props.errorType}>
                {props.children}
            </MobileImageContent>
        );
    }
};

const TextContent = styled.View`
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 28px;
    padding-horizontal: 16px;
`;

const ButtonContent = styled.View`
    align-content: center;
    margin-bottom: 21px;
    margin-horizontal: 70px;
`;

const ButtonContainer = styled.View<{ pressed: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 12px;
    font-family: ${FontFamily.SF_COMPACT_MEDIUM};
    font-size: 16px;
    color: ${Color.ALMOST_BLACK};
    background-color: ${({ pressed }) => (pressed ? Color.BASIC_PRESSED : Color.BACKGROUND_DEFAULT)};
    margin-bottom: 32px;
`;

const VerticalContentWrapper = styled.View`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const Content = styled.View`
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-width: 1px;
    border-color: ${Color.LINE_DEFAULT};
    border-radius: ${Spacing.MEDIUM};
    overflow: hidden;
    flex: 1;
`;

export type ErrorModalProps = {
    id?: UUID;
    title?: string;
    description?: string;
    error?: Error;
    errorType?: ErrorType;
    headerIcon?: ReactNode;
    onRetry?: () => void;
    onClose?: () => void;
    signOut?: () => void;
    retry?: string;
    close?: string;
};

const ContentWrapper = (props: PropsWithChildren) => {
    if (isWeb()) {
        return (
            <VerticalContentWrapper>
                <Content>{props.children}</Content>
            </VerticalContentWrapper>
        );
    } else {
        return <>{props.children}</>;
    }
};

export const ErrorModal: React.FC<ErrorModalProps> = props => {
    const {
        id,
        title,
        description,
        error,
        errorType = 'generic',
        headerIcon,
        signOut,
        onRetry,
        onClose,
        retry,
        close
    } = props;
    const { formatMessage } = useIntl();
    const { bottom, top } = useSafeAreaInsets();
    const { window } = useDimensions();

    const web = isWeb();

    useAnalyticsEventEffect(
        { type: 'error', action: 'open', detail1: `category=${errorType}`, detail2: `message=${error?.message}` },
        []
    );

    // This will handle safe area and use margin from designs
    const BottomMargin = bottom + 22;

    const handleOpenUrl = useCallback(async (url: string) => {
        if (await Linking.canOpenURL(url)) {
            await Linking.openURL(url);
        }
    }, []);

    const imageSize = web ? 300 : window.height * 0.4;

    return (
        <Modal
            isVisible
            hasBackdrop
            backdropColor={web ? Color.BACKGROUND_DEFAULT : Color.BACKGROUND_TERTIARY}
            backdropOpacity={1}
            animationIn="slideInUp"
            animationOut="slideOutDown"
            useNativeDriverForBackdrop={!web}
            coverScreen
            presentationStyle="overFullScreen"
            style={{ margin: 0 }}
        >
            <ContentHorizontalMargins contentWidth="narrow" includePadding={web}>
                <ContentWrapper>
                    <ScreenContainer>
                        <UpperContentWrapper>
                            <EllipticMaskView style={{ minHeight: imageSize }}>
                                {web ? (
                                    !!headerIcon && (
                                        <WebHeader errorType={errorType}>
                                            {headerIcon}
                                            <Text.HEADER_3 style={{ marginLeft: 16, marginTop: headerIcon ? 0 : 16 }}>
                                                {formatMessage(`error-modal.header.${errorType}`)}
                                            </Text.HEADER_3>
                                        </WebHeader>
                                    )
                                ) : (
                                    <MobileHeader
                                        style={{ paddingTop: top }}
                                        deviceWidth={window.width}
                                        errorType={errorType}
                                    >
                                        {headerIcon}
                                        <Text.HEADER_3 style={{ marginLeft: 16, marginTop: headerIcon ? 0 : 16 }}>
                                            {formatMessage(`error-modal.header.${errorType}`)}
                                        </Text.HEADER_3>
                                    </MobileHeader>
                                )}
                                <ImageContentWrapper
                                    deviceWidth={web ? undefined : window.width}
                                    errorType={errorType}
                                    headerIcon={!!headerIcon}
                                >
                                    {errorType === 'connection' ? (
                                        <ConnectionErrorImage height={imageSize} width={imageSize} />
                                    ) : (
                                        <GenericErrorImage height={imageSize} width={imageSize} />
                                    )}
                                </ImageContentWrapper>
                            </EllipticMaskView>
                        </UpperContentWrapper>

                        <LowerContent>
                            <TextContent>
                                <Text.HEADER_2 style={{ marginBottom: 16, textAlign: 'center' }}>
                                    {title ?? formatMessage(`error-modal.${errorType}.title`)}
                                </Text.HEADER_2>
                                <Text.PARAGRAPH_1 style={{ marginBottom: 24, textAlign: 'center' }}>
                                    {description ?? formatMessage(`error-modal.${errorType}.info`)}
                                </Text.PARAGRAPH_1>
                                {errorType === 'connection' ? (
                                    <Text.PARAGRAPH_1 style={{ textAlign: 'center' }}>
                                        {formatMessage('error-modal.connection.info2')}
                                    </Text.PARAGRAPH_1>
                                ) : id ? (
                                    <Text.PARAGRAPH_1>
                                        <FormattedMessage id="error-overlay.error_id" values={{ id }} />
                                    </Text.PARAGRAPH_1>
                                ) : null}
                            </TextContent>
                            <ButtonContent style={{ marginBottom: BottomMargin }}>
                                {onClose && (
                                    <Pressable accessibilityRole="button" accessibilityLabel="Button" onPress={onClose}>
                                        {({ pressed }) => {
                                            return (
                                                <ButtonContainer style={Shadow.styles.primary} pressed={pressed}>
                                                    <Text.BUTTON_LABEL>
                                                        {close ?? formatMessage('error-overlay.close')}
                                                    </Text.BUTTON_LABEL>
                                                </ButtonContainer>
                                            );
                                        }}
                                    </Pressable>
                                )}
                                {onRetry && (
                                    <Pressable accessibilityRole="button" accessibilityLabel="Button" onPress={onRetry}>
                                        {({ pressed }) => {
                                            return (
                                                <ButtonContainer style={Shadow.styles.primary} pressed={pressed}>
                                                    <ReloadIcon />
                                                    <Text.BUTTON_LABEL style={{ marginLeft: 8 }}>
                                                        {retry ?? formatMessage('error-modal.retry')}
                                                    </Text.BUTTON_LABEL>
                                                </ButtonContainer>
                                            );
                                        }}
                                    </Pressable>
                                )}
                                {signOut && (
                                    <Pressable accessibilityRole="button" accessibilityLabel="Button" onPress={signOut}>
                                        {({ pressed }) => {
                                            return (
                                                <ButtonContainer style={Shadow.styles.primary} pressed={pressed}>
                                                    <Text.BUTTON_LABEL>
                                                        {formatMessage('profile.sign-out')}
                                                    </Text.BUTTON_LABEL>
                                                </ButtonContainer>
                                            );
                                        }}
                                    </Pressable>
                                )}
                                <TextButton
                                    label={formatMessage('error-overlay.support', {
                                        link: (value: string) => (
                                            <Text.BUTTON_LABEL style={{ textDecorationLine: 'underline' }}>
                                                {value}
                                            </Text.BUTTON_LABEL>
                                        )
                                    })}
                                    leftIcon={InfoIcon}
                                    onPress={() => handleOpenUrl(formatMessage('error-overlay.support.url', { id }))}
                                />
                            </ButtonContent>
                        </LowerContent>
                    </ScreenContainer>
                </ContentWrapper>
            </ContentHorizontalMargins>
        </Modal>
    );
};
