import { Box } from 'native-base';
import React from 'react';
import styled from 'styled-components/native';

import { ChatAttachmentImage } from '~/components/chat-message';
import { Color } from '~/components/color';
import { CloseIcon } from '~/components/icon';
import { IconButton } from '~/components/icon-button';
import { ChatAttachmentIcon } from '~/components/icon/chat-attachment';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { isSupportedImageFormat, parseFileExtension } from '~/utils';

export type ChatInputAttachmentProps = {
    filename: string;
    url: string;
    onDelete?: () => void;
};

export function ChatInputAttachment(props: ChatInputAttachmentProps) {
    const { url, filename, onDelete } = props;

    return (
        <AttachmentContainer>
            {isSupportedImageFormat(filename) ? (
                <ChatAttachmentImage url={url} form="square" />
            ) : (
                <ChatInputFileAttachment filename={filename} />
            )}
            <Box position="absolute" right="-8px" top="-8px" borderRadius="16px" style={Shadow.styles.primary}>
                <IconButton icon={CloseIcon} onPress={onDelete} />
            </Box>
        </AttachmentContainer>
    );
}

type ChatInputFileAttachmentProps = {
    filename: string;
};

function ChatInputFileAttachment(props: ChatInputFileAttachmentProps) {
    const { filename } = props;
    const extension = parseFileExtension(filename);

    return (
        <FileAttachmentContainer>
            <ChatAttachmentIcon text={extension} size="small" />
            <Spacing.Horizontal.TINY />
            <Text.SMALL_BUTTON_LABEL numberOfLines={1} ellipsizeMode="middle" style={{ flexShrink: 1 }}>
                {filename}
            </Text.SMALL_BUTTON_LABEL>
            <Spacing.Horizontal.TINY />
        </FileAttachmentContainer>
    );
}

const FileAttachmentContainer = styled.View`
    flex-direction: row;
    align-items: center;
    padding: 0 ${Spacing.TINY}px;
    border: 1px ${Color.LINE_DEFAULT};
    border-radius: 8px;
`;

const AttachmentContainer = styled.View`
    margin-bottom: 2px;
    padding: 2px;
`;
