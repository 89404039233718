import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

export const FullScreenContainer = styled(SafeAreaView)`
    flex: 1;
    width: 100%;

    background-color: ${Color.BACKGROUND_DEFAULT};
`;
