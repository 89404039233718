import { useQuery } from '@apollo/client';
import { useMemo, useRef } from 'react';

import { AppointmentFilters, AppointmentsContextDescriptionDocument, Location, Region } from '~/types';

export function useAppointmentsContextDescription(
    calendarReservationAccessId: ID,
    filters: AppointmentFilters,
    region?: Region,
    location?: Location
) {
    const regionId = region?.id;
    const locationId = location?.id;
    const ref = useRef<string>();

    const { data, loading, error } = useQuery(AppointmentsContextDescriptionDocument, {
        variables: {
            calendarReservationAccessId,
            regionId,
            locationId,
            filters
        },
        fetchPolicy: 'cache-first'
    });

    const contextDescription = useMemo(() => {
        const description = data?.root?.calendarReservationAccess?.contextDescription;
        if (description === undefined && ref.current) {
            return ref.current;
        }
        ref.current = description ?? undefined;
        return description;
    }, [data]);

    return { contextDescription, loading, error };
}
