import { useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { AppointmentRemoteContactTypeValues } from '~/contexts/appointments-selection';
import { AppointmentsDocument, fragmentToAppointment, AppointmentFilters, AppointmentTimeOfDay } from '~/types';
import { toISODateString } from '~/utils/date';

export type AppointmentsHookParams = {
    calendarReservationAccessId: ID;
    date?: Date;
    regionId?: ID;
    locationId?: ID;
    timeOfDay?: AppointmentTimeOfDay;
    filters?: AppointmentFilters;
};

export function useAppointments(params: AppointmentsHookParams) {
    const { calendarReservationAccessId, date, regionId, locationId, timeOfDay, filters } = params;
    const { data, loading, error, refetch } = useQuery(AppointmentsDocument, {
        variables: {
            calendarReservationAccessId,
            start: toISODateString(date)!,
            end: toISODateString(date)!,
            regionId,
            locationId,
            timeOfDay,
            // Undefined region id implies "remote times" only
            filters: regionId ? filters : { ...filters, contactTypes: AppointmentRemoteContactTypeValues }
        },
        notifyOnNetworkStatusChange: true,
        skip: date === undefined
    });

    const appointments = data?.root?.calendarReservationAccess?.appointments?.map(fragmentToAppointment);

    const fetchAppointmentDay = useCallback(
        async function (nextDate: Date) {
            const nextDateString = toISODateString(nextDate);
            await refetch({
                calendarReservationAccessId,
                regionId,
                locationId,
                start: nextDateString,
                end: nextDateString
            });
        },
        [calendarReservationAccessId, refetch, regionId, locationId]
    );

    return {
        loading,
        error,
        appointments,
        fetchAppointmentDay
    };
}
