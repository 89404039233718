import PreventScreenshot from '@heltti/react-native-prevent-screenshot';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { fonts } from '~/components/text';
import { AnalyticsProvider } from '~/contexts/analytics';
import { AppConfigProvider } from '~/contexts/app-config';
import { AppUpdateProvider } from '~/contexts/app-update';
import { AuthProvider } from '~/contexts/auth';
import { AppIntlProvider } from '~/contexts/intl';
import { NetworkServiceStateProvider } from '~/contexts/network-service-state';
import { UserIdentityProvider } from '~/contexts/user-identity';
import { withErrorDiagnostics } from '~/error';
import { AppNavigator } from '~/navigator/app-navigator';
import { isNative } from '~/utils/platform';

import { nativeBaseTheme } from './native-base-theme';

SplashScreen.preventAutoHideAsync();

export const App = withErrorDiagnostics(() => {
    const [fontsLoaded] = useFonts(fonts);

    useEffect(() => {
        if (fontsLoaded) {
            setTimeout(SplashScreen.hideAsync, 200);
        }
    }, [fontsLoaded]);

    useEffect(() => {
        if (isNative()) {
            PreventScreenshot.enabled(true);
        }
    }, []);

    return !fontsLoaded ? null : (
        <GestureHandlerRootView style={StyleSheet.absoluteFill}>
            <StatusBar style="auto" />

            <NativeBaseProvider theme={nativeBaseTheme}>
                <AppConfigProvider>
                    <AppIntlProvider>
                        <UserIdentityProvider>
                            <AnalyticsProvider>
                                <AuthProvider>
                                    <NetworkServiceStateProvider>
                                        <AppUpdateProvider>
                                            <AppNavigator />
                                        </AppUpdateProvider>
                                    </NetworkServiceStateProvider>
                                </AuthProvider>
                            </AnalyticsProvider>
                        </UserIdentityProvider>
                    </AppIntlProvider>
                </AppConfigProvider>
            </NativeBaseProvider>
        </GestureHandlerRootView>
    );
});
