import React, { PropsWithChildren, ReactNode } from 'react';
import { Pressable, Text as NativeText, TextProps as NativeTextProps, ViewProps } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { ButtonCheckIcon } from '~/components/icon';
import { Text } from '~/components/text';

type CheckButtonState = 'disabled' | 'enabled' | 'hover' | 'select' | 'selected';

type CheckButtonLabelHelperRenderFunc = (state: CheckButtonState) => ReactNode;

export type CheckButtonType = 'checkbox' | 'radio';

export type CheckButtonProps = ViewProps & {
    selected?: boolean;
    type: CheckButtonType;
    label: ReactNode | CheckButtonLabelHelperRenderFunc;
    helper?: ReactNode | CheckButtonLabelHelperRenderFunc;
    fillContainer?: boolean;
    disabled?: boolean;
    onPress?: () => void | Promise<void>;
};

const CHECK_MARKER_BORDER_COLOR = {
    disabled: Color.LINE_DISABLED,
    enabled: Color.TEXT_SECONDARY,
    hover: Color.BASIC_HOVER,
    select: Color.PRIMARY_PRESSED,
    selected: Color.PRIMARY_DEFAULT
};

const CHECK_MARKER_COLOR = {
    disabled: Color.ICON_DISABLED,
    enabled: Color.TEXT_SECONDARY,
    hover: Color.ALMOST_BLACK,
    select: Color.ALMOST_BLACK,
    selected: Color.ALMOST_BLACK
};

const CheckButtonContainer = styled.View<{ selected: boolean }>`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-vertical: 10px;
    padding-horizontal: 12px;
    border-radius: 8px;

    ${({ selected }) =>
        selected ? `background-color: ${Color.BASIC_PRESSED}` : `background-color: ${Color.BASIC_DEFAULT}`}
`;

const CheckMarkerContainer = styled.View<{ type: CheckButtonType; buttonState: CheckButtonState }>`
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 4px;
    border-width: 2px;
    border-color: ${({ buttonState }) => CHECK_MARKER_BORDER_COLOR[buttonState]};
    background.color: ${Color.BACKGROUND_DEFAULT};

    ${({ type }) => (type === 'radio' ? ' border-radius: 24px; transform: scale(0.92, 1) rotate(45deg);' : '')}
`;

const CheckMarker = styled.View`
    position: absolute;
    left: 12px;
    top: 10px;
`;

const CheckButtonLabelContainer = styled.View`
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2px;
`;

const CheckButtonLabel = (props: NativeTextProps & PropsWithChildren<{ buttonState: CheckButtonState }>) => {
    const { buttonState, children } = props;
    const color = buttonState !== 'disabled' ? Color.ALMOST_BLACK : Color.TEXT_DISABLED;
    return <NativeText style={{ ...Text.BUTTON_LABEL_STYLES, color }}>{children}</NativeText>;
};

const CheckButtonLabelHelper = (props: NativeTextProps & PropsWithChildren<{ buttonState: CheckButtonState }>) => {
    const { buttonState, children } = props;
    const color = buttonState !== 'disabled' ? Color.TEXT_SECONDARY : Color.TEXT_DISABLED;
    return (
        <NativeText style={{ ...Text.BUTTON_LABEL_STYLES, color, fontSize: 14, marginTop: 6 }}>{children}</NativeText>
    );
};

const state = (selected: boolean, disabled: boolean, pressed: boolean) =>
    disabled ? 'disabled' : selected ? 'selected' : pressed ? 'select' : 'enabled';

export const CheckButton = (props: CheckButtonProps) => {
    const { selected = false, label, type, helper, fillContainer = false, disabled = false, onPress, style } = props;

    const renderLabel = (buttonState: CheckButtonState) => {
        if (typeof label === 'function') {
            return label(buttonState);
        }
        return <CheckButtonLabel buttonState={buttonState}>{label}</CheckButtonLabel>;
    };

    const renderHelper = (buttonState: CheckButtonState) => {
        if (helper) {
            if (typeof helper === 'function') {
                return helper(buttonState);
            }
            return (
                <CheckButtonLabelHelper buttonState={buttonState} numberOfLines={3}>
                    {helper}
                </CheckButtonLabelHelper>
            );
        }
        return null;
    };

    return (
        <Pressable
            accessibilityRole={type}
            disabled={disabled}
            onPress={onPress}
            style={[style, { alignSelf: fillContainer ? 'stretch' : 'auto' }]}
        >
            {({ pressed }) => {
                const buttonState = state(selected, disabled, pressed);
                return (
                    <CheckButtonContainer selected={selected}>
                        <CheckMarkerContainer type={type} buttonState={buttonState} />
                        {selected ? (
                            <CheckMarker>
                                <ButtonCheckIcon fill={CHECK_MARKER_COLOR[buttonState]} />
                            </CheckMarker>
                        ) : null}
                        <CheckButtonLabelContainer>
                            {renderLabel(buttonState)}
                            {renderHelper(buttonState)}
                        </CheckButtonLabelContainer>
                    </CheckButtonContainer>
                );
            }}
        </Pressable>
    );
};

CheckButton.displayName = 'CheckButton';
