import React, { useContext, useEffect, useMemo } from 'react';

import { AnalyticsContextProviderProps, AnalyticsContextType, AnalyticsDispatchProviderProps } from './analytics';
import { AppAnalyticsEvent } from './analytics-types';

export const AnalyticsContext = React.createContext<AnalyticsContextType | undefined>(undefined);

export function AnalyticsContextProvider(props: AnalyticsContextProviderProps) {
    const { children } = props;

    const value = useMemo(() => ({ track: () => {}, startSession: () => {}, endSession: () => {} }), []);

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}

export const AnalyticsConsumer = AnalyticsContext.Consumer;
export const AnalyticsProvider = AnalyticsContextProvider;

export function AnalyticsDispatchProvider(props: AnalyticsDispatchProviderProps) {
    const { children } = props;

    return children;
}

export function useAnalytics() {
    const context = useContext(AnalyticsContext);

    if (!context) {
        throw Error('Cannot use context until it defined');
    }

    return context;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAnalyticsEventEffect(event: AppAnalyticsEvent | undefined = undefined, dependencies: any[] = []) {
    const { track } = useAnalytics();

    useEffect(() => {
        if (event) {
            track(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, track, ...dependencies]);
}
