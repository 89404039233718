export enum SystemMessage {
    USER_JOINED = 10,
    USER_LEFT = 11,
    CHAT_CLOSED = 12,
    CHAT_REOPENED = 13,
    CHAT_ESCALATED = 14
}

const typeTranslationKeys = {
    [SystemMessage.USER_JOINED]: 'chat-message.joined_chat',
    [SystemMessage.USER_LEFT]: 'chat-message.left_chat',
    [SystemMessage.CHAT_CLOSED]: 'chat-message.chat_closed',
    [SystemMessage.CHAT_REOPENED]: 'chat-message.chat_reopened',
    [SystemMessage.CHAT_ESCALATED]: 'chat-message.chat_escalated'
} as const;

export const messageTypeToTranslationKey = (messageType: SystemMessage) => {
    return typeTranslationKeys[messageType];
};

export const isSystemMessage = (messageType: number) => {
    return messageType >= 10;
};
