import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect } from 'react';
import { InteractionManager } from 'react-native';

import { useLinking } from '~/contexts/linking';
import * as ErrorDiagnostics from '~/error';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { isString } from '~/utils';

type MainNavigationProp = StackNavigationProp<MainNavigatorParamList>;

export function useHandlePendingLink() {
    const { getAndClearPendingURL } = useLinking();
    const navigateTo = useLinkTo();
    const { navigate } = useNavigation<MainNavigationProp>();

    useEffect(() => {
        const task = InteractionManager.runAfterInteractions(() => {
            const url = getAndClearPendingURL();

            if (url && url !== '/') {
                ErrorDiagnostics.log(`Processing pending deep link url '${url}'`);
                if (isString(url)) {
                    if (url.startsWith('/')) {
                        navigateTo(`${url}`);
                    } else {
                        navigateTo(`/${url}`);
                    }
                } else {
                    navigate(url);
                }
            }
        });

        return () => task.cancel();
    }, [navigateTo, getAndClearPendingURL, navigate]);
}
