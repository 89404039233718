import { Box } from 'native-base';
import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ButtonBackIcon } from '~/components/icon';
import { useIntl } from '~/contexts/intl';
import { isWeb } from '~/utils';

export type ModalPickerProps = {
    bottomPadding?: boolean;
    extraStyles?: StyleProp<ViewStyle>;
    visible: boolean;
    renderContent: () => ReactNode;
    renderActions: () => ReactNode;
    onCancel: () => void;
};

const Card = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 16px;

    align-items: center;
`;

export function ModalPicker(props: ModalPickerProps) {
    const { visible, renderContent, renderActions, onCancel, extraStyles, bottomPadding = true } = props;
    const { formatMessage } = useIntl();

    return (
        <Modal
            isVisible={visible}
            animationIn="slideInUp"
            animationOut="slideOutDown"
            backdropOpacity={0.6}
            onBackdropPress={onCancel}
            statusBarTranslucent
            style={extraStyles ?? {}}
        >
            <Box style={{ position: isWeb() ? 'relative' : 'absolute' }}>
                <Card style={{ paddingBottom: bottomPadding ? 16 : 2 }}>
                    {renderContent()}
                    {renderActions()}
                </Card>
                <Button
                    label={formatMessage('modal-picker.back')}
                    alignCenter
                    type="basic"
                    shape="rounded"
                    leftIcon={ButtonBackIcon}
                    onPress={onCancel}
                />
            </Box>
        </Modal>
    );
}
