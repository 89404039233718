import { RouteProp } from '@react-navigation/native';
import React from 'react';

import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { Nps } from './nps';

type NpsMemberRoute = RouteProp<AppNavigatorParamList, 'nps-member'>;

export const NpsMember = () => {
    const {
        params: { npsUUID, scoreDetail1 }
    } = useRoute<NpsMemberRoute>();

    return (
        <Nps
            npsUUID={npsUUID}
            initialScoreDetail1={scoreDetail1 ? parseInt(scoreDetail1, 10) : undefined}
            detailQuestion1={{
                titleKey: 'nps.detail1.member.title',
                minDescriptionKey: 'nps.detail1.min-description',
                maxDescriptionKey: 'nps.detail1.max-description'
            }}
            detailQuestion2={{
                titleKey: 'nps.detail2.member.title',
                minDescriptionKey: 'nps.detail2.min-description',
                maxDescriptionKey: 'nps.detail2.max-description'
            }}
            npsQuestionTitleKey="nps.nps-question.title"
        />
    );
};
