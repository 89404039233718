import * as ImagePicker from 'expo-image-picker';
import { Box, Flex, View, useBreakpointValue } from 'native-base';
import React, { useEffect, useState, useRef } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { v4 as uuid } from 'uuid';

import { OvalButton, Button } from '~/components/button';
import { CenteredEllipticMask } from '~/components/elliptic-mask/centered-elliptic-mask';
import { ButtonPlusIcon } from '~/components/icon';
import { Image } from '~/components/image';
import { MaskedView } from '~/components/masked-view';
import { useAnalytics } from '~/contexts/analytics';
import { useIntl } from '~/contexts/intl';
import { AppError } from '~/error';
import { isWeb } from '~/utils';

import { Color } from '../color';
import { EllipticMaskView } from '../elliptic-mask';
import { Spacing } from '../spacing';

export enum ProfileImageSize {
    REGULAR = 232,
    SMALL = 148
}

type ProfileImageProps = {
    editable?: boolean;
    imageUrl?: string;
    size?: ProfileImageSize;
    onImageSelected?: (uri: string) => Promise<void>;
};

export const ProfileImage: React.FC<ProfileImageProps> = props => {
    const { formatMessage } = useIntl();

    const { size = ProfileImageSize.REGULAR } = props;
    const { editable, imageUrl, onImageSelected } = props;
    const [selectedImageUri, setSelectedImageUri] = useState<string | undefined>(undefined);
    const mounted = useRef(false);
    const handleError = useErrorHandler();
    const { track } = useAnalytics();

    // We need to track component's mounted state in order to prevent updating `selectedImageUri` if the user
    // navigates away from the view while the `onImageSelected` async call is in progress.

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const handleRequestPermission = async () => {
        let { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (status === 'undetermined') {
            const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();
            status = permission.status;
            track({ type: 'permission-media-library', action: status === 'granted' ? 'granted' : 'denied' });
        }
        return status === 'granted';
    };

    const pickImage = async () => {
        try {
            if (onImageSelected) {
                if (await handleRequestPermission()) {
                    const result = await ImagePicker.launchImageLibraryAsync({
                        mediaTypes: ImagePicker.MediaTypeOptions.Images,
                        allowsEditing: true,
                        aspect: [1, 1],
                        quality: 1,
                        exif: false
                    });
                    if (!result.canceled) {
                        await onImageSelected(result.assets[0].uri);
                        if (mounted.current) {
                            setSelectedImageUri(result.assets[0].uri);
                        }
                    }
                }
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                handleError(
                    new AppError(error, 'profile.error.image-upload-failed', {
                        onClose: () => {},
                        name: 'error-overlay.go_back'
                    })
                );
            } else {
                handleError(error);
            }
        }
    };

    const maskId = uuid();

    return (
        <>
            <View accessibilityRole="image">
                <MaskedView
                    maskId={maskId}
                    style={{ width: size, height: size }}
                    maskElement={<CenteredEllipticMask maskId={maskId} />}
                >
                    <Image
                        source={{ uri: selectedImageUri ?? imageUrl }}
                        resizeMode="cover"
                        style={[{ width: size, aspectRatio: 1 }]}
                    />
                </MaskedView>
                <Box variant="flexMobileOnly" position="absolute" bottom="8px" right="8px">
                    {editable && onImageSelected ? (
                        <OvalButton size="large" icon={ButtonPlusIcon} onPress={pickImage} />
                    ) : null}
                </Box>
            </View>

            <Box variant="flexDesktopOnly">
                {editable && onImageSelected ? (
                    <Button
                        shape="rounded"
                        size="small"
                        label={formatMessage('profile.image.edit')}
                        onPress={pickImage}
                        style={{ marginTop: Spacing.MEDIUM, alignSelf: 'flex-start' }}
                    />
                ) : null}
            </Box>
        </>
    );
};

export const ProfileCardImage: React.FC<ProfileImageProps> = props => {
    const { imageUrl, onImageSelected, editable, size } = props;

    const showDesktopComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const IMAGE_HEIGHT = isWeb() ? 232 : 350;

    return (
        <>
            {showDesktopComponent ? (
                <Flex flexDirection="column" width="350px" flexGrow={1} justifyContent="flex-start" alignItems="center">
                    <ProfileImage
                        editable={editable}
                        imageUrl={imageUrl ?? undefined}
                        onImageSelected={onImageSelected}
                        size={size}
                    />
                </Flex>
            ) : (
                <EllipticMaskView ellipseSize="large" style={{ height: IMAGE_HEIGHT }}>
                    <Flex
                        flexDirection="column"
                        flexBasis="350"
                        width="100%"
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={Color.BACKGROUND_PRIMARY}
                        height={IMAGE_HEIGHT}
                    >
                        <ProfileImage
                            editable={editable}
                            imageUrl={imageUrl ?? undefined}
                            onImageSelected={onImageSelected}
                            size={size}
                        />
                    </Flex>
                </EllipticMaskView>
            )}
        </>
    );
};

ProfileImage.displayName = 'ProfileImage';
