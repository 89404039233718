import { RouteProp } from '@react-navigation/native';
import React from 'react';

import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { Nps } from './nps';

type NpsMindyRoute = RouteProp<AppNavigatorParamList, 'nps-mindy'>;

export const NpsMindy = () => {
    const {
        params: { npsUUID, scoreDetail1 }
    } = useRoute<NpsMindyRoute>();

    return (
        <Nps
            npsUUID={npsUUID}
            initialScoreDetail1={scoreDetail1 ? parseInt(scoreDetail1, 10) : undefined}
            detailQuestion1={{
                titleKey: 'nps.detail1.mindy.title',
                maxDescriptionKey: 'nps.detail1.mindy.max-description',
                minDescriptionKey: 'nps.detail1.min-description'
            }}
            npsQuestionTitleKey="nps.nps-question.mindy.title"
        />
    );
};
