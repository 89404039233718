import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export const AppointmentActionTitle = styled(Text.SUBTITLE_1)`
    color: ${Color.SMOOTHIE};
    text-align: center;
`;

export const AppointmentActionDescription = styled(Text.SMALL_BUTTON_LABEL).attrs({ before: 8, after: 16 })`
    text-align: center;
`;

export const AppointmentActionLink = styled(Text.SMALL_BUTTON_LABEL)`
    text-decoration: underline;
`;
