import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';

import { AppointmentsCalendar as AppointmentsCalendarComponent } from '~/components/appointment';
import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { HeaderCloseButton } from '~/components/header';
import { ScreenContainer } from '~/components/screen';
import { useIntl } from '~/contexts/intl';
import { useAppointmentDays } from '~/hooks/appointment-days';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { toISODateString } from '~/utils/date';

export type CalendarNavigationProp = StackNavigationProp<MainNavigatorParamList, 'appointments-calendar'>;
export type CalendarRouteProp = RouteProp<MainNavigatorParamList, 'appointments-calendar'>;

export function AppointmentsCalendar() {
    const { params } = useRoute<CalendarRouteProp>();
    const { setOptions, navigate } = useNavigation<CalendarNavigationProp>();
    const { formatMessage } = useIntl();

    const [selectedDate, setSelectedDate] = useState(params.date ?? toISODateString(new Date()));

    const {
        appointmentDays,
        fetchMoreAppointmentDays,
        appointmentDaysRange: fetchedAppointmentDaysRange
    } = useAppointmentDays({
        start: params.appointmentDaysRange[0],
        end: params.appointmentDaysRange[1],
        calendarReservationAccessId: params.calendarReservationAccessId,
        regionId: params.regionId,
        locationId: params.locationId,
        timeOfDay: params.timeOfDay,
        filters: params.filters
    });

    useLayoutEffect(() => {
        setOptions({
            headerLeft: () => <HeaderCloseButton />,
            headerTitle: formatMessage('calendar.pick.date'),
            headerTitleAlign: 'left',
            headerTransparent: false,
            headerShadowVisible: false
        });
    }, [formatMessage, setOptions]);

    return (
        <ScreenContainer>
            <AppointmentsCalendarComponent
                appointmentDaysRange={params.appointmentDaysRange}
                date={selectedDate}
                appointmentDays={appointmentDays}
                setSelectedDate={setSelectedDate}
                fetchMoreAppointmentDays={fetchMoreAppointmentDays}
            />
            <BottomContainer>
                <Button
                    label={formatMessage('show')}
                    alignCenter
                    type="primary"
                    onPress={() =>
                        navigate('appointments', {
                            calendarReservationAccessId: params.calendarReservationAccessId,
                            appointmentDaysRange: fetchedAppointmentDaysRange,
                            date: selectedDate,
                            timeOfDay: params.timeOfDay
                        })
                    }
                />
            </BottomContainer>
        </ScreenContainer>
    );
}

const BottomContainer = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    border-top-width: 1px;
    border-top-color: ${Color.LINE_DEFAULT};
    height: 112px;
    align-self: center;
    padding-horizontal: 32px;
    padding-vertical: 24px;
    width: 100%;
`;
