import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorModal, ErrorAuthReset } from '~/components/error';
import { useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { randomIntInRange } from '~/utils';

import { AppError } from './app-error';
import * as ErrorDiagnostics from './app-error-diagnostics';

const generateErrorId = () => {
    const id = randomIntInRange(100000000, 999999999).toString();
    return `${id.slice(0, 3)}-${id.slice(3, 6)}-${id.slice(6, 9)}`;
};

export const AppErrorBoundary = ({ children }: PropsWithChildren<object>) => {
    const { reset } = useAuth();
    const { formatMessage } = useIntl();

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => {
                const id = generateErrorId();

                ErrorDiagnostics.error(error, { errorUuid: id, stack: error.stack ?? 'n/a' });

                if (__DEV__ && typeof jest === 'undefined') {
                    // eslint-disable-next-line no-console
                    console.log(`${id} ${error}: ${JSON.stringify(error)}`);
                }

                // TODO: Consider merging ErrorAuthReset and ErrorOverlay components to a single component that can handle both use cases
                if (['Signature has expired', 'Refresh token is expired'].includes(error.message)) {
                    return (
                        <ErrorAuthReset
                            error={error}
                            reset={() => {
                                reset();
                                resetErrorBoundary();
                            }}
                        />
                    );
                } else {
                    if (error instanceof AppError) {
                        const { error: originalError, description, close } = error;

                        return (
                            <ErrorModal
                                id={id}
                                title={formatMessage('error')}
                                description={formatMessage(description)}
                                error={originalError}
                                errorType={close?.onClose ? 'generic' : 'unrecoverable'}
                                onClose={() => {
                                    if (close?.onClose) {
                                        close.onClose();
                                    } else {
                                        reset();
                                    }
                                    resetErrorBoundary();
                                }}
                                close={formatMessage(close?.name ?? 'error.close.reset')}
                            />
                        );
                    }
                    return (
                        <ErrorModal
                            id={id}
                            error={error}
                            errorType="unrecoverable"
                            onClose={() => {
                                reset();
                                resetErrorBoundary();
                            }}
                        />
                    );
                }
            }}
        >
            {children}
        </ErrorBoundary>
    );
};
