import { formatISO } from 'date-fns';

export const secondsToDate = (secs: number): Date => {
    if (secs >= 0) {
        const t = new Date(Date.UTC(1970, 0, 1)); // Epoch
        t.setUTCSeconds(secs);
        return t;
    }
    throw new Error('Invalid parameter, seconds to convert to date must be positive');
};

export function fromISODateString(date: string): Date;
export function fromISODateString(date?: string): Date | undefined;

export function fromISODateString(date: unknown): unknown {
    if (date && typeof date === 'string' && date.match(/^\d{4}-\d\d-\d\d$/i)) {
        const d = new Date(date);
        return !isNaN(d.getTime()) ? d : undefined;
    }
}

export function toISODateString(date: Date): string;
export function toISODateString(date?: Date): string | undefined;

export function toISODateString(date: unknown): unknown {
    if (date && date instanceof Date) {
        return formatISO(date, { representation: 'date' });
    }
}

export function fromISODateTimeString(date: string): Date;
export function fromISODateTimeString(date?: string): Date | undefined;

export function fromISODateTimeString(date: unknown): unknown {
    if (
        date &&
        typeof date === 'string' &&
        date.match(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i)
    ) {
        const d = new Date(date);
        return !isNaN(d.getTime()) ? d : undefined;
    }
}

export function toISODateTimeString(date: Date): string;
export function toISODateTimeString(date?: Date): string | undefined;

export function toISODateTimeString(date: unknown): unknown {
    if (date && date instanceof Date) {
        return formatISO(date);
    }
}
