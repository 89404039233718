import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ButtonCheckIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M9.06066 10.8624C8.47487 10.2766 7.52513 10.2766 6.93934 10.8624C6.35355 11.4482 6.35355 12.398 6.93934 12.9837L9.06066 10.8624ZM11.0769 15L10.0163 16.0607C10.3408 16.3852 10.796 16.5433 11.2518 16.4898C11.7076 16.4363 12.1139 16.177 12.3544 15.7861L11.0769 15ZM6.93934 12.9837L10.0163 16.0607L12.1376 13.9393L9.06066 10.8624L6.93934 12.9837ZM12.3544 15.7861L17.2775 7.78615L14.7225 6.21385L9.79944 14.2139L12.3544 15.7861Z"
                    strokeWidth={2}
                    fill={fill ?? Color.ALMOST_BLACK}
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
