import { isAfter } from 'date-fns';
import { useMemo } from 'react';

import {
    QuestionnaireResponseBatchFragment,
    QuestionnaireResponseBatchListDocument,
    QuestionnaireResponseBatchQuestionnaireResponseFragment
} from '~/types';
import { relayConnectionReduce } from '~/utils';

import { useLoadingQuery } from '../loading-query';

export type QuestionnaireResponse = {
    id: ID;
    title: string;
    description?: string;
};

export type QuestionnaireResponseBatchListItem = {
    id: ID;
    description?: string;
    openAt: Date;
    closeAt: Date;
    openForResponses: boolean;
    questionnaireResponses: QuestionnaireResponse[];
};

const questionnaireResponseFragmentToQuestionnaireResponse = (
    questionnaireResponseFragment: QuestionnaireResponseBatchQuestionnaireResponseFragment
): QuestionnaireResponse => {
    const {
        id,
        questionnaire: { title, description }
    } = questionnaireResponseFragment;

    return {
        id,
        title: title ?? '',
        description: description ?? undefined
    };
};

export const useQuestionnaireResponseBatchList = () => {
    const { data, loading, error } = useLoadingQuery(QuestionnaireResponseBatchListDocument);

    const questionnaireResponseBatchFragmenToListItem = (
        questionnaireResponseBatchFragment: QuestionnaireResponseBatchFragment
    ): QuestionnaireResponseBatchListItem => {
        const { id, closeAt, questionnaireResponses, message, openAt } = questionnaireResponseBatchFragment;
        const closeAtDate = new Date(closeAt);
        const questionnaireResponseList =
            relayConnectionReduce<QuestionnaireResponseBatchQuestionnaireResponseFragment>(questionnaireResponses);
        const analyzedAtDates = questionnaireResponseList?.map(response => response.analysedAt) ?? [];
        return {
            id,
            closeAt: closeAtDate,
            openAt: new Date(openAt),
            openForResponses:
                isAfter(closeAtDate, new Date()) && analyzedAtDates.some(analyzedAt => analyzedAt === null),
            description: message ?? undefined,
            questionnaireResponses:
                questionnaireResponseList?.map(questionnaireResponseFragmentToQuestionnaireResponse) ?? []
        };
    };

    const questionnaireResponseBatches: QuestionnaireResponseBatchListItem[] | undefined = useMemo(
        () =>
            relayConnectionReduce<QuestionnaireResponseBatchFragment>(data?.root?.questionnaireResponseBatches)?.map(
                questionnaireResponseBatchFragmenToListItem
            ),
        [data]
    );

    return { loading, error, questionnaireResponseBatches };
};
