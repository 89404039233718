import AsyncStorage from '@react-native-async-storage/async-storage';
import { AlertDialog, Input, Row } from 'native-base';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Alert, ListRenderItemInfo } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { List, ListItem } from '~/components/list';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

type DeveloperLocalStorageProps = object;

type LocalStorageItem = [string, string | null];

const CenteredView = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
`;

export const DeveloperLocalStorage: FunctionComponent<DeveloperLocalStorageProps> = props => {
    const [items, setItems] = useState<Readonly<LocalStorageItem[]>>();
    const [itemEdited, setItemEdited] = useState<string>();
    const [valueEdited, setValueEdited] = useState<string>();

    const dialogRef = useRef(null);

    useEffect(() => {
        (async () => setItems(await AsyncStorage.multiGet(await AsyncStorage.getAllKeys())))();
    }, []);

    const onSelectItem = async (key: string) => {
        try {
            setItemEdited(key);
            setValueEdited((await AsyncStorage.getItem(key))!);
        } catch (error) {
            Alert.alert('Error', 'Could not load value');
        }
    };

    const close = () => {
        setItemEdited(undefined);
        setValueEdited(undefined);
    };

    const saveAndClose = async () => {
        try {
            const newValue = valueEdited!.trim();
            if (newValue.length > 0) {
                await AsyncStorage.setItem(itemEdited!, newValue);
                setItems(items!.map(([key, value]) => [key, key === itemEdited ? newValue : value]));
            } else {
                await AsyncStorage.removeItem(itemEdited!);
                setItems(items!.filter(([key, value]) => key !== itemEdited));
            }
        } catch (error) {
            Alert.alert('Error', 'Could not save value');
        } finally {
            close();
        }
    };

    const renderItem = ({ item: [key, value] }: ListRenderItemInfo<LocalStorageItem>) => {
        return <ListItem title={key} description={value ?? 'null'} onPress={async () => onSelectItem(key)} />;
    };

    return (
        <ScreenContainer>
            <AlertDialog leastDestructiveRef={dialogRef} isOpen={itemEdited !== undefined} onClose={close}>
                <AlertDialog.Content>
                    <AlertDialog.Header>Set localstorage value</AlertDialog.Header>

                    <AlertDialog.Body>
                        <Text.CAPTION>{itemEdited}</Text.CAPTION>

                        <Input
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            placeholder="Password"
                            value={valueEdited}
                            defaultValue={valueEdited}
                            onChangeText={setValueEdited}
                            marginY={4}
                        />

                        <Row justifyContent="center">
                            <Button label="Cancel" onPress={close} style={{ marginRight: Spacing.MEDIUM }} />
                            <Button type="primary" label="OK" onPress={saveAndClose} />
                        </Row>
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
            {items?.length ? (
                <List<LocalStorageItem> data={items} renderItem={renderItem} />
            ) : (
                <CenteredView>
                    <Text.P1>No items</Text.P1>
                </CenteredView>
            )}
        </ScreenContainer>
    );
};
