import React, { ReactElement } from 'react';
import { View, ViewProps } from 'react-native';

import { CheckBoxProps } from './check-box';

type CheckGroupProps = ViewProps & {
    children?: CheckBoxElement | CheckBoxElement[];
};

type CheckBoxElement = ReactElement<CheckBoxProps>;

export const CheckGroup = (props: CheckGroupProps) => {
    const { children = [] } = props;

    const checkBoxes = React.Children.map(children, (element: CheckBoxElement, index: number) =>
        React.cloneElement(element, {
            key: index,
            style: [{ marginTop: 6, marginBottom: 6 }, element.props.style]
        })
    );

    return <View {...props}>{checkBoxes}</View>;
};
