import { Box } from 'native-base';
import React, { useCallback, useEffect } from 'react';

import { CheckButton } from '~/components/check/check-button';
import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { useStateCallback } from '~/hooks/state-callback';

import { FormInputProps } from './form-input';

type FormCheckGroupInputOption<T extends string | number> = { option: T; name: string };

type FormCheckGroupInputProps<T extends string | number> = Omit<FormInputProps<T>, 'initialValue' | 'onSubmit'> & {
    options: FormCheckGroupInputOption<T>[];
    initialValue: T[] | null;
    onSubmit?: (value: T[]) => Promise<void>;
};

export function FormCheckGroupInput<T extends string | number>(props: FormCheckGroupInputProps<T>) {
    const { caption, disabled, options, onSubmit, onSetValue, initialValue } = props;
    const [selection, setSelection] = useStateCallback<T[]>([]);

    useEffect(() => {
        if (initialValue !== null) {
            setSelection(initialValue, () => {});
        }
    }, [initialValue, setSelection]);

    const onPress = useCallback(
        (option: T) => {
            const value = onSetValue?.(option) ?? option;

            const optionIndex = selection.indexOf(option);
            if (optionIndex > -1) {
                const modifiedSelection = [...selection];
                modifiedSelection.splice(optionIndex, 1);
                setSelection(modifiedSelection, newSelection => onSubmit?.(newSelection));
            } else {
                setSelection([...selection, value], newSelection => onSubmit?.(newSelection));
            }
        },
        [onSetValue, onSubmit, selection, setSelection]
    );

    return (
        <Box>
            {caption ? (
                <Text.INPUT_FIELD_TITLE style={{ color: disabled ? Color.TEXT_DISABLED : Color.ALMOST_BLACK }}>
                    {caption}
                </Text.INPUT_FIELD_TITLE>
            ) : null}
            <>
                {options.map(({ option, name }) => (
                    <CheckButton
                        style={{ marginBottom: 2 }}
                        type="checkbox"
                        key={option}
                        selected={selection.includes(option)}
                        label={name}
                        onPress={() => onPress(option)}
                    />
                ))}
            </>
        </Box>
    );
}

FormCheckGroupInput.displayName = 'FormCheckGroupInput';
