import { AppMessageId } from '~/contexts/intl';

export type AppErrorClose = {
    onClose: () => void;
    name?: AppMessageId;
};

export class AppError extends Error {
    constructor(public error: Error, public description: AppMessageId, public close?: AppErrorClose) {
        super(error.message);
    }
}
