import { timezone } from 'expo-localization';

export function isUserInBusinessTimezone(): boolean {
    // NOTE: Business timezone should not be hard coded to the client.
    //       This needs to be changed when branching to other countries
    return timezone === 'Europe/Helsinki';
}

export function extractTimezoneAbbreviation(date: Date): string {
    const parts = date.toLocaleString('en-gb', { day: '2-digit', timeZoneName: 'long' }).match(/, ([^)]+)/i);

    let abbreviation = parts?.[1] ?? '';

    if (abbreviation.search(/\W/) >= 0) {
        // Match first letter at each word boundary
        abbreviation = abbreviation.match(/\b\w/g)?.join('').toUpperCase() ?? '';
    }

    return abbreviation;
}

export function localTimezoneAbbreviation(): string {
    return extractTimezoneAbbreviation(new Date());
}
