export function isString(obj: unknown): obj is string {
    return typeof obj === 'string';
}

export function isValidString(obj: unknown): boolean {
    return isString(obj) && obj !== '';
}

export function isNumber(obj: unknown): obj is number {
    return typeof obj === 'number';
}

export function isFunction<T extends Function>(obj: unknown): obj is T {
    return typeof obj === 'function';
}

export function isError(obj: unknown): obj is Error {
    return obj instanceof Error;
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x);
}

export type Mutable<T> = {
    -readonly [k in keyof T]: T[k];
};

export type Extends<T, U extends T> = U;
