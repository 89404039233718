import React from 'react';
import { View } from 'react-native';
import { Svg, Path, Defs, ClipPath, Rect, G } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const FullScreenIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Defs>
                    <ClipPath id="clip">
                        <Rect width="24" height="24" fill={Color.ICON_NEGATIVE} />
                    </ClipPath>
                </Defs>
                <G clipPath="url(#clip)">
                    <Path
                        d="M4 20.0003L10 14.0003"
                        stroke={fill ?? Color.ALMOST_BLACK}
                        fill={Color.TRANSPARENT}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <Path
                        d="M2.99991 15L3.00035 20.0005C3.00039 20.5528 3.44809 21.0004 4.00035 21.0004L9.00053 21.0004"
                        stroke={fill ?? Color.ALMOST_BLACK}
                        fill={Color.TRANSPARENT}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <Path
                        d="M20 3.99994L14 9.99994"
                        stroke={fill ?? Color.ALMOST_BLACK}
                        fill={Color.TRANSPARENT}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <Path
                        d="M21 8.99997L20.9998 3.99977C20.9998 3.4475 20.5521 2.9998 19.9998 2.9998L14.9999 2.9998"
                        stroke={fill ?? Color.ALMOST_BLACK}
                        fill={Color.TRANSPARENT}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </G>
            </Svg>
        </View>
    );
};
