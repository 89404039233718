import { useMemo } from 'react';

import { DocumentListDocument, ListDocumentFragment } from '~/types';
import { relayConnectionReduce } from '~/utils';

import { useLoadingQuery } from '../loading-query';

type DocumentsProps = {
    unseenOnly?: boolean;
};

export type DocumentListItem = ListDocumentFragment;

export const useDocumentList = (props: DocumentsProps) => {
    const { unseenOnly } = props;

    const { data, loading, error } = useLoadingQuery(DocumentListDocument, {
        variables: { unseenOnly }
    });

    const documents: DocumentListItem[] | undefined = useMemo(
        () => relayConnectionReduce<ListDocumentFragment>(data?.root?.documents),
        [data]
    );

    return { loading, error, documents };
};
