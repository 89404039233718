import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect } from 'react';
import { WebView } from 'react-native-webview';

import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type VideoCallNavigation = StackNavigationProp<MainNavigatorParamList, 'appointment'>;

export type VideoCallRouteProp = RouteProp<MainNavigatorParamList, 'video-call'>;

export function VideoCall() {
    const { setOptions } = useNavigation<VideoCallNavigation>();
    const { params } = useRoute<VideoCallRouteProp>();
    const { formatMessage } = useIntl();

    const roomUrl = params.url;
    const roomParameters = '?needancestor&skipMediaPermissionPrompt&floatSelf&minimal';

    useLayoutEffect(() => {
        setOptions({
            headerTitle: formatMessage('video-call.title')
        });
    });

    return (
        <WebView
            startInLoadingState
            source={{ uri: roomUrl + roomParameters }}
            mediaPlaybackRequiresUserAction={false}
            allowsInlineMediaPlayback
            javaScriptEnabled
            domStorageEnabled
        />
    );
}
