import { Flex, useBreakpointValue } from 'native-base';
import React, { ReactNode, useCallback } from 'react';

import { Button } from '~/components/button';
import { HelperButton } from '~/components/button/helper-button';
import { Color } from '~/components/color';
import { ArrowBackIcon, ButtonForwardIcon } from '~/components/icon';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

type Props = {
    image?: ReactNode;
    renderEllipticMask?: boolean;

    title: ReactNode;
    description: ReactNode;
    info?: ReactNode;

    actionButton?: ReactNode;
    buttonLabel?: string;
    onButtonPress?: () => void;

    backButtonLabel?: string;
    backOnButtonPress?: () => void;

    secondaryBottomContent?: ReactNode;

    devMenuAccess?: boolean;
};

export const AuthInstruction: React.FC<Props> = props => {
    const {
        image,
        renderEllipticMask,
        title,
        description,
        info,
        actionButton,
        buttonLabel,
        onButtonPress,
        backButtonLabel,
        backOnButtonPress,
        devMenuAccess,
        secondaryBottomContent
    } = props;

    const renderTopContent = useCallback(() => image, [image]);

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    return (
        <EllipticHeaderScreen
            renderEllipticMask={renderEllipticMask}
            renderTopContent={renderTopContent}
            renderBottomContent={() => (
                <Flex style={{ minHeight: '20%' }}>
                    <Spacing.Vertical.SMALL />
                    <Text.H1 style={{ color: Color.HEADER, textAlign: 'center' }}>{title}</Text.H1>
                    <Spacing.Vertical.LARGE />
                    <Text.P1 style={{ textAlign: 'center' }}>{description}</Text.P1>

                    {info && (
                        <>
                            <Spacing.Vertical.GIGANTIC />
                            <HelperButton title={info} />
                        </>
                    )}
                </Flex>
            )}
            renderSecondaryBottomContent={
                secondaryBottomContent ? () => <Flex>{secondaryBottomContent}</Flex> : undefined
            }
            renderActions={() => (
                <>
                    {backButtonLabel && backOnButtonPress && web && (
                        <Flex flexBasis="256">
                            <Button
                                fillContainer
                                alignCenter
                                leftIcon={ArrowBackIcon}
                                type="secondary"
                                onPress={backOnButtonPress}
                                label={backButtonLabel}
                            />
                        </Flex>
                    )}
                    {actionButton ? (
                        <Flex flexBasis="256">{actionButton}</Flex>
                    ) : (
                        <Flex flexBasis="256">
                            {buttonLabel && onButtonPress && (
                                <Button
                                    fillContainer
                                    alignCenter
                                    rightIcon={ButtonForwardIcon}
                                    type="primary"
                                    onPress={onButtonPress}
                                    label={buttonLabel}
                                />
                            )}
                        </Flex>
                    )}
                </>
            )}
            devMenuAccess={devMenuAccess}
        />
    );
};
