/*
 * Non-secure storage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

enum RegionSelectionStorageKey {
    REGION_ID = 'selected-region-id'
}

export const getRegionId = (identity: string): Promise<ID | null> =>
    AsyncStorage.getItem(`${RegionSelectionStorageKey.REGION_ID}:${identity}`);

export const setRegionId = (identity: string, regionId: ID) =>
    AsyncStorage.setItem(`${RegionSelectionStorageKey.REGION_ID}:${identity}`, regionId);

export const clearRegionId = (identity: string) =>
    AsyncStorage.removeItem(`${RegionSelectionStorageKey.REGION_ID}:${identity}`);
