import { format, parse } from 'date-fns';
import { Column, Row, View } from 'native-base';
import React, { ForwardedRef, ReactNode, forwardRef, useCallback, useEffect, useState } from 'react';
import { StyleSheet, TextInput as NativeTextInput, TextInputProps as NativeTextInputProps } from 'react-native';
import MaskInput from 'react-native-mask-input';

import { ShadowProps } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { fromISODateString, toISODateString } from '~/utils/date';
import { isWeb } from '~/utils/platform';
import { isEmailValid } from '~/utils/validation';

import { Color } from '../color';

export type InputContainerProps = {
    caption?: string;
    focused?: boolean;
    input: ReactNode;
    accessory?: ReactNode;
    flat?: boolean;
    stretch?: boolean;
};

function InputContainer(props: InputContainerProps) {
    const { caption, focused, flat = true, stretch = false, accessory, input } = props;

    return (
        <Column alignItems="flex-start" flexGrow={stretch ? 1 : undefined}>
            {caption ? (
                <Text.INPUT_FIELD_TITLE style={[{ paddingHorizontal: Spacing.SMALL, paddingVertical: Spacing.TINY }]}>
                    {caption}
                </Text.INPUT_FIELD_TITLE>
            ) : null}

            <Row
                alignItems="flex-start"
                bgColor={Color.BACKGROUND_DEFAULT}
                borderColor={focused ? Color.FOCUS_DEFAULT : Color.LINE_DEFAULT}
                borderRadius={8}
                borderWidth={2}
                flexGrow={stretch ? 1 : undefined}
                px={4}
                py={3}
                width="100%"
                {...(flat ? undefined : ShadowProps.primary)}
            >
                <Column alignSelf="stretch" flexGrow={1}>
                    {input}
                </Column>

                {accessory ? (
                    <View ml={1} mr={-1}>
                        {accessory}
                    </View>
                ) : null}
            </Row>
        </Column>
    );
}

export type InputTextProps = Omit<InputContainerProps, 'input' | 'focused'> & NativeTextInputProps;

export function InputText(props: InputTextProps) {
    const { caption, accessory, flat, numberOfLines = 1, style, stretch, ...rest } = props;
    const [focused, setFocused] = useState(false);

    return (
        <InputContainer
            caption={caption}
            focused={focused}
            accessory={accessory}
            flat={flat}
            stretch={stretch}
            input={
                <NativeTextInput
                    {...rest}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    multiline={numberOfLines > 1}
                    numberOfLines={numberOfLines}
                    style={[
                        styles.text,
                        style,
                        // Note: RN style types don't include outline, thus ignoring
                        // @ts-ignore
                        isWeb() ? { outline: 'none' } : {},
                        { flex: stretch ? 1 : 0 },
                        { color: props.editable ? Color.ALMOST_BLACK : '#777777' }
                    ]}
                    textAlignVertical={numberOfLines > 1 ? 'top' : 'center'}
                />
            }
        />
    );
}

export type InputDateTextProps = Omit<InputContainerProps, 'input' | 'rows'> &
    Omit<NativeTextInputProps, 'value' | 'onChangeText'> & {
        value?: ISODate;
        onChangeDate: (date?: ISODate) => void;
    };

export function InputDateText(props: InputDateTextProps) {
    const { value, onChangeDate, caption, accessory, flat, style, ...rest } = props;
    const [currentValue, setCurrentValue] = useState<string>('');
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        const date = fromISODateString(value);
        if (date) {
            setCurrentValue(format(date, 'dd.MM.yyyy'));
        }
    }, [value]);

    useEffect(() => {
        const match = currentValue.match(/\d\d.\d\d.\d{4}/);
        if (match) {
            const date = parse(currentValue, 'dd.MM.yyyy', new Date());
            if (!isNaN(date.getTime())) {
                return onChangeDate(toISODateString(date));
            }
        } else {
            onChangeDate(undefined);
        }
    }, [currentValue, onChangeDate]);

    return (
        <InputContainer
            caption={caption}
            focused={focused}
            accessory={accessory}
            flat={flat}
            input={
                <MaskInput
                    {...rest}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    numberOfLines={1}
                    multiline={false}
                    keyboardType="numeric"
                    placeholderFillCharacter="–"
                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                    value={currentValue}
                    onChangeText={setCurrentValue}
                    style={styles.text}
                />
            }
        />
    );
}

export type InputEmailTextProps = Omit<InputContainerProps, 'input' | 'focused'> & NativeTextInputProps;

export const InputEmailText = forwardRef(function (
    props: InputEmailTextProps,
    ref: ForwardedRef<NativeTextInput> | null
) {
    const { caption, accessory, flat, numberOfLines = 1, style, stretch, onChangeText, ...rest } = props;
    const [focused, setFocused] = useState(false);

    const handleChangeText = useCallback(
        (text: string) => {
            if (text?.length && isEmailValid(text)) {
                onChangeText?.(text);
            } else {
                onChangeText?.('');
            }
        },
        [onChangeText]
    );

    return (
        <InputContainer
            caption={caption}
            focused={focused}
            accessory={accessory}
            flat={flat}
            stretch={stretch}
            input={
                <NativeTextInput
                    ref={ref}
                    {...rest}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    multiline={numberOfLines > 1}
                    numberOfLines={numberOfLines}
                    onChangeText={handleChangeText}
                    autoCorrect={false}
                    autoCapitalize="none"
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoComplete="email"
                    style={[
                        styles.text,
                        style,
                        // Note: RN style types don't include outline, thus ignoring
                        // @ts-ignore
                        isWeb() ? { outline: 'none' } : {},
                        { flex: stretch ? 1 : 0 }
                    ]}
                    textAlignVertical={numberOfLines > 1 ? 'top' : 'center'}
                />
            }
        />
    );
});

const styles = StyleSheet.create({
    text: {
        flex: 1,
        paddingTop: 0,
        paddingBottom: 0,
        alignSelf: 'stretch',
        ...Text.PARAGRAPH_1_STYLES,
        lineHeight: 20
    }
});
