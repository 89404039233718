import {
    Image as RNImage,
    ImageSourcePropType as RNImageSourcePropType,
    NativeSyntheticEvent,
    ImageLoadEventData
} from 'react-native';

export const Image = RNImage;

export type Source = RNImageSourcePropType;
export type OnLoadEvent = NativeSyntheticEvent<ImageLoadEventData>;
