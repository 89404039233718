import React from 'react';

import { FormattedMessage, FormattedTime } from '~/contexts/intl';
import { isUserInBusinessTimezone, localTimezoneAbbreviation } from '~/utils/timezone';

export type TimeProps = {
    value?: Date;
};

export function Time(props: TimeProps) {
    const { value } = props;

    if (!value) {
        return <FormattedMessage id="time.empty" />;
    }

    const formattedTime = <FormattedTime hour="numeric" minute="numeric" value={value} />;

    if (isUserInBusinessTimezone()) {
        return formattedTime;
    } else {
        return (
            <>
                {formattedTime} {localTimezoneAbbreviation()}
            </>
        );
    }
}
