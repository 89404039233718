import {
    InteractionActionFragment,
    InteractionColor,
    InteractionFragment,
    InteractionImportance,
    InteractionInputFragment
} from '~/types';

export type Interaction = Omit<InteractionFragment, '__typename' | 'inputs' | 'actions'> & {
    inputs: InteractionInput[];
    actions: InteractionAction[];
};

export function fragmentToInteraction(fragment: InteractionFragment): Interaction {
    const { __typename, inputs, actions, ...rest } = fragment;
    return {
        ...rest,
        inputs: fragment.inputs.map(fragmentToInteractionInput),
        actions: fragment.actions.map(fragmentToInteractionAction)
    };
}

export type InteractionInput = Omit<InteractionInputFragment, '__typname'>;

export function fragmentToInteractionInput(fragment: InteractionInputFragment): InteractionInput {
    const { __typename, ...rest } = fragment;
    return rest;
}

export type InteractionAction = {
    handle: string;
    title: string;
    value?: string;
    color?: InteractionColor;
    importance?: InteractionImportance;
};

export const fragmentToInteractionAction = (fragment: InteractionActionFragment): InteractionAction => {
    const { handle, title, value, color, importance } = fragment;

    return {
        handle,
        title,
        value: value ?? undefined,
        color: color ?? undefined,
        importance: importance ?? undefined
    };
};
