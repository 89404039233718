import React from 'react';
import styled from 'styled-components/native';

import { ChatList } from '~/components/chat-list';
import { Color } from '~/components/color';
import { LoadingContent } from '~/components/loader';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useChatList } from '~/hooks/chat-list';

const SystemMessage = styled.View`
    background-color: ${Color.BACKGROUND_PRIMARY};
    border-radius: 16px;
    padding-vertical: 8px;
    padding-horizontal: 16px;
    margin: auto;
`;

const ChatListContainer = styled.View`
    padding-top: 24px;
    padding-horizontal: 8px;
`;

export function ChatArchive() {
    const { formatMessage } = useIntl();
    const { loading, chats } = useChatList({ onlyOpen: false });

    return (
        <ContentScrollView
            bounces
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ minHeight: '100%' }}
            overScrollMode="never"
        >
            {loading ? (
                <LoadingContent />
            ) : chats?.length ? (
                <ChatListContainer>
                    <ChatList chats={chats} />
                </ChatListContainer>
            ) : (
                <SystemMessage>
                    <Text.CAPTION>{formatMessage('chat-archive.notification.empty')}</Text.CAPTION>
                </SystemMessage>
            )}
        </ContentScrollView>
    );
}
