export function filter(str: string, regex: RegExp): string {
    return (str.match(regex) ?? []).join('');
}

export function cutoffString(str: string, maxLength: number): string {
    return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
}

export function compare(one: string, two: string): number {
    if (one > two) {
        return 1;
    }
    if (one < two) {
        return -1;
    }
    return 0;
}
