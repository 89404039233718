import React from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type BadgeProps = {
    badge: string;
};

const BadgeContainer = styled.View`
    border-radius: 14px;
    background-color: ${Color.CHAT_BADGE};
    align-items: center;
    justify-content: center;
    padding-horizontal: 5px;
    min-width: 23px;
    min-height: 20px;
`;

export const Badge = (props: BadgeProps) => {
    const { badge } = props;
    return (
        <BadgeContainer>
            <Text.CAPTION style={{ color: Color.TEXT_TERTIARY }}>{badge}</Text.CAPTION>
        </BadgeContainer>
    );
};
