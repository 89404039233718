import { Flex, Card } from 'native-base';
import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { Image } from '~/components/image';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { ArticleCategoryArticle, LayoutStyleProps } from '~/types';
import { strip } from '~/utils/markdown';

import { ArticleCarouselItem } from './article-types';

export type ArticleCarouselProps = {
    title?: string | null;
    description?: string | null;
    articles: readonly ArticleCategoryArticle[];
    onItemPressed: (item: ID) => void;
    layoutStyle?: LayoutStyleProps;
};

const CARD_HEIGHT = 316;
const CARD_WIDTH = 265;
const CARD_IMAGE_HEIGHT = 140;

export const ArticleCarousel: React.FC<ArticleCarouselProps> = props => {
    const { title, description, articles, onItemPressed } = props;

    const renderItem = useCallback(
        (info: ListRenderItemInfo<ArticleCarouselItem>) => {
            const { item } = info;
            const { coverPhotoUrl } = item;

            return (
                <Flex
                    key={item.id}
                    marginRight={Spacing.TINY}
                    borderRadius="16"
                    height={CARD_HEIGHT}
                    width={CARD_WIDTH}
                    grow={0}
                    shrink={0}
                    style={Shadow.styles.primary}
                >
                    <TouchableOpacity onPress={() => onItemPressed(item.id)} accessibilityRole="button" accessible>
                        <Card
                            backgroundColor={Color.BACKGROUND_DEFAULT}
                            shadow="none"
                            borderRadius="16"
                            height={CARD_HEIGHT}
                            padding={0}
                            accessibilityRole="button"
                        >
                            <EllipticMaskView ellipseSize="large" style={{ height: CARD_IMAGE_HEIGHT }}>
                                {coverPhotoUrl ? (
                                    <Image
                                        source={{ uri: coverPhotoUrl }}
                                        resizeMode="cover"
                                        style={[{ width: '100%', height: CARD_IMAGE_HEIGHT }]}
                                    />
                                ) : null}
                            </EllipticMaskView>

                            <Flex margin="8px 16px 0px">
                                <Text.HEADER_3 style={{ color: Color.HEADER }}>{item.title}</Text.HEADER_3>
                                <Text.PARAGRAPH_1 before={8} numberOfLines={3} after={0}>
                                    {strip(item.highlight ?? '')}
                                </Text.PARAGRAPH_1>
                            </Flex>
                        </Card>
                    </TouchableOpacity>
                </Flex>
            );
        },
        [onItemPressed]
    );

    return articles.length ? (
        <Flex marginTop={Spacing.SMALL} marginBottom={Spacing.SMALL}>
            {title ? <Text.HEADER_3 after={Spacing.LARGE}>{title}</Text.HEADER_3> : null}

            {description ? <Text.PARAGRAPH_1 after={Spacing.MEDIUM}>{description}</Text.PARAGRAPH_1> : null}

            <FlatList<ArticleCarouselItem>
                // Add positive padding and negative margin to place the cards at the correct position in the layout yet
                // allowing shadows to be rendered "outside" of the container
                style={{ margin: -Spacing.MEDIUM }}
                contentContainerStyle={{ height: CARD_HEIGHT + 2 * Spacing.MEDIUM, padding: Spacing.MEDIUM }}
                overScrollMode="never"
                data={articles}
                horizontal
                snapToInterval={CARD_WIDTH + Spacing.MEDIUM}
                snapToAlignment="start"
                decelerationRate="fast"
                renderItem={renderItem}
                showsHorizontalScrollIndicator={false}
            />
        </Flex>
    ) : null;
};
