import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';
import React from 'react';
import { ModalProps, Platform } from 'react-native';

import { HeaderBackButton, styles } from '~/components/header';

export const fadeCardStyle = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardStyleInterpolator: ({ current }: any) => ({
        cardStyle: {
            opacity: current.progress
        }
    })
};

export const noHeader = {
    headerShown: false,
    title: ''
};

export const header: StackNavigationOptions = {
    headerShown: true,
    headerTitleAlign: 'left',
    headerTitleStyle: styles.headerTitle,
    headerLeftContainerStyle: styles.headerLeft,
    headerRightContainerStyle: styles.headerRight,
    headerBackTitleVisible: false,
    headerLeft: () => <HeaderBackButton />
};

export const transparentHeader: StackNavigationOptions = {
    ...header,
    headerTransparent: true
};

export const transparentHeaderWithoutTitle: StackNavigationOptions = {
    ...header,
    headerTransparent: true,
    title: ''
};

export const transparentHeaderWithoutTitleOrBack: StackNavigationOptions = {
    ...header,
    headerLeft: () => <></>,
    headerRight: () => <></>,
    headerTransparent: true,
    title: ''
};

export const modal = Platform.select<ModalProps>({
    ios: { animationType: 'slide', presentationStyle: 'formSheet', hardwareAccelerated: true },
    web: { animationType: 'fade', hardwareAccelerated: true }
});
