import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { View } from 'react-native';

import { OvalButton } from '~/components/button';
import { CloseIcon } from '~/components/icon';
import { Spacing } from '~/components/spacing';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type HomeNavigationProp = StackNavigationProp<MainNavigatorParamList>;

export type HeaderCloseButtonProps = {
    onPress?: () => void;
};

export const HeaderCloseButton: React.FC<HeaderCloseButtonProps> = props => {
    const { onPress } = props;
    const { canGoBack, goBack, navigate } = useNavigation<HomeNavigationProp>();

    const handlePress = () => {
        if (onPress) {
            onPress();
        } else if (canGoBack()) {
            goBack();
        } else {
            navigate('home');
        }
    };

    return (
        <View style={{ padding: Spacing.MEDIUM }}>
            <OvalButton size="small" icon={CloseIcon} onPress={handlePress} />
        </View>
    );
};
