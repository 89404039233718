import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ChevronLeftIcon: FunctionComponent<IconProps> = ({ fill, size = 24 }) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M14 6L8 12L14 18"
                    strokeLinecap="round"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                    fill="none"
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
