import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

type ModalScreenContainerProps = object;

const Modal = styled.View`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

export const ModalScreenContainer: FunctionComponent<PropsWithChildren<ModalScreenContainerProps>> = ({ children }) => {
    return <Modal>{children}</Modal>;
};
