import { useBreakpointValue } from 'native-base';

export type ContentWidth = 'small' | 'narrow' | 'midsize' | 'default' | 'full';

export const useContentWidths = (contentWidth: ContentWidth, includePadding: boolean = true) => {
    /* normal width content i.e. home screen */
    const defaultContentWidths = useBreakpointValue({
        base: '100%',
        xl: 1136
    });

    const defaultContentMargin = useBreakpointValue({
        base: 0,
        xl: '72px'
    });

    /* mid sized content i.e. article  */
    const midsizeContentWidths = useBreakpointValue({
        base: '100%',
        lg: 1000
    });

    /* narrow content i.e. appointment preview */
    const narrowContentWidths = useBreakpointValue({
        base: '100%',
        md: 716
    });

    const narrowContentMargin = useBreakpointValue({
        base: 0,
        xl: '298px'
    });

    /* small content i.e. questionnaire questions */
    const smallContentWidths = useBreakpointValue({
        base: '100%',
        md: 578
    });

    let maxWidth;
    let margin = 0;
    switch (contentWidth) {
        case 'small':
            maxWidth = smallContentWidths;
            break;
        case 'narrow':
            maxWidth = narrowContentWidths;
            margin = narrowContentMargin;
            break;
        case 'midsize':
            maxWidth = midsizeContentWidths;
            break;
        case 'full':
            maxWidth = '100%';
            break;
        default:
            maxWidth = defaultContentWidths;
            margin = defaultContentMargin;
    }

    const padding = includePadding ? '16px' : 0;

    return {
        maxWidth,
        margin,
        padding
    };
};
