import { AuthenticationType } from 'expo-local-authentication/src/LocalAuthentication.types';
import React from 'react';
import styled from 'styled-components/native';

import { PinCodeDisplay } from '~/components/pin-code/pin-code-display';
import { PinCodeKeyboard } from '~/components/pin-code/pin-code-keyboard';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { AuthActionResult, BiometricAuthActionResult } from '~/contexts/auth';
import { usePinCodeAuthenticate, usePinCodeSetup } from '~/hooks/pin-code';

export const PinCodeContainer = styled.View`
    flex-direction: column;
    align-items: center;
`;

export type PinCodeSetupProps = {
    onPinCodeSet: (pin: string) => void;
    onPinCodeClear?: () => void;
    length?: number;
};

/**
 * @deprecated Use `PinCodeDisplay`, `PinCodeKeyboard` and `usePinCodeSetup` instead
 */
export const PinCodeSetup = (props: PinCodeSetupProps) => {
    const { states, message, input, handleEnter, handleDelete } = usePinCodeSetup(props);

    return (
        <PinCodeContainer>
            <PinCodeDisplay states={states} />
            <Text.CAPTION>{message}</Text.CAPTION>
            <PinCodeKeyboard value={input} onKeyPress={handleEnter} onDelete={handleDelete} />
        </PinCodeContainer>
    );
};

PinCodeSetup.displayName = 'PinCodeSetup';

export type PinCodeAuthenticateProps = {
    onPinCodeAuthenticate: (pin: string) => Promise<AuthActionResult>;
    onPinCodeAuthenticateFailed: (reason?: string) => Promise<void>;
    onPinCodeClear?: () => void;
    length?: number;
    biometricAuthType?: AuthenticationType;
    authenticateOnMount?: boolean;
    onBiometricAuthenticate?: () => Promise<BiometricAuthActionResult>;
};

/**
 * @deprecated Use `PinCodeDisplay`, `PinCodeKeyboard` and `usePinCodeAuthenticate` instead
 */
export const PinCodeAuthenticate = (props: PinCodeAuthenticateProps) => {
    const { biometricAuthType } = props;

    const { states, message, input, handleEnter, handleDelete, biometricAuthenticate } = usePinCodeAuthenticate(props);

    return (
        <PinCodeContainer>
            <PinCodeDisplay states={states} />

            <Spacing.Vertical.SMALL />

            <Text.CAPTION>{message}</Text.CAPTION>

            <Spacing.Vertical.SMALL />

            <PinCodeKeyboard
                value={input}
                onKeyPress={handleEnter}
                onDelete={handleDelete}
                biometricAuthType={biometricAuthType}
                onBioMetricAuthenticate={biometricAuthenticate}
            />
        </PinCodeContainer>
    );
};

PinCodeAuthenticate.displayName = 'PinCodeAuthenticate';
