import React from 'react';
import { View } from 'react-native';
import { v4 as uuid } from 'uuid';

import { CenteredEllipticMask } from '~/components/elliptic-mask/centered-elliptic-mask';
import { CircleMask } from '~/components/elliptic-mask/circle-mask';
import { Image } from '~/components/image';
import { MaskedView } from '~/components/masked-view';

export type AvatarSize = 'small' | 'tiny' | 'regular' | 'large';

const avatarSize: Record<AvatarSize, number> = {
    small: 34,
    tiny: 24,
    regular: 74,
    large: 80
};

export type Props = {
    imageUri?: string | null;
    size: AvatarSize;
};

export const Avatar = (props: Props) => {
    const { imageUri, size } = props;
    const largish = ['regular', 'large'].includes(size);
    const width = avatarSize[size];
    const maskId = uuid();

    return (
        <View style={{ width, height: width }}>
            <MaskedView
                style={{ width, height: width }}
                maskId={maskId}
                maskElement={largish ? <CenteredEllipticMask maskId={maskId} /> : <CircleMask maskId={maskId} />}
            >
                <Image source={{ uri: imageUri ?? '' }} style={{ width, aspectRatio: 1 }} />
            </MaskedView>
        </View>
    );
};
