import { useMutation } from '@apollo/client';
import { ReactNativeFile } from 'apollo-upload-client';
import { useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import * as mime from 'react-native-mime-types';

import { AppError } from '~/error';
import { ChatMessageAddDocument } from '~/types';

import { Attachment } from './attachment';

export type NewChatMessage = {
    text: string;
    attachments?: Attachment[];
};

const fileFromAttachment = (attachment: Attachment): ReactNativeFile => {
    const type = mime.lookup(attachment.name) || undefined;
    return new ReactNativeFile({ uri: attachment.uri, name: attachment.name, type });
};

export const useAddChatMessage = (chatId: ID) => {
    const [sendMessageMutation] = useMutation(ChatMessageAddDocument);
    const errorHandler = useErrorHandler();

    const sendMessage = useCallback(
        async (newMessage: NewChatMessage) => {
            const { text, attachments = [] } = newMessage;
            try {
                await sendMessageMutation({
                    variables: {
                        input: {
                            chatId,
                            message: text,
                            files: attachments.map(attachment =>
                                attachment.file ? attachment.file : fileFromAttachment(attachment)
                            )
                        }
                    }
                });
            } catch (err) {
                if (err instanceof Error) {
                    errorHandler(
                        new AppError(err, 'error.cannot-add-message', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    errorHandler(err);
                }
            }
        },
        [chatId, errorHandler, sendMessageMutation]
    );

    return useMemo(
        () => ({
            sendMessage
        }),
        [sendMessage]
    );
};
