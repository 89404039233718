import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex } from 'native-base';
import React, { useCallback } from 'react';
import { Alert } from 'react-native';

import BiometricAuthImage from '~/assets/images/auth-biometric.svg';
import { Button, TextButton } from '~/components/button';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useAuth } from '~/contexts/auth';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import * as ErrorDiagnostics from '~/error';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';

type OnboardingBiometricAuthPermissionsNavigation = CompositeNavigationProp<
    StackNavigationProp<OnboardingNavigatorParamList, 'biometric-auth'>,
    StackNavigationProp<AppNavigatorParamList>
>;

export const OnboardingBiometricAuthPermissions = () => {
    const { reset } = useNavigation<OnboardingBiometricAuthPermissionsNavigation>();
    const { nextTopic } = useOnboardingTopics();
    const { biometric } = useAuth();
    const { formatMessage } = useIntl();
    const { callName } = useUserProfile();

    const next = useCallback(
        () => reset({ index: 0, routes: [{ name: nextTopic('biometric-auth') }] }),
        [nextTopic, reset]
    );

    const handleEnableBiometricAuth = useCallback(async () => {
        if (
            !(await biometric.enable({
                promptMessage: formatMessage('auth-pin-authenticate.biometric.prompt', {
                    type: biometric.types?.[0]
                }),
                cancelLabel: formatMessage('auth-pin-authenticate.biometric.cancel'),
                fallbackLabel: formatMessage('auth-pin-authenticate.biometric.fallback'),
                disableDeviceFallback: false
            }))
        ) {
            Alert.alert(
                formatMessage('error.alert.title'),
                formatMessage('onboarding-biometric-auth.error.cannot_enable', {
                    type: biometric.types?.[0].toString()
                }),
                [
                    {
                        text: formatMessage('ok'),
                        style: 'default'
                    }
                ]
            );
        } else {
            next();
        }
    }, [next, biometric, formatMessage]);

    const handleDisableBiometricAuth = useCallback(async () => {
        try {
            await biometric.disable();
        } catch (error) {
            ErrorDiagnostics.error(error);
        } finally {
            next();
        }
    }, [next, biometric]);

    return (
        <EllipticHeaderScreen
            renderTopContent={() => <BiometricAuthImage />}
            renderEllipticMask
            renderBottomContent={() => (
                <>
                    <Text.TITLE>
                        <FormattedMessage id="onboarding-biometric-auth.hello" values={{ name: callName }} />
                    </Text.TITLE>
                    <Spacing.Vertical.MEDIUM />
                    <Text.PARAGRAPH_1>
                        <FormattedMessage id="onboarding-biometric-authenticate.description" />
                    </Text.PARAGRAPH_1>
                    <Spacing.Vertical.MEDIUM />
                </>
            )}
            renderActions={() => (
                <Flex direction="row" mx="4" alignItems="center">
                    <TextButton
                        fillContainer
                        onPress={handleDisableBiometricAuth}
                        label={formatMessage('onboarding.pass')}
                    />
                    <Button
                        fillContainer
                        type="primary"
                        onPress={handleEnableBiometricAuth}
                        label={formatMessage('onboarding.enable')}
                    />
                </Flex>
            )}
        />
    );
};
