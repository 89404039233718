export const SpacingConst = {
    TINY: 4,
    SMALL: 8,
    MEDIUM: 16,
    LARGE: 24,
    HUGE: 32,
    GIGANTIC: 44,
    GINORMOUS: 56,
    MASSIVE: 72
};
