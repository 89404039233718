import { Flex } from 'native-base';
import React, { PropsWithChildren } from 'react';

import { ContentWidth, useContentWidths } from '~/hooks/content-widths/content-widths';
import { isWeb } from '~/utils';

type ContentHorizontalMarginsProps = PropsWithChildren & { contentWidth?: ContentWidth; includePadding?: boolean };

const WebHorizontalMargins: React.FC<ContentHorizontalMarginsProps> = props => {
    const { children, contentWidth = 'default', includePadding = true } = props;

    const { margin, padding, maxWidth } = useContentWidths(contentWidth, includePadding);

    return (
        <Flex flexDirection="column" alignItems="center" grow="1" shrink="1">
            <Flex
                flex={1}
                flexDirection="row"
                maxWidth={maxWidth}
                marginLeft={margin}
                marginRight={margin}
                paddingLeft={padding}
                paddingRight={padding}
                width="100%"
            >
                {children}
            </Flex>
        </Flex>
    );
};

const MobileHorizontalMargins: React.FC<ContentHorizontalMarginsProps> = props => {
    const { children, includePadding = true } = props;

    const padding = includePadding ? '16px' : 0;

    return (
        <Flex paddingLeft={padding} paddingRight={padding} flexDirection="column" flexGrow={1}>
            {children}
        </Flex>
    );
};

export const ContentHorizontalMargins: React.FC<ContentHorizontalMarginsProps> = props => {
    const { children } = props;

    if (isWeb()) {
        return <WebHorizontalMargins {...props}>{children}</WebHorizontalMargins>;
    } else {
        return <MobileHorizontalMargins {...props}>{children}</MobileHorizontalMargins>;
    }
};
