import React, { PropsWithChildren } from 'react';
import { Pressable, Text as NativeText, TextProps as NativeTextProps } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';
import { Text } from '~/components/text';

export type TextButtonType = 'text' | 'link' | 'radio';
export type TextButtonSize = 'tiny' | 'small' | 'regular' | 'large';

type TextButtonState = 'disabled' | 'enabled' | 'hover' | 'select' | 'selected';

type TextButtonVariant = `${TextButtonType}-${TextButtonState}`;
type TextButtonVariantColors = {
    [key in TextButtonVariant]: string;
};

export type TextButtonProps = {
    label: string;
    type?: TextButtonType;
    fillContainer?: boolean;
    loading?: boolean;
    disabled?: boolean;
    selected?: boolean;
    size?: TextButtonSize;
    leftIcon?: React.ComponentType<IconProps>;
    rightIcon?: React.ComponentType<IconProps>;
    onPress?: () => void | Promise<void>;
};

const Container = styled.View`
    align-items: center;
    flex-direction: row;

    background-color: transparent;
    justify-content: center;
`;

const IconContainer = styled.View`
    padding-horizontal: 4px;
`;

type LabelProps = PropsWithChildren<{ state: TextButtonState; type: TextButtonType; size: TextButtonSize }>;

const Label = (props: NativeTextProps & LabelProps) => {
    const { state, type, size, children } = props;
    const styles = ['tiny', 'small'].includes(size) ? Text.SMALL_BUTTON_LABEL_STYLES : Text.BUTTON_LABEL_STYLES;
    const color = FOREGROUND_COLOR[`${type}-${state}` as TextButtonVariant];
    const fontSize = { tiny: 12, small: 14, regular: 16, large: 16 }[size];
    const textDecorationLine = type === 'link' ? 'underline' : 'none';
    return <NativeText style={{ ...styles, fontSize, color, textDecorationLine }}>{children}</NativeText>;
};

const FOREGROUND_COLOR: TextButtonVariantColors = {
    'text-disabled': Color.TEXT_DISABLED,
    'text-enabled': Color.ALMOST_BLACK,
    'text-hover': Color.ALMOST_BLACK,
    'text-select': Color.ALMOST_BLACK,
    'text-selected': Color.ALMOST_BLACK,

    'link-disabled': Color.TEXT_DISABLED,
    'link-enabled': Color.TEXT_SECONDARY,
    'link-hover': Color.TEXT_SECONDARY,
    'link-select': Color.ALMOST_BLACK,
    'link-selected': Color.ALMOST_BLACK,

    'radio-disabled': Color.TEXT_DISABLED,
    'radio-enabled': Color.TEXT_SECONDARY,
    'radio-hover': Color.TEXT_SECONDARY,
    'radio-select': Color.SMOOTHIE,
    'radio-selected': Color.FOCUS_DEFAULT
};

const state = (selected: boolean, disabled: boolean, pressed: boolean) =>
    pressed ? 'select' : disabled ? 'disabled' : selected ? 'selected' : 'enabled';

export const TextButton = (props: TextButtonProps) => {
    const {
        label,
        type = 'text',
        size = 'regular',
        disabled = false,
        selected = false,
        fillContainer = false,
        onPress
    } = props;
    const { leftIcon: LeftIcon, rightIcon: RightIcon } = props;

    return (
        <Pressable
            accessibilityRole="button"
            accessibilityLabel="Text button"
            disabled={disabled}
            onPress={onPress}
            style={{ flex: fillContainer ? 1 : 0 }}
        >
            {({ pressed }) => {
                return (
                    <Container>
                        {LeftIcon && (
                            <IconContainer>
                                <LeftIcon fill={disabled ? Color.ICON_DISABLED : Color.ALMOST_BLACK} />
                            </IconContainer>
                        )}
                        <Label state={state(selected, disabled, pressed)} type={type} size={size}>
                            {label}
                        </Label>
                        {RightIcon && <RightIcon fill={disabled ? Color.ICON_DISABLED : Color.ALMOST_BLACK} />}
                    </Container>
                );
            }}
        </Pressable>
    );
};

TextButton.displayName = 'TextButton';

export type LinkButtonProps = Omit<TextButtonProps, 'type'>;

export const LinkButton = (props: LinkButtonProps) => <TextButton {...props} type="link" />;

LinkButton.displayName = 'LinkButton';

export type RadioTextButtonProps = Omit<TextButtonProps, 'type'> & { selected: boolean };

export const RadioTextButton = (props: LinkButtonProps) => <TextButton {...props} type="radio" />;

RadioTextButton.displayName = 'RadioTextButton';
