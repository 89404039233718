import { useEffect, useRef } from 'react';

export function usePreviousValue<T>(value: T): T {
    const prevValue = useRef<T>(value);

    useEffect(() => {
        prevValue.current = value;
    });

    return prevValue.current;
}
