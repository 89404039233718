export type ActionState = 'init' | 'loading' | 'done';
export type Actions = 'request' | 'success';

export type State = {
    state: ActionState;
};

export const singleAsyncActionStateReducer = ({ state }: State, action: Actions): State => {
    if (state === 'init' && action === 'request') {
        return {
            state: 'loading'
        };
    } else if (state === 'loading' && action === 'success') {
        return {
            state: 'done'
        };
    }
    return { state };
};
