// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectMap<K extends string, V, R = any>(
    obj: Record<K, V>,
    func: (value: V, key: K, index: number) => R
) {
    const mapped = Object.fromEntries<R>(
        Object.entries<V>(obj).map(([key, value], i) => [key, func(value as V, key as K, i)])
    );
    return mapped as Record<K, R>;
}

type Entry<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T];

export function objectFilter<T extends object, V extends object>(
    obj: T,
    fn: (entry: Entry<T>, i: number, arr: Entry<T>[]) => boolean
) {
    return Object.fromEntries((Object.entries(obj) as Entry<T>[]).filter(fn)) as Partial<T> as V;
}
