import React, { PropsWithChildren } from 'react';
import { Platform, Text as NativeText, TextProps, TextStyle } from 'react-native';

import { Color } from '~/components/color';
import { objectMap } from '~/utils/object';

import { FontFamily } from './text-fonts';

export const BODY_FONT_FAMILY = Platform.select({ ios: 'Helvetica', android: 'Roboto', web: 'sans-serif' });

type MarginProps = {
    before?: boolean | number;
    after?: boolean | number;
};

type NativeTextParams = [
    FontFamily,
    TextStyle['fontSize'], // font-size
    TextStyle['fontWeight'], // font-weight
    TextStyle['lineHeight'], // line-height
    TextStyle['letterSpacing'], // letter-spacing
    number, // paragraph-spacing (margin before/after)
    TextStyle['textTransform']?,
    TextStyle['color']?
];

const styles: Record<string, NativeTextParams> = {
    TITLE: [FontFamily.NOI_GROTESK_MEDIUM, 32, '400', 36, -0.25, 16, undefined, Color.HEADER],
    HEADER_1: [FontFamily.NOI_GROTESK_MEDIUM, 32, '400', 36, -0.5, 16],
    HEADER_2: [FontFamily.NOI_GROTESK_MEDIUM, 24, '400', 32, 0.02, 16],
    HEADER_3: [FontFamily.NOI_GROTESK_MEDIUM, 20, '400', 24, 0.02, 8],
    HEADER_4: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '500', 24, 0.02, 8],
        android: [FontFamily.ROBOTO_MEDIUM, 16, '500', 24, 0.02, 8],
        web: [FontFamily.ROBOTO_MEDIUM, 16, '500', 24, 0.02, 8]
    })!,
    HEADER_5: Platform.select<NativeTextParams>({
        ios: [FontFamily.SF_COMPACT_MEDIUM, 14, '400', 16, 0, 8, 'uppercase'],
        android: [FontFamily.ROBOTO_MEDIUM, 14, '400', 16, 0, 8, 'uppercase'],
        web: [FontFamily.ROBOTO_MEDIUM, 14, '400', 16, 0, 8, 'uppercase']
    })!,
    HEADER_6: Platform.select<NativeTextParams>({
        ios: [FontFamily.SF_COMPACT_BOLD, 14, '400', 16, 0, 8],
        android: [FontFamily.ROBOTO_BOLD, 14, '400', 16, 0, 8],
        web: [FontFamily.ROBOTO_BOLD, 14, '400', 16, 0, 8]
    })!,
    SUBTITLE_1: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '700', 20, 0, 8],
        android: [FontFamily.ROBOTO_MEDIUM, 16, '700', 20, 0.15, 8],
        web: [FontFamily.ROBOTO_MEDIUM, 16, '700', 20, 0.15, 8]
    })!,
    SUBTITLE_2: Platform.select<NativeTextParams>({
        ios: [FontFamily.SF_COMPACT_MEDIUM, 14, '500', 16, -0.5, 8, 'uppercase'],
        android: [FontFamily.ROBOTO_MEDIUM, 14, '500', 16, 0.15, 8, 'uppercase'],
        web: [FontFamily.ROBOTO_MEDIUM, 14, '500', 16, 0.15, 8, 'uppercase']
    })!,
    LEAD_1: [FontFamily.NOI_GROTESK_REGULAR, 20, '400', 28, 0, 8],
    LEAD_2: [FontFamily.NOI_GROTESK_REGULAR, 18, '400', 24, 0, 8],
    SYSTEM: [FontFamily.SYSTEM, 16, '400', 20, 0, 12],
    SYSTEM_TITLE: [FontFamily.SYSTEM, 18, '700', 20, 0, 12],
    PARAGRAPH_1: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '400', 24, -0.1, 24],
        android: [FontFamily.ROBOTO_REGULAR, 16, '400', 24, 0, 24],
        web: [FontFamily.ROBOTO_REGULAR, 16, '400', 24, 0, 24]
    })!,
    PARAGRAPH_2: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '500', 20, -0.32, 24],
        android: [FontFamily.ROBOTO_MEDIUM, 16, '500', 20, 0.25, 24],
        web: [FontFamily.ROBOTO_MEDIUM, 16, '500', 20, 0.25, 24]
    })!,
    PARAGRAPH_3: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '700', 20, -0.32, 24],
        android: [FontFamily.ROBOTO_BOLD, 16, '700', 20, 0.25, 24],
        web: [FontFamily.ROBOTO_BOLD, 16, '700', 20, 0.25, 24]
    })!,
    CAPTION: Platform.select<NativeTextParams>({
        ios: [FontFamily.SF_COMPACT_REGULAR, 14, '500', 20, 0, 8],
        android: [FontFamily.ROBOTO_REGULAR, 14, '500', 20, 0.25, 8],
        web: [FontFamily.ROBOTO_REGULAR, 14, '500', 20, 0.25, 8]
    })!,
    KICKER: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 12, '700', 12, -0.55, 8, 'uppercase'],
        android: [FontFamily.ROBOTO_BOLD, 11, '500', 12, 0, 8, 'uppercase'],
        web: [FontFamily.ROBOTO_BOLD, 11, '500', 12, 0, 8, 'uppercase']
    })!,
    BUTTON_LABEL: Platform.select<NativeTextParams>({
        ios: [FontFamily.SYSTEM, 16, '500', 20, 0, 8],
        android: [FontFamily.ROBOTO_REGULAR, 16, '500', 20, 0.5, 8],
        web: [FontFamily.ROBOTO_REGULAR, 16, '500', 20, 0.5, 8]
    })!,
    SMALL_BUTTON_LABEL: Platform.select<NativeTextParams>({
        ios: [FontFamily.SF_COMPACT_MEDIUM, 14, '500', 18, -0.16, 8],
        android: [FontFamily.ROBOTO_REGULAR, 14, '500', 18, 0.8, 8],
        web: [FontFamily.ROBOTO_REGULAR, 14, '500', 18, 0.8, 8]
    })!
};

// Alias some styles for clearer explicit naming
styles.INPUT_FIELD_TITLE = styles.HEADER_6;

const getMargin = (value: boolean | number | undefined, defaultMargin: number): number => {
    if (typeof value === 'boolean') {
        return value ? defaultMargin : 0;
    }
    if (typeof value === 'number') {
        return value;
    }
    return 0;
};

const getNativeStyles = (style: keyof typeof styles) => {
    const [fontFamily, fontSize, fontWeight, lineHeight, letterSpacing, , textTransform, color] = styles[style];
    return {
        fontFamily,
        fontSize,
        fontWeight,
        lineHeight: lineHeight!,
        letterSpacing,
        textTransform,
        color: color ?? Color.ALMOST_BLACK
    };
};

type Props = PropsWithChildren<TextProps & MarginProps>;

const TextComponents = objectMap<keyof typeof styles, NativeTextParams, React.ComponentType<Props>>(
    styles,
    ([fontFamily, fontSize, fontWeight, lineHeight, letterSpacing, margin, textTransform, color], key) => {
        const component = (props: Props) => {
            const { style, after, before, ...rest } = props;
            const textColor = color ?? Color.ALMOST_BLACK;
            return (
                <NativeText
                    accessibilityRole="text"
                    {...rest}
                    style={[
                        {
                            color: textColor,
                            fontFamily,
                            fontSize,
                            fontWeight,
                            lineHeight,
                            letterSpacing,
                            textTransform,
                            marginTop: getMargin(props.before, margin),
                            marginBottom: getMargin(props.after, margin)
                        },
                        style
                    ]}
                >
                    {props.children}
                </NativeText>
            );
        };
        component.displayName = `Text.${key}`;

        return component;
    }
);

export const Text = {
    SYSTEM: TextComponents.SYSTEM,
    SYSTEM_TITLE: TextComponents.SYSTEM_TITLE,

    TITLE: TextComponents.TITLE,
    HEADER_1: TextComponents.HEADER_1,
    HEADER_2: TextComponents.HEADER_2,
    HEADER_3: TextComponents.HEADER_3,
    SUBTITLE_1: TextComponents.SUBTITLE_1,
    SUBTITLE_2: TextComponents.SUBTITLE_2,
    LEAD_1: TextComponents.LEAD_1,
    LEAD_2: TextComponents.LEAD_2,
    PARAGRAPH_1: TextComponents.PARAGRAPH_1,
    PARAGRAPH_2: TextComponents.PARAGRAPH_2,
    PARAGRAPH_3: TextComponents.PARAGRAPH_3,
    CAPTION: TextComponents.CAPTION,
    KICKER: TextComponents.KICKER,
    BUTTON_LABEL: TextComponents.BUTTON_LABEL,
    SMALL_BUTTON_LABEL: TextComponents.SMALL_BUTTON_LABEL,
    INPUT_FIELD_TITLE: TextComponents.INPUT_FIELD_TITLE,

    LEAD_1_STYLES: getNativeStyles('LEAD_1'),
    LEAD_2_STYLES: getNativeStyles('LEAD_2'),
    BUTTON_LABEL_STYLES: getNativeStyles('BUTTON_LABEL'),
    SMALL_BUTTON_LABEL_STYLES: getNativeStyles('SMALL_BUTTON_LABEL'),
    PARAGRAPH_1_STYLES: getNativeStyles('PARAGRAPH_1'),
    PARAGRAPH_2_STYLES: getNativeStyles('PARAGRAPH_2'),
    PARAGRAPH_3_STYLES: getNativeStyles('PARAGRAPH_3'),
    CAPTION_STYLES: getNativeStyles('CAPTION'),
    KICKER_STYLES: getNativeStyles('KICKER'),
    HEADER_1_STYLES: getNativeStyles('HEADER_1'),
    HEADER_2_STYLES: getNativeStyles('HEADER_2'),
    HEADER_3_STYLES: getNativeStyles('HEADER_3'),
    HEADER_4_STYLES: getNativeStyles('HEADER_4'),
    HEADER_5_STYLES: getNativeStyles('HEADER_5'),
    HEADER_6_STYLES: getNativeStyles('HEADER_6'),
    SUBTITLE_1_STYLES: getNativeStyles('SUBTITLE_1'),
    SUBTITLE_2_STYLES: getNativeStyles('SUBTITLE_2'),

    H1: TextComponents.HEADER_1,
    H2: TextComponents.HEADER_2,
    H3: TextComponents.HEADER_3,
    S1: TextComponents.SUBTITLE_1,
    S2: TextComponents.SUBTITLE_2,
    L1: TextComponents.LEAD_1,
    L2: TextComponents.LEAD_2,
    P1: TextComponents.PARAGRAPH_1,
    P2: TextComponents.PARAGRAPH_2,
    P3: TextComponents.PARAGRAPH_3
};
