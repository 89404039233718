/*
 * Non-secure storage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import { AppLocale } from '~/contexts/intl/intl';

enum IntlStorageKey {
    LOCALE = 'locale'
}

export const getLocale = () => AsyncStorage.getItem(IntlStorageKey.LOCALE) as Promise<AppLocale | null>;

export const setLocale = (locale: AppLocale) => AsyncStorage.setItem(IntlStorageKey.LOCALE, locale);

export const clearLocale = () => AsyncStorage.removeItem(IntlStorageKey.LOCALE);
