import { RouteProp, useNavigation } from '@react-navigation/native';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { CheckBox } from '~/components/check';
import { Color } from '~/components/color';
import { HeaderCloseButton } from '~/components/header';
import { ScreenContainer } from '~/components/screen';
import { Text } from '~/components/text';
import { AppointmentContactTypeValues } from '~/contexts/appointments-selection';
import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { AppointmentFilters } from '~/types';

import { toggleCheckFilter, isFilterSet } from './appointments-filters-utils';

import { AppointmentsNavigationProp } from './index';

export type AppointmentsFilterRouteProp = RouteProp<MainNavigatorParamList, 'appointments-filters'>;

const BottomBarContainer = styled.View`
    width: 100%;
    padding-horizontal: 32px;
    padding-vertical: 24px;
    border-top-width: 1px;
    border-top-color: ${Color.LINE_DEFAULT};
`;

type BottomBarProps = {
    onPress: () => void;
};

function BottomBar(props: BottomBarProps) {
    const { onPress } = props;
    const { formatMessage } = useIntl();
    return (
        <BottomBarContainer accessibilityRole="button">
            <Button label={formatMessage('show')} type="primary" alignCenter onPress={onPress} />
        </BottomBarContainer>
    );
}

const Content = styled.ScrollView`
    padding-vertical: 24px;
    padding-horizontal: 16px;
`;

const Subtitle = styled.View`
    margin-bottom: 11px;
`;

const Section = styled.View`
    padding-bottom: 24px;
`;

export function AppointmentsFilters() {
    const { params } = useRoute<AppointmentsFilterRouteProp>();
    const { formatMessage } = useIntl();
    const { navigate, setOptions } = useNavigation<AppointmentsNavigationProp>();

    const [filters, setFilters] = useState<AppointmentFilters>(params.filters ?? {});

    useLayoutEffect(() => {
        setOptions({
            headerLeft: () => <HeaderCloseButton />,
            headerTitle: () => <Text.HEADER_3>{formatMessage('appointment.filter')}</Text.HEADER_3>,
            headerTitleAlign: 'left'
        });
    }, [formatMessage, setOptions]);

    const handleShowFiltered = () => {
        navigate('appointments', {
            calendarReservationAccessId: params.calendarReservationAccessId,
            filters
        });
    };

    /* TODO: Advanced filters are not yet supported on the backend, so temporarily disable UI options
    const selectedIndex = (items: readonly string[], item?: string | null): number =>
        items.findIndex(i => i === (item ?? 'ANY'));

    const genderRadioGroupValues = [...AppointmentSpecialistGenderValues, 'ANY'];
    const ageGroupRadioGroupValues = [...AppointmentSpecialistAgeGroupValues, 'ANY'];
    */

    return (
        <ScreenContainer>
            <Content>
                {!params.remote && (
                    <Section>
                        <Subtitle>
                            <Text.SUBTITLE_1>{formatMessage('appointment.type.label')}</Text.SUBTITLE_1>
                        </Subtitle>
                        {AppointmentContactTypeValues.map(contactType => (
                            <CheckBox
                                key={contactType}
                                selected={isFilterSet(filters, 'contactTypes', contactType)}
                                label={formatMessage(`appointment.type.${contactType}`)}
                                onPress={() => setFilters(toggleCheckFilter(filters, 'contactTypes', contactType))}
                                style={{ marginTop: 5 }}
                            />
                        ))}
                    </Section>
                )}
                {/* TODO: Advanced filters are not yet supported on the backend, so temporarily disable UI options
                <Section>
                    <Subtitle>
                        <Text.SUBTITLE_1>{formatMessage('appointment.language.label')}</Text.SUBTITLE_1>
                    </Subtitle>
                    {AppointmentSpecialistLanguageValues.map(language => (
                        <CheckBox
                            key={language}
                            label={formatMessage(`appointment.language.${language}`)}
                            selected={isFilterSet(filters, 'specialistLanguages', language)}
                            onPress={() => setFilters(toggleCheckFilter(filters, 'specialistLanguages', language))}
                            style={{ marginTop: 5 }}
                        />
                    ))}
                </Section>
                <Section>
                    <Subtitle>
                        <Text.SUBTITLE_1>{formatMessage('appointment.gender.label')}</Text.SUBTITLE_1>
                    </Subtitle>
                    <RadioGroup
                        selectedIndex={selectedIndex(genderRadioGroupValues, filters?.specialistGender)}
                        onChange={index =>
                            setFilters(
                                genderRadioGroupValues[index] !== 'ANY'
                                    ? setRadioFilter(filters, 'specialistGender', genderRadioGroupValues[index])
                                    : clearRadioFilter(filters, 'specialistGender')
                            )
                        }
                    >
                        {genderRadioGroupValues.map(gender => (
                            <RadioButton
                                key={gender}
                                label={formatMessage(`appointment.gender.${gender}` as AppMessageId)}
                            />
                        ))}
                    </RadioGroup>
                </Section>
                <Section>
                    <Subtitle>
                        <Text.SUBTITLE_1>{formatMessage('appointment.age.label')}</Text.SUBTITLE_1>
                    </Subtitle>
                    <RadioGroup
                        selectedIndex={selectedIndex(ageGroupRadioGroupValues, filters?.specialistAgeGroup)}
                        onChange={index =>
                            setFilters(
                                ageGroupRadioGroupValues[index] !== 'ANY'
                                    ? setRadioFilter(filters, 'specialistAgeGroup', ageGroupRadioGroupValues[index])
                                    : clearRadioFilter(filters, 'specialistAgeGroup')
                            )
                        }
                    >
                        {ageGroupRadioGroupValues.map(ageGroup => (
                            <RadioButton
                                key={ageGroup}
                                label={formatMessage(`appointment.age.${ageGroup}` as AppMessageId)}
                            />
                        ))}
                    </RadioGroup>
                </Section>
                */}
            </Content>
            <BottomBar onPress={handleShowFiltered} />
        </ScreenContainer>
    );
}
