import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { Spacing } from '~/components/spacing';

const HeaderContainer = styled.View<{ topInset: number }>`
    align-items: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
    padding-top: ${({ topInset }) => topInset + Spacing.SMALL}px;
    padding-bottom: ${Spacing.SMALL}px;
`;

type Props = {
    devMenuAccess?: boolean;
};

export const ScreenHeaderWithLogo: React.FC<Props> = props => {
    const { top } = useSafeAreaInsets();

    return (
        <HeaderContainer topInset={top}>
            <HorizontalLogo devMenuAccess={props.devMenuAccess} />
        </HeaderContainer>
    );
};
