import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const MinimizeIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M3 20.9998L10 13.9998"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <Path
                    d="M11.0011 19.0003L11.0006 13.9998C11.0006 13.4475 10.5529 12.9999 10.0006 12.9999L5.00045 12.9999"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path d="M21 3L14 10" stroke={fill ?? Color.ALMOST_BLACK} strokeWidth="2" strokeLinecap="round" />
                <Path
                    d="M13 4.99957L13.0002 9.99977C13.0002 10.552 13.4479 10.9997 14.0002 10.9997L19.0001 10.9997"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
};
