import { useDimensions } from '@react-native-community/hooks';
import React, { useEffect, useState } from 'react';
import { Image, ImageLoadEventData, NativeSyntheticEvent } from 'react-native';

import { UrlImageProps } from './url-image';

export const UrlImage: React.FC<UrlImageProps> = props => {
    const { additionalStyles, aspectRatio, headers, onLoad, onLoadEnd, uri, imageWidth } = props;
    const [imagesString, setImagesString] = useState('');

    const getBase64Image = async (res: Response): Promise<string> => {
        const blob = await res.blob();

        const reader = new FileReader();

        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return reader.result as string;
    };

    const handleLoad = (_: NativeSyntheticEvent<ImageLoadEventData>) => {
        Image.getSize(imagesString, (width, height) => {
            onLoad(width, height);
        });
    };

    const {
        window: { width: windowWidth }
    } = useDimensions();

    useEffect(() => {
        fetch(uri, {
            headers
        })
            .then(getBase64Image)
            .then(imgString => setImagesString(imgString));
    }, [headers, uri]);

    return (
        <Image
            source={{ uri: imagesString }}
            resizeMode="contain"
            style={{
                flexGrow: 1,
                width: imageWidth ?? (windowWidth < 500 ? windowWidth - 100 : 400),
                aspectRatio,
                ...additionalStyles
            }}
            onLoad={handleLoad}
            onLoadEnd={onLoadEnd}
        />
    );
};
