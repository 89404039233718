import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { v4 as uuid } from 'uuid';

import { MaskedView } from '~/components/masked-view';

import { ParametricEllipticMask } from './parametric-elliptic-mask';

export type EllipticMaskedViewProps = {
    style?: StyleProp<ViewStyle>;
} & PropsWithChildren<object>;

export function EllipticMaskView(props: EllipticMaskedViewProps) {
    const { children, style } = props;

    const maskId = uuid();

    return (
        <MaskedView
            maskId={maskId}
            style={[styles.container, style]}
            maskElement={<ParametricEllipticMask maskId={maskId} />}
        >
            {children}
        </MaskedView>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});
