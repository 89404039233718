import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ShareIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 12C20 10.3431 18.6569 9 17 9L15 9L15 11L17 11C17.5523 11 18 11.4477 18 12L18 18C18 18.5523 17.5523 19 17 19L7 19C6.44772 19 6 18.5523 6 18L6 12C6 11.4477 6.44772 11 7 11L9 11L9 9L7 9C5.34315 9 4 10.3431 4 12L4 18C4 19.6569 5.34314 21 7 21L17 21C18.6569 21 20 19.6569 20 18L20 12Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
                <Path d="M12 3V15" stroke={fill ?? Color.ALMOST_BLACK} stroke-width="2" />
                <Path d="M7 7L12 2L17 7" stroke={fill ?? Color.ALMOST_BLACK} stroke-width="2" stroke-linecap="round" />
            </Svg>
        </View>
    );
};
