import React from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { DisplayState } from '~/hooks/pin-code';

export type PinCodeDisplayProps = {
    states: DisplayState[];
    size?: 'small' | 'standard';
};

const DisplayContainer = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: ${Spacing.SMALL}px;
`;

const DisplayInputBackground = styled.View<{ size: 'small' | 'standard'; state: DisplayState }>`
    background-color: ${Color.BACKGROUND_DEFAULT};
    elevation: 1;
    width: ${({ size }) => (size === 'small' ? 42 : 52)}px;
    height: ${({ size }) => (size === 'small' ? 42 : 52)}px;
    margin: 0 4px;
    border-radius: 8px;
    border-width: 2px;
    border-color: ${({ state }) =>
        ({
            [DisplayState.EMPTY]: Color.LINE_DEFAULT,
            [DisplayState.VALID]: Color.LINE_DEFAULT,
            [DisplayState.INVALID]: Color.WARNING_DEFAULT
        }[state])};
    align-items: center;
    justify-content: center;
`;

const DisplayInput = styled.View<{ state: DisplayState }>`
    border-width: 2px;
    border-color: ${({ state }) =>
        ({
            [DisplayState.EMPTY]: Color.LINE_DEFAULT,
            [DisplayState.VALID]: Color.PRIMARY_DEFAULT,
            [DisplayState.INVALID]: Color.WARNING_DEFAULT
        }[state])};
    background-color: ${({ state }) =>
        ({
            [DisplayState.EMPTY]: 'transparent',
            [DisplayState.VALID]: Color.PRIMARY_DEFAULT,
            [DisplayState.INVALID]: Color.WARNING_DEFAULT
        }[state])};
    width: 16px;
    height: 16px;
    border-radius: 16px;
`;

export const PinCodeDisplay = (props: PinCodeDisplayProps) => {
    const { states, size = 'standard' } = props;

    return (
        <DisplayContainer>
            {states.map((state, index) => (
                <DisplayInputBackground
                    state={state}
                    size={size}
                    accessible
                    accessibilityRole="text"
                    accessibilityLabel={state}
                    key={`input-${index}`}
                >
                    <DisplayInput state={state} />
                </DisplayInputBackground>
            ))}
        </DisplayContainer>
    );
};
