import { useDimensions } from '@react-native-community/hooks';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedDate } from '~/contexts/intl';
import { isFunction } from '~/utils/type-predicates';

const ITEM_WIDTH = 76;
const ITEM_HEIGHT = 33;

export type InputSelectProps<T extends ReactNode> = {
    caption?: string;
    options: T[];
    optionLabel: (value: T, selected: boolean) => ReactNode;
    optionLabelKeyExtractor: (value: T) => string;
    selectedOption?: T;
    onSelectOption: (value: T) => void;
    onEndReached?: ((info: { distanceFromEnd: number }) => void) | null | undefined;
    scrollableOptionCount?: number;
};

export function InputSelect<T extends ReactNode>(props: InputSelectProps<T>) {
    const flatListRef = useRef<null | FlatList>(null);
    const {
        caption,
        options,
        optionLabel,
        optionLabelKeyExtractor,
        selectedOption,
        onSelectOption,
        onEndReached,
        scrollableOptionCount = 6
    } = props;

    const handleSelectOption = useCallback(
        (value: T) => {
            const index = options.findIndex(val => val === value);
            if (index !== -1) {
                flatListRef?.current?.scrollToIndex({ index, viewPosition: 0.5 });
            }
            onSelectOption(value);
        },
        [onSelectOption, options]
    );

    useEffect(() => {
        const index = options.findIndex(val => val === selectedOption);
        if (index > 0) {
            flatListRef?.current?.scrollToIndex({ index, viewPosition: 0.5 });
        }
    }, [options, selectedOption]);

    const getItemLayout = useCallback(
        (_: unknown, index: number) => ({
            length: ITEM_WIDTH,
            offset: ITEM_WIDTH * index,
            index
        }),
        []
    );

    const renderItem = useCallback(
        ({ item, index }: ListRenderItemInfo<T> | { item: T; index: number }) => (
            <InputSelectOption
                label={isFunction(optionLabel) ? optionLabel(item, item === selectedOption) : item}
                value={item}
                selected={item === selectedOption}
                onSelectItem={handleSelectOption}
                first={index === 0}
                last={index === options.length - 1}
                stretch={false}
            />
        ),
        [handleSelectOption, optionLabel, selectedOption, options.length]
    );

    const { screen } = useDimensions();
    const [contentWidth, setContentWidth] = useState(0);

    const handleContentSizeChange = useCallback((width: number, height: number) => {
        setContentWidth(width);
    }, []);

    return (
        <InputContainer>
            {Boolean(caption) && (
                <Text.INPUT_FIELD_TITLE
                    numberOfLines={1}
                    style={{ paddingHorizontal: Spacing.MEDIUM + Spacing.SMALL, paddingVertical: Spacing.TINY }}
                >
                    {caption}
                </Text.INPUT_FIELD_TITLE>
            )}
            {options.length < scrollableOptionCount ? (
                <NonScrollableInputSelectOptionContainer>
                    {options.map((item, index) => (
                        <InputSelectOption
                            key={optionLabelKeyExtractor(item)}
                            label={isFunction(optionLabel) ? optionLabel(item, item === selectedOption) : item}
                            value={item}
                            selected={item === selectedOption}
                            onSelectItem={handleSelectOption}
                            first={index === 0}
                            last={index === options.length - 1}
                            stretch
                        />
                    ))}
                </NonScrollableInputSelectOptionContainer>
            ) : (
                <ScrollableInputSelectOptionContainer>
                    <FlatList
                        contentContainerStyle={{ paddingHorizontal: Spacing.MEDIUM }}
                        ref={flatListRef}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        data={options}
                        scrollEnabled={contentWidth > screen.width}
                        renderItem={renderItem}
                        // This is needed for scrollToDay
                        getItemLayout={getItemLayout}
                        keyExtractor={optionLabelKeyExtractor}
                        onEndReached={onEndReached}
                        onEndReachedThreshold={0.1}
                        onContentSizeChange={handleContentSizeChange}
                    />
                </ScrollableInputSelectOptionContainer>
            )}
        </InputContainer>
    );
}

export type InputSelectNumberProps = Omit<InputSelectProps<number>, 'optionLabel' | 'optionLabelKeyExtractor'>;

export function InputSelectNumber(props: InputSelectNumberProps) {
    const optionLabel = (value: number, selected: boolean) => (
        <Text.SMALL_BUTTON_LABEL style={{ color: selected ? Color.TEXT_TERTIARY : Color.ALMOST_BLACK }}>
            {value}
        </Text.SMALL_BUTTON_LABEL>
    );
    return (
        <InputSelect<number>
            {...props}
            optionLabel={optionLabel}
            optionLabelKeyExtractor={(value: number) => value.toString()}
        />
    );
}

export type InputSelectDateProps = Omit<InputSelectProps<ISODate>, 'optionLabel' | 'optionLabelKeyExtractor'>;

export function InputSelectDate(props: InputSelectDateProps) {
    const optionLabel = (value: ISODate, selected: boolean) => (
        <Text.SMALL_BUTTON_LABEL style={{ color: selected ? Color.TEXT_TERTIARY : Color.ALMOST_BLACK }}>
            <FormattedDate weekday="short" day="numeric" month="numeric" value={value} />
        </Text.SMALL_BUTTON_LABEL>
    );
    return (
        <InputSelect<ISODate>
            {...props}
            optionLabel={optionLabel}
            optionLabelKeyExtractor={(value: ISODate) => value}
        />
    );
}

export type InputSelectOptionProps<T> = {
    label: ReactNode;
    value: T;
    selected?: boolean;
    onSelectItem: (value: T) => void;
    first: boolean;
    last: boolean;
    stretch: boolean;
};

export function InputSelectOption<T>(props: InputSelectOptionProps<T>) {
    const { label, value, selected, onSelectItem, first, last, stretch } = props;

    return (
        <InputSelectOptionContainer
            accessibilityRole="button"
            key={JSON.stringify(value)}
            selected={selected}
            onPress={() => onSelectItem(value)}
            first={first}
            last={last}
            stretch={stretch}
        >
            <Text.SMALL_BUTTON_LABEL style={{ color: selected ? Color.TEXT_TERTIARY : Color.ALMOST_BLACK }}>
                {label}
            </Text.SMALL_BUTTON_LABEL>
        </InputSelectOptionContainer>
    );
}

const InputContainer = styled.View`
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
`;

const InputSelectOptionContainer = styled.TouchableOpacity<{
    selected?: boolean;
    first: boolean;
    last: boolean;
    stretch: boolean;
}>`
    border-radius: 8px;
    align-items: center;
    min-width: ${ITEM_WIDTH}px;
    justify-content: center;
    margin: ${({ first, last }) => `0 ${last ? 0 : Spacing.TINY}px 0 ${first ? 0 : Spacing.TINY}px`}
    background-color: ${({ selected }) => (selected ? Color.FOCUS_DEFAULT : Color.BASIC_DEFAULT)}
    color: ${({ selected }) => (selected ? Color.TEXT_TERTIARY : Color.TEXT_DEFAULT)};
    border: ${({ selected }) => (selected ? 'none' : `1px solid ${Color.LINE_DEFAULT}`)}
    ${({ stretch }) => (stretch ? 'flex: 1;' : '')}
`;

const NonScrollableInputSelectOptionContainer = styled.View`
    height: ${ITEM_HEIGHT}px;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between;
    padding: 0 ${Spacing.MEDIUM}px;
`;

const ScrollableInputSelectOptionContainer = styled.View`
    height: ${ITEM_HEIGHT}px;
    align-self: stretch;
`;
