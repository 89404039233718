import React, { FunctionComponent, ReactNode } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

export type ListAccessoryRenderFunc = (pressed: boolean) => ReactNode;

export type ListItemProps = {
    title?: string;
    description?: string;
    appearance?: 'default' | 'compact';
    accessory?: ListAccessoryRenderFunc;
    onPress: () => void | Promise<void>;
    children?: ReactNode;
};

const ListItemContainer = styled(Pressable)<{ appearance?: 'default' | 'compact' }>`
    flex-direction: row;
    align-items: center;
`;

const ListItemTextContainer = styled.View`
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px;
`;

export const ListItemTitle = styled(Text.BUTTON_LABEL).attrs({ after: 4, numberOfLines: 1 })`
    color: ${Color.HEADER};
`;

export const ListItemDescription = styled(Text.P1).attrs({ muted: true })`
    color: ${Color.TEXT_SECONDARY};
`;

export const ListItemPane = styled.Pressable`
    padding: 16px 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

export const ListItemPaneContent = styled.View`
    padding: 8px 18px 2px;
`;

export const ListItemDivider = styled.View`
    border-color: ${Color.LINE_DEFAULT};
    border-bottom-width: ${StyleSheet.hairlineWidth}px;
    margin-left: ${Spacing.MEDIUM}px;
`;

const AccessoryContainer = styled.View`
    width: 40px;
    align-items: center;
    margin: 8px;
`;

export const ListItem: FunctionComponent<ListItemProps> = props => {
    const { title, description, appearance, accessory, onPress, children } = props;
    return (
        <ListItemContainer onPress={onPress} appearance={appearance}>
            {({ pressed }: { pressed: boolean }) =>
                children ?? (
                    <>
                        <ListItemTextContainer>
                            {title?.length ? <ListItemTitle numberOfLines={1}>{title}</ListItemTitle> : null}
                            {description?.length ? <ListItemDescription>{description}</ListItemDescription> : null}
                        </ListItemTextContainer>
                        {accessory && <AccessoryContainer>{accessory(pressed)}</AccessoryContainer>}
                    </>
                )
            }
        </ListItemContainer>
    );
};
