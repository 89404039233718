import { StackHeaderProps, StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React from 'react';

import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { useIntl } from '~/contexts/intl';
import { useHandlePendingLink } from '~/hooks/linking';
import { TabsNavigator } from '~/navigator/tabs-navigator';
import { AccountRemoval } from '~/screens/account-removal';
import { Appointment, AppointmentPreview, Appointments } from '~/screens/appointments';
import { AppointmentsCalendar } from '~/screens/appointments/appointments-calendar';
import { AppointmentsFilters } from '~/screens/appointments/appointments-filters';
import { Article } from '~/screens/article';
import { CalendarEvents } from '~/screens/calendar-events';
import { CalendarReservationAccesses } from '~/screens/calendar-reservation-accesses/calendar-reservation-accesses';
import { Chat } from '~/screens/chat';
import { ChatArchive } from '~/screens/chat-archive';
import { NewContact } from '~/screens/contact';
import { ContactReason } from '~/screens/contact/contact-reason';
import { Document } from '~/screens/document';
import { Documents } from '~/screens/documents';
import { Home } from '~/screens/home';
import { Library } from '~/screens/library';
import { Profile, ProfileEdit, ProfileVerify } from '~/screens/profile';
import { QuestionnaireResponse } from '~/screens/questionnaire-response/questionnaire-response';
import { Questionnaires } from '~/screens/questionnaires';
import { Services } from '~/screens/services';
import { SickLeave, SickLeaveCalendar } from '~/screens/sick-leave';
import { StaffProfile } from '~/screens/staff-profile';
import { VideoCall } from '~/screens/video-call';
import { AppointmentFilters, AppointmentTimeOfDay } from '~/types';

import { header, noHeader } from './screen-options';

export type MainNavigatorParamList = {
    tabs: undefined;
    'account-removal': undefined;
    appointments: {
        calendarReservationAccessId: ID;
        calendarEventId?: ID;
        regionId?: ID;
        locationId?: ID;
        date?: ISODate;
        appointmentDaysRange?: [ISODate, ISODate];
        timeOfDay?: AppointmentTimeOfDay;
        filters?: AppointmentFilters;
    };
    'appointments-filters': {
        calendarReservationAccessId: ID;
        filters?: AppointmentFilters;
        remote?: boolean;
    };
    'appointments-calendar': {
        calendarReservationAccessId: ID;
        appointmentDaysRange: [ISODate, ISODate];
        date?: ISODate;
        regionId?: ID;
        locationId?: ID;
        timeOfDay?: AppointmentTimeOfDay;
        filters?: AppointmentFilters;
    };
    appointment: { calendarEventId: ID };
    'appointment-preview': { appointment: JSONString; calendarEventId?: ID };
    'appointment-confirmed': { calendarEventId: ID };
    'calendar-events': undefined;
    'calendar-reservation-accesses': undefined;
    documents: undefined;
    document: { documentId: ID };
    questionnaires: undefined;
    'video-call': { url: string };
    'staff-profile': { user: JSONString };
    chat: { chatId: ID; messageId?: ID };
    'chat-archive': undefined;
    'contact-reason': { title: string; actionType: string; description: string };
    'new-contact': undefined;
    'sick-leave': { date?: ISODate };
    'sick-leave-calendar': { date?: ISODate };
    'questionnaire-response': { questionnaireResponseId: ID };
    'questionnaire-response-old': { questionnaireResponseId: ID };
    services: undefined;
    article: { id?: ID; tag?: string };
    faq: { is?: ID; tag?: string };
    profile: undefined;
    'profile-edit': undefined;
    'profile-verify': undefined;
    library: undefined;
    home: undefined;
};

const { Navigator, Group, Screen } = createStackNavigator<MainNavigatorParamList>();

export const MainNavigator = () => {
    const { formatMessage } = useIntl();

    const responsiveHeader: StackNavigationOptions = useBreakpointValue({
        base: {
            ...header
        },
        md: {
            header: (props: StackHeaderProps) => <HelttiHeaderStackHeaderWrapper {...props} />
        }
    });

    useHandlePendingLink();

    return (
        <Navigator screenOptions={{ headerMode: 'screen' }}>
            <Screen name="tabs" component={TabsNavigator} options={{ ...noHeader }} />
            <Screen name="chat" component={Chat} options={{ ...responsiveHeader, title: '' }} />
            <Screen
                name="chat-archive"
                component={ChatArchive}
                options={{
                    ...responsiveHeader,
                    title: formatMessage('chat-archive.title'),
                    headerShadowVisible: false
                }}
            />
            <Screen name="account-removal" component={AccountRemoval} options={{ ...responsiveHeader }} />
            <Screen name="appointments" component={Appointments} />
            <Group screenOptions={{ presentation: 'modal' }}>
                <Screen name="appointments-filters" component={AppointmentsFilters} />
                <Screen name="appointments-calendar" component={AppointmentsCalendar} />
            </Group>
            <Screen name="appointment" component={Appointment} />
            <Screen name="appointment-preview" component={AppointmentPreview} />
            <Screen name="appointment-confirmed" component={Appointment} options={{ ...responsiveHeader }} />
            <Screen name="calendar-events" component={CalendarEvents} options={{ ...responsiveHeader }} />
            <Screen
                name="calendar-reservation-accesses"
                component={CalendarReservationAccesses}
                options={{ ...responsiveHeader }}
            />
            <Screen name="documents" component={Documents} options={{ ...responsiveHeader }} />
            <Screen name="document" component={Document} options={{ ...responsiveHeader }} />
            <Screen name="questionnaires" component={Questionnaires} options={{ ...responsiveHeader }} />
            <Screen name="questionnaire-response" component={QuestionnaireResponse} options={{ ...responsiveHeader }} />
            <Screen
                name="questionnaire-response-old"
                component={QuestionnaireResponse}
                options={{ ...responsiveHeader }}
            />
            <Screen name="video-call" component={VideoCall} options={{ ...responsiveHeader }} />
            <Screen name="staff-profile" component={StaffProfile} options={{ ...responsiveHeader }} />
            <Screen name="new-contact" component={NewContact} options={{ ...responsiveHeader }} />
            <Screen name="contact-reason" component={ContactReason} options={{ ...responsiveHeader }} />
            <Screen name="sick-leave" component={SickLeave} options={{ ...responsiveHeader }} />
            <Screen name="sick-leave-calendar" component={SickLeaveCalendar} options={{ ...responsiveHeader }} />
            <Screen name="article" component={Article} options={{ ...responsiveHeader }} />
            <Screen name="faq" component={Article} options={{ ...responsiveHeader }} />
            <Screen name="profile" component={Profile} options={{ ...responsiveHeader }} />
            <Screen name="profile-edit" component={ProfileEdit} options={{ ...responsiveHeader }} />
            <Screen
                name="profile-verify"
                component={ProfileVerify}
                options={{ ...responsiveHeader, title: formatMessage('profile.verify.authenticate') }}
            />
            <Screen name="home" component={Home} options={{ ...responsiveHeader }} />
            <Screen name="library" component={Library} options={{ ...responsiveHeader }} />
            <Screen name="services" component={Services} options={{ ...responsiveHeader }} />
        </Navigator>
    );
};
