import { AppointmentFilters } from '~/types';

type AppointmentCheckFilter = Pick<AppointmentFilters, 'contactTypes' | 'specialistLanguages'>;
type AppointmentRadioFilter = Pick<AppointmentFilters, 'specialistGender' | 'specialistAgeGroup'>;

export function isFilterSet(filters: AppointmentFilters, filter: keyof AppointmentFilters, value: string): boolean {
    if (Array.isArray(filters[filter])) {
        return (filters[filter] as string[] | undefined)?.includes(value) ?? false;
    }
    return filters[filter] === value;
}

export function setRadioFilter(
    filters: AppointmentFilters,
    filter: keyof AppointmentRadioFilter,
    value: string
): AppointmentFilters {
    return {
        ...filters,
        [filter]: value
    };
}

export function clearRadioFilter(
    filters: AppointmentFilters,
    filter: keyof AppointmentRadioFilter
): AppointmentFilters {
    const { [filter]: val, ...rest } = filters;
    return rest;
}

export function setCheckFilter(
    filters: AppointmentFilters,
    filter: keyof AppointmentCheckFilter,
    value: string
): AppointmentFilters {
    if (filter in filters) {
        const values = (filters[filter] ?? []) as string[];
        if (values.includes(value)) {
            return filters;
        }
        return { ...filters, [filter]: [...values, value] };
    }
    return { ...filters, [filter]: [value] };
}

export function clearCheckFilter(
    filters: AppointmentFilters,
    filter: keyof AppointmentCheckFilter,
    value: string
): AppointmentFilters {
    if (filter in filters) {
        const { [filter]: val, ...rest } = filters;
        const values = (val ?? []) as string[];
        const other = values?.filter(f => f !== value);
        if (other?.length > 0) {
            return { ...rest, [filter]: other };
        }
        return rest;
    }
    return filters;
}

export function toggleCheckFilter(
    filters: AppointmentFilters,
    filter: keyof AppointmentCheckFilter,
    value: string
): AppointmentFilters {
    if (isFilterSet(filters, filter, value)) {
        return clearCheckFilter(filters, filter, value);
    } else {
        return setCheckFilter(filters, filter, value);
    }
}
