import { Box, Flex } from 'native-base';
import React, { useState } from 'react';
import { View } from 'react-native';

import { BubbleLines } from '~/components/bubble-lines';
import { Color } from '~/components/color';
import { CloseIcon } from '~/components/icon';
import { IconButton } from '~/components/icon-button';
import { MarkdownCompact } from '~/components/markdown';
import { Shadow } from '~/components/shadow';
import { ChatNotification } from '~/hooks/chat';

import { Action } from './action';

type ChatNotificationAlertProps = {
    alert: ChatNotification;
    chatId: ID;
};

const ChatNotificationAlert = (props: ChatNotificationAlertProps) => {
    const { alert, chatId } = props;
    const { message, actions } = alert;
    const [visible, setVisible] = useState<boolean>(true);

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) {
        return null;
    }

    return (
        <Flex
            backgroundColor={Color.BACKGROUND_PRIMARY}
            p={4}
            m={2}
            borderRadius="16px"
            alignItems="flex-start"
            style={Shadow.styles.primary}
        >
            <Box position="absolute" w="200px" height="24px" bottom="0" right="0">
                <BubbleLines fill={Color.BACKGROUND_DEFAULT} />
            </Box>
            <Box position="absolute" right="8px" top="8px" borderRadius="16px" style={Shadow.styles.primary}>
                <IconButton icon={CloseIcon} onPress={onClose} />
            </Box>
            <Flex shrink="1">
                <MarkdownCompact>{message}</MarkdownCompact>
            </Flex>
            <Flex direction="row" mt={2}>
                {actions?.map(action => (
                    <Action key={action.id} action={action} chatId={chatId} />
                ))}
            </Flex>
        </Flex>
    );
};

type ChatNotificationAlertsProps = {
    alerts: readonly ChatNotification[];
    chatId: ID;
};

export const ChatNotificationAlerts = (props: ChatNotificationAlertsProps) => {
    const { alerts, chatId } = props;

    return (
        <View>
            {alerts.map(alert => (
                <ChatNotificationAlert key={alert.id} alert={alert} chatId={chatId} />
            ))}
        </View>
    );
};
