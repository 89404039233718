import { useCallback, useMemo, useReducer } from 'react';
import useAsyncEffect from 'use-async-effect';

import { PinCode } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { inputToDisplayStates, PIN_CODE_LENGTH, PIN_ENTRY_DELAY } from '~/hooks/pin-code/pin-code';
import { timeout } from '~/utils';

const INPUT_EMPTY = '';

type PinSetupInputState = 'cleared' | 'one' | 'next' | 'two' | 'success' | 'failure';

type PinCodeSetupState = {
    inputState: PinSetupInputState;
    input: PinCode;
    reference: PinCode | undefined;
};

type PinCodeSetupAction = { type: 'enter'; digit: number } | { type: 'delete' } | { type: 'continue' };

const pinCodeSetupReducer = (state: PinCodeSetupState, action: PinCodeSetupAction): PinCodeSetupState => {
    const { inputState, input, reference } = state;
    switch (action.type) {
        case 'enter': {
            const { digit } = action;
            switch (inputState) {
                case 'cleared':
                case 'one': {
                    const inputValue = `${input}${digit}`;
                    return {
                        inputState: input.length < PIN_CODE_LENGTH - 1 ? 'one' : 'next',
                        input: inputValue,
                        reference: undefined
                    };
                }
                case 'next': {
                    return { inputState: 'two', input: INPUT_EMPTY, reference: input };
                }
                case 'two': {
                    const inputValue = `${input}${digit}`;
                    if (reference!.startsWith(inputValue)) {
                        return {
                            inputState: inputValue === reference ? 'success' : 'two',
                            input: inputValue,
                            reference
                        };
                    }
                    return { inputState: 'failure', input: inputValue, reference };
                }
                default: {
                    return state;
                }
            }
        }
        case 'delete': {
            switch (inputState) {
                case 'two': {
                    if (input.length === 0) {
                        return { inputState: 'cleared', input: INPUT_EMPTY, reference: undefined };
                    }
                }
                // eslint-disable-next-line no-fallthrough
                case 'cleared':
                case 'one': {
                    if (input.length > 0) {
                        const inputValue = input.slice(0, -1);
                        return { ...state, input: inputValue };
                    }
                    return state;
                }
                case 'failure': {
                    if (input.length > 0) {
                        const inputValue = input.slice(0, -1);
                        return { inputState: 'two', input: inputValue, reference };
                    }
                    return state;
                }
                case 'success': {
                    return { inputState: 'cleared', input: INPUT_EMPTY, reference: undefined };
                }
                default: {
                    return state;
                }
            }
        }
        case 'continue': {
            if (inputState === 'next') {
                return { inputState: 'two', input: INPUT_EMPTY, reference: input };
            }
            return state;
        }
    }
};

export type PinCodeSetupHookProps = {
    onPinCodeSet: (pin: string) => void;
    onPinCodeClear?: () => void;
};

export function usePinCodeSetup(props: PinCodeSetupHookProps) {
    const { formatMessage } = useIntl();
    const { onPinCodeSet, onPinCodeClear } = props;
    const [{ inputState, input, reference }, dispatch] = useReducer(pinCodeSetupReducer, {
        inputState: 'one',
        input: '',
        reference: undefined
    });
    const states = inputToDisplayStates(input, PIN_CODE_LENGTH, reference);

    const handleEnter = useCallback((digit: number) => dispatch({ type: 'enter', digit }), [dispatch]);
    const handleDelete = useCallback(() => dispatch({ type: 'delete' }), [dispatch]);

    useAsyncEffect(
        async mounted => {
            if (mounted()) {
                if (inputState === 'next') {
                    await timeout(PIN_ENTRY_DELAY);
                    dispatch({ type: 'continue' });
                }
                if (inputState === 'success') {
                    onPinCodeSet(input);
                }
                if (inputState === 'cleared') {
                    onPinCodeClear?.();
                }
            }
        },
        [inputState, input]
    );

    const messages: { [key in PinSetupInputState]: string } = useMemo(
        () => ({
            cleared: formatMessage('pin-code-setup.enter-pin'),
            one: formatMessage('pin-code-setup.enter-pin'),
            next: formatMessage('pin-code-setup.enter-pin'),
            two: formatMessage('pin-code-setup.re-enter-pin'),
            failure: formatMessage('pin-code-setup.pin-mismatch'),
            success: formatMessage('pin-code-setup.pin-set')
        }),
        [formatMessage]
    );

    return { states, input, message: messages[inputState], handleEnter, handleDelete };
}
