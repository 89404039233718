import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';

import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { ChatMessageDraftMutationDocument } from '~/types';

export const useChatMessageDraftMutation = (chatId: ID) => {
    const [saveDraft] = useMutation(ChatMessageDraftMutationDocument);
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const fireMutation = useCallback(
        (message: string) => {
            saveDraft({
                variables: {
                    input: {
                        chatId,
                        message
                    }
                }
                // Silently log errors if saving draft fails
            }).catch(err => ErrorDiagnostics.error(err));
        },
        [chatId, saveDraft]
    );

    const updateDraftMessage = useCallback(
        (newMessage: string) => {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => fireMutation(newMessage), 2000);
        },
        [fireMutation]
    );

    return useMemo(
        () => ({
            updateDraftMessage
        }),
        [updateDraftMessage]
    );
};
