import { Point } from 'geojson';

import {
    AppointmentDayFragment,
    AppointmentFragment,
    ArticleFragment,
    ArticleCategoryArticleFragment,
    CalendarEventFragment,
    ChatMessageAttachmentFragment,
    ChatMessageFileAttachmentFragment,
    ChatMessageIdAttachmentFragment,
    ChatMessageInteractionFragment,
    LocationFragment,
    Maybe,
    NextAppointmentFragment,
    RegionFragment,
    UserFragment,
    ArticleCategoryFragment,
    ArticleCategorySubCategoryFragment,
    ArticleCategorySubArticleFragment
} from '~/types/schema';
import { relayConnectionReduce } from '~/utils';
import { fromISODateString, fromISODateTimeString } from '~/utils/date';
import { isWeb } from '~/utils/platform';

// TODO: FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (isWeb()) {
    // @ts-ignore
    window._frameTimestamp = null;
}

export type CalendarEvent = Omit<CalendarEventFragment, 'start' | 'end' | 'calendar' | 'cancelledAt' | '__typename'> & {
    start: Date;
    end: Date;
    cancelledAt: Maybe<Date>;
    user?: User;
    calendarName: string;
};

export function fragmentToCalendarEvent(fragment: CalendarEventFragment): CalendarEvent {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        start: fromISODateTimeString(fragment.start),
        end: fromISODateTimeString(fragment.end),
        user: fragment.calendar.user ? fragmentToUser(fragment.calendar.user) : undefined,
        cancelledAt: fragment.cancelledAt ? fromISODateTimeString(fragment.cancelledAt) : null,
        calendarName: fragment.calendar.title
    };
}

export type AppointmentDay = Omit<AppointmentDayFragment, 'date' | 'nextAppointments' | '__typename'> & {
    date: Date;
    nextAppointments: NextAppointment[];
};

export type Appointment = Omit<AppointmentFragment, 'start' | 'location' | 'user' | '__typename'> & {
    start: Date;
    location?: Location;
    user: User;
};

export type NextAppointment = Omit<NextAppointmentFragment, 'start' | '__typename'> & {
    start: Date;
};

export function fragmentToAppointmentDay(fragment: AppointmentDayFragment): AppointmentDay {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        date: fromISODateString(fragment.date),
        nextAppointments: fragment.nextAppointments.map(fragmentToNextAppointment)
    };
}

export function fragmentToAppointment(fragment: AppointmentFragment): Appointment {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        start: fromISODateTimeString(fragment.start),
        location: fragment.location ? fragmentToLocation(fragment.location) : undefined
    };
}

function fragmentToNextAppointment(fragment: NextAppointmentFragment): NextAppointment {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        start: fromISODateTimeString(fragment.start),
        location: fragment.location ? fragmentToLocation(fragment.location) : null
    };
}

export type Location = Omit<LocationFragment, 'geometry' | '__typename'> & {
    geometry?: Point;
};

export function fragmentToLocation(fragment: LocationFragment): Location {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        geometry: fragment.geometry ?? undefined
    };
}

export type Region = Omit<RegionFragment, 'locations' | '__typename'> & {
    locations: Location[];
};

export function fragmentToRegion(fragment: RegionFragment): Region {
    const { __typename, ...rest } = fragment;
    return {
        ...rest,
        locations: relayConnectionReduce<LocationFragment>(fragment.locations)?.map(fragmentToLocation) ?? []
    };
}

export type User = Omit<UserFragment, '__typename'> & object;

export function fragmentToUser(fragment: UserFragment): User {
    const { __typename, ...rest } = fragment;
    return {
        ...rest
    };
}

export const isFileAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageFileAttachmentFragment => {
    return 'filename' in attachment;
};

export const isInteractionAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageInteractionFragment => {
    return 'uiInteraction' in attachment;
};

export const isIdAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageIdAttachmentFragment => {
    return 'attachmentId' in attachment;
};

export type ArticleCategorySubArticle = Omit<ArticleCategorySubArticleFragment, '__typename'>;

export type ArticleCategorySubCategory = Omit<ArticleCategorySubCategoryFragment, '__typename' | 'articles'> & {
    articles: readonly ArticleCategorySubArticle[];
};

function fragmentToArticleCategorySubCategory(
    fragment: ArticleCategorySubCategoryFragment
): ArticleCategorySubCategory {
    const { __typename, ...rest } = fragment;
    return rest;
}

export type ArticleCategoryArticle = Omit<ArticleCategoryArticleFragment, '__typename' | 'childCategories'> & {
    childCategories: readonly ArticleCategorySubCategory[];
};

function fragmentToArticleCategoryArticle(fragment: ArticleCategoryArticleFragment): ArticleCategoryArticle {
    const { __typename, childCategories, ...rest } = fragment;
    return {
        ...rest,
        childCategories:
            relayConnectionReduce<ArticleCategorySubCategoryFragment>(childCategories)?.map(
                fragmentToArticleCategorySubCategory
            ) ?? []
    };
}

export type ArticleCategory = Omit<ArticleCategoryFragment, '__typename' | 'articles'> & {
    articles: readonly ArticleCategoryArticle[];
};

export function fragmentToArticleCategory(fragment: ArticleCategoryFragment): ArticleCategory {
    const { __typename, articles, ...rest } = fragment;
    return {
        ...rest,
        articles: articles.map(fragmentToArticleCategoryArticle)
    };
}

export type Article = Omit<ArticleFragment, '__typename' | 'childCategories' | 'childArticles'> & {
    childCategories: ArticleCategory[];
};

export function fragmentToArticle(fragment: ArticleFragment): Article {
    const { __typename, childCategories, ...rest } = fragment;
    return {
        ...rest,
        childCategories:
            relayConnectionReduce<ArticleCategoryFragment>(childCategories)?.map(fragmentToArticleCategory) ?? []
    };
}
