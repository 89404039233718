import { useMutation, useQuery } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import * as ErrorDiagnostics from '~/error';
import { UiInteractionContext, GetInteractionsDocument, InteractionDocument } from '~/types';

import { fragmentToInteraction, Interaction, InteractionAction } from './interaction-types';

export type InteractionsHookOptions = {
    context?: UiInteractionContext;
};

export type InteractionHookResult = {
    loading: boolean;
    interaction?: Interaction;
    actionLoading: boolean;
    onAction: (action: InteractionAction) => Promise<void>;
};

export function useInteraction(options?: InteractionsHookOptions): InteractionHookResult {
    const { loading, data, error } = useQuery(GetInteractionsDocument, { fetchPolicy: 'network-only' });
    const [mutate] = useMutation(InteractionDocument);
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const linkTo = useLinkTo();

    const handleError = useErrorHandler();

    const uiInteraction = data?.root?.interactions?.filter(i =>
        options?.context ? i.context === options.context : true
    )?.[0];
    const interaction = uiInteraction?.interaction ? fragmentToInteraction(uiInteraction.interaction) : undefined;

    const onAction = useCallback(
        async (action: InteractionAction) => {
            if (uiInteraction && interaction) {
                try {
                    setActionLoading(true);

                    const result = await mutate({
                        variables: {
                            input: {
                                id: uiInteraction.id,
                                actionHandle: action.handle,
                                values: null
                            }
                        },
                        refetchQueries: [GetInteractionsDocument]
                    });

                    const { redirectTo } = result.data?.interaction?.interactionResult ?? {};
                    if (redirectTo) {
                        const redirectToUrl = new URL(redirectTo);
                        ErrorDiagnostics.log(`Redirecting to path ${redirectToUrl.pathname}`);
                        linkTo(redirectToUrl.pathname);
                    }
                } catch (actionError: unknown) {
                    handleError(actionError);
                } finally {
                    setActionLoading(false);
                }
            }
        },
        [handleError, interaction, linkTo, mutate, uiInteraction]
    );

    if (error) {
        handleError(error);
    }

    return {
        loading,
        interaction,
        actionLoading,
        onAction
    };
}
