/**
 * Session timeout
 *
 * Defines maximum app background time after which the app will be locked
 */
export const appSessionTimeout = 60 * 1000;

/**
 * Inactivity timeout
 *
 * Defines the timeout for app becoming `inactive` when moved to the background
 */
export const appInactivityTimeout = 6 * 1000;

export const __UNIT_TEST__ = typeof jest !== 'undefined';

// To update digest
// $ echo -n "This is the new password" | shasum -a 256 | awk '{ print $1 }'
export const DEVELOPER_PASSWORD_DIGEST = '19c85eb71fd48399b31cda7520d62ba69ffbd7ee41e345e6719594e9ea68cedd';
