import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { OvalButton } from '~/components/button';
import { ButtonBackIcon } from '~/components/icon';
import { Spacing } from '~/components/spacing';

export type HeaderBackButtonProps = {
    onPress?: () => void;
};

export function HeaderBackButton(props: HeaderBackButtonProps) {
    const { onPress } = props;
    const { canGoBack, goBack } = useNavigation();

    const handlePress = () => {
        if (onPress) {
            onPress();
        } else {
            goBack();
        }
    };

    if (!canGoBack()) {
        return null;
    }

    return (
        <View style={{ paddingLeft: Spacing.MEDIUM }}>
            <OvalButton size="small" icon={ButtonBackIcon} onPress={handlePress} />
        </View>
    );
}

HeaderBackButton.displayName = 'HeaderBackButton';
