import { Flex } from 'native-base';
import React from 'react';

import { SystemMessageContainer } from '~/components/chat-message';
import { MarkdownCompact } from '~/components/markdown';
import { ChatNotification } from '~/hooks/chat';

type ChatNotificatonInlineMessagesProps = {
    inlines: ChatNotification[];
};

export const ChatNotificationInlineMessages = (props: ChatNotificatonInlineMessagesProps) => {
    const { inlines } = props;

    return (
        <Flex pt={2} pb={2} alignItems="center" mx={4}>
            {inlines.map(({ id, message }) => (
                <SystemMessageContainer key={id}>
                    <MarkdownCompact>{message}</MarkdownCompact>
                </SystemMessageContainer>
            ))}
        </Flex>
    );
};
