import React from 'react';
import { View, ViewProps } from 'react-native';

import { objectMap } from '~/utils/object';

import { SpacingConst } from './spacing-const';

export const SpacingComponents = {
    Horizontal: {
        ...objectMap<keyof typeof SpacingConst, number, React.ComponentType<ViewProps>>(SpacingConst, (width, key) => {
            const component = () => <View style={{ width, height: 1 }} />;
            component.displayName = `Spacing.${key}`;
            return component;
        }),
        Fill: () => <View style={{ flexDirection: 'row', flex: 1 }} />
    },

    Vertical: {
        ...objectMap<keyof typeof SpacingConst, number, React.ComponentType<ViewProps>>(SpacingConst, (height, key) => {
            const component = () => <View style={{ height, width: 1 }} />;
            component.displayName = `Spacing.${key}`;
            return component;
        }),
        Fill: () => <View style={{ flexDirection: 'column', flexShrink: 1, flexGrow: 1, borderWidth: 0 }} />
    }
};
