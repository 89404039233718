import React from 'react';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { Markdown } from '~/components/markdown';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useInteraction } from '~/hooks/interaction';

export function ModalInteraction() {
    const { loading, interaction, actionLoading, onAction } = useInteraction({ context: 'MODAL' });

    if (loading || !interaction) {
        return null;
    }
    const { title, description, actions } = interaction;

    return (
        <Modal isVisible animationIn="slideInUp" animationOut="slideOutDown" backdropOpacity={0.6}>
            <Container>
                <Card>
                    <InteractionTitle>{title}</InteractionTitle>
                    <Spacing.Vertical.MEDIUM />
                    {description ? <Markdown>{description}</Markdown> : null}
                    <Spacing.Vertical.MEDIUM />
                    {/* The actions are listed from "left to right", which should translate to top to bottom */}
                    {actions.reverse().map(action => (
                        <InteractionAction key={action.handle}>
                            <Button
                                alignCenter
                                disabled={actionLoading}
                                loading={actionLoading && action.importance === 'PRIMARY'}
                                disabledStateRendered={action.importance !== 'PRIMARY'}
                                style={{ alignSelf: 'stretch' }}
                                type={action.importance === 'PRIMARY' ? 'primary' : 'basic'}
                                shape="rounded"
                                label={action.title}
                                onPress={async () => {
                                    await onAction(action);
                                }}
                            />
                            <Spacing.Vertical.SMALL />
                        </InteractionAction>
                    ))}
                </Card>
            </Container>
        </Modal>
    );
}

const InteractionTitle = styled(Text.SUBTITLE_1)`
    color: ${Color.SMOOTHIE};
    text-align: center;
`;

const InteractionAction = styled.View`
    align-self: stretch;
`;

const Container = styled.View`
    position: absolute;
    bottom: 4px;
    width: 100%;
`;

const Card = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 16px;

    align-items: center;
`;
