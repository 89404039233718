import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const DocumentAttachmentIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7H13ZM9 15V6.80073H7L7 15H9ZM15 6.80073V14.8944H17V6.80073L15 6.80073ZM13 14.8944V7H11V14.8944H13ZM13.1679 15.4491C13.0584 15.2849 13 15.0919 13 14.8944H11C11 15.4867 11.1753 16.0657 11.5038 16.5585L13.1679 15.4491ZM14.8321 15.4491C14.4362 16.0429 13.5638 16.0429 13.1679 15.4491L11.5038 16.5585C12.6913 18.3397 15.3087 18.3397 16.4962 16.5585L14.8321 15.4491ZM15 14.8944C15 15.0919 14.9416 15.2849 14.8321 15.4491L16.4962 16.5585C16.8247 16.0657 17 15.4867 17 14.8944H15ZM14.4262 5.03622C14.7991 5.54898 15 6.16671 15 6.80073L17 6.80073C17 5.74402 16.6652 4.71448 16.0437 3.85988L14.4262 5.03622ZM9.57379 5.03622C10.7718 3.38891 13.2282 3.38891 14.4262 5.03622L16.0437 3.85988C14.0469 1.11435 9.95306 1.11435 7.95632 3.85988L9.57379 5.03622ZM9 6.80073C9 6.16671 9.20087 5.54898 9.57379 5.03622L7.95632 3.85988C7.33479 4.71448 7 5.74402 7 6.80073H9Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
                <Path
                    d="M21 8V19H23V8H21ZM20 20H4.00054V22H20V20ZM3.00055 19L3.00008 8.00068L1.00008 8.00076L1.00055 19.0001L3.00055 19ZM19 7H20V5H19V7ZM4.00008 7.00063H5V5.00063H4.00008V7.00063ZM4.00054 20C3.44828 20 3.00057 19.5523 3.00055 19L1.00055 19.0001C1.00062 20.6569 2.34374 22 4.00054 22V20ZM21 19C21 19.5523 20.5523 20 20 20V22C21.6569 22 23 20.6569 23 19H21ZM23 8C23 6.34315 21.6569 5 20 5V7C20.5523 7 21 7.44772 21 8H23ZM3.00008 8.00068C3.00006 7.44838 3.44778 7.00063 4.00008 7.00063V5.00063C2.34318 5.00063 1.00001 6.34386 1.00008 8.00076L3.00008 8.00068Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
            </Svg>
        </View>
    );
};
