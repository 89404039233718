import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import React, { ComponentType } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps, TabHomeIcon, TabLibraryIcon, TabMeIcon, TabServicesIcon } from '~/components/icon';
import { Text } from '~/components/text';
import { AppMessageId, useIntl } from '~/contexts/intl';
import { Home } from '~/screens/home';
import { Library } from '~/screens/library';
import { Profile } from '~/screens/profile/profile';
import { Services } from '~/screens/services';

export type TabNavigatorParamList = {
    home: undefined;
    services: undefined;
    library: undefined;
    profile: undefined;
};

// TODO: Query badges
const badges = {
    home: undefined,
    services: undefined,
    library: undefined,
    profile: undefined
};

export const TabBarItemCaption = styled(Text.CAPTION)`
    color: ${Color.PRIMARY_DEFAULT};
`;

type TabBarItemProps = { focused: boolean };
export type TabBarItemFunction = (props: TabBarItemProps) => JSX.Element;

export const tabBarIcon = (Component: ComponentType<IconProps>) => (props: TabBarItemProps) => {
    return (
        <View /* TODO: Animated.View entering={FadeIn.duration(300)}*/>
            <Component fill={props.focused ? Color.PRIMARY_DEFAULT : Color.ICON_DEFAULT} />
        </View>
    );
};

export const tabBarLabel = (id: AppMessageId) => (props: TabBarItemProps) => {
    const { formatMessage } = useIntl();
    return (
        <View /* TODO: Animated.View entering={FadeInLeft} exiting={FadeOutLeft}*/>
            <TabBarItemCaption style={{ color: props.focused ? Color.PRIMARY_DEFAULT : Color.ICON_DEFAULT }}>
                {formatMessage(id)}
            </TabBarItemCaption>
        </View>
    );
};

export const TabBarItemContainer = styled(/*Animated.*/ View)`
    flex-direction: column;
    align-items: center;
`;

export const TabBarCaptionBadgeContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
`;

export const TabBarItemBadge = styled(View)`
    position: absolute;
    right: -12px;
    background-color: ${Color.CHAT_BADGE};
    border-radius: 4px;
    width: 8px;
    height: 8px;
`;

type TabNavigationScreen = {
    name: keyof TabNavigatorParamList;
    component: React.FC;
    options: { titleKey: AppMessageId } & BottomTabNavigationOptions;
};

export const tabNavigationScreens: TabNavigationScreen[] = [
    {
        name: 'home',
        component: Home,
        options: {
            tabBarIcon: tabBarIcon(TabHomeIcon),
            tabBarLabel: tabBarLabel('tab-bar.home'),
            tabBarBadge: badges.home,
            titleKey: 'tab-bar.home'
        }
    },
    {
        name: 'services',
        component: Services,
        options: {
            tabBarIcon: tabBarIcon(TabServicesIcon),
            tabBarLabel: tabBarLabel('tab-bar.services'),
            tabBarBadge: badges.services,
            titleKey: 'tab-bar.services'
        }
    },
    {
        name: 'library',
        component: Library,
        options: {
            tabBarIcon: tabBarIcon(TabLibraryIcon),
            tabBarLabel: tabBarLabel('tab-bar.library'),
            tabBarBadge: badges.library,
            titleKey: 'tab-bar.library'
        }
    },
    {
        name: 'profile',
        component: Profile,
        options: {
            tabBarIcon: tabBarIcon(TabMeIcon),
            tabBarLabel: tabBarLabel('tab-bar.me'),
            tabBarBadge: badges.profile,
            titleKey: 'tab-bar.me'
        }
    }
];
