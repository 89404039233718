import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';

export const ContentContainer = styled.View<{ bottomInset: number; topInset: number }>`
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
    padding-top: ${({ topInset }) => topInset}px;
    height: 100%;
    color: ${Color.TEXT_TERTIARY};
    flex-direction: column;
    justify-content: space-between;
`;

export const HeaderContainer = styled.View`
    padding: ${Spacing.SMALL}px ${Spacing.MEDIUM}px;
    flex-direction: row;
    flex: 0 0 auto;
    align-items: center;
`;

export const TitleContainer = styled.View`
    flex: 1;
    margin-horizontal: ${Spacing.SMALL}px;
`;

export const AttachmentContentContainer = styled.View`
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
`;

export const BottomContentContainer = styled.View`
    align-self: center;
    flex: 0 0 auto;
`;
