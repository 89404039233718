import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Markdown, MarkdownLead } from '~/components/markdown';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

export const ArticleTitle = Text.TITLE;

export const ArticleSubtitle = Text.SUBTITLE_2;

export const ArticleHighlight = MarkdownLead;

export const ArticleMarkdown = Markdown;

export const ArticleSpacer = styled.View`
    height: 1px;
    width: 100%;
    margin-top: ${Spacing.SMALL}px;
    background-color: ${Color.LINE_DEFAULT};
`;
