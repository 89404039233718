import { ApolloClient, ApolloError, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';

import { customFetch } from '~/app/app-fetch';
import { AppEndpointConfig } from '~/contexts/app-config';
import { AppLocale } from '~/contexts/intl';
import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { getDeviceUuid } from '~/hooks/device-registration/device-registration-persist';
import {
    EIdentInitDocument,
    EIdentVerificationRequiredDocument,
    EIdentVerifyDocument,
    ResendSmsTokenDocument,
    SignUpWithEmailDocument,
    TokenObtainDocument,
    TokenObtainWithSmsCodeDocument,
    TokenRefreshDocument,
    TokenRevokeDocument,
    VerifyEmailDocument
} from '~/types';
import { isWeb } from '~/utils';

export type TokenAuthResult = {
    token: string;
    exp: number;
    id: string;
    refreshToken: string;
    refreshExpiresIn: number;
};

type JWTPayload = {
    id: string;
    username: string;
    exp: number;
    origIat: number;
};

export const createAuthClient = ({ http, host }: AppEndpointConfig) => {
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: createUploadLink({
            uri: `${http}://${host}/member-api/graphql/`,
            credentials: 'same-origin',
            fetch: customFetch
        })
    });
};

function handleResult<
    T extends {
        token?: string | null;
        refreshToken?: string | null;
        refreshExpiresIn?: number | null;
        error?: string | null;
    }
>(result: T | undefined | null): TokenAuthResult {
    const { token, refreshToken, refreshExpiresIn, error } = result ?? {};

    // The following error handling is required in order to support `ObtainTokenWithSMSCodeMutation` which has an
    // inconsistent behavior compared to other APIs: it returns optional `error` parameter in (successful) response
    // in case of invalid token or input code. Other APIs would return GraphQL errors.

    if (error) {
        throw new Error(error);
    }
    if (token?.length && refreshToken?.length && refreshExpiresIn) {
        const { exp, id } = jwtDecode<JWTPayload>(token);

        return {
            token,
            exp,
            id,
            refreshToken,
            refreshExpiresIn
        };
    }
    throw new Error('Invalid response');
}

export class RecoverableError extends Error {
    constructor(msg: string) {
        super(msg);
        // see https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, RecoverableError.prototype);
    }
}

function handleError(error: unknown): Error {
    if (error instanceof ApolloError) {
        if (error.graphQLErrors) {
            return new Error(error.graphQLErrors[0].message);
        }
        if (error.networkError) {
            return new RecoverableError(`${error.networkError}`);
        }
        if (error.clientErrors) {
            return error.clientErrors[0];
        }
    }
    return new Error(`${error}`);
}

export const tokenObtain = async (
    authClient: ApolloClient<object>,
    username: string,
    password: string
): Promise<TokenAuthResult> => {
    try {
        const { data } = await authClient.mutate({
            mutation: TokenObtainDocument,
            variables: { username, password },
            errorPolicy: 'none'
        });
        // NOTE! With default error policy `none`, the mutation will reject on both network and GraphQL errors
        // (see thorough explanation on Apollo mutation error handling at https://stackoverflow.com/a/59472340),
        // so handle errors in `catch` (reject) handler

        return handleResult(data?.obtainToken);
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client token auth failed: ${error}`);
        throw error;
    }
};

export const tokenRefresh = async (
    authClient: ApolloClient<object>,
    oldRefreshToken: string
): Promise<TokenAuthResult> => {
    try {
        const { data } = await authClient.mutate({
            mutation: TokenRefreshDocument,
            variables: { refreshToken: oldRefreshToken, isWeb: isWeb() },
            errorPolicy: 'none'
        });
        return handleResult(data?.refreshToken);
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client token refresh failed: ${error}`);
        throw error;
    }
};

export const tokenRevoke = async (authClient: ApolloClient<object>, refreshToken: string): Promise<boolean> => {
    try {
        const { data } = await authClient.mutate({
            mutation: TokenRevokeDocument,
            variables: { refreshToken },
            errorPolicy: 'none'
        });

        const { revoked = 0 } = data?.revokeToken ?? {};
        return revoked > 0;
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client token revoke failed: ${error}`);
        throw error;
    }
};

export const eidentVerificationRequired = async (authClient: ApolloClient<object>, email: string): Promise<boolean> => {
    try {
        const { data } = await authClient.query({
            query: EIdentVerificationRequiredDocument,
            variables: { email }
        });

        return data?.eidentVerificationRequired === true;
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client e-ident verification url fetch failed: ${error}`);
        throw error;
    }
};

export const eidentInit = async (authClient: ApolloClient<object>): Promise<string> => {
    try {
        const { data } = await authClient.mutate({
            mutation: EIdentInitDocument,
            variables: {
                input: {
                    appId: isWeb() ? 'MEMBER_WEB' : 'MEMBER',
                    deviceId: await getDeviceUuid()
                }
            },
            errorPolicy: 'none'
        });
        return data!.eidentInit!.url;
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client e-ident init failed: ${error}`);
        throw error;
    }
};

export const eidentVerify = async (authClient: ApolloClient<object>, query: string): Promise<TokenAuthResult> => {
    try {
        const { data } = await authClient.mutate({
            mutation: EIdentVerifyDocument,
            variables: {
                input: {
                    query,
                    appId: isWeb() ? 'MEMBER_WEB' : 'MEMBER',
                    deviceId: await getDeviceUuid()
                }
            },
            errorPolicy: 'none'
        });
        return handleResult(data?.eidentVerifyV2);
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client e-ident verify failed: ${error}`);
        throw error;
    }
};

export const signUpWithEmail = async (
    authClient: ApolloClient<object>,
    token: string,
    firstName: string,
    lastName: string,
    phone: string,
    language: AppLocale
): Promise<TokenAuthResult> => {
    try {
        const { data } = await authClient.mutate({
            mutation: SignUpWithEmailDocument,
            variables: {
                input: {
                    token,
                    firstName,
                    lastName,
                    phone,
                    language
                }
            },
            errorPolicy: 'none'
        });
        return handleResult(data?.signUpWithEmail);
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client sign up with email failed: ${error}`);
        throw error;
    }
};

export const verifyEmail = async (authClient: ApolloClient<object>, email: string): Promise<boolean> => {
    try {
        const { data } = await authClient.mutate({
            mutation: VerifyEmailDocument,
            variables: { input: { email } },
            errorPolicy: 'none'
        });

        if (data?.verifyEmail) {
            return data?.verifyEmail?.success;
        }

        return false;
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client email verification failed: ${error}`);
        throw error;
    }
};

export const verifySMSToken = async (
    authClient: ApolloClient<object>,
    tokenFromEmail: string,
    codeFromSMS: string
): Promise<TokenAuthResult> => {
    try {
        const { data } = await authClient.mutate({
            mutation: TokenObtainWithSmsCodeDocument,
            variables: { input: { tokenFromEmail, codeFromSms: codeFromSMS } },
            errorPolicy: 'none'
        });
        return handleResult(data?.obtainTokenWithSmsCode);
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client verify SMS failed: ${error}`);
        throw error;
    }
};

export const resendSMSToken = async (authClient: ApolloClient<object>, tokenFromEmail: string): Promise<boolean> => {
    try {
        const { data } = await authClient.mutate({
            mutation: ResendSmsTokenDocument,
            variables: { input: { tokenFromEmail } },
            errorPolicy: 'none'
        });
        return data?.resendSmsToken?.success === true;
    } catch (err: unknown) {
        const error = handleError(err);
        ErrorDiagnostics.error(`Auth client resend SMS failed: ${error}`);
        throw error;
    }
};
