import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useReducer, useRef, useState } from 'react';
import { Animated } from 'react-native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { FormGroup } from '~/components/form';
import { ArrowBackIcon } from '~/components/icon';
import { InputText } from '~/components/input';
import { Markdown } from '~/components/markdown';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useDocumentReferral } from '~/hooks/document-referral';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { AnimatedLogoHeader } from '~/screens/home';

export type DownloadReferralNavigation = StackScreenProps<AppNavigatorParamList, 'download-referral'>['navigation'];

type DocumentReferralState = 'clean' | 'loading' | 'dirty';
type Actions = 'reset' | 'start_loading' | 'loading_complete';

type State = {
    state: DocumentReferralState;
};

const documentReferralStateReducer = ({ state }: State, action: Actions): State => {
    if (action === 'start_loading') {
        return {
            state: 'loading'
        };
    } else if (action === 'loading_complete') {
        return {
            state: 'dirty'
        };
    } else if (action === 'reset') {
        return {
            state: 'clean'
        };
    }
    return { state };
};

export function DownloadReferral() {
    const { navigate } = useNavigation<DownloadReferralNavigation>();
    const {
        canDownloadReferral,
        cleanMemberDocumentReferral,
        downloadMemberDocumentReferral,
        getMemberDocumentReferral
    } = useDocumentReferral();
    const { formatMessage } = useIntl();
    const offset = useRef(new Animated.Value(0)).current;

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const [{ state }, dispatch] = useReducer(documentReferralStateReducer, { state: 'clean' });
    const [code, setCode] = useState('');
    const [lastName, setLastName] = useState('');

    const disableForm = state === 'loading' || canDownloadReferral;
    const isFormValid = code && !!lastName && lastName.length >= 3;

    const backButtonLabel = formatMessage('goBackToStart');
    const openReferralButtonLabel = formatMessage('external-referral.button.open');
    const downloadReferralButtonLabel = formatMessage('external-referral.button.download');
    const resetReferralButtonLabel = formatMessage('external-referral.button.clear');

    const onBack = useCallback(() => navigate('intro'), [navigate]);
    const handleReset = useCallback(() => {
        cleanMemberDocumentReferral();
        setCode('');
        setLastName('');
        dispatch('reset');
    }, [cleanMemberDocumentReferral]);

    const onSubmitOpen = useCallback(async () => {
        if (!isFormValid || state === 'loading') {
            return;
        }
        dispatch('start_loading');

        await getMemberDocumentReferral({ token: code, lastName });

        dispatch('loading_complete');
    }, [code, getMemberDocumentReferral, isFormValid, lastName, state]);

    const onSubmitDownload = useCallback(async () => {
        if (!canDownloadReferral) {
            return;
        }
        dispatch('start_loading');
        await downloadMemberDocumentReferral();
        handleReset();
    }, [canDownloadReferral, downloadMemberDocumentReferral, handleReset]);

    const submitButton = canDownloadReferral ? (
        <Flex flexBasis={web ? '206' : '56'}>
            <Button
                fillContainer
                alignCenter
                type="primary"
                onPress={onSubmitDownload}
                disabled={state === 'loading'}
                label={downloadReferralButtonLabel}
            />
        </Flex>
    ) : (
        <Flex flexBasis={web ? '206' : '56'}>
            <Button
                fillContainer
                alignCenter
                type="primary"
                onPress={onSubmitOpen}
                disabled={state === 'loading' || !isFormValid}
                label={openReferralButtonLabel}
            />
        </Flex>
    );

    const backButton = (
        <Flex flexBasis={web ? '206' : '56'} marginRight={Spacing.SMALL}>
            <Button
                fillContainer
                alignCenter
                leftIcon={ArrowBackIcon}
                type="secondary"
                onPress={onBack}
                label={backButtonLabel}
            />
        </Flex>
    );

    const resetButton = (
        <Flex flexBasis={web ? '206' : '56'} marginRight={Spacing.SMALL}>
            <Button fillContainer alignCenter type="secondary" onPress={handleReset} label={resetReferralButtonLabel} />
        </Flex>
    );

    const buttonStack = (
        <>
            {backButton}
            {resetButton}
            {submitButton}
        </>
    );

    const downloadReferralForm = (
        <Flex flexGrow={1} flexDir={web ? 'row' : 'column-reverse'}>
            <Flex minW="40%" flexDir="column" flexGrow={web ? 1 : 0}>
                <FormGroup>
                    <InputText
                        testID="oneTimeCode"
                        value={code}
                        textContentType="oneTimeCode"
                        returnKeyType="next"
                        caption={formatMessage('external-referral.form.code')}
                        editable={!disableForm}
                        selectTextOnFocus={!disableForm}
                        onChangeText={setCode}
                    />
                    <InputText
                        testID="familyName"
                        value={lastName}
                        textContentType="familyName"
                        returnKeyType="done"
                        caption={formatMessage('external-referral.form.last-name')}
                        editable={!disableForm}
                        selectTextOnFocus={!disableForm}
                        onChangeText={setLastName}
                    />
                </FormGroup>
            </Flex>
            <Flex paddingLeft={web ? Spacing.TINY : 2} paddingBottom={Spacing.TINY} maxWidth={web && '60%'}>
                <Text.HEADER_3>{formatMessage('external-referral.instructions.clinic.title')}</Text.HEADER_3>
                <Markdown>{formatMessage('external-referral.instructions.clinic.description')}</Markdown>

                <Text.HEADER_3>{formatMessage('external-referral.instructions.member.title')}</Text.HEADER_3>
                <Markdown>{formatMessage('external-referral.instructions.member.description')}</Markdown>
            </Flex>
        </Flex>
    );

    const mobileExtraStyle = { paddingBottom: Spacing.GINORMOUS };

    return (
        <ContentHorizontalMargins contentWidth="full" includePadding={web}>
            <AnimatedLogoHeader offset={offset} />
            <EllipticHeaderScreen
                devMenuAccess
                renderBottomContent={() => (
                    <Flex style={!web && mobileExtraStyle}>
                        <Flex style={{ minHeight: '20%' }}>
                            <Spacing.Vertical.SMALL />
                            <Text.H1 style={{ color: Color.HEADER, textAlign: 'center' }}>
                                {formatMessage('external-referral.title')}
                            </Text.H1>
                            <Spacing.Vertical.SMALL />
                        </Flex>
                        <Flex>{downloadReferralForm}</Flex>
                        <Spacing.Vertical.SMALL />
                        {state === 'dirty' && (
                            <Text.P3 style={{ textAlign: 'center' }}>
                                {canDownloadReferral
                                    ? formatMessage('external-referral.form.token-valid')
                                    : formatMessage('external-referral.form.error')}
                            </Text.P3>
                        )}
                        <Spacing.Vertical.MASSIVE />
                    </Flex>
                )}
                renderActions={() =>
                    web ? <>{buttonStack}</> : <Flex flexDirection="column-reverse">{buttonStack}</Flex>
                }
            />
        </ContentHorizontalMargins>
    );
}
