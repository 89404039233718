import SecureStorage from '~/secure-storage';

enum UserIdentitySecureStorageKey {
    USER_IDENTITY = 'auth-user-identity'
}

export const setAuthIdentity = async (id: string) =>
    SecureStorage.setItem(UserIdentitySecureStorageKey.USER_IDENTITY, id);

export const getAuthIdentity = async (): Promise<string | null> => {
    try {
        return await SecureStorage.getItem(UserIdentitySecureStorageKey.USER_IDENTITY);
    } catch (error: unknown) {
        await clearAuthIdentity();
        return null;
    }
};

export const clearAuthIdentity = async () => SecureStorage.deleteItem(UserIdentitySecureStorageKey.USER_IDENTITY);
