import {
    BottomTabBarProps,
    BottomTabHeaderProps,
    BottomTabNavigationOptions,
    createBottomTabNavigator
} from '@react-navigation/bottom-tabs';
import { Flex, useBreakpointValue } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Color } from '~/components/color';
import { HelttiHeaderBottomTabBarWrapper } from '~/components/heltti-header';
import { Spacing } from '~/components/spacing';
import { useIntl } from '~/contexts/intl';
import { noHeader } from '~/navigator/screen-options';
import { isFunction } from '~/utils';

import {
    TabBarCaptionBadgeContainer,
    TabBarItemBadge,
    TabBarItemContainer,
    TabBarItemFunction,
    TabNavigatorParamList,
    tabNavigationScreens
} from './tab-navigation-config';

const { Navigator, Screen } = createBottomTabNavigator<TabNavigatorParamList>();

const TabBar: React.FC<BottomTabBarProps> = props => {
    const { state, descriptors, navigation, insets } = props;

    const showBottomTabBar = useBreakpointValue({
        base: true,
        md: false
    });

    if (!showBottomTabBar) {
        return null;
    }

    return (
        <Flex
            flexDirection="row"
            bgColor={Color.BACKGROUND_DEFAULT}
            borderTopWidth="2px"
            borderTopColor={Color.LINE_DEFAULT}
            padding={`${Spacing.SMALL}px 0 ${insets.bottom}px`}
        >
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key];
                const { tabBarIcon: TabBarIcon, tabBarLabel: TabBarLabel, tabBarBadge } = options;
                const focused = state.index === index;

                const onPress = () => {
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true
                    });

                    if (!focused && !event.defaultPrevented) {
                        navigation.navigate(route.name);
                    }
                };

                return (
                    <TouchableOpacity
                        key={route.key}
                        disabled={focused}
                        accessibilityRole="button"
                        accessibilityState={focused ? { selected: true } : {}}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        style={{ flex: 1, alignItems: 'center' }}
                    >
                        <TabBarItemContainer /* TODO: layout={Layout.duration(300)}*/>
                            {isFunction<TabBarItemFunction>(TabBarIcon) && TabBarIcon({ focused })}
                            <TabBarCaptionBadgeContainer>
                                {isFunction<TabBarItemFunction>(TabBarLabel) && <TabBarLabel focused={focused} />}
                                {tabBarBadge ? <TabBarItemBadge /> : null}
                            </TabBarCaptionBadgeContainer>
                        </TabBarItemContainer>
                    </TouchableOpacity>
                );
            })}
        </Flex>
    );
};

export const TabsNavigator = () => {
    const { formatMessage } = useIntl();
    const responsiveHeader: BottomTabNavigationOptions = useBreakpointValue({
        base: {
            ...noHeader
        },
        md: {
            header: (props: BottomTabHeaderProps) => <HelttiHeaderBottomTabBarWrapper {...props} />
        }
    });

    return (
        <Navigator tabBar={props => <TabBar {...props} />} screenOptions={{ ...responsiveHeader }}>
            {tabNavigationScreens.map(({ options, ...rest }) => (
                <Screen
                    {...rest}
                    options={{ title: formatMessage(options.titleKey), ...options }}
                    key={options.titleKey}
                />
            ))}
        </Navigator>
    );
};
