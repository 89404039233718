import React from 'react';

import { Button } from '~/components/button';
import { useAction } from '~/hooks/chat/action';
import { ChatNotificationActionFragment } from '~/types';

type ActionProps = {
    action: ChatNotificationActionFragment;
    chatId: ID;
};

export const Action = (props: ActionProps) => {
    const { action, chatId } = props;

    const { onAction, actionLabel, loading } = useAction({ action, chatId });

    if (loading) {
        return null;
    }

    return <Button size="small" type="primary" shape="rounded" label={actionLabel} onPress={onAction} />;
};
