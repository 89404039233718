import { useMutation } from '@apollo/client';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useCallback, useMemo, useState } from 'react';

import { GetMemberDocumentUrlThroughTokenDocument } from '~/types';

export type DocumentReferralProps = {
    token: string;
    lastName: string;
};

export function useDocumentReferral() {
    const [getMemberDocumentReferralMutation] = useMutation(GetMemberDocumentUrlThroughTokenDocument);

    const [filename, setFilename] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');

    const canDownloadReferral = useMemo(() => filename !== '' && downloadUrl !== '', [downloadUrl, filename]);

    const cleanMemberDocumentReferral = useCallback(() => {
        setDownloadUrl('');
        setFilename('');
    }, [setDownloadUrl, setFilename]);

    const getMemberDocumentReferral = useCallback(
        async (props: DocumentReferralProps) => {
            const { token, lastName } = props;
            try {
                const result = await getMemberDocumentReferralMutation({ variables: { input: { token, lastName } } });
                setDownloadUrl(result.data?.exportDocumentUrl?.document?.downloadUrl ?? '');
                setFilename(result.data?.exportDocumentUrl?.document?.filename ?? '');
            } catch (err) {
                cleanMemberDocumentReferral();
            }
        },
        [cleanMemberDocumentReferral, getMemberDocumentReferralMutation]
    );

    const downloadMemberDocumentReferral = useCallback(async () => {
        if (filename !== '' && downloadUrl !== '') {
            await axios
                .get(downloadUrl, {
                    responseType: 'blob'
                })
                .then(res => {
                    fileDownload(res.data, filename);
                });

            return true;
        }

        return false;
    }, [downloadUrl, filename]);

    return {
        cleanMemberDocumentReferral,
        getMemberDocumentReferral,
        downloadMemberDocumentReferral,
        canDownloadReferral
    };
}
