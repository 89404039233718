import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import { Box, Flex, Spacer, useBreakpointValue } from 'native-base';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { HeaderCloseButton, HeaderContinueLaterButton } from '~/components/header';
import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { ArrowForwardIcon } from '~/components/icon/arrow-forward';
import { LoadingContent } from '~/components/loader';
import { Markdown } from '~/components/markdown';
import { ProgressIndicator } from '~/components/progress-indicator';
import { QuestionnaireQuestion } from '~/components/questionnaire/questionnaire-question';
import { ScreenContainer } from '~/components/screen';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useContentWidths } from '~/hooks/content-widths/content-widths';
import { useQuestionnaireResponse } from '~/hooks/questionnaire-response';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type QuestionnaireResponseNavigation = StackNavigationProp<MainNavigatorParamList, 'questionnaire-response'>;
type DocumentRoute = RouteProp<MainNavigatorParamList, 'questionnaire-response'>;

export const QuestionnaireResponse: React.FC = () => {
    const { setOptions, goBack, canGoBack, navigate } = useNavigation<QuestionnaireResponseNavigation>();
    const {
        params: { questionnaireResponseId }
    } = useRoute<DocumentRoute>();

    const { bottom } = useSafeAreaInsets();
    const { formatMessage } = useIntl();
    const {
        loading,
        title,
        intro,
        summary,
        responseSummary,
        pages,
        questionPageCount,
        initialPage,
        saveResponses,
        onValueChanged
    } = useQuestionnaireResponse(questionnaireResponseId);

    const [page, setPage] = useState<number>(-1);
    const isOnQuestionPage = useMemo(() => page > 0 && page <= questionPageCount, [page, questionPageCount]);
    const isOnIntroPage = page === 0;
    const isOnSummaryPage = page > questionPageCount;

    const { maxWidth } = useContentWidths('midsize', false);
    const leftDividerLineWidth = useBreakpointValue({
        base: 0,
        lg: 16
    });

    const showMobileHeader: boolean = useBreakpointValue({
        base: true,
        md: false
    });

    useEffect(() => {
        if (page === -1 && initialPage !== undefined) {
            setPage(initialPage);
        }
    }, [initialPage, page]);

    const handleForward = () => {
        if (isOnQuestionPage) {
            saveResponses(page);
        }
        setPage(page + 1);
    };

    const handleBack = () => {
        saveResponses(page);
        setPage(page - 1);
    };

    const handleBackToStart = () => {
        setPage(0);
    };

    const handleClose = useCallback(() => {
        if (isOnQuestionPage) {
            saveResponses(page);
        }
        if (canGoBack()) {
            goBack();
        } else {
            navigate('questionnaires');
        }
    }, [canGoBack, goBack, isOnQuestionPage, navigate, page, saveResponses]);

    useLayoutEffect(() => {
        if (showMobileHeader) {
            setOptions({
                headerShadowVisible: isOnQuestionPage,
                headerLeft: () => (isOnIntroPage ? <HeaderCloseButton onPress={handleClose} /> : null),
                headerTitle: isOnQuestionPage
                    ? props => (
                          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Text.HEADER_3 numberOfLines={1} style={{ flexShrink: 1, marginRight: Spacing.SMALL }}>
                                  {title}
                              </Text.HEADER_3>
                              <HeaderContinueLaterButton onPress={handleClose} />
                          </Flex>
                      )
                    : ''
            });
        } else {
            setOptions({
                header: (props: StackHeaderProps) => (
                    <HelttiHeaderStackHeaderWrapper
                        {...props}
                        headerRight={<HeaderContinueLaterButton onPress={handleClose} />}
                    />
                )
            });
        }
    }, [handleClose, isOnIntroPage, isOnQuestionPage, setOptions, showMobileHeader, title]);

    return (
        <ScreenContainer style={{ paddingBottom: bottom }}>
            {loading ? (
                <LoadingContent />
            ) : (
                <Flex direction="column" flexGrow="1" mt="3">
                    <ContentScrollView>
                        <ContentHorizontalMargins contentWidth="narrow" includePadding={false}>
                            <Flex ml="4" flexGrow="1" flexShrink="1">
                                {isOnIntroPage && (
                                    <Flex mr="4" flexShrink="1">
                                        <Text.HEADER_1>{title}</Text.HEADER_1>
                                        <Markdown>{intro}</Markdown>
                                    </Flex>
                                )}
                                {isOnQuestionPage && (
                                    <Flex pr="4">
                                        {!showMobileHeader && <Text.HEADER_1>{title}</Text.HEADER_1>}

                                        {pages[page].map(questionWithResponse => (
                                            <Flex
                                                key={questionWithResponse.question.id}
                                                borderBottomWidth="1"
                                                borderBottomColor={Color.LINE_DEFAULT}
                                            >
                                                <QuestionnaireQuestion
                                                    {...questionWithResponse}
                                                    onChange={onValueChanged}
                                                />
                                            </Flex>
                                        ))}
                                    </Flex>
                                )}
                                {isOnSummaryPage && (
                                    <Box mr="4">
                                        <Text.H1>{title}</Text.H1>
                                        <Text.P1>{summary}</Text.P1>
                                        <Markdown>{responseSummary}</Markdown>
                                    </Box>
                                )}
                            </Flex>
                        </ContentHorizontalMargins>
                    </ContentScrollView>
                    <Flex flexGrow="0">
                        <Flex direction="row" alignItems="center" minHeight="8">
                            <Flex flexBasis="0" h="2px" grow="1" shrink="0" bg={Color.BORDER_DEFAULT} />
                            <Flex grow="0" shrink="1" flexBasis={maxWidth} flexDirection="row" alignItems="center">
                                <Flex
                                    flexBasis={leftDividerLineWidth}
                                    h="2px"
                                    grow="1"
                                    shrink="0"
                                    bg={Color.BORDER_DEFAULT}
                                />
                                {isOnQuestionPage && (
                                    <Flex grow="0" shrink="1" flexBasis={maxWidth}>
                                        <ProgressIndicator currentStep={page} steps={questionPageCount} />
                                    </Flex>
                                )}
                                <Flex flexBasis="16" h="2px" grow="1" shrink="0" bg={Color.BORDER_DEFAULT} />
                            </Flex>
                            <Flex flexBasis="0" h="2px" grow="1" shrink="0" bg={Color.BORDER_DEFAULT} />
                        </Flex>
                        <ContentHorizontalMargins contentWidth="midsize" includePadding>
                            <Flex mt="5" flexGrow="1" mb="6">
                                <Flex direction="row">
                                    <Flex flexGrow="2" flexShrink="1" flexBasis="185">
                                        {isOnQuestionPage && (
                                            <Button
                                                type="basic"
                                                label={formatMessage('previous')}
                                                onPress={handleBack}
                                            />
                                        )}
                                        {isOnSummaryPage && (
                                            <Button
                                                type="basic"
                                                label={formatMessage('start-again')}
                                                onPress={handleBackToStart}
                                            />
                                        )}
                                    </Flex>
                                    <Spacer flexGrow="3" w="4" />
                                    <Flex flexGrow="2" flexShrink="1" flexBasis="185">
                                        {isOnIntroPage && (
                                            <Button
                                                type="primary"
                                                label={formatMessage('start')}
                                                rightIcon={ArrowForwardIcon}
                                                onPress={handleForward}
                                            />
                                        )}
                                        {isOnQuestionPage && (
                                            <Button
                                                type="primary"
                                                label={formatMessage('next')}
                                                onPress={handleForward}
                                            />
                                        )}
                                        {isOnSummaryPage && (
                                            <Button
                                                type="primary"
                                                label={formatMessage('close')}
                                                onPress={handleClose}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </ContentHorizontalMargins>
                    </Flex>
                </Flex>
            )}
        </ScreenContainer>
    );
};
