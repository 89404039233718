import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import React, { createContext, useState, PropsWithChildren, useContext, useCallback } from 'react';
import { Platform } from 'react-native';
import SemVer from 'semver/classes/semver';
import useAsyncEffect from 'use-async-effect';

import { useAnalytics } from '~/contexts/analytics';
import { useAppConfig } from '~/contexts/app-config';
import * as ErrorDiagnostics from '~/error';

import { createAppUpdateClient, clientAppUpdateState } from './app-update-client';

export type AppUpdateState = 'unknown' | 'ready' | 'error' | 'app-store-update-required';

export type AppUpdateContextType = {
    state: AppUpdateState;
    update: () => Promise<false | void>;
};

export const AppUpdateContext = createContext<AppUpdateContextType | undefined>(undefined);

async function openAppStore() {
    const url = Constants.expoConfig?.extra?.appStoreUrl[Platform.OS];

    if (url) {
        await Linking.openURL(url);
    }
}

function AppUpdateContextProvider(props: PropsWithChildren<object>) {
    const { children } = props;
    const { config } = useAppConfig();
    const { track } = useAnalytics();
    const [state, setState] = useState<AppUpdateState>('unknown');

    useAsyncEffect(async () => {
        try {
            const appVersion = new SemVer(Constants.expoConfig?.version ?? Application.nativeApplicationVersion!);
            const appUpdateState = await clientAppUpdateState(createAppUpdateClient(config), appVersion.raw);

            ErrorDiagnostics.log(`Checking for app update, version: ${appVersion.raw} state: ${appUpdateState}`);

            if (appUpdateState === 'APP_STORE_REQUIRED') {
                setState('app-store-update-required');
                return;
            }
            setState('ready');
        } catch (error: unknown) {
            ErrorDiagnostics.error(`App update check failed: ${error}`);
            setState('ready');
        }
    }, []);

    const update = useCallback(async () => {
        track({ type: 'app-update', action: 'init', detail1: `state=${state}` });

        if (state === 'app-store-update-required') {
            return openAppStore();
        }
    }, [state, track]);

    return <AppUpdateContext.Provider value={{ state, update }}>{children}</AppUpdateContext.Provider>;
}

export const AppUpdateConsumer = AppUpdateContext.Consumer;
export const AppUpdateProvider = AppUpdateContextProvider;

export function useAppUpdate() {
    const context = useContext(AppUpdateContext);
    if (!context) {
        throw Error('Cannot use app updates context until it has been defined');
    }
    return context;
}
