import { CompositeNavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Divider } from '~/components/divider';
import { ErrorModal } from '~/components/error';
import { FilePreviewModal } from '~/components/file-preview';
import { LoadingContent } from '~/components/loader';
import { Markdown } from '~/components/markdown';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { UserInfo } from '~/components/user';
import { useAnalytics } from '~/contexts/analytics';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useDocument } from '~/hooks/document';
import { useDownload } from '~/hooks/download';
import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { isNative, isPdfFormat, isSupportedImageFormat } from '~/utils';

export type DocumentNavigation = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'document'>,
    StackNavigationProp<AppNavigatorParamList>
>;

type DocumentRoute = RouteProp<MainNavigatorParamList, 'document'>;

const ContentContainer = styled.View`
    flex: 1;
    flex-direction: column;
`;

const Section = styled.View`
    margin: ${Spacing.MEDIUM}px ${Spacing.MEDIUM}px;
`;

const DocumentScreen: FunctionComponent = () => {
    const { goBack, setOptions } = useNavigation<DocumentNavigation>();
    const {
        params: { documentId }
    } = useRoute<DocumentRoute>();
    const { download } = useDownload();
    const { loading, document, error, createToken, deleteToken } = useDocument({ documentId });
    const [tokenLoading, setTokenLoading] = useState(false);
    const [preview, setPreview] = useState(false);
    const { track } = useAnalytics();
    const { formatMessage, formatDate } = useIntl();

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.document.title') }));
    const isDesktopSize: boolean = useBreakpointValue({ base: false, md: true });

    const openDocument = async () => {
        const { url, filename } = document!;
        if (isNative() && (isSupportedImageFormat(filename) || isPdfFormat(filename))) {
            setPreview(true);
        } else if (url && filename) {
            await download(url, filename);
        }
        track({ type: 'document', action: 'open' });
    };

    const handleGenerateExportTokens = async () => {
        setTokenLoading(true);
        await createToken();
        setTokenLoading(false);
    };

    const handleDeleteExportTokens = async () => {
        setTokenLoading(true);
        await deleteToken(document!.exportToken!.id);
        setTokenLoading(false);
    };

    if (error) {
        return <ErrorModal error={error} onRetry={() => {}} onClose={goBack} />;
    }

    return (
        <ContentHorizontalMargins contentWidth="narrow" includePadding={false}>
            <ContentScrollView>
                <ContentContainer>
                    {preview ? (
                        <FilePreviewModal
                            url={document!.url!}
                            title={document!.filename}
                            filename={document!.filename}
                            onClose={() => setPreview(false)}
                        />
                    ) : null}
                    {loading || !document ? (
                        <LoadingContent />
                    ) : (
                        <>
                            <Section>
                                {isDesktopSize && (
                                    <Text.HEADER_2>
                                        <FormattedMessage id="profile.document.title" />
                                    </Text.HEADER_2>
                                )}
                                <Text.PARAGRAPH_1>
                                    <FormattedMessage id="profile.document.filename" />: {document.filename}
                                </Text.PARAGRAPH_1>
                                <Text.PARAGRAPH_1>
                                    <FormattedMessage id="profile.document.added-date" />:{' '}
                                    {formatDate(document.addedDate, { dateStyle: 'long' })}
                                </Text.PARAGRAPH_1>
                            </Section>

                            <Divider />

                            <Section>
                                <Text.HEADER_2>
                                    <FormattedMessage id="profile.document.comment" />
                                </Text.HEADER_2>
                                {document.createdBy && <UserInfo user={document.createdBy} image />}
                                <Text.PARAGRAPH_1>{document.comment}</Text.PARAGRAPH_1>
                            </Section>

                            <Divider />

                            <Section>
                                <Text.HEADER_2 after={Spacing.SMALL}>
                                    <FormattedMessage id="profile.document.export-tokens.title" />
                                </Text.HEADER_2>

                                {document.exportToken ? (
                                    <>
                                        <View style={{ marginBottom: Spacing.MEDIUM }}>
                                            <Text.PARAGRAPH_1>
                                                <FormattedMessage id="profile.document.export-tokens.name" />:{' '}
                                                {document.exportToken.name}
                                            </Text.PARAGRAPH_1>
                                            <Text.PARAGRAPH_1>
                                                <FormattedMessage id="profile.document.export-tokens.code" />:{' '}
                                                {document.exportToken.token}
                                            </Text.PARAGRAPH_1>
                                            <Text.PARAGRAPH_1>
                                                <FormattedMessage id="profile.document.export-tokens.valid-until" />:{' '}
                                                {formatDate(document.exportToken.validUntil, { dateStyle: 'long' })}
                                            </Text.PARAGRAPH_1>
                                        </View>
                                        <Button
                                            type="secondary"
                                            loading={tokenLoading}
                                            onPress={handleDeleteExportTokens}
                                            label={formatMessage('profile.document.export-tokens.delete-token')}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        type="secondary"
                                        loading={tokenLoading}
                                        onPress={handleGenerateExportTokens}
                                        label={formatMessage('profile.document.export-tokens.create-token')}
                                    />
                                )}

                                <Markdown contentContainerStyle={{ marginTop: Spacing.MEDIUM }}>
                                    {formatMessage('profile.document.export-tokens.help')}
                                </Markdown>
                            </Section>

                            <Section>
                                <Button
                                    type="primary"
                                    onPress={openDocument}
                                    label={formatMessage('profile.document.open')}
                                />
                            </Section>
                        </>
                    )}
                </ContentContainer>
            </ContentScrollView>
        </ContentHorizontalMargins>
    );
};

export { DocumentScreen as Document };
