import React from 'react';
import styled from 'styled-components/native';

import { Badge } from '~/components/badge';
import { Color } from '~/components/color';
import { ChevronDownIcon } from '~/components/icon';
import { IconButton } from '~/components/icon-button';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';

const ScrollToBottomContainer = styled.View`
    flex-direction: row;
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding: 6px;
    ${Shadow.primary}
`;

type ScrollToBottomButtonProps = {
    badge?: string;
    onPress: () => void;
};

export const ScrollToBottomButton = (props: ScrollToBottomButtonProps) => {
    const { badge, onPress } = props;

    return (
        <ScrollToBottomContainer>
            {badge && (
                <>
                    <Badge badge={badge} />
                    <Spacing.Horizontal.TINY />
                </>
            )}
            <IconButton icon={ChevronDownIcon} onPress={onPress} />
        </ScrollToBottomContainer>
    );
};
