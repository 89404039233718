import { useMutation } from '@apollo/client';
import { ReactNativeFile } from 'apollo-upload-client';
import { useCallback } from 'react';

import { useAnalytics } from '~/contexts/analytics';
import { useIntl } from '~/contexts/intl';
import { ProfileEditableFields } from '~/screens/profile';
import { ProfileFieldEditDocument, ProfileImageUploadDocument } from '~/types';

export function useProfileEdit(memberId: ID) {
    const [updateProfileFields] = useMutation(ProfileFieldEditDocument);
    const [uploadProfileImage] = useMutation(ProfileImageUploadDocument);
    const { setCurrentLocale } = useIntl();
    const { track } = useAnalytics();

    const profileImageUpload = useCallback(
        async (uri: string) => {
            let file;
            if (uri.startsWith('data:')) {
                // Base64 encoded image with web picker
                const res = await fetch(uri);
                file = await res?.blob();
            } else {
                // File-based image with mobile pickers
                const name = uri.substring(uri.lastIndexOf('/') + 1);
                file = new ReactNativeFile({ uri, name, type: 'image/jpg' });
            }

            await uploadProfileImage({
                variables: { input: { file } },
                optimisticResponse: {
                    avatarEdit: {
                        member: {
                            id: memberId,
                            avatarBigUrl: uri,
                            __typename: 'MemberNode'
                        }
                    }
                }
            });
            track({ type: 'profile', action: 'update-image' });
        },
        [memberId, uploadProfileImage, track]
    );

    const profileFieldUpdate = useCallback(
        async (fields: Partial<ProfileEditableFields>) => {
            if ('lang' in fields && fields.lang) {
                await updateProfileFields({
                    variables: { input: { ...fields } },
                    update: cache => {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: 'root' });
                        cache.gc();
                    }
                });
                await setCurrentLocale(fields.lang);
            } else {
                await updateProfileFields({
                    variables: { input: { ...fields } }
                });
            }

            track({ type: 'profile', action: 'update-fields', detail1: `fields=${Object.keys(fields)}` });
        },
        [setCurrentLocale, updateProfileFields, track]
    );

    return { profileFieldUpdate, profileImageUpload };
}
