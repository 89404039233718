import { Calendar } from 'expo-calendar';
import React from 'react';
import styled from 'styled-components/native';

import { ButtonSheet } from '~/components/button';
import { ChevronRightIcon } from '~/components/icon';
import { ModalPicker } from '~/components/modal-picker';
import { FormattedMessage } from '~/contexts/intl';

import { AppointmentActionDescription, AppointmentActionTitle } from './appointment-actions';
import { isWeb } from '~/utils';

export type AppointmentModifyProps = {
    calendars?: Calendar[];
    onAddToCalendar: (calendar: Calendar) => Promise<void>;
    onCancel: () => void;
};

const CalendarsContainer = styled.View`
    align-self: stretch;
`;

export function AppointmentAddToCalendar(props: AppointmentModifyProps) {
    const { calendars, onAddToCalendar, onCancel } = props;

    return (
        <ModalPicker
            visible={!!calendars}
            extraStyles={isWeb() ? { width: 343, marginLeft: 'auto', marginRight: 'auto' } : undefined}
            renderContent={() => (
                <>
                    <AppointmentActionTitle>
                        <FormattedMessage id="appointment.add-calendar.title" />
                    </AppointmentActionTitle>
                    <AppointmentActionDescription>
                        <FormattedMessage id="appointment.add-calendar.description" />
                    </AppointmentActionDescription>
                </>
            )}
            renderActions={() => {
                if (calendars && calendars.length > 0) {
                    return (
                        <CalendarsContainer>
                            <ButtonSheet
                                carets
                                items={calendars.map((calendar: Calendar) => ({
                                    key: calendar.id,
                                    label: calendar.title,
                                    right: ChevronRightIcon,
                                    onPress: () => onAddToCalendar(calendar)
                                }))}
                            />
                        </CalendarsContainer>
                    );
                }
                return (
                    <CalendarsContainer>
                        <AppointmentActionDescription>
                            <FormattedMessage id="appointment.add-calendar.no-calendars" />
                        </AppointmentActionDescription>
                    </CalendarsContainer>
                );
            }}
            onCancel={onCancel}
        />
    );
}
