import AsyncStorage from '@react-native-async-storage/async-storage';

import SecureStorage from '~/secure-storage';
import { secondsToDate } from '~/utils/date';

/*
 * Secure (keychain) storage
 */

enum AuthSecureStorageKey {
    AUTH_REFRESH_TOKEN = 'auth-refresh-token',
    AUTH_REFRESH_TOKEN_EXPIRATION = 'auth-refresh-token-expiration',
    AUTH_PIN_CODE = 'auth-pin-code'
}

let _token: string | null = null;

export const getAuthToken = (): string | null => _token;

export const setAuthToken = (newToken: string | null) => (_token = newToken);

export const clearAuthToken = () => (_token = null);

export const getRefreshToken = async () => SecureStorage.getItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN);

export const setRefreshToken = async (token: string) =>
    SecureStorage.setItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN, token);

export const clearRefreshToken = async () => SecureStorage.deleteItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN);

export const getRefreshExpiresIn = async (): Promise<Date | null> => {
    const exp = await SecureStorage.getItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN_EXPIRATION);
    return exp ? new Date(exp) : null;
};

export const setRefreshExpiresIn = async (expiration: number) =>
    SecureStorage.setItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN_EXPIRATION, secondsToDate(expiration).toISOString());

export const clearRefreshExpiresIn = async () =>
    SecureStorage.deleteItem(AuthSecureStorageKey.AUTH_REFRESH_TOKEN_EXPIRATION);

export const getPinCode = async () => SecureStorage.getItem(AuthSecureStorageKey.AUTH_PIN_CODE);

export const setPinCode = async (pinCode: string) => SecureStorage.setItem(AuthSecureStorageKey.AUTH_PIN_CODE, pinCode);

export const clearPinCode = async () => SecureStorage.deleteItem(AuthSecureStorageKey.AUTH_PIN_CODE);

/*
 * Non-secure storage
 */
enum AuthStorageKey {
    BIOMETRIC_AUTH_ENABLED = 'auth-biometric-enabled'
}

export const getBiometricAuthEnabled = async (): Promise<boolean | null> => {
    const result = await AsyncStorage.getItem(AuthStorageKey.BIOMETRIC_AUTH_ENABLED);
    if (result) {
        return result === 'yes';
    }
    return null;
};

export const setBiometricAuthEnabled = (enabled: boolean) => {
    return AsyncStorage.setItem(AuthStorageKey.BIOMETRIC_AUTH_ENABLED, enabled ? 'yes' : 'no');
};

export const clearBiometricAuthEnabled = () => AsyncStorage.removeItem(AuthStorageKey.BIOMETRIC_AUTH_ENABLED);
