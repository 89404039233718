import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { Color } from '~/components/color';
import { LottieView } from '~/components/lottie';
import { Text } from '~/components/text';
import { useTimeout } from '~/hooks/timer';
import { LayoutStyleProps } from '~/types';
import { isWeb } from '~/utils/platform';

export type LoaderProps = {
    delay?: number;
    size: 'small' | 'medium' | 'large';
    type?: 'regular' | 'oval';
    fill?: string;
    caption?: string;
    layoutStyle?: LayoutStyleProps;
};

const LOADER_ASSETS = {
    regular: {
        small: require('./assets/loader-small.json'),
        medium: require('./assets/loader-medium.json'),
        large: require('./assets/loader-large.json')
    },
    oval: {
        small: require('./assets/loader-oval-indeterminate-medium.json'),
        medium: require('./assets/loader-oval-indeterminate-medium.json'),
        large: require('./assets/loader-oval-indeterminate-large.json'),
        progress: require('./assets/loader-oval-medium.json')
    }
};

const SIZES = {
    small: 24,
    medium: 44,
    large: 96
};

export function Loader(props: LoaderProps) {
    const { delay, size, type = 'regular', fill = Color.PRIMARY_DEFAULT, caption, layoutStyle } = props;
    const [visible, setVisible] = useState<boolean>(delay === undefined);

    useTimeout(() => setVisible(true), delay);

    return visible ? (
        <View style={[layoutStyle, { alignItems: 'center', justifyContent: 'center' }]}>
            {isWeb() ? (
                <ActivityIndicator />
            ) : (
                <LottieView
                    style={{ width: SIZES[size], height: SIZES[size] }}
                    autoPlay
                    loop
                    source={LOADER_ASSETS[type][size]}
                    colorFilters={['lead', 'follower-1', 'follower-2'].map(keypath => ({
                        keypath,
                        color: fill
                    }))}
                />
            )}
            {caption ? <Text.SUBTITLE_1 before={16}>{caption}</Text.SUBTITLE_1> : null}
        </View>
    ) : null;
}

export type OvalLoaderProps = {
    progress: number;
    total: number;
};

export const OvalLoader = (props: OvalLoaderProps) => {
    const { progress, total } = props;

    if (total <= 0) {
        return <Loader size="medium" type="oval" />;
    }
    return (
        <LottieView
            style={{ width: SIZES['medium'], height: SIZES['medium'] }}
            source={LOADER_ASSETS['oval']['progress']}
            progress={(progress * 1.0) / total}
        />
    );
};
