import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useLayoutEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Color } from '~/components/color';
import { HorizontalLogo } from '~/components/horizontal-logo';

export type LogoHeaderOptions = {
    range?: number;
    headerRight?: ReactNode;
};

const HEADER_HEIGHT_RANGE = [84, 48];
const HEADER_PADDING_RANGE = [16, 8];

export function useLogoNavigationHeader(options: LogoHeaderOptions) {
    const { range = 30, headerRight } = options;
    const { setOptions } = useNavigation();
    const offset = useSharedValue<number>(0);
    const { top } = useSafeAreaInsets();

    const scrollHandler = useAnimatedScrollHandler(event => {
        offset.value = event.contentOffset.y;
    });

    const inputRange = [0, range];

    const headerStyle = useAnimatedStyle(() => ({
        height: interpolate(offset.value, inputRange, HEADER_HEIGHT_RANGE, Extrapolate.CLAMP) + top,
        paddingBottom: interpolate(offset.value, inputRange, HEADER_PADDING_RANGE, Extrapolate.CLAMP),
        shadowOpacity: interpolate(offset.value, inputRange, [0, 0.85], Extrapolate.CLAMP),
        elevation: interpolate(offset.value, inputRange, [0, 1], Extrapolate.CLAMP)
    }));

    const headerLogoStyle = useAnimatedStyle(() => ({
        height: interpolate(offset.value, inputRange, [42, 32], Extrapolate.CLAMP)
    }));

    const scrollViewStyle = useAnimatedStyle(() => ({
        paddingTop: interpolate(offset.value, inputRange, HEADER_HEIGHT_RANGE, Extrapolate.CLAMP) + top
    }));
    const contentContainerStyle = { paddingBottom: HEADER_HEIGHT_RANGE[1] };

    useLayoutEffect(() => {
        setOptions({
            headerShown: true,
            headerTransparent: false,
            header: () => (
                <Animated.View style={[styles.header, headerStyle]}>
                    <View style={styles.items}>
                        <Animated.View style={[styles.logo, headerLogoStyle]}>
                            <HorizontalLogo />
                        </Animated.View>
                        {headerRight}
                    </View>
                </Animated.View>
            )
        });
    }, [setOptions, headerStyle, headerLogoStyle, headerRight, top]);

    return { onScroll: scrollHandler, scrollViewStyle, contentContainerStyle };
}

const styles = StyleSheet.create({
    header: {
        position: 'absolute',
        backgroundColor: Color.BACKGROUND_DEFAULT,
        paddingHorizontal: 16,
        paddingBottom: HEADER_PADDING_RANGE[0],
        height: HEADER_HEIGHT_RANGE[0],
        width: '100%',
        justifyContent: 'flex-end'
    },
    items: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    logo: {
        aspectRatio: 119 / 33
    }
});
