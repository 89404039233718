import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const SendIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M6.17035 3.02806C5.36683 2.64745 4.50336 3.4262 4.79928 4.26462L5.76584 7.0032C6.52221 9.14627 8.42449 10.6781 10.6796 10.9599L14.5039 11.438C14.7873 11.4734 15 11.7144 15 12C15 12.2856 14.7873 12.5266 14.5039 12.562L10.6796 13.0401C8.42449 13.3219 6.52221 14.8537 5.76584 16.9968L4.79928 19.7354C4.50337 20.5738 5.36683 21.3526 6.17035 20.9719L22.0963 13.4281C22.6481 13.1667 23 12.6107 23 12C23 11.3893 22.6481 10.8333 22.0963 10.5719L6.17035 3.02806Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
            </Svg>
        </View>
    );
};
