import { useMutation } from '@apollo/client';
import { useErrorHandler } from 'react-error-boundary';

import { AppError } from '~/error';
import { RequestAccountRemovalDocument } from '~/types';

export const useRequestAccountRemoval = () => {
    const [requestAccountRemovalMutation] = useMutation(RequestAccountRemovalDocument);
    const errorHandler = useErrorHandler();

    const requestAccountRemoval = async (additionalInformation: string) => {
        try {
            await requestAccountRemovalMutation({
                variables: {
                    input: {
                        additionalInformation
                    }
                }
            });
        } catch (err) {
            if (err instanceof Error) {
                errorHandler(
                    new AppError(err, 'error-modal.generic.title', {
                        onClose: () => {},
                        name: 'error-overlay.go_back'
                    })
                );
            } else {
                errorHandler(err);
            }
        }
    };

    return requestAccountRemoval;
};
