import React from 'react';
import { View } from 'react-native';

import { Button, ButtonProps } from '~/components/button';
import { Spacing } from '~/components/spacing';
import { useIntl } from '~/contexts/intl';

export const HeaderProfileEditButton = (props: Pick<ButtonProps, 'onPress'>) => {
    const { onPress } = props;
    const { formatMessage } = useIntl();

    return (
        <View style={{ marginRight: Spacing.MEDIUM, marginBottom: Spacing.SMALL }}>
            <Button size="small" shape="rounded" label={formatMessage('edit')} onPress={onPress} />
        </View>
    );
};

HeaderProfileEditButton.displayName = 'HeaderProfileEditButton';
