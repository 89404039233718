import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { ButtonSheet } from '~/components/button';
import { Spacing } from '~/components/spacing';
import { useIntl } from '~/contexts/intl';
import { compare } from '~/utils/string';

import { ProfileNavigation } from './profile';

export const ProfileButtonList: React.FC = () => {
    const { navigate } = useNavigation<ProfileNavigation>();
    const { formatMessage } = useIntl();
    return (
        <ButtonSheet
            leadingSeparator
            trailingSeparator
            carets
            style={{ marginVertical: Spacing.LARGE }}
            items={[
                {
                    key: 'calendar-reservation-accesses',
                    label: formatMessage('profile.calendar-reservation-accesses.description'),
                    title: formatMessage('profile.calendar-reservation-accesses.title'),
                    onPress: () => navigate('calendar-reservation-accesses')
                },
                {
                    key: 'calendar-events',
                    label: formatMessage('profile.calendar-events.description'),
                    title: formatMessage('profile.calendar-events.title'),
                    onPress: () => navigate('calendar-events')
                },
                {
                    key: 'documents',
                    label: formatMessage('profile.documents.description'),
                    title: formatMessage('profile.documents.title'),
                    onPress: () => navigate('documents')
                },
                {
                    key: 'questionnaires',
                    label: formatMessage('profile.questionnaires.description'),
                    title: formatMessage('profile.questionnaires.title'),
                    onPress: () => navigate('questionnaires')
                }
            ].sort((one, two) => compare(one.title, two.title))}
        />
    );
};
