import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components/native';

import { InfoIcon } from '~/components/icon';
import { Text } from '~/components/text';

export type HelperButtonFlavor = 'icon' | 'label' | 'caption';

export type HelperButtonType = 'default' | 'positive' | 'negative';

export type HelperButtonProps = PropsWithChildren<{
    // TODO: extend component to support multiple flavors and types
    //  flavor?: HelperButtonFlavor;
    // type?: HelperButtonType;
    // disabled?: boolean;
    title?: ReactNode;
    // caption?: ReactNode);
}>;

const Container = styled.View`
    flex-direction: row;
    justify-content: flex-start;
`;

export function HelperButton(props: HelperButtonProps) {
    const { title } = props;

    return (
        <Container>
            <InfoIcon />
            <Text.SMALL_BUTTON_LABEL style={{ marginLeft: 4, flexShrink: 1 }}>{title}</Text.SMALL_BUTTON_LABEL>
        </Container>
    );
}
