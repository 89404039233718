import React from 'react';

import { ArticleCarousel } from '~/components/article/article-carousel';
import { ArticleList } from '~/components/article/article-list';
import { ArticleCategory } from '~/types';

export type ArticleCategoryProps = {
    category: ArticleCategory;
    onItemPressed: (id: ID) => void;
};

export function ArticleCategoryView(props: ArticleCategoryProps) {
    const { category, onItemPressed } = props;
    const { displayType, title, description, articles } = category;

    if (displayType === 'CAROUSEL') {
        return (
            <ArticleCarousel
                title={title}
                description={description}
                articles={articles}
                onItemPressed={onItemPressed}
            />
        );
    }

    if (displayType === 'LIST') {
        return (
            <ArticleList title={title} description={description} articles={articles} onItemPressed={onItemPressed} />
        );
    }

    return null;
}
