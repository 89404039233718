import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { ButtonSheet } from '~/components/button';
import { ArticleNavigationProp } from '~/screens/article';
import { ArticleCategorySubArticle } from '~/types';

export type ArticleChildCategoryProps = {
    title?: string | null;
    articles?: readonly ArticleCategorySubArticle[];
};

export function ArticleChildCategory(props: ArticleChildCategoryProps) {
    const { title, articles = [] } = props;
    const { push } = useNavigation<ArticleNavigationProp>();

    return articles.length > 0 ? (
        <>
            <ButtonSheet
                carets
                leadingSeparator
                title={title ?? undefined}
                items={articles.map(childArticle => ({
                    key: childArticle.id,
                    title: childArticle.title,
                    label: childArticle.highlight ?? childArticle.title,
                    image: childArticle.coverPhotoUrl ?? undefined,
                    onPress: () => push('article', { id: childArticle.id })
                }))}
            />
        </>
    ) : null;
}
