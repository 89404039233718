import React from 'react';
import styled from 'styled-components/native';

import { InfoIcon } from '~/components/icon';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { CalendarEventType, AppointmentContactType } from '~/types';

export type AppointmentInfoProps = {
    type: AppointmentContactType | CalendarEventType;
};

const InfoContainer = styled.View`
    flex-direction: row;
    margin: 16px;
`;

const InfoTextContainer = styled.View`
    flex: 1;
    margin-left: 8px;
`;

const InfoTitle = styled(Text.SUBTITLE_1)``;
const InfoDescription = styled(Text.SMALL_BUTTON_LABEL)`
    margin-top: 2px;
`;

export function AppointmentContactInfo(props: AppointmentInfoProps) {
    const { type } = props;
    const { formatMessage } = useIntl();

    const description = formatMessage('appointment.contact-info.description', { type });

    if (description === '') {
        return null;
    }

    return (
        <InfoContainer>
            <InfoIcon />
            <InfoTextContainer>
                <InfoTitle>{formatMessage('appointment.contact-info.title', { type })}</InfoTitle>
                <InfoDescription>{description}</InfoDescription>
            </InfoTextContainer>
        </InfoContainer>
    );
}
