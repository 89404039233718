import React from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { EIdentWeb } from '~/components/e-ident/e-ident.web';

const ScreenContainer = styled.View`
    flex: 1;
    background-color: ${Color.BACKGROUND_TERTIARY};
`;

export function ProfileVerify() {
    return (
        <ScreenContainer>
            <EIdentWeb />
        </ScreenContainer>
    );
}
