export enum Color {
    TRANSPARENT = '#FFFFFF00',

    // PRIMARY COLORS
    ALMOST_BLACK = '#2F2F2F',
    BLACK = '#000000',
    CHERRY = '#FF4259',

    // SECONDARY COLORS (used in illustrations etc)
    BLUE_VEINS_LIGHT = '#C1D4F7',
    BLUE_VEINS = '#47A4E7',
    BLUE_VEINS_DARK = '#13367C',

    BRUTAL_BRUISE_EXTRA_LIGHT = '#E9E2F9',
    BRUTAL_BRUISE = '#BAA7E2',
    BRUTAL_BRUISE_DARK = '#303A93',

    SUBMARINE_LIGHT = '#FFFDD2',
    SUBMARINE = '#FDFAA0',
    SUBMARINE_DARK = '#FCF871',

    COLD_TURKEY = '#FFCED3',
    CHERRY_DISABLED = '#FFB8BD',

    SMOOTHIE_LIGHT = '#C0E9D3',
    SMOOTHIE = '#007585',

    // BACKGROUNDS
    BACKGROUND_DEFAULT = '#FFFFFF',
    BACKGROUND_PRIMARY = '#F8F7F7',
    BACKGROUND_SECONDARY = '#FFF0F0',
    BACKGROUND_TERTIARY = '#FFE5E5',
    BACKGROUND_GRADIENT = 'linear-gradient(#FFFFFF, #FFE5E5)',
    BACKGROUND_DISABLED = '#F3F2EF',

    // BUTTON BACKGROUNDS
    BASIC_DEFAULT = '#FFFFFF',
    BASIC_HOVER = '#F6F5F3',
    BASIC_PRESSED = '#E2E1DF',

    PRIMARY_DEFAULT = '#118488',
    PRIMARY_HOVER = '#017484',
    PRIMARY_PRESSED = '#006472',

    WARNING_DEFAULT = '#FF334C',
    WARNING_HOVER = '#E53E52',
    WARNING_PRESSED = '#D73C4F',

    // LINES
    LINE_DEFAULT = '#E2E1DF',
    BORDER_DEFAULT = '#E2E1DF',
    LINE_DISABLED = '#F3F2F2',
    GRAY_MID = '#E7E6E4',

    // TEXTS
    TEXT_DEFAULT = '#2F2F2F',
    TEXT_SECONDARY = '#535353',
    TEXT_TERTIARY = '#FFFFFF',
    TEXT_DISABLED = '#AAA9A7',

    HEADER = '#005466',

    // CHAT
    CHAT_SENDER = '#FFF0F0',
    CHAT_RECIPIENT = '#F6F5F3',
    SEND_MESSAGE = '#007585',
    CHAT_BADGE = '#F0273F',

    // TASKS
    TASK_DEFAULT = '#3058A7',
    TASK_COMPLETED = '#00A67E',
    TASK_ACTIVE = '#FFFDD2',

    // STATE
    FOCUS_DEFAULT = '#3A65B8',
    BUSY_DEFAULT = '#FFB82E',

    // ICONS
    ICON_DEFAULT = '#2F2F2F',
    ICON_PRIMARY = '#FF4259',
    ICON_SECONDARY = '#535353',
    ICON_NEGATIVE = '#FFFFFF',
    ICON_DISABLED = '#AAA9A7',
    ICON_TERTIARY = '#00A67E',

    // This is not in Figma but think this is important
    HELTTI_RED = '#E74011',

    // SHADOWS
    PRIMARY_DROP_SHADOW = 'rgba(0, 0, 0, 0.18)',
    SECONDARY_DROP_SHADOW = 'rgba(0, 0, 0, 0.25)'
}
