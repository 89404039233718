import { Flex, useBreakpointValue } from 'native-base';
import React, { PropsWithChildren } from 'react';

import { Color } from '~/components/color';

export type SidebarProps = PropsWithChildren;

export const Sidebar = (props: SidebarProps) => {
    const { children } = props;

    const isDesktop: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const sidebarWidth = useBreakpointValue({
        base: 256,
        lg: 312
    });

    return (
        <Flex
            borderRadius="16"
            borderWidth={isDesktop ? 1 : 0}
            borderColor={Color.BORDER_DEFAULT}
            flexDir="column"
            grow={isDesktop ? 0 : 1}
            flexBasis={isDesktop && sidebarWidth}
        >
            {children}
        </Flex>
    );
};
