import { Box, Flex } from 'native-base';
import React from 'react';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type ProgressIndicatorProps = {
    currentStep: number;
    steps: number;
    numberOfDividers?: number;
};

export const ProgressIndicator = (props: ProgressIndicatorProps) => {
    const { currentStep, numberOfDividers = 5, steps } = props;
    const value = (currentStep * 100) / steps;

    return (
        <Flex direction="row" alignItems="center" w="100%">
            <Flex direction="column" grow="1" shrink="1">
                <Flex h="2" />
                <Flex h="2px" bgColor={Color.BORDER_DEFAULT} direction="row">
                    <Box w={`${value}%`} h="2px" bgColor={Color.TASK_COMPLETED} />
                </Flex>
                <Flex h="2" direction="row">
                    {[...Array(numberOfDividers)].map((_, index) => {
                        return (
                            <Box
                                key={index}
                                w={`${100 / (numberOfDividers + 1)}%`}
                                h="2"
                                borderRightColor={
                                    currentStep >= ((index + 1) / (numberOfDividers + 1)) * steps
                                        ? Color.TASK_COMPLETED
                                        : Color.BORDER_DEFAULT
                                }
                                borderRightWidth="2"
                            />
                        );
                    })}
                </Flex>
            </Flex>
            <Flex
                shrink="0"
                grow="0"
                basis={steps > 9 ? '60' : '50'}
                alignItems="center"
                borderRadius="2xl"
                borderColor={currentStep === steps ? Color.TASK_COMPLETED : Color.BORDER_DEFAULT}
                borderWidth="2"
                py="3px"
                px="2"
            >
                <Text.CAPTION>
                    {currentStep}/{steps}
                </Text.CAPTION>
            </Flex>
        </Flex>
    );
};
