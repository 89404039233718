import React, { ReactNode } from 'react';
import { Pressable, Text as NativeText } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { ButtonCheckIcon } from '~/components/icon';

export type RadioImageButtonProps = {
    disabled: boolean;
    selected?: boolean;
    image?: ReactNode;
    imageLabel?: string;
    onPress?: () => void | Promise<void>;
};

const ActiveBackground = styled.View<{ selected: boolean }>`
    border-radius: 8px;
    flex: 1;

    margin: 0 4px;
    ${({ selected }) =>
        selected
            ? `border-width: 2px; border-color: ${Color.FOCUS_DEFAULT}; padding: 3px;`
            : `border-width: 1px; border-color: ${Color.LINE_DEFAULT}; padding: 4px;`};
`;

const CheckBoxContainer = styled.View`
    width: 24px;
    height: 24px;
`;

const OvalButton = styled.View`
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-color: ${Color.PRIMARY_DEFAULT};
    background.color: ${Color.BACKGROUND_DEFAULT};
    border-radius: 24px;
    transform: scale(0.92, 1) rotate(45deg);
`;

const CheckMarker = styled.View`
    position: absolute;
    left: 0px;
    top: 0px;
`;

const ImageContainer = styled.View<{ disabled: boolean; selected: boolean }>`
    align-items: center;
    margin-top: -10px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const Label = styled.View<{ disabled: boolean; selected: boolean }>`
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export function RadioImageButton(props: RadioImageButtonProps) {
    const type = 'radio';
    const { selected = false } = props;
    return (
        <ActiveBackground selected={selected}>
            <Pressable accessibilityRole={type} disabled={props.disabled} onPress={props.onPress}>
                {props.selected ? (
                    <CheckBoxContainer>
                        <OvalButton />
                        <CheckMarker>
                            <ButtonCheckIcon fill={Color.PRIMARY_DEFAULT} />
                        </CheckMarker>
                    </CheckBoxContainer>
                ) : (
                    <CheckBoxContainer />
                )}
                <ImageContainer disabled={props.disabled} selected={selected}>
                    {props.image}
                </ImageContainer>
                <Label disabled={props.disabled} selected={selected}>
                    {props.imageLabel && <NativeText>{props.imageLabel}</NativeText>}
                </Label>
            </Pressable>
        </ActiveBackground>
    );
}

RadioImageButton.displayName = 'ImageButton';
