import { useCallback } from 'react';

import { InteractionAction } from '~/hooks/interaction';
import { useRichLink } from '~/hooks/linking/rich-link';

export const useInteractionAction = () => {
    const handleLink = useRichLink();

    const onAction = useCallback(
        async (interactionId: ID, action: InteractionAction) => {
            const { handle, value } = action;
            if (handle === 'Open') {
                await handleLink(value!);
            } else {
                throw new Error(`Received unknown handle ${handle}`);
            }
        },
        [handleLink]
    );

    return { onAction };
};
