import React from 'react';
import { Ellipse, Mask } from 'react-native-svg';

import { useEllipticMaskValues } from '~/hooks/elliptic-mask/elliptic-mask-values';

export type ParametricEllipticMaskProps = {
    maskId: string;
};

export const ParametricEllipticMask = (props: ParametricEllipticMaskProps) => {
    const { maskId } = props;
    const rx = 2.7;
    const ry = 2.6;
    const minimumPointX = 0.23;
    const height = 1;

    const [cx, cy] = useEllipticMaskValues(rx, ry, minimumPointX, height);

    return (
        <Mask
            id={maskId}
            maskUnits="objectBoundingBox"
            maskContentUnits="objectBoundingBox"
            x="0"
            y="0"
            width="1"
            height="1"
        >
            <Ellipse cx={cx} cy={cy} rx={rx} ry={ry} rotation={-45} fill="#ffffff" />
        </Mask>
    );
};
