import { Platform } from 'react-native';

/**
 * App runs natively on a device
 */
export const isNative = () => {
    return Platform.OS !== 'web';
};

/**
 * App runs in a web browser
 */
export const isWeb = () => {
    return Platform.OS === 'web';
};
