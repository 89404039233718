import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Animated, RefreshControl } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { ArticleRoot } from '~/components/article';
import { MessageHeaderButton } from '~/components/button';
import { CallToActionList } from '~/components/call-to-action-list/call-to-action-list';
import { ChatList } from '~/components/chat-list';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Divider } from '~/components/divider';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { useArticle } from '~/hooks/article';
import { useCalendarEventList } from '~/hooks/calendar-event-list';
import { useCallToActions } from '~/hooks/call-to-actions';
import { useChatList } from '~/hooks/chat-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { CalendarEvent } from '~/types';

import { UpcomingAppointments } from './upcoming-appointments';

type HomeNavigationProp = StackNavigationProp<MainNavigatorParamList, 'tabs'>;

export const Home: React.FC = () => {
    const { navigate } = useNavigation<HomeNavigationProp>();
    const { formatMessage } = useIntl();
    const { top } = useSafeAreaInsets();
    const handleError = useErrorHandler();

    const { showAnimatedLogoHeader, marginTop } = useBreakpointValue({
        base: {
            showAnimatedLogoHeader: true,
            marginTop: top + HEADER_COLLAPSED_HEIGHT
        },
        md: {
            showAnimatedLogoHeader: false,
            marginTop: top
        }
    });

    // TODO: Remove this and the useFocusEffect refetch when a subscription is used for keeping the chat data up to date
    const [reFetchingChats, setReFetchingChats] = useState(false);

    const {
        chats,
        loading: loadingChats,
        error: errorChats,
        refetch: refetchChatList,
        loadingInitial: chatsLoadingInitial
    } = useChatList({ onlyOpen: true });

    const {
        article: topicalArticle,
        loading: loadingArticle,
        loadingInitial: articleLoadingInitial
    } = useArticle({ tag: 'root_topical' });

    const { calendarEvents } = useCalendarEventList();

    const { callToActions, loading: loadingCTAs, loadingInitial: ctaLoadingInitial } = useCallToActions('HOME');

    const loadingInitial = chatsLoadingInitial || articleLoadingInitial || ctaLoadingInitial;
    const loading = loadingChats || loadingArticle || loadingCTAs;

    const offset = useRef(new Animated.Value(0)).current;

    const webComponent = useBreakpointValue({
        base: false,
        lg: true
    });

    const actionsTabDirection = useBreakpointValue({
        base: 'column',
        lg: 'row'
    });

    useFocusEffect(
        useCallback(() => {
            setReFetchingChats(true);

            refetchChatList()
                .then(() => {
                    setReFetchingChats(false);
                })
                .catch(error => {
                    ErrorDiagnostics.error(error);
                });
        }, [refetchChatList])
    );

    if (errorChats) {
        handleError(errorChats);
    }

    const handleOpenCalendarEvent = useCallback(
        (calendarEvent: CalendarEvent) => navigate('appointment', { calendarEventId: calendarEvent.id }),
        [navigate]
    );

    return (
        <>
            {showAnimatedLogoHeader && <AnimatedLogoHeader offset={offset} />}

            <ContentScrollView
                bounces
                refreshControl={
                    <RefreshControl
                        refreshing={!loadingInitial && !reFetchingChats && loading}
                        onRefresh={refetchChatList}
                    />
                }
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], {
                    useNativeDriver: false
                })}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
                overScrollMode="never"
                contentContainerStyle={{ minHeight: '80%' }}
                style={{ marginTop }}
            >
                <ContentHorizontalMargins>
                    <ContentContainer>
                        {/** Actions Tabs */}
                        <Flex flexDir={actionsTabDirection} flexGrow="1">
                            <Flex flexGrow="1">
                                {/** Upcoming appointments */}
                                {calendarEvents && calendarEvents.length > 0 && (
                                    <>
                                        {webComponent && (
                                            <MessageHeaderContainer>
                                                <Text.HEADER_3>
                                                    <FormattedMessage id="appointments.title" />
                                                </Text.HEADER_3>

                                                <MessageHeaderButton
                                                    onPress={() => navigate('calendar-events')}
                                                    buttonText={formatMessage('home.all-appointments')}
                                                />
                                            </MessageHeaderContainer>
                                        )}
                                        <UpcomingAppointments
                                            handleOpenCalendarEvent={handleOpenCalendarEvent}
                                            calendarEvents={calendarEvents}
                                        />

                                        {webComponent && (
                                            <Divider leftInset={0} before={Spacing.LARGE} after={Spacing.MEDIUM} />
                                        )}
                                    </>
                                )}

                                {/** Messages */}
                                <MessageHeaderContainer>
                                    <Text.HEADER_3>
                                        <FormattedMessage id="chats.title" />
                                    </Text.HEADER_3>

                                    <MessageHeaderButton
                                        onPress={() => navigate('chat-archive')}
                                        buttonText={webComponent ? formatMessage('home.all-chats') : undefined}
                                    />
                                </MessageHeaderContainer>

                                <ChatList loading={loading && (chats?.length ?? 0) === 0} chats={chats} />
                            </Flex>

                            {/** CTAs */}
                            <Flex paddingLeft={{ lg: '87' }} flexBasis={{ lg: '489' }}>
                                {!webComponent && (
                                    <Flex>
                                        <Divider leftInset={0} before={Spacing.LARGE} after={Spacing.LARGE} />
                                    </Flex>
                                )}
                                <MessageHeaderContainer>
                                    <Text.HEADER_3>
                                        <FormattedMessage id="home.ctas" />
                                    </Text.HEADER_3>

                                    <MessageHeaderButton
                                        onPress={() => navigate('services')}
                                        buttonText={webComponent ? formatMessage('home.all-ctas') : undefined}
                                    />
                                </MessageHeaderContainer>
                                <CallToActionList callToActions={callToActions} gridCustomColumnCount={1} />
                            </Flex>
                        </Flex>
                        {/** Topical articles */}
                        {topicalArticle ? <ArticleRoot onlyShowChildCategories article={topicalArticle} /> : null}
                    </ContentContainer>
                </ContentHorizontalMargins>
            </ContentScrollView>
        </>
    );
};

const RANGE = 38;

const HEADER_EXPANDED_HEIGHT = 76;
const HEADER_COLLAPSED_HEIGHT = 48;

const HEADER_EXPANDED_PADDING = 16;
const HEADER_COLLAPSED_PADDING = 14;

type AnimatedLogoHeaderProps = {
    offset: Animated.Value;
};

export function AnimatedLogoHeader(props: AnimatedLogoHeaderProps) {
    const { offset } = props;
    const insets = useSafeAreaInsets();

    const inputRange = [0, RANGE + insets.top];

    const height = offset.interpolate({
        inputRange,
        outputRange: [HEADER_EXPANDED_HEIGHT + insets.top, HEADER_COLLAPSED_HEIGHT + insets.top],
        extrapolate: 'clamp'
    });

    const shadowOpacity = offset.interpolate({ inputRange, outputRange: [0, 0.85], extrapolate: 'clamp' });
    const elevation = offset.interpolate({ inputRange, outputRange: [0, 1], extrapolate: 'clamp' });

    const paddingBottom = offset.interpolate({
        inputRange,
        outputRange: [HEADER_EXPANDED_PADDING, HEADER_COLLAPSED_PADDING],
        extrapolate: 'clamp'
    });

    const logoHeight = offset.interpolate({ inputRange, outputRange: [42, 32], extrapolate: 'clamp' });

    return (
        <AnimatedLogoHeaderBackground style={{ height, paddingBottom, shadowOpacity, elevation }}>
            <AnimatedLogoHeaderItems>
                <AnimatedLogoHeaderLogoContainer style={{ height: logoHeight }}>
                    <HorizontalLogo />
                </AnimatedLogoHeaderLogoContainer>
            </AnimatedLogoHeaderItems>
        </AnimatedLogoHeaderBackground>
    );
}

const AnimatedLogoHeaderBackground = styled(Animated.View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding: 0 16px ${HEADER_EXPANDED_PADDING}px;
    height: ${HEADER_EXPANDED_HEIGHT}px;
    width: 100%;
    justify-content: flex-end;
`;

const AnimatedLogoHeaderItems = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const AnimatedLogoHeaderLogoContainer = styled(Animated.View)``;

const ContentContainer = styled.View`
    flex: 1;
    margin-top: ${HEADER_EXPANDED_HEIGHT - HEADER_COLLAPSED_HEIGHT}px;
    padding: 0;
`;

const MessageHeaderContainer = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${Spacing.MEDIUM}px;
`;
