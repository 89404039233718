import { removeMdPlugin } from 'markdown-it-remove';
import { MarkdownIt } from 'react-native-markdown-display';

export function strip(markdown: string, newline?: string) {
    const markdownIt = new MarkdownIt().use(removeMdPlugin, {
        replaceNewlineValue: newline ?? ' ',
        removeTags: true,
        escapeHtml: true
    });
    return markdownIt.render(markdown).trim();
}
