import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { AppError } from '~/error';
import {
    CalendarEventDocument,
    CalendarEventListDocument,
    CancelCalendarEventDocument,
    fragmentToCalendarEvent
} from '~/types';

export type UseCalendarEventHookResult = ReturnType<typeof useCalendarEvent>;

export function useCalendarEvent(calendarEventId: ID, pollInterval?: number) {
    const { data, error, loading, refetch } = useQuery(CalendarEventDocument, {
        variables: { calendarEventId },
        pollInterval
    });
    const [cancelCalendarEvent] = useMutation(CancelCalendarEventDocument);
    const handleError = useErrorHandler();

    const calendarEventFragment = data?.root?.calendarEvent ?? undefined;
    const calendarEvent = calendarEventFragment ? fragmentToCalendarEvent(calendarEventFragment) : undefined;

    const handleCancelCalendarEvent = useCallback(async () => {
        try {
            const { data: cancelCalendarEventData } = await cancelCalendarEvent({
                variables: { input: { eventId: calendarEventId } },
                refetchQueries: [{ query: CalendarEventListDocument }]
            });
            const fragment = cancelCalendarEventData?.cancelCalendarEvent?.calendarEvent;
            if (fragment) {
                return fragmentToCalendarEvent(fragment);
            }
        } catch (err: unknown) {
            if (err instanceof Error) {
                handleError(
                    new AppError(err, 'error.cancel-appointment-failed', {
                        onClose: () => {},
                        name: 'error-overlay.go_back'
                    })
                );
            } else {
                handleError(err);
            }
        }
    }, [calendarEventId, cancelCalendarEvent, handleError]);

    // If you are looking for corresponding `modifyCalendarEvent` mutation, see `hooks/appointment`. Appointment
    // modification utilizes appointment reservation flow and performs exactly the same steps as normal appointment
    // reservation, except that the original calendar event id is carried through the flow and `modifyCalendarEvent`
    // mutation is executed (with original calendar event id) instead of `reserveAppointment`.

    return {
        loading,
        error,
        calendarEvent,
        refetch,
        cancelCalendarEvent: handleCancelCalendarEvent
    };
}
