import React from 'react';
import { View } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export function TabServicesIcon({ fill, size = 32 }: IconProps) {
    return (
        <View style={{ width: size, height: size }}>
            <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.50295 19C3.56995 19 2.00295 20.567 2.00295 22.5C2.00295 24.3063 3.37209 25.7941 5.12857 25.9803L5.93334 26.0656L6.01773 26.8705C6.20208 28.6288 7.69078 30 9.49844 30C11.4314 30 12.9984 28.433 12.9984 26.5L12.9992 19.0714M0.00295067 22.5C0.00295067 19.4624 2.46538 17 5.50295 17H12.9278C14.0718 17 14.9992 17.9274 14.9992 19.0714L14.9984 26.5C14.9984 29.5375 12.536 32 9.49844 32C6.92108 32 4.7587 30.2279 4.16181 27.8351C1.77212 27.2361 0.00295067 25.0752 0.00295067 22.5ZM17.0018 19.0714C17.0018 17.9274 17.9292 17 19.0732 17H26.4981C29.5357 17 31.9981 19.4624 31.9981 22.5C31.9981 25.0752 30.2289 27.2361 27.8392 27.8351C27.2423 30.2279 25.08 32 22.5026 32C19.4651 32 17.0027 29.5376 17.0026 26.5001L17.0018 19.0714ZM19.0018 19.0714L19.0026 26.4999C19.0026 28.4329 20.5696 30 22.5026 30C24.3103 30 25.799 28.6288 25.9833 26.8705L26.0677 26.0656L26.8725 25.9803C28.629 25.7941 29.9981 24.3063 29.9981 22.5C29.9981 20.567 28.4311 19 26.4981 19H19.0732C19.0338 19 19.0019 19.032 19.0018 19.0714Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.50295 19C3.56995 19 2.00295 20.567 2.00295 22.5C2.00295 24.3063 3.37209 25.7941 5.12857 25.9803L5.93334 26.0656L6.01773 26.8705C6.20208 28.6288 7.69078 30 9.49844 30C11.4314 30 12.9984 28.433 12.9984 26.5L12.9992 19.0714M0.00295067 22.5C0.00295067 19.4624 2.46538 17 5.50295 17H12.9278C14.0718 17 14.9992 17.9274 14.9992 19.0714L14.9984 26.5C14.9984 29.5375 12.536 32 9.49844 32C6.92108 32 4.7587 30.2279 4.16181 27.8351C1.77212 27.2361 0.00295067 25.0752 0.00295067 22.5ZM17.0018 19.0714C17.0018 17.9274 17.9292 17 19.0732 17H26.4981C29.5357 17 31.9981 19.4624 31.9981 22.5C31.9981 25.0752 30.2289 27.2361 27.8392 27.8351C27.2423 30.2279 25.08 32 22.5026 32C19.4651 32 17.0027 29.5376 17.0026 26.5001L17.0018 19.0714ZM19.0018 19.0714L19.0026 26.4999C19.0026 28.4329 20.5696 30 22.5026 30C24.3103 30 25.799 28.6288 25.9833 26.8705L26.0677 26.0656L26.8725 25.9803C28.629 25.7941 29.9981 24.3063 29.9981 22.5C29.9981 20.567 28.4311 19 26.4981 19H19.0732C19.0338 19 19.0019 19.032 19.0018 19.0714Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.4951 13C28.4281 13 29.9951 11.433 29.9951 9.5C29.9951 7.6937 28.626 6.20586 26.8695 6.0197L26.0647 5.93441L25.9804 5.12955C25.796 3.37118 24.3073 2 22.4997 2C20.5667 2 18.9997 3.567 18.9997 5.5L18.9989 12.9286M31.9951 9.5C31.9951 12.5376 29.5327 15 26.4951 15H19.0703C17.9263 15 16.9989 14.0726 16.9989 12.9286L16.9997 5.5C16.9997 2.46248 19.4621 0 22.4997 0C25.077 0 27.2394 1.77213 27.8363 4.16488C30.226 4.76389 31.9951 6.9248 31.9951 9.5ZM14.9963 12.9286C14.9963 14.0726 14.0689 15 12.9248 15H5.5C2.46243 15 0 12.5376 0 9.5C0 6.9248 1.76917 4.76389 4.15886 4.16488C4.75575 1.77213 6.91813 0 9.49548 0C12.533 0 14.9954 2.46237 14.9955 5.49989L14.9963 12.9286ZM12.9963 12.9286L12.9955 5.5001C12.9955 3.56711 11.4285 2 9.49548 2C7.68783 2 6.19913 3.37118 6.01478 5.12955L5.93039 5.93441L5.12562 6.0197C3.36914 6.20586 2 7.6937 2 9.5C2 11.433 3.567 13 5.5 13H12.9248C12.9643 13 12.9962 12.968 12.9963 12.9286Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
            </Svg>
        </View>
    );
}
