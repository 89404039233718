import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import { FullScreenContainer } from './full-screen-container';

export const KeyboardAvoidingFullScreenContainer: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <FullScreenContainer>
            <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <View style={styles.inner}>{children}</View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </FullScreenContainer>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    inner: {
        padding: 24,
        flex: 1
    }
});
