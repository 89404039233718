import React from 'react';

import { ButtonProps } from '~/components/button';
import { useIntl } from '~/contexts/intl';

import { HeaderTextButton } from './header-text-button';

export const HeaderContinueLaterButton: React.FC<Pick<ButtonProps, 'onPress'>> = ({ onPress }) => {
    const { formatMessage } = useIntl();

    return <HeaderTextButton onPress={onPress} label={formatMessage('continue-later')} />;
};
