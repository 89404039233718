import PreventScreenshot from '@heltti/react-native-prevent-screenshot';
import { useEffect, useMemo } from 'react';

import { isNative } from '~/utils/platform';

export function useDisableSecurityScreen() {
    useEffect(() => {
        if (isNative()) {
            PreventScreenshot.enabled(false);
        }

        return () => {
            if (isNative()) {
                PreventScreenshot.enabled(true);
            }
        };
    }, []);
}

export function useSecurityScreen() {
    return useMemo(
        () => ({
            enableSecurityScreen: isNative() ? () => PreventScreenshot.enabled(true) : () => {},
            disableSecurityScreen: isNative() ? () => PreventScreenshot.enabled(false) : () => {}
        }),
        []
    );
}
