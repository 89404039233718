import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Animated } from 'react-native';

import AuthenticationSuccessImage from '~/assets/images/authentication-success.svg';
import { useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';

import { AuthInstruction } from '../auth/auth-instruction';
import { AnimatedLogoHeader } from '../home';

type OnboardingPinSetupIntroNavigation = StackNavigationProp<OnboardingNavigatorParamList, 'pin-setup-intro'>;

export function OnboardingPinSetupIntro() {
    const { navigate } = useNavigation<OnboardingPinSetupIntroNavigation>();
    const { formatMessage } = useIntl();
    const { callName } = useUserProfile();

    const next = useCallback(() => navigate('pin-setup'), [navigate]);

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <AuthInstruction
                devMenuAccess
                renderEllipticMask
                image={
                    web ? (
                        <AuthenticationSuccessImage width="272" height="272" viewBox="0 0 350 350" />
                    ) : (
                        <AuthenticationSuccessImage />
                    )
                }
                title={formatMessage('onboarding.pin-setup-intro.title', { callName })}
                description={formatMessage('onboarding.pin-setup-intro.description')}
                buttonLabel={formatMessage('onboarding.pin-setup-intro.button.title')}
                onButtonPress={next}
            />
        </>
    );
}
