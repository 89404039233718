import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Animated } from 'react-native';

import HeroImage from '~/assets/images/shining-heart.svg';
import { HeaderDownloadReferralButton } from '~/components/header/header-download-referral-button';
import { useIntl } from '~/contexts/intl';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { AnimatedLogoHeader } from '../home';

import { AuthInstruction } from './auth-instruction';
import { isWeb } from '~/utils';

type AuthIntroNavigation = StackNavigationProp<AppNavigatorParamList>;

export function AuthIntro() {
    const { navigate } = useNavigation<AuthIntroNavigation>();
    const { formatMessage } = useIntl();

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const next = useCallback(() => navigate('verification-check'), [navigate]);

    const downloadReferral = useCallback(() => navigate('download-referral'), [navigate]);
    const downloadReferralLabel = formatMessage('auth-intro.referrals');

    useExtendedNavigationHeader({
        headerRight: isWeb() ? (
            <HeaderDownloadReferralButton label={downloadReferralLabel} onPress={downloadReferral} isWeb={web} />
        ) : null
    });

    const offset = useRef(new Animated.Value(0)).current;

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <AuthInstruction
                devMenuAccess
                renderEllipticMask
                image={web ? <HeroImage width="272" height="272" viewBox="0 0 350 350" /> : <HeroImage />}
                title={formatMessage('auth-intro.title')}
                description={formatMessage('auth-intro.description')}
                buttonLabel={formatMessage('auth-intro.button.title')}
                onButtonPress={next}
            />
        </>
    );
}
