import { Box } from 'native-base';
import React, { useCallback, useEffect } from 'react';

import { RadioButton } from '~/components/check';
import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { useStateCallback } from '~/hooks/state-callback';

import { FormInputProps } from './form-input';

type FormRadioGroupInputOption<T extends string | number> = { option: T; name: string };

type FormRadioGroupInputProps<T extends string | number> = FormInputProps<T> & {
    options: FormRadioGroupInputOption<T>[];
};

export function FormRadioGroupInput<T extends string | number>(props: FormRadioGroupInputProps<T>) {
    const { caption, disabled, options, onSubmit, onSetValue, initialValue } = props;
    const [selection, setSelection] = useStateCallback<T | null>(null);

    useEffect(() => {
        if (initialValue !== undefined && initialValue !== null) {
            setSelection(initialValue, () => {});
        }
    }, [initialValue, setSelection]);

    const onPress = useCallback(
        (option: T) => {
            const value = onSetValue?.(option) ?? option;

            setSelection(value, newSelection => onSubmit?.(newSelection!));
        },
        [onSetValue, onSubmit, setSelection]
    );

    return (
        <Box>
            {caption ? (
                <Text.INPUT_FIELD_TITLE style={{ color: disabled ? Color.TEXT_DISABLED : Color.ALMOST_BLACK }}>
                    {caption}
                </Text.INPUT_FIELD_TITLE>
            ) : null}
            <>
                {options.map(({ option, name }) => (
                    <RadioButton
                        key={option}
                        selected={selection === option}
                        label={name}
                        onPress={() => onPress(option)}
                    />
                ))}
            </>
        </Box>
    );
}

FormRadioGroupInput.displayName = 'FormRadioGroupInput';
