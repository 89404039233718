import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Alert, Animated } from 'react-native';

import PushNotificationsImage from '~/assets/images/push-notifications.svg';
import { Button, TextButton } from '~/components/button';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { usePushNotifications } from '~/contexts/push-notifications';
import { useUserProfile } from '~/contexts/user-profile';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';

import { AnimatedLogoHeader } from '../home';

type OnboardingPushPermissionsNavigation = StackNavigationProp<OnboardingNavigatorParamList, 'push-notifications'>;

export const OnboardingPushPermissions = () => {
    const { reset } = useNavigation<OnboardingPushPermissionsNavigation>();
    const { nextTopic } = useOnboardingTopics();
    const { requestPermissions } = usePushNotifications();
    const { formatMessage } = useIntl();
    const { callName } = useUserProfile();

    const next = useCallback(
        () => reset({ index: 0, routes: [{ name: nextTopic('push-notifications') }] }),
        [nextTopic, reset]
    );

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    const handleEnablePushPermissions = useCallback(async () => {
        if (!(await requestPermissions())) {
            Alert.alert(
                formatMessage('error.alert.title'),
                formatMessage('onboarding-push-notifications.error.cannot_enable'),
                [
                    {
                        text: formatMessage('ok'),
                        style: 'default'
                    }
                ]
            );
        } else {
            next();
        }
    }, [requestPermissions, formatMessage, next]);

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <EllipticHeaderScreen
                renderEllipticMask
                renderTopContent={() => <PushNotificationsImage />}
                renderBottomContent={() => (
                    <>
                        <Text.TITLE>
                            <FormattedMessage id="onboarding-push-notifications.hello" values={{ name: callName }} />
                        </Text.TITLE>

                        <Spacing.Vertical.SMALL />

                        <Text.PARAGRAPH_1>
                            <FormattedMessage id="onboarding-push-notifications.description" />
                        </Text.PARAGRAPH_1>
                    </>
                )}
                renderActions={() => (
                    <Flex direction="row" mx="4" alignItems="center">
                        <TextButton fillContainer onPress={next} label={formatMessage('onboarding.pass')} />
                        <Button
                            fillContainer
                            type="primary"
                            onPress={handleEnablePushPermissions}
                            label={formatMessage('onboarding.enable')}
                        />
                    </Flex>
                )}
            />
        </>
    );
};
