import { Box, Flex } from 'native-base';
import React from 'react';
import { Pressable, Image, StyleProp, FlexStyle } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { ChevronRightIcon } from '~/components/icon';
import { Text } from '~/components/text';
import { strip } from '~/utils/markdown';
import { isFunction, isString } from '~/utils/type-predicates';

type ButtonSheetItemKey = string;

export type ButtonSheetItemProps = {
    key: string;
    title?: string;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    image?: string | (() => React.ReactNode);
    color?: Color;
    onPress?: () => void | Promise<void>;
};

export type ButtonSheetProps = {
    disabled?: boolean;
    leadingSeparator?: boolean;
    trailingSeparator?: boolean;
    carets?: boolean;
    title?: string;
    items: ButtonSheetItemProps[];
    style?: StyleProp<FlexStyle>;
    onPressItem?: (key: ButtonSheetItemKey) => void | Promise<void>;
};

type ButtonState = 'disabled' | 'enabled' | 'hover' | 'select';

type ButtonVariant = `${ButtonState}`;
type ButtonVariantColors = {
    [key in ButtonVariant]: string;
};

const BACKGROUND_COLOR: ButtonVariantColors = {
    disabled: Color.BACKGROUND_DEFAULT,
    enabled: Color.BACKGROUND_DEFAULT,
    hover: Color.BASIC_HOVER,
    select: Color.BACKGROUND_DEFAULT
};

const FOREGROUND_COLOR = {
    disabled: Color.TEXT_DISABLED,
    enabled: Color.ALMOST_BLACK,
    hover: Color.BASIC_HOVER,
    select: Color.BASIC_PRESSED
};

const ButtonSheetItemContainer = styled.View<{ buttonState: ButtonState }>`
    background-color: ${({ buttonState }) => BACKGROUND_COLOR[buttonState]};
    flex-direction: row;
    align-items: center;
    padding: 16px;
    opacity: ${({ buttonState }) => ({ select: 0.75, disabled: 0.5, enabled: 1.0, hover: 1.0 }[buttonState])};
`;

const ButtonSheetItemSeparator = styled.View`
    height: 1px;
    background-color: ${Color.LINE_DEFAULT};
    margin-left: 16px;
`;

const ButtonSheetItemTitle = styled(Text.BUTTON_LABEL).attrs({ numberOfLines: 2 })`
    color: ${Color.HEADER};
    margin-bottom: 8px;
`;

const ButtonSheetItemLabel = styled(Text.PARAGRAPH_1).attrs({ numberOfLines: 2 })``;

const ButtonImageContainer = styled.View`
    width: 64px;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;

    margin-right: 16px;
`;

const ButtonTextContainer = styled.View`
    flex: 1 1 auto;
`;

const ButtonCaretContainer = styled.View`
    margin-left: 16px;
`;

const state = (disabled: boolean, pressed: boolean) => (pressed ? 'select' : disabled ? 'disabled' : 'enabled');

function renderImage(image?: string | (() => React.ReactNode)) {
    if (image) {
        if (isString(image)) {
            return (
                <Image
                    accessibilityRole="image"
                    accessible
                    source={{ uri: image }}
                    style={{ width: 64, height: 64, borderRadius: 8 }}
                />
            );
        }
        if (isFunction(image)) {
            return image();
        }
    }
    return null;
}

export const ButtonSheet = (props: ButtonSheetProps) => {
    const {
        title,
        items = [],
        disabled = false,
        style,
        onPressItem,
        leadingSeparator,
        trailingSeparator,
        carets = false
    } = props;

    return (
        <Flex style={style} backgroundColor={Color.BACKGROUND_DEFAULT}>
            {leadingSeparator ? <ButtonSheetItemSeparator /> : null}
            {title ? <Text.SUBTITLE_2 style={{ marginLeft: 16, marginTop: 16 }}>{title}</Text.SUBTITLE_2> : null}
            {items.map((itemProps: ButtonSheetItemProps, index: number) => {
                const {
                    key,
                    title: itemTitle,
                    label,
                    disabled: itemDisabled = false,
                    image,
                    color = Color.TEXT_DEFAULT,
                    onPress
                } = itemProps;
                const cleanLabel = label ? strip(label) : undefined;
                return (
                    <Pressable
                        key={key}
                        accessibilityRole="button"
                        disabled={disabled || itemDisabled}
                        onPress={onPress ?? (() => onPressItem?.(key))}
                    >
                        {({ pressed }) => {
                            const currentState: ButtonVariant = state(disabled || itemDisabled, pressed);
                            const fill = FOREGROUND_COLOR[currentState];
                            return (
                                <>
                                    {index !== 0 ? <ButtonSheetItemSeparator /> : null}
                                    <ButtonSheetItemContainer buttonState={currentState}>
                                        {image ? (
                                            <ButtonImageContainer>{renderImage(image)}</ButtonImageContainer>
                                        ) : null}
                                        <ButtonTextContainer>
                                            {itemTitle ? (
                                                <ButtonSheetItemTitle>{itemTitle}</ButtonSheetItemTitle>
                                            ) : null}
                                            {cleanLabel ? (
                                                <ButtonSheetItemLabel style={{ color }}>
                                                    {cleanLabel}
                                                </ButtonSheetItemLabel>
                                            ) : null}
                                        </ButtonTextContainer>
                                        {carets && (
                                            <ButtonCaretContainer>
                                                <ChevronRightIcon fill={fill} />
                                            </ButtonCaretContainer>
                                        )}
                                    </ButtonSheetItemContainer>
                                </>
                            );
                        }}
                    </Pressable>
                );
            })}
            {trailingSeparator && <ButtonSheetItemSeparator />}
        </Flex>
    );
};

ButtonSheet.displayName = 'ButtonSheet';
