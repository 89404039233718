import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useLayoutEffect } from 'react';
import { StyleSheet, Text } from 'react-native';
import Animated, {
    Extrapolation,
    interpolate,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue
} from 'react-native-reanimated';

import { Color } from '~/components/color';
import { styles } from '~/components/header';

export type ExtendedHeaderOptions = {
    offset?: number;
    range?: number;
    title?: string;
    headerLeft?: ReactNode;
    headerRight?: ReactNode;
};

export function useExtendedNavigationHeader(options: ExtendedHeaderOptions) {
    const { offset: minOffset = 20, range = 30, title, headerLeft, headerRight } = options;
    const { setOptions } = useNavigation();
    const offset = useSharedValue<number>(0);

    const scrollHandler = useAnimatedScrollHandler(event => {
        offset.value = event.contentOffset.y;
    });

    const inputRange = [Math.max(0, minOffset), range + minOffset];
    const interpolateOptions = {
        extrapolateLeft: Extrapolation.CLAMP,
        extrapolateRight: Extrapolation.CLAMP
    };

    const headerBackgroundStyle = useAnimatedStyle(() => {
        const opacity = interpolate(offset.value, inputRange, [0, 1], interpolateOptions);
        return {
            backgroundColor: Color.BACKGROUND_DEFAULT,
            opacity,
            shadowOpacity: interpolate(offset.value, inputRange, [0, 0.85]),
            elevation: interpolate(offset.value, inputRange, [0, 1])
        };
    });

    const headerTitleStyle = useAnimatedStyle(() => {
        const opacity = interpolate(offset.value, inputRange, [0, 1], interpolateOptions);
        return {
            opacity
        };
    });

    useLayoutEffect(() => {
        setOptions({
            headerShown: true,
            headerTransparent: true,
            headerBackground: () => <Animated.View style={[StyleSheet.absoluteFillObject, headerBackgroundStyle]} />,
            headerTitle: () => (
                <Animated.View style={headerTitleStyle}>
                    <Text numberOfLines={1} style={styles.headerTitle}>
                        {title}
                    </Text>
                </Animated.View>
            ),
            headerLeft: () => headerLeft,
            headerRight: () => headerRight
        });
    }, [setOptions, headerBackgroundStyle, headerTitleStyle, headerLeft, headerRight, title]);

    return { onScroll: scrollHandler };
}
