import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Animated, {
    interpolateColor,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue,
    interpolate
} from 'react-native-reanimated';

export type DynamicHeaderColorOptions = {
    color: { from: string; to: string };
    range?: number;
    title?: string;
};

export const useDynamicHeaderColor = (options: DynamicHeaderColorOptions) => {
    const { color, range, title } = options;

    if (Platform.OS !== 'android') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { setOptions } = useNavigation();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const offset = useSharedValue<number>(0);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const scrollHandler = useAnimatedScrollHandler(event => {
            offset.value = event.contentOffset.y;
        });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const style = useAnimatedStyle(() => {
            const backgroundColor = interpolateColor(offset.value, [0, range ?? 200], [color.from, color.to]);
            const shadowOpacity = interpolate(offset.value, [0, range ?? 200], [0, 0.85]);

            return {
                backgroundColor,
                // Note! These properties should match the default react-navigation header shadow props
                shadowOpacity,
                borderBottomColor: 'rgb(216, 216, 216)',
                shadowColor: 'rgb(216, 216, 216)',
                shadowRadius: 0,
                shadowOffset: { width: 0, height: 0.33 }
            };
        });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setOptions({
                headerBackground: () => <Animated.View style={[StyleSheet.absoluteFillObject, style]} />,
                headerTransparent: false,
                title
            });
        }, [setOptions, style, title]);

        return { onScroll: scrollHandler };
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { setOptions } = useNavigation();

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setOptions({
                headerBackground: () => <View style={[StyleSheet.absoluteFillObject, { backgroundColor: color.to }]} />,
                headerTransparent: false,
                title
            });
        });
        return { onScroll: undefined };
    }
};
