import { mapMultipleBy, writableDeepCopy } from '@heltti/common';
import unionWith from 'lodash/unionWith';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { config } from '~/app/config';
import { ButtonSheetItemProps } from '~/components/button';
import { isSystemMessage } from '~/components/chat/system-message-types';
import { InteractionTheme } from '~/components/chat-message/interaction-message';
import { ChevronRightIcon, TickerIcon } from '~/components/icon';
import { useUserProfile } from '~/contexts/user-profile';
import { AppError } from '~/error';
import { fragmentToInteractionAction, InteractionAction } from '~/hooks/interaction';
import { useInteractionAction } from '~/hooks/interaction-action';
import { useLoadingQuery } from '~/hooks/loading-query';
import {
    ChatDocument,
    ChatMemberFragment,
    ChatMembersFragment,
    ChatMessageFileAttachmentFragment,
    ChatMessageFragment,
    ChatMessageIdAttachmentFragment,
    ChatMessageInteractionFragment,
    ChatNotificationActionFragment,
    ChatNotificationFragment,
    ChatQuery,
    ChatQueryVariables,
    ChatUpdatesDocument,
    ChatUpdatesSubscription,
    ChatUserFragment,
    ChatUsersFragment,
    isFileAttachment,
    isIdAttachment,
    isInteractionAttachment
} from '~/types';
import { idToGlobalId, RelayConnection, relayConnectionReduce, relayCursorForLastEdge } from '~/utils';
import { valueForKey } from '~/utils/keyvalue';

export type ChatMessageUser = {
    id: ID;
    name: string;
    title?: string;
    avatarUrl?: string;
};

export type ChatUserLastSeenInfo = {
    userId: ID;
    avatarUrl: string;
    lastMessageSeenId?: ID;
};

export type ChatMessageAttachment = {
    id: ID;
    filename: string;
    url: string;
};

type BaseChatMessage = {
    id: ID;
    createdAt: Date;
};

export type ChatMessageInteraction = BaseChatMessage & {
    interactionId: ID;
    title?: string;
    description?: string;
    label?: string;
    theme: InteractionTheme;
    actions: InteractionAction[];
};

export type ChatMessageIdAttachment = BaseChatMessage & {
    attachmentId: string;
};

export type SystemChatMessage = BaseChatMessage & {
    user?: ChatMessageUser;
    type: number;
};

export type DateChangeChatMessage = BaseChatMessage & {
    date: Date;
};

export type TextChatMessage = SystemChatMessage & {
    text: string;
    attachments: ChatMessageAttachment[];
};

export type NewMessagesChatMessage = BaseChatMessage & {
    badge: string;
};

export type ChatMessage =
    | TextChatMessage
    | ChatMessageInteraction
    | ChatMessageIdAttachment
    | SystemChatMessage
    | DateChangeChatMessage
    | NewMessagesChatMessage;

export const isChatMessageInteraction = (message: ChatMessage): message is ChatMessageInteraction => {
    return 'actions' in message;
};

export const isChatMessageIdAttachment = (message: ChatMessage): message is ChatMessageIdAttachment => {
    return 'attachmentId' in message;
};

export const isTextChatMessage = (message: ChatMessage): message is TextChatMessage => {
    return 'type' in message && !isSystemMessage(message.type);
};

export const isSystemChatMessage = (message: ChatMessage): message is SystemChatMessage => {
    return 'type' in message && isSystemMessage(message.type);
};

export const isDateChangeChatMessage = (message: ChatMessage): message is DateChangeChatMessage => {
    return 'date' in message;
};

export const isNewMessagesChatMessage = (message: ChatMessage): message is NewMessagesChatMessage => {
    return 'badge' in message;
};

export const isSeenableChatMessage = (message: ChatMessage): boolean => {
    return (
        isTextChatMessage(message) ||
        isSystemChatMessage(message) ||
        isChatMessageInteraction(message) ||
        isChatMessageIdAttachment(message)
    );
};

export type ChatNotification = {
    id: ID;
    message: string;
    actions?: readonly ChatNotificationActionFragment[];
};

const notificationFragmentToChatNotification = (notificationFragment: ChatNotificationFragment): ChatNotification => {
    const { id, contents, actions } = notificationFragment;
    const message = contents ? valueForKey<string>(contents, 'message') : '';
    return {
        id,
        message: message ?? '',
        actions: actions ?? []
    };
};

const fragmentToUser = (chatMessageFragment: ChatMessageFragment): ChatMessageUser | undefined => {
    const { member, user } = chatMessageFragment;
    const messageUser = (user ? user : member)!;
    if (!messageUser) {
        return;
    }
    return {
        id: messageUser.id,
        name: `${messageUser.firstName} ${messageUser.lastName}`,
        title: user?.title ? user.title : '',
        avatarUrl: messageUser.avatarSmallUrl ?? undefined
    };
};

const fragmentToAttachment = (attachmentFragment: ChatMessageFileAttachmentFragment): ChatMessageAttachment => {
    const { id, filename, url } = attachmentFragment;

    return {
        id,
        filename,
        // See src/app/config.ts regarding localhost URLs on Android
        url: __DEV__ && Platform.OS === 'android' ? url!.replace(/localhost:50001/g, config.development.host) : url!
    };
};

const fragmentToIdAttachment = (
    attachmentFragment: ChatMessageIdAttachmentFragment,
    createdAt: Date,
    messageId: ID
): ChatMessageIdAttachment => {
    const { attachmentId } = attachmentFragment;
    return {
        id: messageId,
        createdAt,
        attachmentId
    };
};

const chatMessageInteractionToInteraction = (
    chatMessageInteraction: ChatMessageInteractionFragment,
    createdAt: Date,
    messageId: ID
): ChatMessageInteraction => {
    const {
        uiInteraction: {
            interaction: { id, title, description, label, color, actions }
        }
    } = chatMessageInteraction;
    return {
        id: messageId,
        interactionId: id,
        createdAt,
        title: title ?? undefined,
        description: description ?? undefined,
        label: label ?? undefined,
        theme: color as InteractionTheme,
        actions: actions.map(fragmentToInteractionAction)
    };
};

const fragmentToMessages = (chatMessageFragment: ChatMessageFragment): ChatMessage[] => {
    const { id: messageId, message, type, createdAt, attachmentsV2: attachments } = chatMessageFragment;

    const createdAtDate = new Date(createdAt);
    const fileAttachments = attachments.filter(isFileAttachment).map(fragmentToAttachment);
    const interactions = attachments
        .filter(isInteractionAttachment)
        .map(interactionFragment => chatMessageInteractionToInteraction(interactionFragment, createdAtDate, messageId));
    const idAttachments = attachments
        .filter(isIdAttachment)
        .map(idAttachmentFragment => fragmentToIdAttachment(idAttachmentFragment, createdAtDate, messageId));
    if (isSystemMessage(type) || message || fileAttachments.length > 0) {
        return [
            {
                id: messageId,
                text: message,
                user: fragmentToUser(chatMessageFragment),
                createdAt: createdAtDate,
                type,
                attachments: fileAttachments
            },
            ...interactions,
            ...idAttachments
        ];
    }
    return [...interactions, ...idAttachments];
};

export type SubscriptionData<T> = {
    subscriptionData: { data: T };
};

export const updateQuerySubscription = (
    prev: ChatQuery,
    { subscriptionData }: SubscriptionData<ChatUpdatesSubscription>
) => {
    const { data: updatedData } = subscriptionData;

    if (updatedData?.chat?.chat && updatedData?.chat?.messageAdd) {
        const { chat, messageAdd } = updatedData.chat ?? {};
        return {
            ...prev,
            root: {
                chat: {
                    ...chat,
                    messages: {
                        edges: unionWith(
                            [
                                {
                                    __typename: 'ChatMessageNodeEdge',
                                    node: writableDeepCopy(messageAdd)
                                }
                            ],
                            prev.root?.chat?.messages?.edges ?? [],
                            (a, b) => a?.node?.id === b?.node?.id
                        ),
                        pageInfo: prev.root?.chat?.messages?.pageInfo
                    }
                }
            }
        } as ChatQuery;
    }
    return prev;
};

const chatUserFragmentToLastSeenInfo = (user: ChatUserFragment): ChatUserLastSeenInfo => {
    return {
        userId: user.user.id,
        avatarUrl: user.user.avatarSmallUrl!,
        lastMessageSeenId: user.lastMessageSeen?.id
    };
};

const chatMemberFragmentToLastSeenInfo = (member: ChatMemberFragment): ChatUserLastSeenInfo => {
    return {
        userId: member.member.id,
        avatarUrl: member.member.avatarSmallUrl!,
        lastMessageSeenId: member.lastMessageSeen?.id
    };
};

const hasLastMessageSeenId = (user: ChatUserFragment | ChatMemberFragment): boolean => {
    return user.lastMessageSeen !== undefined;
};

const notLeft = (user: ChatUserFragment | ChatMemberFragment): boolean => {
    return !user.leftAt;
};

const mapMessageInteractions = (
    messageInteractions: readonly ChatMessageInteractionFragment[],
    onAction: (interactionId: ID, action: InteractionAction) => Promise<void>
): ButtonSheetItemProps[] => {
    return messageInteractions.map(messageInteraction => {
        return {
            key: messageInteraction.id,
            left: TickerIcon,
            right: ChevronRightIcon,
            label: messageInteraction.uiInteraction.interaction.title!,
            onPress: () =>
                onAction(
                    messageInteraction.uiInteraction.id,
                    fragmentToInteractionAction(messageInteraction.uiInteraction.interaction.actions[0])
                )
        };
    });
};

export type ChatProps = {
    queryVariables: ChatQueryVariables;
    ensureMessagePresent?: ID;
};

export const useChat = (props: ChatProps) => {
    const {
        queryVariables: { chatId },
        queryVariables,
        ensureMessagePresent
    } = props;

    const subscriptionVariables = useMemo(() => ({ chatId }), [chatId]);

    const { data, loading, loadingInitial, error, fetchMore, refetch } = useLoadingQuery(ChatDocument, {
        variables: queryVariables,
        subscription: ChatUpdatesDocument,
        subscriptionVariables,
        subscriptionUpdateQuery: updateQuerySubscription
    });
    const {
        messages,
        users,
        members,
        notifications,
        unreadMessagesCountV2: unreadMessagesCount,
        title,
        closedAt,
        readonly,
        messageInteractions
    } = data?.root?.chat ?? {};

    const [fetchingMore, setFetchingMore] = useState(false);
    const { id: currentUserId } = useUserProfile()!;
    const { formatDate } = useIntl();
    const { onAction } = useInteractionAction();

    const errorHandler = useErrorHandler();
    const [persistentLastMessageSeen, setPersistentLastMessageSeen] = useState<ID | undefined>(undefined);

    const lastMessageSeenId = useMemo(() => {
        return relayConnectionReduce<ChatMemberFragment>(members)?.find(member => member.member.id === currentUserId)
            ?.lastMessageSeen?.id;
    }, [currentUserId, members]);

    // Ensures either message given for initial scroll to, or last message seen is loaded
    const isReadyForInitialRender = useMemo(() => {
        if (loadingInitial) {
            return false;
        }
        const checkForMessageId = ensureMessagePresent ? ensureMessagePresent : lastMessageSeenId;
        if (checkForMessageId) {
            const convertedMessages = relayConnectionReduce<ChatMessageFragment>(messages);
            if (convertedMessages?.find(message => message.id === checkForMessageId)) {
                return true;
            }
            const latestMessage = convertedMessages?.[0];
            if (latestMessage) {
                const [checkForMessageDbId] = idToGlobalId(checkForMessageId);
                const [latestMessageDbId] = idToGlobalId(latestMessage.id);
                // if last message seen has been updated but message not yet present in messages,
                // no need to load older messages
                return checkForMessageDbId >= latestMessageDbId;
            }
        } else if (messages?.pageInfo && !messages.pageInfo.hasNextPage) {
            return true;
        }
        return false;
    }, [ensureMessagePresent, lastMessageSeenId, loadingInitial, messages]);

    useEffect(() => {
        if (!loading && lastMessageSeenId !== messages?.edges[0]?.node?.id) {
            setPersistentLastMessageSeen(lastMessageSeenId);
        }
        // Should be set only once when all relevant messages are loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReadyForInitialRender]);

    useEffect(() => {
        if (persistentLastMessageSeen && lastMessageSeenId === messages?.edges[0]?.node?.id) {
            setPersistentLastMessageSeen(undefined);
        }
    }, [lastMessageSeenId, messages, persistentLastMessageSeen]);

    const loadMore = useCallback(async () => {
        if (messages?.edges && !loading && !fetchingMore) {
            setFetchingMore(true);
            try {
                await fetchMore({
                    variables: { after: relayCursorForLastEdge(messages.edges) }
                });
            } catch (err) {
                if (err instanceof Error) {
                    errorHandler(
                        new AppError(err, 'error.cannot-load-messages', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    errorHandler(err);
                }
            } finally {
                setFetchingMore(false);
            }
        }
    }, [fetchMore, fetchingMore, loading, messages, errorHandler]);

    useEffect(() => {
        if (!loading && !isReadyForInitialRender) {
            loadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isReadyForInitialRender]);

    const alertNotifications = useMemo(
        () =>
            notifications?.filter(({ context }) => context === 'ALERT').map(notificationFragmentToChatNotification) ??
            [],
        [notifications]
    );
    const inlineNotifications = useMemo(
        () =>
            notifications?.filter(({ context }) => context === 'INLINE').map(notificationFragmentToChatNotification) ??
            [],
        [notifications]
    );

    const notCurrentUser = useCallback(
        (member: ChatMemberFragment): boolean => {
            return member.member.id !== currentUserId;
        },
        [currentUserId]
    );

    const chatUsersAndMembersToLastSeenInfos = useCallback(
        (
            userFragments: ChatUsersFragment | undefined,
            memberFragments: ChatMembersFragment | undefined
        ): Map<string, ChatUserLastSeenInfo[]> => {
            const filteredUsers =
                relayConnectionReduce<ChatUserFragment>(userFragments)
                    ?.filter(notLeft)
                    .filter(hasLastMessageSeenId)
                    .map(chatUserFragmentToLastSeenInfo) ?? [];
            const filteredMembers =
                relayConnectionReduce<ChatMemberFragment>(memberFragments)
                    ?.filter(notLeft)
                    .filter(hasLastMessageSeenId)
                    .filter(notCurrentUser)
                    .map(chatMemberFragmentToLastSeenInfo) ?? [];
            return mapMultipleBy([...filteredUsers, ...filteredMembers], participant => participant.lastMessageSeenId!);
        },
        [notCurrentUser]
    );

    const prepareMessages = useCallback(
        (messageConnection: RelayConnection<ChatMessageFragment> | undefined) => {
            const hasDifferentDate = (a: ChatMessage, b: ChatMessage) =>
                formatDate(a.createdAt) !== formatDate(b.createdAt);

            const mappedMessages =
                relayConnectionReduce<ChatMessageFragment>(messageConnection)?.flatMap(fragmentToMessages) ?? [];

            const messagesWithDateChanges = mappedMessages.reduce<ChatMessage[]>(
                (accumulator, currentMessage, currentIndex, array) => {
                    if (currentIndex > 0) {
                        const nextMessage = array[currentIndex - 1];
                        if (hasDifferentDate(currentMessage, nextMessage)) {
                            accumulator.push({
                                id: formatDate(nextMessage.createdAt),
                                createdAt: nextMessage.createdAt,
                                date: nextMessage.createdAt
                            });
                        }
                        if (currentMessage.id === persistentLastMessageSeen) {
                            accumulator.push({
                                id: 'unreadMessages',
                                createdAt: nextMessage.createdAt,
                                badge: '' + unreadMessagesCount
                            });
                        }
                    }
                    accumulator.push(currentMessage);
                    return accumulator;
                },
                []
            );

            return messagesWithDateChanges;
        },
        [formatDate, persistentLastMessageSeen, unreadMessagesCount]
    );

    return useMemo(() => {
        return {
            loadingInitial: !isReadyForInitialRender,
            error,
            lastMessageSeenInfos: chatUsersAndMembersToLastSeenInfos(users, members),
            messages: prepareMessages(messages),
            users: relayConnectionReduce<ChatUserFragment>(users),
            alertNotifications,
            inlineNotifications,
            unreadMessagesCount,
            messageInteractions: mapMessageInteractions(messageInteractions ?? [], onAction),
            title,
            loadMore,
            loadingMore: fetchingMore,
            lastMessageSeenId,
            hasMoreMessages: messages?.pageInfo.hasNextPage ?? false,
            canSendMessages: !closedAt && !readonly,
            refetch
        };
    }, [
        isReadyForInitialRender,
        error,
        chatUsersAndMembersToLastSeenInfos,
        users,
        members,
        prepareMessages,
        messages,
        alertNotifications,
        inlineNotifications,
        unreadMessagesCount,
        messageInteractions,
        onAction,
        title,
        loadMore,
        fetchingMore,
        lastMessageSeenId,
        closedAt,
        readonly,
        refetch
    ]);
};
