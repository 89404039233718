import { Platform, StyleSheet } from 'react-native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export const styles = StyleSheet.create({
    white: {
        backgroundColor: Color.BACKGROUND_DEFAULT,
        borderBottomWidth: 1,
        borderBottomColor: Color.LINE_DEFAULT,
        shadowColor: Color.ALMOST_BLACK,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2
    },
    headerTitle: {
        ...Text.HEADER_3_STYLES,
        paddingBottom: Platform.OS === 'ios' ? 4 : 0
    },
    headerLeft: {},
    headerRight: {}
});
