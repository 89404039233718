import { useDimensions } from '@react-native-community/hooks';
import { Flex } from 'native-base';
import React, { useState } from 'react';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { OvalButton } from '~/components/button';
import { Color } from '~/components/color';
import { ButtonBackIcon, ShareIcon } from '~/components/icon';
import { Loader } from '~/components/loader';
import { UrlImage } from '~/components/scaled-image/url-image';
import { Text } from '~/components/text';
import { useAuth } from '~/contexts/auth';
import { useDownload } from '~/hooks/download';

import { FilePreviewModalProps } from './file-preview-modal';
import {
    BottomContentContainer,
    ContentContainer,
    HeaderContainer,
    TitleContainer
} from './file-preview-modal-containers';

export const FilePreviewModal: React.FC<FilePreviewModalProps> = props => {
    const { title, description, url, onClose } = props;
    const { bottom, top } = useSafeAreaInsets();
    const { screen } = useDimensions();
    const { download, loading } = useDownload();

    return (
        <Modal
            isVisible
            hasBackdrop
            backdropColor={Color.BLACK}
            backdropOpacity={1}
            animationIn="zoomIn"
            animationOut="zoomOut"
            deviceHeight={screen.height}
            statusBarTranslucent
            onBackButtonPress={onClose}
        >
            <ContentContainer bottomInset={bottom} topInset={top}>
                <HeaderContainer>
                    <OvalButton size="small" icon={ButtonBackIcon} onPress={onClose} />
                    <TitleContainer testID="filePreviewTitle">
                        <Text.HEADER_3 numberOfLines={1} style={{ color: Color.TEXT_TERTIARY }}>
                            {title}
                        </Text.HEADER_3>
                    </TitleContainer>
                    <OvalButton size="small" icon={ShareIcon} onPress={() => download(url, title)} disabled={loading} />
                </HeaderContainer>
                <Flex grow="1" shrink="1" alignItems="center" justifyContent="center">
                    <ImageFilePreview url={url} />
                </Flex>
                {description !== undefined && (
                    <BottomContentContainer>
                        <Text.PARAGRAPH_1 style={{ color: Color.TEXT_TERTIARY }}>{description}</Text.PARAGRAPH_1>
                    </BottomContentContainer>
                )}
            </ContentContainer>
        </Modal>
    );
};

type ImageFilePreviewProps = {
    url: string;
};

const ImageFilePreview: React.FC<ImageFilePreviewProps> = props => {
    const { url } = props;
    const { remote } = useAuth();
    const { window } = useDimensions();

    const [loading, setLoading] = useState<boolean>(true);

    const [aspectRatio, setAspectRatio] = useState<number>(1);

    const handleLoad = (width: number, height: number) => {
        setAspectRatio(width / height);
    };

    const widthByHeight = window.height * aspectRatio;
    const imageWidth = widthByHeight < window.width ? widthByHeight * 0.8 : '100%';

    return (
        <>
            <UrlImage
                headers={{ Authorization: `JWT ${remote.getAuthToken()}` }}
                uri={url}
                aspectRatio={aspectRatio}
                imageWidth={imageWidth}
                additionalStyles={{
                    display: loading ? 'none' : 'flex'
                }}
                onLoadEnd={() => setLoading(false)}
                onLoad={handleLoad}
            />
            {loading && (
                <Loader
                    size="medium"
                    layoutStyle={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }}
                />
            )}
        </>
    );
};
