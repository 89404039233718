import axios from 'axios';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useAuth } from '~/contexts/auth';
import { AppError } from '~/error';
import { isWeb } from '~/utils';

export const useDownload = () => {
    const [bytesDownloaded, setBytesDownloaded] = useState(0);
    const [bytesTotal, setBytesTotal] = useState(-1);
    const [loading, setLoading] = useState(false);
    const errorHandler = useErrorHandler();
    const { remote } = useAuth();

    const progressCallback = (data: FileSystem.DownloadProgressData) => {
        const { totalBytesWritten, totalBytesExpectedToWrite } = data;
        setBytesDownloaded(totalBytesWritten);
        setBytesTotal(totalBytesExpectedToWrite);
    };

    const download = async (url: string, filename: string) => {
        if (isWeb()) {
            setLoading(true);
            axios
                .get(url, {
                    headers: { Authorization: `JWT ${remote.getAuthToken()}` },
                    responseType: 'blob'
                })
                .then(res => {
                    fileDownload(res.data, filename);
                    setLoading(false);
                })
                .then(error => {
                    errorHandler(error);
                    setLoading(false);
                });
        } else {
            setLoading(true);
            try {
                const downloadResumable = FileSystem.createDownloadResumable(
                    url,
                    FileSystem.documentDirectory + encodeURIComponent(filename),
                    { headers: { Authorization: `JWT ${remote.getAuthToken()}` } },
                    progressCallback
                );
                const downloadResult = await downloadResumable.downloadAsync();
                if (downloadResult?.status !== 200) {
                    throw new Error(`Download failed with status ${downloadResult?.status}`);
                }
                setLoading(false);
                await Sharing.shareAsync(downloadResult.uri);
            } catch (err) {
                if (err instanceof Error) {
                    errorHandler(
                        new AppError(err, 'error.cannot-download-attachment', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    errorHandler(err);
                }
            } finally {
                setBytesDownloaded(0);
                setBytesTotal(-1);
                setLoading(false);
            }
        }
    };

    return {
        download,
        bytesDownloaded,
        bytesTotal,
        loading
    };
};
