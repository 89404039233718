import { StatusBar } from 'expo-status-bar';
import { Flex, useBreakpointValue } from 'native-base';
import React, { Children, ReactNode } from 'react';
import { isFragment } from 'react-is';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { ScreenHeaderWithLogo } from '~/components/screen/screen-header-with-logo';
import { Spacing } from '~/components/spacing';

import { ScreenContainer } from './screen-container';

const TopContainer = styled.View`
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_SECONDARY};
`;

const BottomContainer = styled.View`
    justify-content: center;
    align-items: center;
    padding: 0 ${Spacing.MEDIUM}px ${Spacing.LARGE}px;
`;

const ActionsContainer = styled.View<{ bottomInset: number; topBorder?: boolean }>`
    background-color: ${Color.BACKGROUND_DEFAULT};
    width: 100%;
    max-width: 767px;
    flex-direction: row;
    padding: ${Spacing.LARGE}px 0px ${Spacing.LARGE}px;
    align-items: center;
    justify-content: center;
    ${({ topBorder }) => (topBorder ? `border-top-width: 1px; border-top-color: ${Color.LINE_DEFAULT};` : '')}
`;

const ActionContainer = styled.View`
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
`;

type TopGradientScreenActionsProps = {
    actionOrActions?: ReactNode;
    topBorder?: boolean;
};

export const EllipticHeaderScreenActions = (props: TopGradientScreenActionsProps) => {
    const { actionOrActions, topBorder } = props;
    const { bottom } = useSafeAreaInsets();

    return actionOrActions ? (
        <ActionsContainer bottomInset={bottom} topBorder={topBorder}>
            {isFragment(actionOrActions) && Children.toArray(actionOrActions.props.children).length > 1 ? (
                (actionOrActions.props.children ?? []).map((action: ReactNode, index: number) => (
                    <ActionContainer key={index}>{action}</ActionContainer>
                ))
            ) : (
                <ActionContainer>{actionOrActions}</ActionContainer>
            )}
        </ActionsContainer>
    ) : null;
};

export type EllipticHeaderScreenProps = {
    renderEllipticMask?: boolean;
    renderTopContent?: () => ReactNode;
    topContentHeight?: string;
    renderBottomContent: () => ReactNode;
    renderSecondaryBottomContent?: () => ReactNode;
    renderActions?: () => ReactNode;
    devMenuAccess?: boolean;
};

export const EllipticHeaderScreen = (props: EllipticHeaderScreenProps) => {
    const {
        renderEllipticMask,
        renderTopContent,
        topContentHeight,
        renderBottomContent,
        renderSecondaryBottomContent,
        renderActions,
        devMenuAccess
    } = props;
    const { bottom } = useSafeAreaInsets();
    const actionOrActions = renderActions?.();

    const web: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const topContentRenderBasis = web && topContentHeight ? topContentHeight : '320';

    return (
        <>
            <StatusBar style="dark" />

            <ScreenContainer bottomInset={bottom}>
                {web ? (
                    <>
                        <Flex
                            flexGrow={1}
                            alignContent="center"
                            justifyContent="space-between"
                            marginTop="76px"
                            alignItems="stretch"
                            borderTopColor={Color.BORDER_DEFAULT}
                            borderTopWidth={1}
                        >
                            <Flex
                                alignSelf="center"
                                flexGrow={0}
                                width={{ md: '684' }}
                                borderColor={Color.BORDER_DEFAULT}
                                borderWidth={1}
                                borderRadius="16"
                                marginTop="74"
                            >
                                {renderEllipticMask && renderTopContent && (
                                    <Flex flexBasis={topContentRenderBasis}>
                                        <EllipticMaskView ellipseSize="regular" style={{ flex: 1 }}>
                                            <TopContainer>{renderTopContent()}</TopContainer>
                                        </EllipticMaskView>
                                    </Flex>
                                )}
                                <Spacing.Vertical.MEDIUM />
                                <BottomContainer>{renderBottomContent()}</BottomContainer>
                                {renderSecondaryBottomContent && (
                                    <BottomContainer>{renderSecondaryBottomContent()}</BottomContainer>
                                )}
                            </Flex>
                            <Flex
                                flexDir="column"
                                flexGrow={0}
                                flexBasis="96"
                                borderTopColor={Color.BORDER_DEFAULT}
                                borderTopWidth={1}
                                alignSelf="stretch"
                                alignItems="center"
                            >
                                <EllipticHeaderScreenActions actionOrActions={actionOrActions} />
                            </Flex>
                        </Flex>
                    </>
                ) : (
                    <>
                        {devMenuAccess && <ScreenHeaderWithLogo devMenuAccess={devMenuAccess} />}
                        {renderEllipticMask && renderTopContent && (
                            <EllipticMaskView ellipseSize="large" style={{ flex: 1 }}>
                                <TopContainer>{renderTopContent()}</TopContainer>
                            </EllipticMaskView>
                        )}
                        <Spacing.Vertical.MEDIUM />

                        <BottomContainer>{renderBottomContent()}</BottomContainer>

                        <EllipticHeaderScreenActions actionOrActions={actionOrActions} />
                    </>
                )}
            </ScreenContainer>
        </>
    );
};
