import React, { useState } from 'react';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { Markdown } from '~/components/markdown';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { ChatMessageAttachment } from '~/hooks/chat';

import { ChatAttachment } from './chat-attachment';

const TEXT_CUTOFF_LENGTH = 600;

const themes = {
    recipient: {
        backgroundColor: Color.CHAT_RECIPIENT,
        containerColor: Color.BACKGROUND_DEFAULT,
        containerMarginLeft: 34,
        containerMarginRight: 54,
        timestampColor: Color.TEXT_SECONDARY
    },
    sender: {
        backgroundColor: Color.CHAT_SENDER,
        containerColor: Color.BACKGROUND_DEFAULT,
        containerMarginLeft: 54,
        containerMarginRight: 10,
        timestampColor: Color.TEXT_SECONDARY
    },
    sender_wide: {
        backgroundColor: Color.CHAT_SENDER,
        containerColor: Color.BACKGROUND_DEFAULT,
        containerMarginLeft: 10,
        containerMarginRight: 10,
        timestampColor: Color.TEXT_SECONDARY
    }
};

type ChatMessageTheme = typeof themes.recipient;

const ChatMessageContainer = styled.View<ChatMessageTheme>`
    background-color: ${({ containerColor }) => containerColor};
    margin-left: ${({ containerMarginLeft }) => containerMarginLeft}px;
    margin-right: ${({ containerMarginRight }) => containerMarginRight}px;
    flex-direction: row;
    flex-shrink: 1;
`;

const ChatMessageBubble = styled.View<ChatMessageTheme>`
    flex: 0 1 auto;
    flex-direction: column;
    border-radius: 15px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const MessageTextContainer = styled.View`
    padding-vertical: ${Spacing.SMALL}px;
    padding-horizontal: ${Spacing.MEDIUM}px;
`;

const AttachmentContainer = styled.View`
    flex-direction: column;
    flex: 1 1 auto;
    padding: 2px 2px 0 2px;
`;

const BubbleArrowLeft = styled.View<ChatMessageTheme>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: absolute;
    width: 20px;
    height: 25px;
    bottom: 0;
    left: -5px;
`;

const BubbleArrowRight = styled.View<ChatMessageTheme>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: absolute;
    width: 35px;
    height: 25px;
    bottom: 0;
    right: -20px;
`;

const AvatarContainer = styled.View`
    position: absolute;
    bottom: -3px;
    left: -34px;
`;

const BubbleArrowLeftMask = styled.View<ChatMessageTheme>`
    background-color: ${({ containerColor }) => containerColor};
    position: absolute;
    width: 45px;
    height: 40px;
    bottom: -3px;
    left: -45px;
    border-bottom-right-radius: 25px;
`;

const BubbleArrowRightMask = styled.View<ChatMessageTheme>`
    background-color: ${({ containerColor }) => containerColor};
    position: absolute;
    width: 25px;
    height: 40px;
    bottom: -3px;
    right: -25px;
    border-bottom-left-radius: 25px;
`;

const MessageFooterContainer = styled.View`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: -8px;
`;

export type PublicChatMessageProps = {
    message: string;
    attachments?: ChatMessageAttachment[];
    timestamp?: string;
    avatarUri?: string;
};

export type TailPosition = 'left' | 'right';
export type Theme = 'recipient' | 'sender' | 'sender_wide';

export type ChatMessageProps = {
    tailPosition?: TailPosition;
    theme: Theme;
} & PublicChatMessageProps;

export const ChatMessage = (props: ChatMessageProps) => {
    const { avatarUri, theme, message, tailPosition, timestamp, attachments = [] } = props;
    const { formatMessage } = useIntl();
    const [showLongText, setShowLongText] = useState(false);
    const chatMessageTheme = themes[theme];
    const cutoffNeeded = message.length > TEXT_CUTOFF_LENGTH;

    const handlePress = () => {
        setShowLongText(!showLongText);
    };

    return (
        <ChatMessageContainer {...chatMessageTheme}>
            <ChatMessageBubble {...chatMessageTheme}>
                {attachments.length > 0 && (
                    <AttachmentContainer>
                        {attachments.map((attachment, index) => (
                            // See https://shopify.github.io/flash-list/docs/fundamentals/performant-components#remove-key-prop
                            /* eslint-disable-next-line react/no-array-index-key */
                            <ChatAttachment
                                key={index}
                                filename={attachment.filename}
                                description={timestamp}
                                url={attachment.url}
                            />
                        ))}
                    </AttachmentContainer>
                )}
                <MessageTextContainer>
                    <Markdown>
                        {showLongText || !cutoffNeeded ? message : message.slice(0, TEXT_CUTOFF_LENGTH) + '...'}
                    </Markdown>
                    {timestamp || cutoffNeeded ? (
                        <MessageFooterContainer>
                            {timestamp ? (
                                <Text.CAPTION style={{ color: chatMessageTheme.timestampColor }}>
                                    {timestamp}
                                </Text.CAPTION>
                            ) : null}
                            {cutoffNeeded ? (
                                <Button
                                    label={formatMessage(
                                        showLongText ? 'chat-message.show_less' : 'chat-message.show_more'
                                    )}
                                    shape="rounded"
                                    size="tiny"
                                    type="secondary"
                                    onPress={handlePress}
                                />
                            ) : null}
                        </MessageFooterContainer>
                    ) : null}
                </MessageTextContainer>
            </ChatMessageBubble>
            {tailPosition === 'left' && (
                <>
                    <BubbleArrowLeft {...chatMessageTheme} />
                    <BubbleArrowLeftMask {...chatMessageTheme} />
                </>
            )}
            {avatarUri && (
                <AvatarContainer>
                    <Avatar imageUri={avatarUri} size="small" />
                </AvatarContainer>
            )}
            {tailPosition === 'right' && (
                <>
                    <BubbleArrowRight {...chatMessageTheme} />
                    <BubbleArrowRightMask {...chatMessageTheme} />
                </>
            )}
        </ChatMessageContainer>
    );
};
