import { StyleSheet } from 'react-native';

export const ShadowStyles = StyleSheet.create({
    /**
     * @deprecated Use `Shadow.primary` or `Shadow.styles.primary`
     */
    PRIMARY_DROPSHADOW: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.18,
        shadowRadius: 4,
        elevation: 4
    },
    /**
     * @deprecated Use `Shadow.secondary` or `Shadow.styles.secondary`
     */
    SECONDARY_DROPSHADOW: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25,
        shadowRadius: 12,
        elevation: 12
    }
});

export const ShadowProps = {
    primary: { boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.18);', elevation: 4 }
};

export const Shadow = {
    none: `
        elevation: 0;
        shadow-opacity: 0;
    `,
    primary: `
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
        elevation: 4;
    `,
    primaryTopOnly: `
        box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.05);
        elevation: 4;
    `,
    secondary: `
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
        elevation: 12;
    `,
    styles: StyleSheet.create({
        none: {
            elevation: 0,
            shadowOpacity: 0
        },
        primary: {
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.18,
            shadowRadius: 2,
            elevation: 4
        },
        secondary: {
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0.25,
            shadowRadius: 12,
            elevation: 12
        }
    })
};
