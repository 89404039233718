import { Box } from 'native-base';
import React, { PropsWithChildren } from 'react';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type SidebarSectionProps = {
    title?: string;
} & PropsWithChildren;

export const SidebarSection: React.FC<SidebarSectionProps> = ({ title, children }) => {
    return (
        <Box pl="4" py="4" pr="4" borderTopColor={Color.BORDER_DEFAULT} borderTopWidth="1">
            {title && (
                <Box mb="4">
                    <Text.SUBTITLE_2>{title.toUpperCase()}</Text.SUBTITLE_2>
                </Box>
            )}
            {children}
        </Box>
    );
};
