import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components/native';

import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { ArrowForwardOutlineIcon } from '~/components/icon';
import { List, ListItem, ListItemDivider } from '~/components/list';
import { LoadingContent } from '~/components/loader';
import { PreviousScreenHeader } from '~/components/navigation/previous-screen-header';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { SystemMessage } from '~/components/system-message';
import { useIntl } from '~/contexts/intl';
import {
    useCalendarReservationAccessList,
    useGlobalCalendarReservationAccessList
} from '~/hooks/calendar-reservation-access-list/calendar-reservation-access-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { strip } from '~/utils/markdown';

type CalendarReservationAccessesNavigation = StackNavigationProp<
    MainNavigatorParamList,
    'calendar-reservation-accesses'
>;

const NoContentContainer = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export function CalendarReservationAccesses() {
    const { calendarReservationAccesses, loading, error } = useCalendarReservationAccessList();
    const {
        globalCalendarReservationAccesses,
        loading: globalLoading,
        error: globalError
    } = useGlobalCalendarReservationAccessList();
    const { canGoBack, goBack, navigate, setOptions } = useNavigation<CalendarReservationAccessesNavigation>();
    const { formatMessage } = useIntl();
    useErrorHandler(error);
    useErrorHandler(globalError);

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.calendar-reservation-accesses.title') }));

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                <Flex flexDir="column" grow={1} paddingTop={4} paddingLeft={4} paddingRight={4}>
                    {webComponent && (
                        <PreviousScreenHeader goBack={() => (canGoBack() ? goBack() : navigate('profile'))} />
                    )}
                    {loading || globalLoading ? (
                        <LoadingContent />
                    ) : (
                        <List
                            data={[
                                ...(calendarReservationAccesses ?? []),
                                ...(globalCalendarReservationAccesses ?? [])
                            ]}
                            renderItem={({ item }) => (
                                <ListItem
                                    title={formatMessage('profile.calendar-reservation-accesses.item.title')}
                                    accessory={() => <ArrowForwardOutlineIcon />}
                                    description={
                                        item.contextDescription ? strip(item.contextDescription, '\n') : undefined
                                    }
                                    onPress={() =>
                                        navigate('appointments', {
                                            calendarReservationAccessId: item.id
                                        })
                                    }
                                />
                            )}
                            ItemSeparatorComponent={ListItemDivider}
                            ListEmptyComponent={
                                <NoContentContainer>
                                    <Spacing.Vertical.MASSIVE />
                                    <SystemMessage
                                        label={formatMessage('profile.calendar-reservation-accesses.none')}
                                    />
                                </NoContentContainer>
                            }
                        />
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
}
