import React from 'react';
import { View } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export function TabLibraryIcon({ fill, size = 32 }: IconProps) {
    return (
        <View style={{ width: size, height: size }}>
            <Svg width="32" height="32" viewBox="0 0 27 32" fill="none">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.6801 9.19882C23.2657 3.96532 17.3794 0.819193 11.3896 2.41051C5.40193 4.00127 1.89583 9.63846 3.30901 14.8677C4.04815 17.6027 5.98892 19.7532 8.54057 20.9712C9.1828 21.2777 9.73568 21.8263 9.98827 22.5631C10.0775 22.8233 10.3221 22.998 10.5972 22.998H17.0083C17.5128 22.998 17.9616 22.6775 18.1252 22.2003C18.3999 21.3991 18.9733 20.7829 19.6402 20.3906C23.7211 17.9901 25.8339 13.4684 24.6801 9.19882ZM10.8761 0.47756C17.7555 -1.35011 24.8642 2.21403 26.6108 8.67704C28.034 13.9433 25.3918 19.3277 20.6543 22.1144C20.3336 22.303 20.114 22.5662 20.0171 22.8489C19.5763 24.1345 18.3674 24.998 17.0083 24.998H10.5972C9.46757 24.998 8.46275 24.2803 8.09638 23.2117C8.0392 23.045 7.89878 22.881 7.67902 22.7761C4.65739 21.3338 2.28521 18.7454 1.37827 15.3894C-0.369496 8.92214 3.99453 2.30579 10.8761 0.47756Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9963 14.9094C18.9963 16.0642 18.0602 17.0003 16.9054 17.0003H11.9963C9.78717 17.0003 7.9963 15.2094 7.9963 13.0003C7.9963 11.1001 9.32123 9.50943 11.0977 9.10163C11.5055 7.32521 13.0962 6.00028 14.9963 6.00028C17.2054 6.00028 18.9963 7.79114 18.9963 10.0003V14.9094ZM16.9054 15.0003C16.9556 15.0003 16.9963 14.9596 16.9963 14.9094V10.0003C16.9963 8.89571 16.1009 8.00028 14.9963 8.00028C13.8917 8.00028 12.9963 8.89571 12.9963 10.0003L12.9963 11.0003H11.9963C10.8917 11.0003 9.9963 11.8957 9.9963 13.0003C9.9963 14.1049 10.8917 15.0003 11.9963 15.0003H16.9054Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.9963 23.0003H18.9963V26.0003C18.9963 28.2094 17.2054 30.0003 14.9963 30.0003H12.9963C10.7872 30.0003 8.9963 28.2094 8.9963 26.0003V23.0003ZM10.9963 25.0003V26.0003C10.9963 27.1049 11.8917 28.0003 12.9963 28.0003H14.9963C16.1009 28.0003 16.9963 27.1049 16.9963 26.0003V25.0003H10.9963Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9963 28.0003H15.9963V29.0003H16.9963C16.9963 30.6571 15.6532 32.0003 13.9963 32.0003C12.3395 32.0003 10.9963 30.6571 10.9963 29.0003V28.0003Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
            </Svg>
        </View>
    );
}
