import React, { ComponentType } from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';
import { Text } from '~/components/text';

import { Shadow } from '../shadow';

export type TaskColorScheme = 'primary' | 'secondary' | 'negative' | 'active';

export type TaskProps = {
    text: string;
    icon?: ComponentType<IconProps>;
    scheme: TaskColorScheme;
};

const Container = styled.View<{ scheme: TaskColorScheme }>`
    border-radius: 4px;
    padding: 0px 2px;
    min-height: 24px;

    background-color: ${({ scheme }) => BACKGROUND_COLOR[scheme]};

    align-self: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    ${Shadow.primary}
`;

const Label = styled(Text.KICKER)<{ scheme: TaskColorScheme }>`
    text-transform: uppercase;
    color: ${({ scheme }) => FOREGROUND_COLOR[scheme]};
    padding: 4px 4px 2px 2px;
`;

const IconContainer = styled.View`
    margin-left: 0px;
    margin-right: 2px;
`;

const BACKGROUND_COLOR: { [key in TaskColorScheme]: Color } = {
    primary: Color.TASK_DEFAULT,
    secondary: Color.TASK_COMPLETED,
    negative: Color.CHERRY,
    active: Color.TASK_ACTIVE
};

const FOREGROUND_COLOR: { [key in TaskColorScheme]: Color } = {
    primary: Color.BACKGROUND_DEFAULT,
    secondary: Color.BACKGROUND_DEFAULT,
    negative: Color.BACKGROUND_DEFAULT,
    active: Color.TEXT_DEFAULT
};

export function Task(props: TaskProps) {
    const { text, icon: Icon, scheme } = props;

    return (
        <Container scheme={scheme}>
            {Icon ? (
                <IconContainer>
                    <Icon fill={FOREGROUND_COLOR[scheme]} highlight={FOREGROUND_COLOR[scheme]} />
                </IconContainer>
            ) : null}
            <Label numberOfLines={1} scheme={scheme}>
                {text}
            </Label>
        </Container>
    );
}
