import { extendTheme } from 'native-base';

import { Color } from '~/components/color';

// Color palettes can be generated with https://json-color-palette-generator.vercel.app/

// Consts for styles used in different components (e.g Box & Flex with same style)
const contentDividerStyle = {
    height: '1px',
    width: '100%',
    marginRight: '-31px',
    borderBottomWidth: '1px',
    borderBottomColor: Color.LINE_DEFAULT
};

const negativeMarginContainerStyle = {
    marginRight: '-16px'
};

const flexMobileOnlyStyle = {
    display: {
        base: 'flex',
        md: 'none'
    }
};

const flexDesktopOnlyStyle = {
    display: {
        base: 'none',
        md: 'flex'
    }
};

// Variants & Settings that get exported to the Theme

export const nativeBaseTheme = extendTheme({
    components: {
        Box: {
            baseStyle: {
                variant: 'default'
            },
            variants: {
                contentDivider: contentDividerStyle,
                negativeMarginContainer: negativeMarginContainerStyle,
                flexMobileOnly: flexMobileOnlyStyle,
                flexDesktopOnly: flexDesktopOnlyStyle
            }
        },
        Flex: {
            baseStyle: {
                variant: 'default'
            },
            variants: {
                standardFlexResponsive: {
                    flexDirection: {
                        base: 'column',
                        md: 'row'
                    }
                },
                contentDivider: contentDividerStyle,
                negativeMarginContainer: negativeMarginContainerStyle,
                flexMobileOnly: flexMobileOnlyStyle,
                flexDesktopOnly: flexDesktopOnlyStyle
            }
        }
    },
    colors: {
        primaryDefault: {
            50: '#0fd0d7',
            100: '#11bcc2',
            200: '#12a8ad',
            300: '#139599',
            400: '#118488',
            500: '#12787c',
            600: '#126d70',
            700: '#126265',
            800: '#125759',
            900: '#124d4f'
        }
    }
});
