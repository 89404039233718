import { useNavigation } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { Color } from '~/components/color';
import { HeaderProfileEditButton } from '~/components/header';
import { useIntl } from '~/contexts/intl';
import { useDisableSecurityScreen } from '~/hooks/disable-security-screen';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { header, noHeader, transparentHeader } from '~/navigator/screen-options';
import {
    OnboardingBiometricAuthPermissions,
    OnboardingCompleted,
    OnboardingPinSetup,
    OnboardingPushPermissions,
    OnboardingProfileReview,
    OnboardingCommunicationPermissions,
    OnboardingPinSetupIntro
} from '~/screens/onboarding';
import { ProfileEdit, ProfileVerify } from '~/screens/profile';

export type OnboardingNavigatorParamList = {
    'pin-setup-intro': undefined;
    'pin-setup': undefined;
    'biometric-auth': undefined;
    'push-notifications': undefined;
    'communication-permissions': undefined;
    'profile-check': undefined;
    'profile-edit': undefined;
    'profile-verify': undefined;
    completed: undefined;
};

const { Navigator, Screen } = createStackNavigator<OnboardingNavigatorParamList>();

type OnboardingNavigation = StackNavigationProp<OnboardingNavigatorParamList>;

const useInitialRoute = () => {
    const { topics } = useOnboardingTopics();
    return { initialRoute: topics?.[0] };
};

export const OnboardingNavigator = () => {
    const { formatMessage } = useIntl();
    const { initialRoute } = useInitialRoute();
    const { navigate } = useNavigation<OnboardingNavigation>();

    useDisableSecurityScreen();

    return initialRoute ? (
        <Navigator
            initialRouteName={initialRoute}
            screenOptions={{ cardStyle: { backgroundColor: Color.BACKGROUND_DEFAULT } }}
        >
            <Screen name="pin-setup-intro" component={OnboardingPinSetupIntro} options={{ ...noHeader }} />
            <Screen name="pin-setup" component={OnboardingPinSetup} options={{ ...noHeader }} />
            <Screen name="biometric-auth" component={OnboardingBiometricAuthPermissions} options={{ ...noHeader }} />
            <Screen name="push-notifications" component={OnboardingPushPermissions} options={{ ...noHeader }} />
            <Screen
                name="communication-permissions"
                component={OnboardingCommunicationPermissions}
                options={{ ...noHeader, title: formatMessage('onboarding-terms.title') }}
            />
            <Screen
                name="profile-check"
                component={OnboardingProfileReview}
                options={{
                    ...header,
                    title: formatMessage('onboarding-profile.title'),
                    headerRight: () => <HeaderProfileEditButton onPress={() => navigate('profile-edit')} />,
                    headerLeft: () => null
                }}
            />
            <Screen
                name="profile-edit"
                component={ProfileEdit}
                options={{
                    ...transparentHeader,
                    title: formatMessage('profile.title')
                }}
            />
            <Screen
                name="profile-verify"
                component={ProfileVerify}
                options={{
                    ...header,
                    title: formatMessage('profile.verify.authenticate')
                }}
            />
            <Screen name="completed" component={OnboardingCompleted} options={{ ...noHeader }} />
        </Navigator>
    ) : null;
};
