import { useDimensions } from '@react-native-community/hooks';
import { useHeaderHeight } from '@react-navigation/elements';
import { CompositeNavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HeaderBackButton } from '~/components/header';
import { InfoIcon } from '~/components/icon';
import { InputText } from '~/components/input';
import { Markdown } from '~/components/markdown';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import * as ErrorDiagnostics from '~/error';
import { useMemberActionMutation } from '~/hooks/member-actions';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { MemberActionType } from '~/types';

export type ContactReasonNavigation = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'contact-reason'>,
    StackNavigationProp<AppNavigatorParamList>
>;

type ContactReasonRoute = RouteProp<MainNavigatorParamList, 'contact-reason'>;

const contactReasonHeroImage = require('~/assets/images/contact-reason-background.png');

export function ContactReasonScreen() {
    const scrollViewRef = useRef<Animated.ScrollView | null>(null);
    const { setOptions, reset } = useNavigation<ContactReasonNavigation>();
    const {
        params: { title: actionTitle, actionType, description }
    } = useRoute<ContactReasonRoute>();
    const { bottom } = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    const { loading, onAction } = useMemberActionMutation();
    const { window } = useDimensions();
    const { formatMessage } = useIntl();

    const [title, onChangeTitle] = useState('');
    const [contactReason, onChangeReason] = useState('');

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const columnDirection = useBreakpointValue({
        base: 'column',
        md: 'row'
    });

    const IMAGE_HEIGHT = useBreakpointValue({
        base: 460,
        md: 280
    });

    const { onScroll } = useExtendedNavigationHeader({
        offset: 0,
        range: 60,
        title: formatMessage('new-contact.title'),
        headerLeft: <HeaderBackButton />
    });

    useEffect(() => {
        setOptions({
            headerLeft: () => <HeaderBackButton />,
            title: actionTitle,
            headerTransparent: true
        });
    }, [actionTitle, setOptions]);

    const handlePress = useCallback(async () => {
        const linkUrl = await onAction(actionType as MemberActionType, title, contactReason);
        if (linkUrl) {
            // This work, but parsing the URL manually is not perhaps the most solid approach to determine the new
            // navigation state. There should some other way, similar to `useLinkTo` to pass a URL while resetting
            // the navigation state

            const url = new URL(linkUrl);
            const pathComponents = url.pathname.split('/').reverse();
            const [chatId, resource] = pathComponents;

            if (resource === 'chats' && chatId) {
                reset({ index: 0, routes: [{ name: 'tabs' }, { name: 'chat', params: { chatId } }] });
            } else {
                ErrorDiagnostics.error(`Invalid member action URL: ${linkUrl}`);
            }
        }
    }, [actionType, contactReason, onAction, reset, title]);

    // @ts-ignore
    const handleScroll = useCallback(() => scrollViewRef?.current?.scrollToEnd(), []);

    return (
        <ScrollableScreenContainer>
            <ContentScrollView
                // 🤬 Jest fails with `ref={scrollViewRef}`, assign via ref function
                ref={(r: Animated.ScrollView) => (scrollViewRef.current = r)}
                showsVerticalScrollIndicator={false}
                automaticallyAdjustKeyboardInsets
                keyboardDismissMode="on-drag"
                onScroll={onScroll}
                bounces
                scrollEventThrottle={16}
                overScrollMode="never"
                contentContainerStyle={webComponent ? { minHeight: window.height } : {}}
            >
                <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                    <Flex marginTop={{ md: headerHeight * 1.5 }} flexDir={columnDirection} shrink={1}>
                        <Flex>
                            <EllipticMaskView ellipseSize="regular" style={{ height: IMAGE_HEIGHT }}>
                                <Image
                                    resizeMode="cover"
                                    source={contactReasonHeroImage}
                                    style={{ width: '100%', height: IMAGE_HEIGHT }}
                                />
                                <Text.TITLE
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: headerHeight,
                                        padding: Spacing.MEDIUM,
                                        color: Color.TEXT_TERTIARY
                                    }}
                                >
                                    {actionTitle}
                                </Text.TITLE>
                            </EllipticMaskView>
                        </Flex>
                        <Flex
                            paddingTop={{ base: 12, md: 0 }}
                            paddingLeft={{ base: 4, lg: 20 }}
                            paddingRight={{ base: 4, md: 20 }}
                            paddingBottom={Spacing.MEDIUM + bottom}
                            marginTop={{ base: -Spacing.HUGE, md: 0 }}
                            shrink={1}
                        >
                            <InputText
                                placeholder={formatMessage('contact-reason.title_placeholder')}
                                value={title}
                                onChangeText={onChangeTitle}
                                numberOfLines={1}
                            />
                            <Spacing.Vertical.MEDIUM />
                            <InputText
                                stretch
                                numberOfLines={5}
                                placeholder={formatMessage('contact-reason.reason_placeholder')}
                                value={contactReason}
                                onChangeText={onChangeReason}
                                onFocus={handleScroll}
                                style={webComponent ? { flexGrow: 1 } : { height: 80 }}
                            />
                            <DescriptionContainer>
                                <IconContainer>
                                    <InfoIcon />
                                </IconContainer>
                                <TextContainer>
                                    <Markdown>{description}</Markdown>
                                </TextContainer>
                            </DescriptionContainer>
                            <Button
                                type="primary"
                                loading={loading}
                                disabled={loading || !title || !contactReason}
                                label={formatMessage('contact-reason.start_conversation')}
                                onPress={handlePress}
                                fillContainer
                            />
                        </Flex>
                    </Flex>
                </ContentHorizontalMargins>
            </ContentScrollView>
        </ScrollableScreenContainer>
    );
}

export { ContactReasonScreen as ContactReason };

const ScrollableScreenContainer = styled.View`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const DescriptionContainer = styled.View`
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 25px;
`;

const IconContainer = styled.View`
    padding-top: 10px;
    padding-right: 5px;
`;

const TextContainer = styled.View`
    flex: 1 1 auto;
`;
