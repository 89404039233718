import React from 'react';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Card, CardDivider } from '~/components/card';
import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { AppointmentContactType, CalendarEventType } from '~/types';

import { Shadow } from '../shadow';

import { AppointmentHeader } from './appointment-header';

type AppointmentBubbleProps = {
    date: Date;
    contactType: AppointmentContactType | CalendarEventType;
    duration: number;
    staffName: string;
    staffTitle: string;
    staffAvatarUrl: string;
    isDisabled?: boolean;
};

const AppointmentBubbleContainer = styled.View`
    flex: 1 1 auto;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const AppointmentReceiverSection = styled.View`
    flex-direction: row;
    padding: 16px;
    align-items: center;
    align-self: flex-start;
`;

const ReceiverContainer = styled.View`
    padding-left: 16px;
`;

export const AppointmentBubble = (props: AppointmentBubbleProps) => {
    const { date, duration, contactType, staffName, staffTitle, staffAvatarUrl, isDisabled = false } = props;

    return (
        <AppointmentBubbleContainer>
            <Card style={Shadow.styles.primary} isDisabled={isDisabled}>
                <AppointmentHeader
                    headerType="condensed"
                    task="view-appointment"
                    date={date}
                    type={contactType}
                    duration={duration}
                />
                <CardDivider />
                <AppointmentReceiverSection>
                    <Avatar imageUri={staffAvatarUrl} size="small" />
                    <ReceiverContainer>
                        <Text.PARAGRAPH_3>{staffName}</Text.PARAGRAPH_3>
                        <Text.PARAGRAPH_1>{staffTitle}</Text.PARAGRAPH_1>
                    </ReceiverContainer>
                </AppointmentReceiverSection>
            </Card>
        </AppointmentBubbleContainer>
    );
};
