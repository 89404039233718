import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Color } from '~/components/color';

type TriangleIconProps = {
    color: Color;
};

export const TriangleIcon: React.FC<TriangleIconProps> = props => {
    return (
        <Svg width="25" height="24" viewBox="0 0 25 24">
            <Path
                d="M11.6318 7.53499C12.0157 6.86318 12.9844 6.86318 13.3682 7.53499L17.9219 15.5039C18.3028 16.1705 17.8215 17 17.0536 17H7.94636C7.17854 17 6.69717 16.1705 7.07812 15.5039L11.6318 7.53499Z"
                stroke={props.color}
                strokeWidth="2"
                fillOpacity={0}
            />
        </Svg>
    );
};
