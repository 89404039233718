import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ArticleTitle, ArticleHighlight, ArticleMarkdown, ArticleChildCategory } from '~/components/article';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HeaderBackButton } from '~/components/header';
import { Image } from '~/components/image';
import { Loader } from '~/components/loader';
import { PreviousScreenHeader } from '~/components/navigation/previous-screen-header';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { useAnalyticsEventEffect } from '~/contexts/analytics';
import { useIntl } from '~/contexts/intl';
import { REACHABILITY_THRESHOLD } from '~/contexts/network-service-state';
import { AppError } from '~/error';
import { useArticle } from '~/hooks/article';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

export type ArticleNavigationProp = StackNavigationProp<MainNavigatorParamList, 'article'>;
export type ArticleRouteProp = RouteProp<MainNavigatorParamList, 'article'>;

export function Article() {
    const { canGoBack, goBack, navigate } = useNavigation<ArticleNavigationProp>();
    const { params } = useRoute<ArticleRouteProp>();
    const { bottom } = useSafeAreaInsets();
    const { formatMessage } = useIntl();
    const handleError = useErrorHandler();
    const headerHeight = useHeaderHeight();

    const { article, loadingInitial, error } = useArticle(params);
    const { title, highlight, content, coverPhotoUrl, childCategories = [] } = article ?? {};

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const IMAGE_HEIGHT = useBreakpointValue({
        base: 220,
        md: 150
    });

    const { onScroll } = useExtendedNavigationHeader({
        offset: IMAGE_HEIGHT - headerHeight,
        range: 50,
        title: title ?? '',
        headerLeft: <HeaderBackButton />
    });

    useAnalyticsEventEffect(
        title !== undefined ? { type: 'article', action: 'open', detail1: `title=${title}` } : undefined,
        [title]
    );

    if (error) {
        handleError(
            new AppError(error, 'services.error.article-not-available', {
                name: 'error-overlay.go_back',
                onClose: () => (canGoBack() ? goBack() : navigate('home'))
            })
        );
    }

    if (loadingInitial) {
        return (
            <Flex alignItems="center" justifyContent="center" backgroundColor={Color.BACKGROUND_DEFAULT}>
                <Loader
                    delay={REACHABILITY_THRESHOLD}
                    size="medium"
                    caption={formatMessage('services.article.loading')}
                />
            </Flex>
        );
    }

    return article ? (
        <ContentScrollView
            contentContainerStyle={{ paddingBottom: bottom }}
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            overScrollMode="never"
        >
            <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                <Flex marginTop={{ md: headerHeight * 1.5 }} variant="standardFlexResponsive" width="100%">
                    <Flex grow={0}>
                        <EllipticMaskView ellipseSize="large">
                            {coverPhotoUrl ? (
                                <Image
                                    source={{ uri: coverPhotoUrl }}
                                    resizeMode="cover"
                                    style={{ width: '100%', height: IMAGE_HEIGHT }}
                                />
                            ) : (
                                <Flex width="100%" height={IMAGE_HEIGHT} backgroundColor={Color.BACKGROUND_SECONDARY} />
                            )}
                        </EllipticMaskView>
                    </Flex>
                    <Flex grow={1} shrink={1} paddingLeft={{ base: '4', lg: '20' }}>
                        {/* Breadcrumbs for Web, temporarily 'go back' */}
                        {webComponent && (
                            <PreviousScreenHeader goBack={() => (canGoBack() ? goBack() : navigate('home'))} />
                        )}
                        <Flex paddingRight={{ base: '4', lg: '0' }}>
                            {title ? (
                                <ArticleTitle before={8} after={20}>
                                    {title}
                                </ArticleTitle>
                            ) : null}
                            {highlight ? (
                                <View style={{ marginBottom: 20 }}>
                                    <ArticleHighlight>{highlight}</ArticleHighlight>
                                </View>
                            ) : null}
                            {content ? <ArticleMarkdown>{content}</ArticleMarkdown> : null}
                        </Flex>
                        {childCategories ? (
                            <Flex>
                                <Spacing.Vertical.HUGE />
                                {childCategories.map(category => (
                                    <ArticleChildCategory
                                        key={category.id}
                                        title={category.title}
                                        articles={category.articles}
                                    />
                                ))}
                            </Flex>
                        ) : null}
                    </Flex>
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    ) : null;
}
