import buffer from '@turf/buffer';
import centerOfMass from '@turf/center-of-mass';
import { feature, Units } from '@turf/helpers';
import { Point, Polygon, Position } from 'geojson';

import { LatLng, Region as MapRegion } from '~/components/map';

export function findCentroid(polygon: Polygon): Point {
    return centerOfMass(polygon).geometry;
}

export function geoJsonPolygonToMapRegion(polygon: Polygon): MapRegion {
    const [tl, br] = polygon.coordinates.flat().reduce<[Position | undefined, Position | undefined]>(
        (acc, curr) => {
            const [longitude, latitude] = curr;
            const [min, max] = acc;

            const ntl: Position = !min ? curr : [Math.min(min[0], longitude), Math.min(min[1], latitude)];
            const nbr: Position = !max ? curr : [Math.max(max[0], longitude), Math.max(max[1], latitude)];

            return [ntl, nbr];
        },
        [undefined, undefined]
    );
    return {
        longitude: (tl![0] + br![0]) / 2,
        latitude: (tl![1] + br![1]) / 2,
        longitudeDelta: br![0] - tl![0],
        latitudeDelta: br![1] - tl![1]
    };
}

export function geoJsonPointToMapRegion(point: Point, distance: number, units?: Units): MapRegion {
    return geoJsonPolygonToMapRegion(buffer(feature(point), distance, { units: units ?? 'meters' }).geometry);
}

export function geoJsonPointToLatLng(point: Point): LatLng {
    return { longitude: point.coordinates[0], latitude: point.coordinates[1] };
}

export function latLngToGeoJsonPoint(point: LatLng): Point {
    return { type: 'Point', coordinates: [point.longitude, point.latitude] };
}
