import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { ChatMessagesSeenMutationDocument } from '~/types';
import { idToGlobalId } from '~/utils';

export const useMarkAsSeen = (chatId: ID, lastMessageSeenId: ID | undefined) => {
    const [markAsSeen] = useMutation(ChatMessagesSeenMutationDocument);

    const [lastMessageSeenDbId] = useMemo(
        () => (lastMessageSeenId ? idToGlobalId(lastMessageSeenId) : [-1]),
        [lastMessageSeenId]
    );

    const markMessagesAsSeen = useCallback(
        async (messageIds: ID[]) => {
            const unseenMessageIds = messageIds.filter(messageId => {
                const [dbId] = idToGlobalId(messageId);
                return dbId > lastMessageSeenDbId;
            });
            if (unseenMessageIds.length === 0) {
                return;
            }
            try {
                ErrorDiagnostics.log('Setting messages as seen, ids ' + unseenMessageIds);
                await markAsSeen({ variables: { input: { chatId, messageIds: unseenMessageIds } } });
            } catch (err) {
                ErrorDiagnostics.error(err);
            }
        },
        [chatId, lastMessageSeenDbId, markAsSeen]
    );
    return markMessagesAsSeen;
};
