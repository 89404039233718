import React from 'react';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Color } from '~/components/color';
import { LogoProvider } from '~/components/logo';
import { Text } from '~/components/text';
import { Time } from '~/components/time';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { Appointment } from '~/types';

type AppointmentListRowProps = {
    appointment: Appointment;
    onPress: (appointment: Appointment) => void;
};

const Container = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    flex: 0 0 auto;
    height: auto;
    margin-left: 16px;
    margin-right: 4px;
    padding: 16px 16px 16px 0px;
`;

const Info = styled.View`
    flex-direction: column;
    flex: 1 0 auto;
    margin-right: 8px;
`;

const Location = styled.View`
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
`;

export function AppointmentListRow(props: AppointmentListRowProps) {
    const { appointment, onPress } = props;
    const { user, start, duration, location, contactTypes } = appointment;
    const { formatMessage } = useIntl();

    return (
        <Container accessibilityRole="button" onPress={() => onPress(appointment)}>
            <Info>
                <Text.PARAGRAPH_3 after={4} style={{ color: Color.SMOOTHIE }}>
                    <Time value={start} /> • <FormattedMessage id="time.duration" values={{ duration }} />
                </Text.PARAGRAPH_3>
                <Text.PARAGRAPH_2 after={2} numberOfLines={1}>
                    {user.firstName} {user.lastName}
                </Text.PARAGRAPH_2>
                <Text.PARAGRAPH_1 after={2} numberOfLines={1}>
                    {user.title}
                </Text.PARAGRAPH_1>
                <Text.CAPTION after={2} numberOfLines={1}>
                    {contactTypes.map(type => formatMessage('appointment.type', { type })).join(' • ')}
                </Text.CAPTION>
                {location ? (
                    <Location>
                        <LogoProvider provider={location.organizationName} />
                        <Text.SMALL_BUTTON_LABEL style={{ marginLeft: 6 }} numberOfLines={1}>
                            {location.name}
                        </Text.SMALL_BUTTON_LABEL>
                    </Location>
                ) : null}
            </Info>
            <Avatar size="large" imageUri={user.avatarSmallUrl} />
        </Container>
    );
}
