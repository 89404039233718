import { useDimensions, useKeyboard } from '@react-native-community/hooks';
import { Flex } from 'native-base';
import React, { useMemo, useState } from 'react';
import { Pressable, ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { OvalButton } from '~/components/button';
import { Color } from '~/components/color';
import { RoundedDivider } from '~/components/divider';
import {
    CameraIcon,
    DocumentAttachmentIcon,
    ImageAttachmentIcon,
    FullScreenIcon,
    MinimizeIcon,
    SendIcon
} from '~/components/icon';
import { IconButton } from '~/components/icon-button';
import { Input } from '~/components/input';
import { Loader } from '~/components/loader';
import { Spacing } from '~/components/spacing';
import { useIntl } from '~/contexts/intl';
import { NewChatMessage, useAttachment } from '~/hooks/chat';
import { useChatMessageDraft } from '~/hooks/chat-message-draft/chat-message-draft';
import { useChatMessageDraftMutation } from '~/hooks/chat-message-draft/chat-message-draft-mutation';

import { ChatInputAttachment } from './chat-input-attachment';
import { isWeb } from '~/utils';

const ChatInputSectionContainer = styled.View`
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    border-top-color: ${Color.LINE_DEFAULT};
    border-top-width: 1px;
    margin-top: 5px;
`;

const ChatInputSectionInnerContainer = styled.View`
    align-self: stretch;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
`;

const UpperContainer = styled.View`
    flex-direction: column;
    padding-vertical: ${Spacing.MEDIUM}px;
    padding-right: ${Spacing.MEDIUM}px;
    margin-left: ${Spacing.MEDIUM}px;
    flex: 1 0 auto;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const LowerContainer = styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    padding-top: ${Spacing.SMALL}px;
    margin-left: ${Spacing.MEDIUM}px;
    padding-right: ${Spacing.MEDIUM}px;
    padding-bottom: ${Spacing.SMALL}px;
    justify-content: space-between;
    align-items: center;
`;

const IconContainer = styled.View`
    flex: 0 0 35%;
    flex-direction: row;
    gap: ${Spacing.LARGE}px;
`;

const SendContainer = styled.View`
    flex: 1 0 auto;
    align-items: flex-end;
    height: 35px;
    justify-content: center;
`;

export type ChatInputSectionProps = {
    onSendMessage: (message: NewChatMessage) => Promise<void>;
    canSendMessages: boolean;
    chatId: ID;
};

export type ChatInputSectionWithDraftMessageProps = ChatInputSectionProps & {
    draftMessage: string;
};

export const ChatInputSection = (props: ChatInputSectionProps) => {
    const { draftMessage } = useChatMessageDraft(props.chatId);
    return <ChatInputSectionWithDraftMessage {...props} draftMessage={draftMessage} />;
};

const ChatInputSectionWithDraftMessage = (props: ChatInputSectionWithDraftMessageProps) => {
    const { chatId, onSendMessage, canSendMessages, draftMessage } = props;
    const { attachments, removeAttachment, clearAttachments, onCamera, onImage, onDocument } = useAttachment();
    const { formatMessage } = useIntl();
    const [isFullscreenInput, setIsFullscreenInput] = useState(false);
    const [text, setText] = useState(draftMessage);
    const [loading, setLoading] = useState(false);
    const { screen } = useDimensions();
    const { keyboardHeight, keyboardShown } = useKeyboard();
    const { updateDraftMessage } = useChatMessageDraftMutation(chatId);

    const onSend = async () => {
        setLoading(true);
        try {
            await onSendMessage({ text, attachments });
            clearAttachments();
            updateDraftMessage('');
            setText('');
            setIsFullscreenInput(false);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeText = (newMessage: string) => {
        updateDraftMessage(newMessage);
        setText(newMessage);
    };

    const fullScreenInputHeight = useMemo(() => {
        const fullHeight = screen.height * 0.7;
        if (keyboardShown) {
            return fullHeight - keyboardHeight;
        } else {
            return fullHeight * 0.7;
        }
    }, [keyboardShown, keyboardHeight, screen]);

    const onFullScreenPress = () => {
        setIsFullscreenInput(!isFullscreenInput);
    };

    const fullScreenStyles = { height: fullScreenInputHeight };
    const nonFullscreenStyles = { maxHeight: 100 };

    if (!canSendMessages && text === '') {
        return null;
    }

    return (
        <ChatInputSectionContainer>
            <ChatInputSectionInnerContainer>
                <UpperContainer>
                    <Input
                        placeholder={formatMessage('chat-input.placeholder')}
                        placeholderTextColor={Color.TEXT_SECONDARY}
                        multiline
                        backgroundColor={Color.BACKGROUND_DEFAULT}
                        value={text}
                        flat
                        testID="chat-input"
                        onChangeText={handleChangeText}
                        style={isFullscreenInput ? fullScreenStyles : nonFullscreenStyles}
                        accessoryRight={() => (
                            <Pressable
                                accessibilityRole="button"
                                testID={isFullscreenInput ? 'minimize-button' : 'fullscreen-button'}
                                onPress={onFullScreenPress}
                                style={{ alignSelf: 'flex-start', paddingBottom: 1 }}
                            >
                                {isFullscreenInput ? (
                                    <MinimizeIcon fill={Color.ICON_SECONDARY} />
                                ) : (
                                    <FullScreenIcon fill={Color.ICON_SECONDARY} />
                                )}
                            </Pressable>
                        )}
                    />
                </UpperContainer>

                {attachments.length > 0 && (
                    <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces style={{ padding: 10 }}>
                        {attachments.map(attachment => {
                            const { uri, name } = attachment;
                            return (
                                <Flex key={uri} maxWidth={screen.width * 0.8} h="50px" mr="10px" mb="5px">
                                    <ChatInputAttachment
                                        url={uri}
                                        filename={name}
                                        onDelete={() => {
                                            removeAttachment(attachment);
                                        }}
                                    />
                                </Flex>
                            );
                        })}
                    </ScrollView>
                )}
                <RoundedDivider />
                <LowerContainer>
                    <IconContainer>
                        {!isWeb() && <IconButton onPress={onCamera} icon={CameraIcon} />}
                        {!isWeb() && <IconButton onPress={onImage} icon={ImageAttachmentIcon} />}
                        <IconButton onPress={onDocument} icon={DocumentAttachmentIcon} />
                    </IconContainer>
                    <SendContainer>
                        {loading ? (
                            <Loader type="oval" size="small" />
                        ) : (
                            <OvalButton
                                icon={SendIcon}
                                onPress={onSend}
                                size="small"
                                fill={Color.SEND_MESSAGE}
                                disabled={!canSendMessages || (!text && attachments.length === 0)}
                            />
                        )}
                    </SendContainer>
                </LowerContainer>
            </ChatInputSectionInnerContainer>
        </ChatInputSectionContainer>
    );
};
