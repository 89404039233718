import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { AppointmentList } from '~/components/appointment-list';
import { Button, TextButton } from '~/components/button';
import { Color } from '~/components/color';
import { ChevronLeftIcon, ChevronRightIcon, ReloadIcon } from '~/components/icon';
import { Loader } from '~/components/loader';
import { NotificationSystemMessage } from '~/components/notifications';
import { SCREEN_HEIGHT_RATIO } from '~/components/screen';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useAppointments } from '~/hooks/appointments';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { Appointment } from '~/types';
import { isNative } from '~/utils';
import { fromISODateString, toISODateString } from '~/utils/date';

import { AppointmentContentProps } from './appointments-content-header';

export type AppointmentsNavigationProp = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'appointments'>,
    StackNavigationProp<AppNavigatorParamList>
>;

const Footer = styled.View<{ bottomInset: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 16px;
    padding-horizontal: 12px;
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
    border-top-width: 1px;
    border-top-color: ${Color.LINE_DEFAULT};
`;

const AppointmentListNoContentContainer = styled.View`
    min-height: ${120 * SCREEN_HEIGHT_RATIO}px;
    align-items: center;
    justify-content: center;
    padding: 16px;
`;

export const AppointmentsContentList: React.FC<AppointmentContentProps> = props => {
    const { selection, loading: appointmentDaysLoading, appointmentDays } = props;
    const { calendarReservationAccessId, calendarEventId, region, location, date, setDate, timeOfDay, filters } =
        selection;
    const { bottom } = useSafeAreaInsets();

    const {
        appointments,
        loading: appointmentsLoading,
        fetchAppointmentDay,
        error
    } = useAppointments({
        calendarReservationAccessId,
        date: fromISODateString(date),
        regionId: region?.id,
        locationId: location?.id,
        timeOfDay,
        filters
    });

    const { navigate } = useNavigation<AppointmentsNavigationProp>();
    const { formatMessage } = useIntl();

    const [dayIndex, setDayIndex] = useState<number>(0);

    const handleOpenAppointment = useCallback(
        (appointment: Appointment) => {
            navigate('appointment-preview', { appointment: JSON.stringify(appointment), calendarEventId });
        },
        [calendarEventId, navigate]
    );

    const handleReload = useCallback(async () => {
        if (date) {
            await fetchAppointmentDay(fromISODateString(date));
        }
    }, [date, fetchAppointmentDay]);

    useEffect(() => {
        const newIndex =
            date && appointmentDays ? appointmentDays.findIndex(day => toISODateString(day.date) === date) : 0;
        setDayIndex(newIndex);
    }, [appointmentDays, date]);

    if (appointmentDaysLoading || appointmentsLoading) {
        return (
            <AppointmentListNoContentContainer
                style={{
                    // Set container top margin to prevent loader from "jumping" when upper content size changes
                    marginTop: !appointmentsLoading ? 56 /* = combined appointment selector height + margin */ : 0
                }}
            >
                <Loader delay={800 /* TODO: adjust dynamically based on net info connection speed */} size="medium" />
            </AppointmentListNoContentContainer>
        );
    }

    if (
        appointmentDays === undefined ||
        appointmentDays.length === 0 ||
        appointments === undefined ||
        appointments.length === 0
    ) {
        return (
            <AppointmentListNoContentContainer>
                <NotificationSystemMessage message={formatMessage('appointments.no-appointments-available')} />
            </AppointmentListNoContentContainer>
        );
    }

    if (error) {
        return (
            <AppointmentListNoContentContainer
                style={{
                    backgroundColor: Color.BACKGROUND_PRIMARY,
                    borderBottomWidth: 1,
                    borderBottomColor: Color.LINE_DEFAULT
                }}
            >
                <Text.CAPTION style={{ color: Color.WARNING_DEFAULT }} after={16}>
                    <FormattedMessage id="appointments.error.cannot-load-appointments" />
                </Text.CAPTION>
                <Button
                    size="small"
                    shape="rounded"
                    leftIcon={ReloadIcon}
                    label={formatMessage('appointments.error.try-again')}
                    onPress={handleReload}
                />
            </AppointmentListNoContentContainer>
        );
    }

    return (
        <Flex flexGrow={1}>
            <AppointmentList appointments={appointments} onOpenAppointment={handleOpenAppointment} />
            {isNative() && (
                <Footer bottomInset={bottom}>
                    <TextButton
                        label={formatMessage('appointments.day.previous')}
                        onPress={() => setDate(toISODateString(appointmentDays[dayIndex - 1].date))}
                        leftIcon={ChevronLeftIcon}
                        disabled={dayIndex === 0}
                    />
                    <TextButton
                        label={formatMessage('appointments.day.next')}
                        onPress={() => setDate(toISODateString(appointmentDays[dayIndex + 1].date))}
                        rightIcon={ChevronRightIcon}
                        disabled={dayIndex === appointmentDays.length - 1}
                    />
                </Footer>
            )}
        </Flex>
    );
};
