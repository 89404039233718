import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { Animated } from 'react-native';

import { Button } from '~/components/button';
import { HelperButton } from '~/components/button/helper-button';
import { Color } from '~/components/color';
import { HeaderProfileEditButton } from '~/components/header';
import { ProfileCard } from '~/components/profile-card';
import { ProfileImage, ProfileImageSize } from '~/components/profile-image';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';

import { AnimatedLogoHeader } from '../home';

type OnboardingProfileReviewNavigation = CompositeNavigationProp<
    StackNavigationProp<OnboardingNavigatorParamList, 'profile-check'>,
    StackNavigationProp<AppNavigatorParamList>
>;

export const OnboardingProfileReview = () => {
    const { navigate, setOptions, reset } = useNavigation<OnboardingProfileReviewNavigation>();
    const { nextTopic } = useOnboardingTopics();
    const { formatMessage } = useIntl();
    const { userProfileReviewed, reviewUserProfile, ...rest } = useUserProfile();

    const showMobileHeader = useBreakpointValue({
        base: true,
        md: false
    });

    const offset = useRef(new Animated.Value(0)).current;

    const profileImageSize = showMobileHeader ? ProfileImageSize.REGULAR : ProfileImageSize.SMALL;

    useLayoutEffect(() => {
        if (!showMobileHeader) {
            setOptions({
                header: (props: StackHeaderProps) => <></>
            });
        }
    }, [setOptions, showMobileHeader]);

    const next = useCallback(async () => {
        await reviewUserProfile();
        reset({
            index: 0,
            routes: [{ name: nextTopic('profile-check') ?? 'root' }]
        });
    }, [nextTopic, reset, reviewUserProfile]);

    return (
        <>
            {!showMobileHeader && <AnimatedLogoHeader offset={offset} />}
            <EllipticHeaderScreen
                renderEllipticMask
                topContentHeight="260"
                renderTopContent={() => (
                    <>
                        {!showMobileHeader && (
                            <>
                                <Flex
                                    width="100%"
                                    maxHeight="45"
                                    grow="1"
                                    flexDir="row"
                                    justifyContent="space-between"
                                    paddingLeft="4"
                                    paddingTop="2"
                                >
                                    <Flex>
                                        <Text.H2 style={{ color: Color.HEADER }}>
                                            {formatMessage('onboarding-profile.title')}
                                        </Text.H2>
                                    </Flex>
                                    <Flex>
                                        <HeaderProfileEditButton onPress={() => navigate('profile-edit')} />
                                    </Flex>
                                </Flex>
                                <Flex
                                    variant="contentDivider"
                                    height="1px"
                                    width="100%"
                                    borderBottomWidth="1px"
                                    borderBottomColor={Color.LINE_DEFAULT}
                                />
                                <Spacing.Vertical.SMALL />
                            </>
                        )}
                        <Flex paddingBottom="2" marginTop={{ base: 35, md: 0 }}>
                            <HelperButton title={formatMessage('onboarding-profile.description')} />
                        </Flex>
                        <ProfileImage
                            imageUrl={rest.avatarBigUrl ? rest.avatarBigUrl : undefined}
                            size={profileImageSize}
                        />
                    </>
                )}
                renderBottomContent={() => <ProfileCard {...rest} isOnboarding />}
                renderActions={() => (
                    <Flex flexBasis="256">
                        <Button fillContainer type="primary" onPress={next} label={formatMessage('next')} />
                    </Flex>
                )}
            />
        </>
    );
};
