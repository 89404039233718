import { useLoadingQuery } from '~/hooks/loading-query';
import { GetArticleDocument, fragmentToArticle } from '~/types';

export type ArticleHookParams = {
    id?: ID;
    tag?: string;
};

export function useArticle({ id, tag }: ArticleHookParams) {
    if (!id && !tag) {
        throw new Error('Either id or tag needs to specified');
    }

    const { data, error, loading, loadingInitial, refetch } = useLoadingQuery(GetArticleDocument, {
        variables: { id, tag },
        notifyOnNetworkStatusChange: false
    });

    const articleFragment = data?.root?.article ?? undefined;
    const article = articleFragment ? fragmentToArticle(articleFragment) : undefined;

    return { loading, loadingInitial, error, article, refetch };
}
