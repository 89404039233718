import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { fragmentToRegion, RegionsDocument } from '~/types';

export type RegionsHookResponse = ReturnType<typeof useRegions>;

export function useRegions() {
    const { data, loading, error } = useQuery(RegionsDocument);

    const regions = useMemo(() => data?.root?.regions?.map(fragmentToRegion), [data]);

    return { loading, error, regions };
}
