import { Flex, Spacer, useBreakpointValue } from 'native-base';
import React, { useReducer, useRef, useState } from 'react';
import { Animated, Linking } from 'react-native';

import { Button } from '~/components/button';
import { CheckButton } from '~/components/check/check-button';
import { InputText } from '~/components/input';
import { Loader } from '~/components/loader';
import { NumberRangeInput } from '~/components/number-range-input/number-range-input';
import { ScreenContainer } from '~/components/screen';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Text } from '~/components/text';
import { AppMessageId, FormattedMessage, useIntl } from '~/contexts/intl';
import { useSendNps } from '~/hooks/nps/nps-send';
import { singleAsyncActionStateReducer } from '~/reducers/single-action-reducer';
import { AnimatedLogoHeader } from '~/screens/home';
import { isWeb } from '~/utils';

export type QuestionDetail = {
    titleKey: AppMessageId;
    minDescriptionKey: AppMessageId;
    maxDescriptionKey: AppMessageId;
};

export type NpsProps = {
    npsUUID: string;
    initialScoreDetail1?: number;
    detailQuestion1: QuestionDetail;
    detailQuestion2?: QuestionDetail;
    npsQuestionTitleKey: AppMessageId;
};

export const Nps: React.FC<NpsProps> = props => {
    const { npsUUID, initialScoreDetail1, detailQuestion1, detailQuestion2, npsQuestionTitleKey } = props;
    const [{ state }, dispatch] = useReducer(singleAsyncActionStateReducer, { state: 'init' });

    const sendNps = useSendNps(npsUUID);

    const offset = useRef(new Animated.Value(0)).current;

    const [scoreDetail1, setScoreDetail1] = useState(initialScoreDetail1);
    const [scoreDetail2, setScoreDetail2] = useState<number | undefined>(undefined);
    const [scoreNps, setScoreNps] = useState<number | undefined>(undefined);
    const [comment, setComment] = useState('');
    const [contactPermission, setContactPermission] = useState(false);

    const { formatMessage } = useIntl();

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const handleChange = (value: number, setter: (value: number) => void) => {
        setter(value);
    };

    const handleSend = async () => {
        if (scoreNps === undefined) {
            return;
        }

        dispatch('request');
        await sendNps({
            scoreNps,
            scoreDetail1,
            scoreDetail2,
            comment,
            contactPermission
        });
        dispatch('success');
    };

    const renderDetailQuestion = (
        questionDetails: QuestionDetail,
        value: number | undefined,
        valueUpdaterFunction: (value: number) => void
    ) => {
        const { titleKey, minDescriptionKey, maxDescriptionKey } = questionDetails;

        return (
            <NumberRangeInput
                title={formatMessage(titleKey)}
                min={1}
                max={5}
                minLabel={formatMessage(minDescriptionKey)}
                maxLabel={formatMessage(maxDescriptionKey)}
                value={value}
                onChange={newValue => handleChange(newValue, valueUpdaterFunction)}
            />
        );
    };

    return (
        <ScreenContainer>
            <ContentScrollView>
                <Flex alignItems="center" px={4} shrink={1}>
                    <AnimatedLogoHeader offset={offset} />
                    <Spacer h="24" />
                    {state === 'init' && (
                        <>
                            <Text.H1>
                                <FormattedMessage id="nps.title" />
                            </Text.H1>

                            <Spacer h="12" />

                            {renderDetailQuestion(detailQuestion1, scoreDetail1, setScoreDetail1)}

                            <Spacer h="12" />

                            {detailQuestion2 && (
                                <>
                                    {renderDetailQuestion(detailQuestion2, scoreDetail2, setScoreDetail2)}
                                    <Spacer h="12" />
                                </>
                            )}

                            <NumberRangeInput
                                title={formatMessage(npsQuestionTitleKey)}
                                min={0}
                                max={10}
                                minLabel={formatMessage('nps.nps-question.min-description')}
                                maxLabel={formatMessage('nps.nps-question.max-description')}
                                value={scoreNps}
                                onChange={value => handleChange(value, setScoreNps)}
                            />

                            <Spacer h="12" />

                            <Flex>
                                <Text.HEADER_3>
                                    <FormattedMessage id="nps.comment.title" />
                                </Text.HEADER_3>
                                <InputText
                                    stretch={isWeb()}
                                    numberOfLines={5}
                                    value={comment}
                                    onChangeText={setComment}
                                    style={webComponent ? { flexGrow: 1 } : { height: 50 }}
                                />
                            </Flex>

                            <Spacer h="12" />

                            <CheckButton
                                label={formatMessage('nps.contact-permission.title')}
                                type="checkbox"
                                selected={contactPermission}
                                onPress={() => setContactPermission(!contactPermission)}
                            />

                            <Spacer h="12" />

                            <Button
                                label={formatMessage('nps.send')}
                                type="primary"
                                disabled={scoreNps === undefined}
                                onPress={handleSend}
                            />

                            <Spacer h="12" />

                            <Text.CAPTION
                                onPress={() => {
                                    Linking.openURL(
                                        'https://heltti.fi/wp-content/uploads/2018/03/MyHeltti-Data-Protection-Description-20180228.pdf'
                                    );
                                }}
                            >
                                <FormattedMessage id="nps.data-protection-description" />
                            </Text.CAPTION>
                        </>
                    )}
                    {state === 'loading' && <Loader size="large" />}
                    {state === 'done' && (
                        <Text.HEADER_2>
                            <FormattedMessage id="nps.thank-you" />
                        </Text.HEADER_2>
                    )}
                </Flex>
            </ContentScrollView>
        </ScreenContainer>
    );
};
