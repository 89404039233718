import { range } from 'lodash';
import { Flex } from 'native-base';
import React from 'react';

import { Button } from '~/components/button';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

export type NumberRangeInputProps = {
    min: number;
    max: number;
    minLabel: string;
    maxLabel: string;
    title: string;
    value?: number;
    onChange: (value: number) => void;
};

export const NumberRangeInput: React.FC<NumberRangeInputProps> = props => {
    const { min, max, minLabel, maxLabel, title, value, onChange } = props;

    const scores = range(min, max + 1);

    return (
        <Flex alignItems="center">
            <Text.HEADER_3 after={Spacing.SMALL}>{title}</Text.HEADER_3>
            <Flex alignItems="center">
                <Flex direction="row" wrap="wrap" mb={2}>
                    {scores.map(score => (
                        <Button
                            key={score}
                            label={'' + score}
                            onPress={() => onChange(score)}
                            type={score === value ? 'primary' : 'basic'}
                            style={{ marginRight: Spacing.SMALL, marginBottom: Spacing.SMALL }}
                        />
                    ))}
                </Flex>
                <Flex direction="row" justifyContent="space-between" alignSelf="stretch">
                    <Text.CAPTION>
                        {min} - {minLabel}
                    </Text.CAPTION>
                    <Text.CAPTION>
                        {max} - {maxLabel}
                    </Text.CAPTION>
                </Flex>
            </Flex>
        </Flex>
    );
};
