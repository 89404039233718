import { ApolloError, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { AppointmentRemoteContactTypeValues } from '~/contexts/appointments-selection';
import * as ErrorDiagnostics from '~/error';
import {
    AppointmentDaysDocument,
    fragmentToAppointmentDay,
    AppointmentFilters,
    AppointmentTimeOfDay,
    AppointmentDay
} from '~/types';
import { toISODateString } from '~/utils/date';

export type AppointmentDaysHookParams = {
    calendarReservationAccessId: ID;
    start: ISODate;
    end: ISODate;
    regionId?: ID;
    locationId?: ID;
    timeOfDay?: AppointmentTimeOfDay;
    filters?: AppointmentFilters;
};

export type AppointmentDaysResult = {
    loading: boolean;
    error?: ApolloError;
    appointmentDays?: AppointmentDay[];
    fetchMoreAppointmentDays: (from: ISODate, to: ISODate) => Promise<void>;
    appointmentDaysRange: [string, string];
};

export function useAppointmentDays(
    params: AppointmentDaysHookParams,
    cacheOnly: boolean = false
): AppointmentDaysResult {
    const { calendarReservationAccessId, start, end, regionId, locationId, timeOfDay, filters } = params;

    const { data, loading, error, fetchMore } = useQuery(AppointmentDaysDocument, {
        variables: {
            calendarReservationAccessId,
            start,
            end,
            regionId,
            locationId,
            timeOfDay,
            // Undefined region id implies "remote times" only
            filters: regionId ? filters : { ...filters, contactTypes: AppointmentRemoteContactTypeValues }
        },
        fetchPolicy: cacheOnly ? 'cache-only' : 'cache-first'
    });

    const appointmentDays = useMemo(
        () =>
            data?.root?.calendarReservationAccess?.appointmentDays
                .map(fragmentToAppointmentDay)
                .filter(appointmentDay => appointmentDay.eventCount),
        [data]
    );

    const fetchMoreAppointmentDays = async (from: ISODate, to: ISODate) => {
        try {
            await fetchMore({ variables: { start: from, end: to } });
        } catch (err: unknown) {
            ErrorDiagnostics.error(err);
        }
    };

    return {
        loading,
        error,
        appointmentDays,
        fetchMoreAppointmentDays,
        appointmentDaysRange: [
            start,
            appointmentDays && appointmentDays.length > 0
                ? toISODateString(appointmentDays[appointmentDays.length - 1].date)
                : end
        ]
    };
}

export const appointmentDaysTypePolicy = {
    keyArgs: ['regionId', 'locationId', 'calendarReservationAccessId', 'timeOfDay'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge(existing = [], incoming: any[], { args }: any) {
        // Merge existing and incoming arrays by object `__ref` to prevent duplicates if there are overlapping queries

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const identity = ({ __ref }: any) => __ref;
        const set = new Set([...existing.map(identity), ...incoming.map(identity)]);

        return [...set].map(ref => ({ __ref: ref }));
    }
};
