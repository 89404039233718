import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { PinCodeDisplay, PinCodeKeyboard } from '~/components/pin-code';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useAuth } from '~/contexts/auth';
import * as ErrorDiagnostics from '~/error';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { usePinCodeSetup } from '~/hooks/pin-code';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';
import { timeout } from '~/utils';

type OnboardingPinSetupNavigation = CompositeNavigationProp<
    StackNavigationProp<OnboardingNavigatorParamList, 'pin-setup'>,
    StackNavigationProp<AppNavigatorParamList>
>;

const ScreenContainer = styled.View<{ bottomInset: number }>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
`;

const HeaderContainer = styled.View<{ topInset: number }>`
    align-items: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
    padding-top: ${({ topInset }) => topInset + Spacing.SMALL}px;
    padding-bottom: ${Spacing.SMALL}px;
`;

const TopContainer = styled.View`
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
`;

const BottomContainer = styled.View`
    align-items: center;
    padding: 0 ${Spacing.MEDIUM}px ${Spacing.MEDIUM}px;
`;

const Message = styled(Text.P1)`
    height: 50px;
    text-align: center;
`;

export const OnboardingPinSetup = () => {
    const { top, bottom } = useSafeAreaInsets();
    const { reset } = useNavigation<OnboardingPinSetupNavigation>();
    const { nextTopic } = useOnboardingTopics();
    const { pin } = useAuth();

    const handlePinCodeSet = useCallback(
        async (pinCode: string) => {
            try {
                await pin.setPinCode(pinCode);
                await timeout(750);
                reset({ index: 0, routes: [{ name: nextTopic('pin-setup-intro') }] });
            } catch (error) {
                ErrorDiagnostics.error(error);
            }
        },
        [nextTopic, pin, reset]
    );

    const { states, message, input, handleEnter, handleDelete } = usePinCodeSetup({
        onPinCodeSet: handlePinCodeSet
    });

    return (
        <>
            <StatusBar style="dark" />

            <ScreenContainer bottomInset={bottom}>
                <HeaderContainer topInset={top}>
                    <HorizontalLogo />
                </HeaderContainer>

                <EllipticMaskView ellipseSize="large" style={{ flex: 1 }}>
                    <TopContainer>
                        <PinCodeDisplay states={states} />
                        <Spacing.Vertical.MEDIUM />
                        <Message>{message}</Message>
                    </TopContainer>
                </EllipticMaskView>

                <Spacing.Vertical.MEDIUM />

                <BottomContainer>
                    <PinCodeKeyboard value={input} onKeyPress={handleEnter} onDelete={handleDelete} />
                </BottomContainer>
            </ScreenContainer>
        </>
    );
};
