import { mergeDeep } from '@apollo/client/utilities';
import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import MarkdownDisplay, { ASTNode, MarkdownIt } from 'react-native-markdown-display';

import { Button, ButtonShape, ButtonSize, ButtonType } from '~/components/button';
import { EmbeddedYouTubeVideo, isEmbeddedYouTubeAttributes } from '~/components/youtube';
import { useRichLink } from '~/hooks/linking/rich-link';
import { isString } from '~/utils/type-predicates';

import { compact, lead, standard } from './markdown.styles';

export type MarkdownProps = PropsWithChildren<{
    paragraph?: TextStyle;
    contentContainerStyle?: StyleProp<ViewStyle>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles?: StyleSheet.NamedStyles<any>;
}>;

const markdownIt = MarkdownIt({ typographer: true })
    .use(require('markdown-it-directive'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .use((md: any) => {
        ['youtube', 'cta'].forEach(directive => {
            md.blockDirectives[directive] = (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state: any,
                content: string,
                contentTitle: string,
                inlineContent: string,
                dests: string[][],
                attrs: object
            ) => {
                const token = state.push(`${directive}_block`, '', 0);
                token.content = inlineContent;
                token.attrs = Object.entries(attrs ?? {});
            };
        });
    });

export function Markdown(props: MarkdownProps) {
    const { contentContainerStyle, children, styles: overrides = {} } = props;

    const rules = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        youtube_block: (node: ASTNode) => {
            const { content, attributes } = node;
            if (isEmbeddedYouTubeAttributes(attributes)) {
                return <EmbeddedYouTubeVideo key={node.key} content={content} attributes={attributes} />;
            }
            return null;
        },
        cta_block: (node: ASTNode) => {
            const { content, attributes } = node;
            if (content && isEmbeddedButtonAttributes(attributes)) {
                return <EmbeddedButton key={node.key} content={content} attributes={attributes} />;
            }
            return null;
        }
    };
    const handleLink = useRichLink();
    return (
        <View style={contentContainerStyle}>
            {/* @ts-ignore see https://github.com/iamacup/react-native-markdown-display/issues/176#issuecomment-1234251423 */}
            <MarkdownDisplay
                onLinkPress={(url: string) => {
                    handleLink(url);
                    return false;
                }}
                debugPrintTree={false}
                mergeStyle={false}
                markdownit={markdownIt}
                rules={rules}
                style={mergeDeep(standard, overrides, {})}
            >
                {children}
            </MarkdownDisplay>
        </View>
    );
}

export type MarkdownSubStyleProps = Omit<MarkdownProps, 'styles'>;

export function MarkdownCompact(props: MarkdownSubStyleProps) {
    const { children, ...rest } = props;
    return (
        <Markdown {...rest} styles={compact}>
            {children}
        </Markdown>
    );
}

export function MarkdownLead(props: MarkdownSubStyleProps) {
    const { children, ...rest } = props;
    return (
        <Markdown {...rest} styles={lead}>
            {children}
        </Markdown>
    );
}

type EmbeddedButtonAttributes = {
    href: string;
    type?: ButtonType;
    size?: ButtonSize;
    shape?: ButtonShape;
};

type EmbeddedButtonProps = {
    content: string;
    attributes: EmbeddedButtonAttributes;
};

function isEmbeddedButtonAttributes(attrs: Record<string, unknown>): attrs is EmbeddedButtonAttributes {
    return 'href' in attrs && isString(attrs.href);
}

function EmbeddedButton(props: EmbeddedButtonProps) {
    const {
        content,
        attributes: { href, type, size, shape }
    } = props;

    const handleLink = useRichLink();

    return (
        <View style={standard.button}>
            <Button
                type={(type?.trim()?.match(/basic|primary|secondary|negative/)?.[0] as ButtonType) ?? undefined}
                size={(size?.trim()?.match(/tiny|small|regular|large|huge/)?.[0] as ButtonSize) ?? undefined}
                shape={(shape?.trim()?.match(/square|rounded/)?.[0] as ButtonShape) ?? undefined}
                label={content?.trim() ?? '-'}
                onPress={() => handleLink(href?.trim())}
            />
        </View>
    );
}
