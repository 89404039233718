import { Flex } from 'native-base';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { TriangleIcon } from '~/components/triangle-icon/triangle-icon';
import { isNative } from '~/utils/platform';

export type BannerNofificationType = 'default' | 'warning' | 'error';

type BannerNotificationProps = {
    text: string;
    type?: BannerNofificationType;
};

export const BannerNotification: React.FC<BannerNotificationProps> = props => {
    const { text, type } = props;

    let backgroundColor;
    let color;
    let triangle = null;

    switch (type) {
        case 'warning':
            backgroundColor = Color.SUBMARINE;
            color = Color.TEXT_DEFAULT;
            triangle = <TriangleIcon color={Color.ALMOST_BLACK} />;
            break;
        case 'error':
            backgroundColor = Color.WARNING_DEFAULT;
            color = Color.TEXT_TERTIARY;
            triangle = <TriangleIcon color={Color.TEXT_TERTIARY} />;
            break;
        default:
            backgroundColor = Color.BACKGROUND_PRIMARY;
            color = Color.TEXT_DEFAULT;
    }

    const { top } = useSafeAreaInsets();

    const bannerStyles: StyleProp<ViewStyle> = { padding: Spacing.SMALL };

    if (isNative()) {
        bannerStyles.paddingTop = top;
    }

    return (
        <Flex alignItems="center" direction="column" style={{ backgroundColor, ...bannerStyles }}>
            <Flex direction="row">
                {triangle}
                <Text.PARAGRAPH_1 style={{ color }}>{text}</Text.PARAGRAPH_1>
            </Flex>
        </Flex>
    );
};
