import { ParamListBase, RouteProp, useRoute as useNavigationRoute } from '@react-navigation/native';
import { useMemo } from 'react';

import { decodeURLParam } from '~/utils/decode-url-param';
import { objectMap } from '~/utils/object';

export function useRoute<T extends RouteProp<ParamListBase>>(): T {
    const { params, ...rest } = useNavigationRoute<T>();

    const decodedParams = useMemo(() => {
        if (params === undefined) {
            return undefined;
        }
        return objectMap(params, param => {
            if (typeof param === 'string') {
                return decodeURLParam(param);
            }
            return param;
        });
    }, [params]);

    return {
        params: decodedParams,
        ...rest
    } as T;
}
