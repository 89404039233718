import { makeSpanId, makeTraceId } from '@heltti/common';

import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { getDeviceUuid } from '~/hooks/device-registration/device-registration-persist';
import { isString } from '~/utils/type-predicates';

export async function customFetch(info: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    if (!isString(info)) {
        throw new Error('Unexpected input');
    }

    let operationName;

    try {
        operationName = init ? JSON.parse(init.body as unknown as string)?.operationName : undefined;
    } catch {
        // No op
    }

    const transaction = ErrorDiagnostics.transaction({ op: 'http.client', name: operationName });

    const customInput = `${info}?o=${operationName}`;
    const customInit = {
        ...init,
        headers: {
            ...init?.headers,
            'Accept-Encoding': 'gzip',

            'X-Heltti-Application': 'myheltti',
            /**
             * Set device id header to be able to identify the device
             */
            'X-Device-Id': await getDeviceUuid(),

            /**
             * Set Sentry-Trace header to enable tracking through all service layers
             *
             * See https://develop.sentry.dev/sdk/performance/#header-sentry-trace for more details
             */
            'Sentry-Trace': `${makeTraceId()}-${makeSpanId()}-${transaction?.sampled ? 1 : 0}`,

            /**
             * Clear Cookies when app does a cold start
             *
             * NOTE:    This doesn't prevent Cookies being set later and doesn't clear them unless the app is
             *          killed and started
             */
            Cookie: ''
        }
    };

    try {
        return await fetch(customInput, customInit);
    } catch (error: unknown) {
        transaction?.setStatus('unknown_error');
        throw error;
    } finally {
        transaction?.finish();
    }
}
