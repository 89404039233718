/**
 * Return a decimal number within range from min to max (inclusive)
 * @param min range lower bound
 * @param max range upper bound
 */
export const randomNumberInRange = (min: number, max: number): number => Math.random() * (max - min) + min;

/**
 * Return an integer  within range from min to max (inclusive)
 * @param min range lower bound
 * @param max range upper bound
 */
export const randomIntInRange = (min: number, max: number) => Math.round(randomNumberInRange(min, max));
