import { Dimensions } from 'react-native';

export const SCREEN_DIMENSIONS = Dimensions.get('screen');

export const SCREEN_WIDTH = SCREEN_DIMENSIONS.width;
export const SCREEN_HEIGHT = SCREEN_DIMENSIONS.height;

export const REFERENCE_SCREEN_WIDTH = 375;
export const REFERENCE_SCREEN_HEIGHT = 812;

export const SCREEN_WIDTH_RATIO = SCREEN_DIMENSIONS.width / REFERENCE_SCREEN_WIDTH;
export const SCREEN_HEIGHT_RATIO = SCREEN_DIMENSIONS.height / REFERENCE_SCREEN_HEIGHT;

export const SCREEN_RATIO = Math.min(SCREEN_WIDTH_RATIO, SCREEN_HEIGHT_RATIO);
