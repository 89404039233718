import { useHeaderHeight } from '@react-navigation/elements';
import { Flex, useBreakpointValue } from 'native-base';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { RefreshControl } from 'react-native';

import { CallToActionList } from '~/components/call-to-action-list/call-to-action-list';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Loader } from '~/components/loader';
import { NoContentContainer } from '~/components/no-content-container';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { REACHABILITY_THRESHOLD } from '~/contexts/network-service-state';
import { useCallToActions } from '~/hooks/call-to-actions/use-call-to-actions';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';

export const Services: React.FC = () => {
    const { formatMessage } = useIntl();

    const headerHeight = useHeaderHeight();
    const handleError = useErrorHandler();

    const { callToActions, loadingInitial, loading, refetch, error } = useCallToActions('SERVICES');

    const { onScroll } = useExtendedNavigationHeader({
        title: formatMessage('services.title')
    });

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    if (error) {
        handleError(error);
    }

    if (loadingInitial) {
        return (
            <NoContentContainer>
                <Loader delay={REACHABILITY_THRESHOLD} size="medium" />
            </NoContentContainer>
        );
    }

    return (
        <ContentScrollView
            bounces
            onScroll={onScroll}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            overScrollMode="never"
            refreshControl={<RefreshControl refreshing={!loadingInitial && loading} onRefresh={refetch} />}
        >
            <ContentHorizontalMargins includePadding={!webComponent}>
                <Flex grow="1" marginTop={{ base: headerHeight, md: headerHeight * 1.5 }} width="100%">
                    <Flex>
                        <Text.TITLE after={Spacing.MEDIUM}>
                            <FormattedMessage id="services.title" />
                        </Text.TITLE>
                        <Spacing.Vertical.LARGE />
                        <Text.PARAGRAPH_1>
                            <FormattedMessage id="services.description" />
                        </Text.PARAGRAPH_1>
                        <Spacing.Vertical.GIGANTIC />
                    </Flex>
                    <Flex>
                        <CallToActionList
                            callToActions={callToActions}
                            noContentMessage={formatMessage('services.noServicesAvailable')}
                        />
                    </Flex>
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
};
