import { NotificationPermissionsStatus } from 'expo-notifications';
import React, { PropsWithChildren } from 'react';

import { PushPermissionStatus } from '~/contexts/push-notifications/push-notifications-persist';

export type PushNotificationsContextType = Readonly<{
    status: PushPermissionStatus | null;
    requestPermissions: () => Promise<NotificationPermissionsStatus>;
}>;

export const PushNotificationsContext = React.createContext<PushNotificationsContextType | undefined>(undefined);

export type PushNotificationContextProviderProps = PropsWithChildren<object>;
