import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';
import { Shadow } from '~/components/shadow';
import { LayoutStyleProps } from '~/types';

type IconButtonContainerProps = {
    pressed: boolean;
};

const IconButtonContainer = styled.View<IconButtonContainerProps>`
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

type IconButtonProps = {
    icon: FunctionComponent<IconProps>;
    onPress?: () => void | Promise<void>;
    loading?: boolean;
    disabled?: boolean;
    layoutStyle?: LayoutStyleProps;
    flat?: boolean;
};

export const IconButton: FunctionComponent<IconButtonProps> = props => {
    const { icon: Icon, onPress, layoutStyle, flat = true } = props;
    return (
        <Pressable
            onPress={onPress}
            accessibilityRole="button"
            style={[flat ? {} : Shadow.styles.primary, layoutStyle]}
        >
            {({ pressed }) => (
                <IconButtonContainer pressed={pressed}>
                    <Icon size={24} fill={pressed ? Color.FOCUS_DEFAULT : Color.ICON_SECONDARY} />
                </IconButtonContainer>
            )}
        </Pressable>
    );
};
