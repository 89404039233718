import AsyncStorage from '@react-native-async-storage/async-storage';
import { v4 as uuid } from 'uuid';

/*
 * Non-secure storage
 */
enum DeviceRegistrationStorageKey {
    DEVICE_UUID = 'device-uuid'
}

// Cache in memory as this is accessed often and should not change in the lifetime of the App being installed
let _deviceUUID: UUID | null = null;

export const getDeviceUuid = async (): Promise<UUID> => {
    if (!_deviceUUID) {
        _deviceUUID = await AsyncStorage.getItem(DeviceRegistrationStorageKey.DEVICE_UUID);

        if (!_deviceUUID) {
            _deviceUUID = uuid();

            await setDeviceUuid(_deviceUUID);
        }
    }

    return _deviceUUID;
};

export const setDeviceUuid = (deviceUUID: UUID) => {
    return AsyncStorage.setItem(DeviceRegistrationStorageKey.DEVICE_UUID, deviceUUID);
};
