import { RouteProp } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useBreakpointValue } from 'native-base';
import React, { useRef } from 'react';
import { Animated } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import HeroImage from '~/assets/images/code-envelope.svg';
import { LinkButton } from '~/components/button';
import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { PinCodeDisplay, PinCodeKeyboard } from '~/components/pin-code';
import { SCREEN_WIDTH_RATIO } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { useSMSCodeAuthentication } from '~/hooks/sms-authentication/sms-authentication';
import { AppNavigatorParamList, UnauthenticatedNavigatorParamList } from '~/navigator/app-navigator';
import { AnimatedLogoHeader } from '~/screens/home';

import { AuthInstruction } from './auth-instruction';

export type AuthSMSAuthenticateNavigation = StackScreenProps<AppNavigatorParamList, 'sms-authentication'>['navigation'];

const ScreenContainer = styled.View<{ bottomInset: number }>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
`;

const HeaderContainer = styled.View<{ topInset: number }>`
    align-items: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
    padding-top: ${({ topInset }) => topInset + Spacing.SMALL}px;
    padding-bottom: ${Spacing.SMALL}px;
`;

const TopContainer = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
`;

const BottomContainer = styled.View`
    align-items: stretch;
    padding: 0 ${Spacing.MEDIUM}px ${Spacing.MEDIUM}px;
`;

const ForgottenLinkContainer = styled.View`
    padding: ${Spacing.MEDIUM}px 0 ${Spacing.HUGE}px;
`;

type AuthSMSRouteProp = RouteProp<UnauthenticatedNavigatorParamList, 'sms-authentication'>;

export const AuthSmsAuthentication: React.FC = () => {
    const {
        params: { token }
    } = useRoute<AuthSMSRouteProp>();

    const { top, bottom } = useSafeAreaInsets();
    const { formatMessage } = useIntl();

    const { states, inputValue, message, onInput, onDelete, loading, resendSMS } = useSMSCodeAuthentication({
        tokenFromEmail: token
    });

    const web: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    const renderSecondaryBottomContent = () => (
        <>
            <ForgottenLinkContainer>
                <LinkButton
                    size="small"
                    label={formatMessage('auth-sms-authenticate.resend-code')}
                    onPress={resendSMS}
                />
            </ForgottenLinkContainer>

            <PinCodeKeyboard value={inputValue} onKeyPress={onInput} onDelete={onDelete} disabled={loading} />
        </>
    );

    if (web) {
        return (
            <>
                <AnimatedLogoHeader offset={offset} />
                <AuthInstruction
                    devMenuAccess
                    image={web ? <HeroImage width="272" height="272" viewBox="0 0 350 350" /> : <HeroImage />}
                    renderEllipticMask
                    title={<PinCodeDisplay size={SCREEN_WIDTH_RATIO < 1.0 ? 'small' : 'standard'} states={states} />}
                    description={message}
                    secondaryBottomContent={renderSecondaryBottomContent()}
                />
            </>
        );
    }

    return (
        <>
            <StatusBar style="dark" />

            <ScreenContainer bottomInset={bottom}>
                <HeaderContainer topInset={top}>
                    <HorizontalLogo />
                </HeaderContainer>

                <EllipticMaskView ellipseSize="large" style={{ flex: 1 }}>
                    <TopContainer>
                        <PinCodeDisplay size={SCREEN_WIDTH_RATIO < 1.0 ? 'small' : 'standard'} states={states} />
                        <Spacing.Vertical.MEDIUM />
                        <Text.P1 style={{ paddingHorizontal: Spacing.MEDIUM }}>{message}</Text.P1>
                    </TopContainer>
                </EllipticMaskView>

                <Spacing.Vertical.MEDIUM />

                <BottomContainer>{renderSecondaryBottomContent()}</BottomContainer>
            </ScreenContainer>
        </>
    );
};
