import axios from 'axios';
import { useErrorHandler } from 'react-error-boundary';

import { AppError } from '~/error';

export type NPSValues = {
    scoreDetail1?: number;
    scoreDetail2?: number;
    scoreNps: number;
    comment?: string;
    contactPermission: boolean;
};

export const useSendNps = (npsUUID: ID) => {
    const handleError = useErrorHandler();

    const sendNps = (values: NPSValues) => {
        return axios
            .post('/member-api/nps/' + npsUUID, values)
            .then(result => {
                return result.data;
            })
            .catch(error => {
                handleError(new AppError(error, 'error.alert.generic'));
            });
    };

    return sendNps;
};
