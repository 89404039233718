import { ApolloClient, InMemoryCache } from '@apollo/client';

import { AppEndpointConfig } from '~/contexts/app-config';
import { GetAppUpdateStateDocument } from '~/types';

export const createAppUpdateClient = ({ http, host }: AppEndpointConfig) => {
    return new ApolloClient({
        uri: `${http}://${host}/member-api/graphql/`,
        cache: new InMemoryCache()
    });
};

export type AppUpdateState = 'OK' | 'APP_STORE_REQUIRED' | 'APP_OTA_REQUIRED';

export async function clientAppUpdateState(client: ApolloClient<object>, version: string): Promise<AppUpdateState> {
    try {
        const { data } = await client.query({ query: GetAppUpdateStateDocument, variables: { version } });
        return data.appUpdateState;
    } catch (error: unknown) {
        throw new Error(`Client app update state query failed: ${error}`);
    }
}
