import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { ArticleNavigationProp } from '~/screens/article';
import { ArticleCategorySubArticle } from '~/types';

import { CompactButtonSheet } from '../button/button-sheet-compact';

export type ArticleCompactChildCategoryProps = {
    title?: string | null;
    articles?: readonly ArticleCategorySubArticle[];
};

export function ArticleCompactChildCategory(props: ArticleCompactChildCategoryProps) {
    const { title, articles = [] } = props;
    const { push } = useNavigation<ArticleNavigationProp>();

    return articles.length > 0 ? (
        <>
            <CompactButtonSheet
                title={title ?? undefined}
                items={articles.map(childArticle => ({
                    key: childArticle.id,
                    title: childArticle.title,
                    label: childArticle.highlight ?? childArticle.title,
                    image: childArticle.coverPhotoUrl ?? undefined,
                    onPress: () => push('article', { id: childArticle.id })
                }))}
            />
        </>
    ) : null;
}
