import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { getUserProfileLastReviewed, setUserProfileLastReviewed } from '~/contexts/user-profile/user-profile-persist';
import * as ErrorDiagnostics from '~/error';
import { AppError } from '~/error';
import { useLoadingQuery } from '~/hooks/loading-query';
import { EmploymentFragment, GetUserProfileDocument, UserProfileFragment } from '~/types';
import { idToGlobalId, relayConnectionReduce } from '~/utils';

export type UserProfileContextType = Omit<UserProfileFragment, 'employments'> & {
    employment: EmploymentFragment | null;

    userProfileReviewed: Date | null;
    reviewUserProfile: Function;
};

export const UserProfileContext = React.createContext<UserProfileContextType | undefined>(undefined);

const UserProfileContextProvider = (props: PropsWithChildren<object>) => {
    const { children } = props;
    const { identity } = useAuth();
    const { loadingInitial, data, error } = useLoadingQuery(GetUserProfileDocument);
    const { locale, setCurrentLocale } = useIntl();
    const [userProfileReviewed, setUserProfileReviewed] = useState<Date | null>();

    useAsyncEffect(async mounted => {
        if (mounted()) {
            setUserProfileReviewed(await getUserProfileLastReviewed((await identity.get())!));
        }
    }, []);

    useEffect(() => {
        if (data?.root?.me) {
            const [id, type] = idToGlobalId(data.root.me.id);
            ErrorDiagnostics.registerUser(`${type}:${id.toString().replace('-', '')}`);
        } else {
            ErrorDiagnostics.registerUser(null);
        }
    }, [data]);

    useAsyncEffect(
        async mounted => {
            const lang = data?.root?.me?.lang;
            if (mounted() && lang && lang !== locale) {
                await setCurrentLocale(lang);
            }
        },
        [data, setCurrentLocale]
    );

    const reviewUserProfile = useCallback(
        async () => setUserProfileLastReviewed((await identity.get())!, new Date()),
        [identity]
    );

    if (loadingInitial || userProfileReviewed === undefined) {
        return null;
    }
    if (error) {
        throw new AppError(error, 'user-profile.error.cannot_load');
    }

    let value = undefined;

    if (data?.root?.me) {
        const profile = data.root.me;

        const employments = relayConnectionReduce<EmploymentFragment>(profile.employments) ?? [];
        const employment = employments?.[0] ?? null;

        value = {
            ...profile,
            employment,
            userProfileReviewed,
            reviewUserProfile
        };
    }

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export const UserProfileConsumer = UserProfileContext.Consumer;
export const UserProfileProvider = UserProfileContextProvider;

export const useUserProfile = () => {
    const context = useContext<UserProfileContextType | undefined>(UserProfileContext);

    if (!context) {
        throw Error('Cannot use user profile context until it has been defined');
    }

    return context;
};
