import React, { FunctionComponent } from 'react';
import { TextInputProps } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { isWeb } from '~/utils/platform';

import { Shadow } from '../shadow';

export type InputSize = 'small' | 'medium' | 'large';

type InputProps = {
    accessoryLeft?: RenderProp<TextInputProps>;
    accessoryRight?: RenderProp<TextInputProps>;
    backgroundColor?: Color;
    rounded?: boolean;
    flat?: boolean;
    multiline?: boolean;
    size?: InputSize;
} & TextInputProps;

const InputContainer = styled.View<{ rounded: boolean; backgroundColor: Color; flat: boolean }>`
    flex-direction: row;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ rounded }) => (rounded ? '20' : '6')}px;
    ${({ flat }) => (!flat ? Shadow.primary : '')}
`;

const InputComponent = styled.TextInput<InputProps>`
    flex: 1;
    color: ${Color.ALMOST_BLACK};
    font-weight: 400;
    font-size: 17px;
    ${({ size }) => (size ? `height: ${{ small: '50px', medium: '120px', large: '180px' }[size]}` : '')}
    ${isWeb() && 'outline-width: 0'}
`;

/**
 * @deprecated Use TextInput or DateTextInput
 */
export const Input: FunctionComponent<InputProps> = props => {
    const {
        accessoryLeft,
        accessoryRight,
        rounded = false,
        backgroundColor = Color.BACKGROUND_PRIMARY,
        flat = true
    } = props;
    return (
        <InputContainer rounded={rounded} backgroundColor={backgroundColor} flat={flat}>
            {accessoryLeft?.(props)}
            {/* TODO: Fix TS error */}
            {/* @ts-ignore */}
            <InputComponent textAlignVertical="top" {...props} />
            {accessoryRight?.(props)}
        </InputContainer>
    );
};
