import { Flex } from 'native-base';
import React, { forwardRef, useCallback } from 'react';
import {
    FlatList,
    LayoutChangeEvent,
    ListRenderItemInfo,
    NativeScrollEvent,
    NativeSyntheticEvent,
    ViewToken
} from 'react-native';

import {
    ChatMessage as ChatMessageComponent,
    ChatClosedMessage,
    ChatEscalatedMessage,
    ChatReopenedMessage,
    DateChangeMessage,
    NewMessages,
    TailPosition,
    UserJoinedMessage,
    UserLeftMessage
} from '~/components/chat-message';
import { ChatIdAttachment } from '~/components/chat-message/chat-id-attachment';
import { InteractionMessage } from '~/components/chat-message/interaction-message';
import { MessageSeen } from '~/components/message-seen';
import { Spacing } from '~/components/spacing';
import { useIntl } from '~/contexts/intl';
import {
    ChatMessage,
    ChatMessageIdAttachment,
    ChatMessageInteraction,
    ChatUserLastSeenInfo,
    DateChangeChatMessage,
    isChatMessageIdAttachment,
    isChatMessageInteraction,
    isDateChangeChatMessage,
    isNewMessagesChatMessage,
    isSystemChatMessage,
    isTextChatMessage,
    NewMessagesChatMessage,
    SystemChatMessage,
    TextChatMessage
} from '~/hooks/chat';
import { InteractionAction } from '~/hooks/interaction';
import { isUserInBusinessTimezone, localTimezoneAbbreviation } from '~/utils/timezone';

import { isSystemMessage, SystemMessage } from './system-message-types';

const messageContainerAlignItems = (messageType: 'sender' | 'recipient' | 'other') => {
    switch (messageType) {
        case 'sender':
            return 'flex-end';
        case 'recipient':
            return 'flex-start';
        case 'other':
            return 'center';
    }
};

const areMessagesFromSameSender = (a: ChatMessage, b: ChatMessage) => {
    if (!isTextChatMessage(a) || !isTextChatMessage(b)) {
        return false;
    }

    return a.user && b.user && a.user.id === b.user.id;
};

const marginForType = (message: ChatMessage): number => {
    if (isTextChatMessage(message)) {
        return Spacing.TINY / 2;
    } else if (isSystemChatMessage(message)) {
        return Spacing.LARGE;
    } else {
        return Spacing.LARGE;
    }
};

export type ChatMessagesProps = {
    messages: ChatMessage[];
    onAction: (interactionId: ID, action: InteractionAction) => Promise<void>;
    lastMessageSeenInfos: Map<string, ChatUserLastSeenInfo[]>;
    initialScrollIndex?: number;
    userId: ID;
    onViewableItemsChanged: (items: { changed: ViewToken[]; viewableItems: ViewToken[] }) => void;
    onLayout: (event: LayoutChangeEvent) => void;
    onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    onEndReached: () => void;
    onScrollToIndexFailed: (info: { index: number; averageItemLength: number }) => void;
};

export const ChatMessages = forwardRef<FlatList<ChatMessage>, ChatMessagesProps>((props, ref) => {
    const {
        initialScrollIndex,
        lastMessageSeenInfos,
        messages,
        onAction,
        onLayout,
        onScroll,
        onScrollToIndexFailed,
        onViewableItemsChanged,
        userId,
        onEndReached
    } = props;

    const { formatDate, formatTime } = useIntl();

    const isMessageByCurrentUser = useCallback(
        (message: TextChatMessage) => {
            return message.user?.id === userId;
        },
        [userId]
    );

    const dateToChatTime = useCallback(
        (date: Date) => {
            let time;

            // Only time if sent within a day
            if (date.valueOf() > Date.now() - 24 * 3600 * 1000) {
                time = formatTime(date, { timeStyle: 'short' });
            } else {
                time = formatDate(date, { dateStyle: 'short', timeStyle: 'short' });
            }

            if (!isUserInBusinessTimezone()) {
                time = `${time} ${localTimezoneAbbreviation()}`;
            }

            return time;
        },
        [formatDate, formatTime]
    );

    const calculateMarginForMessage = (index: number): number => {
        if (index === 0) {
            return 0;
        }
        const message = messages[index];
        const previousMessage = messages[index - 1];
        if (isTextChatMessage(message) && isTextChatMessage(previousMessage)) {
            if (areMessagesFromSameSender(message, previousMessage)) {
                return 2;
            } else {
                return Spacing.SMALL;
            }
        }
        return Math.max(marginForType(message), marginForType(previousMessage));
    };

    const isMessageFromSameSenderAsNextMessage = (index: number) => {
        if (index >= messages.length - 1) {
            return false;
        }
        const message = messages[index];
        const nextMessage = messages[index + 1];
        return areMessagesFromSameSender(message, nextMessage);
    };

    const determineTailPosition = (index: number): TailPosition | undefined => {
        if (!isMessageFromSameSenderAsNextMessage(index)) {
            return isMessageByCurrentUser(messages[index] as TextChatMessage) ? 'right' : 'left';
        }
        return undefined;
    };

    const renderSystemMessage = (message: SystemChatMessage) => {
        if (message.type === SystemMessage.USER_JOINED) {
            return (
                <UserJoinedMessage
                    name={message.user!.name}
                    title={message.user!.title!}
                    imageUri={message.user!.avatarUrl!}
                    timestamp={dateToChatTime(message.createdAt)}
                />
            );
        } else if (message.type === SystemMessage.USER_LEFT) {
            return <UserLeftMessage name={message.user!.name} title={message.user!.title!} />;
        } else if (message.type === SystemMessage.CHAT_CLOSED) {
            return <ChatClosedMessage timestamp={dateToChatTime(message.createdAt)} />;
        } else if (message.type === SystemMessage.CHAT_REOPENED) {
            return <ChatReopenedMessage timestamp={dateToChatTime(message.createdAt)} />;
        } else if (message.type === SystemMessage.CHAT_ESCALATED) {
            return <ChatEscalatedMessage timestamp={dateToChatTime(message.createdAt)} />;
        }
    };

    const renderChatMessage = (message: TextChatMessage, index: number) => {
        return (
            <ChatMessageComponent
                theme={isMessageByCurrentUser(message) ? 'sender' : 'recipient'}
                message={message.text}
                attachments={message.attachments}
                avatarUri={
                    !isMessageFromSameSenderAsNextMessage(index) && !isMessageByCurrentUser(message)
                        ? message.user?.avatarUrl
                        : undefined
                }
                timestamp={
                    !isMessageFromSameSenderAsNextMessage(index) && message.createdAt
                        ? dateToChatTime(message.createdAt)
                        : undefined
                }
                tailPosition={determineTailPosition(index)}
            />
        );
    };

    const determineMessageType = (message: ChatMessage) => {
        if (!isTextChatMessage(message)) {
            return 'other';
        }
        if (message === undefined || isSystemMessage(message.type)) {
            return 'other';
        }
        return isMessageByCurrentUser(message) ? 'sender' : 'recipient';
    };

    const renderInteractionMessage = (interaction: ChatMessageInteraction) => {
        return (
            <Flex direction="row">
                <InteractionMessage
                    label={interaction.label}
                    title={interaction.title}
                    description={interaction.description}
                    actionLabel={interaction.actions[0].title} // TODO: Support only for single action interactions
                    theme={interaction.theme}
                    onPress={() => onAction(interaction.id, interaction.actions[0])}
                />
            </Flex>
        );
    };

    const renderIdAttachmentMessage = (attachment: ChatMessageIdAttachment) => {
        return (
            <Flex direction="row">
                <ChatIdAttachment attachmentId={attachment.attachmentId} />
            </Flex>
        );
    };

    const renderNewMessagesMessage = (message: NewMessagesChatMessage) => {
        return (
            <Flex direction="row">
                <NewMessages badge={message.badge} />
            </Flex>
        );
    };

    const renderDateChangeMessage = (message: DateChangeChatMessage) => {
        return <DateChangeMessage date={message.createdAt} />;
    };

    const renderLastSeenIndicator = (message: ChatMessage) => {
        const lastSeenMessageByUsers =
            lastMessageSeenInfos
                .get(message.id)
                ?.filter(seenInfo => isTextChatMessage(message) && seenInfo.userId !== message.user?.id) ?? [];

        if (lastSeenMessageByUsers.length > 0) {
            return (
                <Flex alignSelf="flex-end" mt="5px" mb="3px">
                    <MessageSeen imageUris={lastSeenMessageByUsers.map(user => user.avatarUrl)} />
                </Flex>
            );
        }
    };

    const renderItem = (info: ListRenderItemInfo<ChatMessage>) => {
        const { item: message, index } = info;
        const messageType = determineMessageType(message);
        return (
            <Flex mb={`${calculateMarginForMessage(index)}px`} align={messageContainerAlignItems(messageType)} grow="1">
                <Flex mx={4} grow="1" direction="column">
                    {isTextChatMessage(message) && renderChatMessage(message, index)}
                    {isSystemChatMessage(message) && renderSystemMessage(message)}
                    {isChatMessageInteraction(message) && renderInteractionMessage(message)}
                    {isChatMessageIdAttachment(message) && renderIdAttachmentMessage(message)}
                    {renderLastSeenIndicator(message)}
                    {isNewMessagesChatMessage(message) && renderNewMessagesMessage(message)}
                </Flex>
                {isDateChangeChatMessage(message) && renderDateChangeMessage(message)}
            </Flex>
        );
    };

    return (
        <FlatList<ChatMessage>
            ref={ref}
            onScroll={onScroll}
            scrollEventThrottle={200}
            inverted
            onViewableItemsChanged={onViewableItemsChanged}
            initialScrollIndex={initialScrollIndex}
            onLayout={onLayout}
            viewabilityConfig={{ minimumViewTime: 1000, viewAreaCoveragePercentThreshold: 20 }}
            keyExtractor={item => item.id}
            data={messages}
            renderItem={renderItem}
            onScrollToIndexFailed={onScrollToIndexFailed}
            onEndReached={onEndReached}
            contentContainerStyle={{ flex: 1 }}
        />
    );
});
