import addDays from 'date-fns/addDays';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import {
    AppointmentContactType,
    AppointmentFilters,
    AppointmentSpecialistAgeGroup,
    AppointmentSpecialistGender,
    AppointmentSpecialistLanguage,
    AppointmentTimeOfDay,
    Location,
    Region
} from '~/types';
import { isWeb } from '~/utils';
import { toISODateString } from '~/utils/date';

export const AppointmentContactTypeValues: readonly AppointmentContactType[] = ['PHONE', 'VIDEO', 'VISIT'] as const;
export const AppointmentRemoteContactTypeValues: readonly AppointmentContactType[] = ['PHONE', 'VIDEO'] as const;

export const AppointmentTimeOfDayValues: readonly AppointmentTimeOfDay[] = ['ALL', 'MORNING', 'AFTERNOON'] as const;

export const AppointmentSpecialistGenderValues: readonly AppointmentSpecialistGender[] = [
    'MALE',
    'FEMALE',
    'OTHER'
] as const;

export const AppointmentSpecialistLanguageValues: readonly AppointmentSpecialistLanguage[] = [
    'EN',
    'FI',
    'SV'
] as const;

export const AppointmentSpecialistAgeGroupValues: readonly AppointmentSpecialistAgeGroup[] = [
    'UNDER_30',
    'BETWEEN_30_45',
    'BETWEEN_46_59',
    'OVER_60'
] as const;

type AppointmentsSelectionContextType = {
    calendarReservationAccessId: ID;
    calendarEventId?: ID;

    region?: Region;
    setRegion: (region?: Region) => void;

    date?: ISODate;
    setDate: (date?: ISODate) => void;

    appointmentDaysRange: [ISODate, ISODate];
    setAppointmentDaysRange: (range: [ISODate, ISODate]) => void;

    timeOfDay: AppointmentTimeOfDay;
    setTimeOfDay: (timeOfDay: AppointmentTimeOfDay) => void;

    filters: AppointmentFilters;
    setFilters: (filters: Partial<AppointmentFilters>) => void;

    location?: Location;
    setLocation: (location?: Location) => void;

    initialDate?: ISODate;
    initialFilters?: AppointmentFilters;
};

export const AppointmentsSelectionContext = createContext<AppointmentsSelectionContextType | undefined>(undefined);

export type AppointmentsSelectionContextProviderProps = {
    calendarReservationAccessId: ID;
    calendarEventId?: ID; // Optional calendar event id must be specified if this context is created for modifying an existing appointment
    region?: Region;
    initialAppointmentDaysRange?: [ISODate, ISODate];
    initialDate?: ISODate;
    initialTimeOfDay?: AppointmentTimeOfDay;
    initialFilters?: AppointmentFilters;
};

function AppointmentsSelectionContextProvider(props: PropsWithChildren<AppointmentsSelectionContextProviderProps>) {
    const {
        calendarReservationAccessId,
        calendarEventId,
        initialAppointmentDaysRange,
        initialDate,
        initialTimeOfDay,
        initialFilters,
        children
    } = props;

    const initialRegion = props.region;

    const [date, setDate] = useState<ISODate>();
    const [timeOfDay, setTimeOfDay] = useState<AppointmentTimeOfDay>(initialTimeOfDay ?? 'ALL');
    const [filters, setFilters] = useState<AppointmentFilters>(initialFilters ?? {});
    const [location, setLocation] = useState<Location | undefined>();

    // Holds the region select input value for web version
    const [currentRegion, setCurrentRegion] = useState<Region | undefined>(initialRegion);

    const region = isWeb() ? currentRegion : props.region;

    const [appointmentDaysRange, setAppointmentDaysRange] = useState<[ISODate, ISODate]>([
        toISODateString(new Date()),
        toISODateString(addDays(new Date(), 14))
    ]);

    useEffect(() => {
        if (initialDate) {
            setDate(initialDate);
        }
        if (initialAppointmentDaysRange) {
            setAppointmentDaysRange(initialAppointmentDaysRange);
        }
        if (initialTimeOfDay) {
            setTimeOfDay(initialTimeOfDay);
        }
        if (initialFilters) {
            setFilters(initialFilters);
        }
    }, [initialDate, initialTimeOfDay, initialFilters, initialAppointmentDaysRange]);

    return (
        <AppointmentsSelectionContext.Provider
            value={{
                calendarReservationAccessId,
                calendarEventId,
                appointmentDaysRange,
                setAppointmentDaysRange,
                date,
                setDate,
                filters,
                setFilters,
                timeOfDay,
                setTimeOfDay,
                location,
                setLocation,
                region,
                setRegion: setCurrentRegion
            }}
        >
            {children}
        </AppointmentsSelectionContext.Provider>
    );
}

export const AppointmentsSelectionProvider = AppointmentsSelectionContextProvider;

export type AppointmentsSelection = AppointmentsSelectionContextType;

export function useAppointmentsSelection(): AppointmentsSelection {
    const context = useContext(AppointmentsSelectionContext);
    if (!context) {
        throw Error('Cannot use context until it defined');
    }
    return context;
}
