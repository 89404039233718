import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { isValidString } from '~/utils/type-predicates';

type FormStaticTextProps = PropsWithChildren<{
    caption?: string;
    value?: string | null;
}>;

const Field = styled.View`
    flex-direction: row;
    margin-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;

    border-bottom-width: 2px;
    border-color: ${Color.LINE_DEFAULT};
`;

export function FormStaticText(props: FormStaticTextProps) {
    const { caption, value } = props;
    return (
        <View>
            <Text.INPUT_FIELD_TITLE>{caption}</Text.INPUT_FIELD_TITLE>
            <Field>
                <Text.LEAD_1
                    style={{
                        width: '100%',
                        height: 28,
                        marginBottom: 4,
                        lineHeight: 24,
                        color: !value ? Color.TEXT_DISABLED : Color.ALMOST_BLACK
                    }}
                >
                    {isValidString(value) ? value : '—'}
                </Text.LEAD_1>
            </Field>
        </View>
    );
}

FormStaticText.displayName = 'FormStaticText';
