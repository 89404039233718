import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ReloadIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M5.38867 12.7168C5.39366 11.9622 5.521 11.1826 5.78345 10.4027C7.07081 6.57735 11.1214 4.1939 14.8308 5.07916C16.7682 5.54154 18.2293 6.8042 18.9997 8.45009"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                />
                <Path
                    d="M20.251 12C20.246 12.7546 20.1187 13.5342 19.8562 14.3141C18.5688 18.1394 14.5182 20.5229 10.8089 19.6376C8.87147 19.1753 7.41036 17.9126 6.63997 16.2667"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                />
                <Path
                    d="M16.5001 8.6369L19.602 8.63637L19.602 5.60299"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                    fill={Color.TRANSPARENT}
                    strokeLinecap="round"
                />
                <Path
                    d="M9.13952 16.0799L6.03767 16.0804L6.03767 19.1138"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                    fill={Color.TRANSPARENT}
                    strokeLinecap="round"
                />
            </Svg>
        </View>
    );
};
