import { useNavigation, CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Animated } from 'react-native';

import CompletedImage from '~/assets/images/completed.svg';
import { Button } from '~/components/button';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';

import { AnimatedLogoHeader } from '../home';

type OnboardingCompletedNavigation = CompositeNavigationProp<
    StackNavigationProp<AppNavigatorParamList>,
    StackNavigationProp<OnboardingNavigatorParamList, 'completed'>
>;

export const OnboardingCompleted = () => {
    const { reset } = useNavigation<OnboardingCompletedNavigation>();
    const { formatMessage } = useIntl();
    const { callName } = useUserProfile();

    const next = useCallback(() => reset({ index: 0, routes: [{ name: 'main' }] }), [reset]);

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <EllipticHeaderScreen
                renderEllipticMask
                renderTopContent={() => <CompletedImage />}
                renderBottomContent={() => (
                    <Flex>
                        <Text.TITLE>
                            <FormattedMessage id="onboarding-completed.hello" values={{ name: callName }} />
                        </Text.TITLE>
                        <Spacing.Vertical.MEDIUM />
                        <Text.PARAGRAPH_1>
                            <FormattedMessage id="onboarding-completed.description" />
                        </Text.PARAGRAPH_1>
                        <Spacing.Vertical.MASSIVE />
                    </Flex>
                )}
                renderActions={() => (
                    <Flex flexBasis="256" marginBottom={{ base: '16', md: '0' }}>
                        <Button
                            fillContainer
                            type="primary"
                            onPress={next}
                            label={formatMessage('onboarding-completed.completed')}
                        />
                    </Flex>
                )}
            />
        </>
    );
};
