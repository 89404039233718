import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Animated, TextInput } from 'react-native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ButtonForwardIcon } from '~/components/icon';
import { InputEmailText } from '~/components/input';
import { KeyboardShift } from '~/components/keyboard-shift';
import { ScreenContainerV2 } from '~/components/screen';
import { ScreenHeaderWithLogo } from '~/components/screen/screen-header-with-logo';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useAuth } from '~/contexts/auth';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { AppError } from '~/error';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { AnimatedLogoHeader } from '../home';

import { AuthInstruction } from './auth-instruction';

type AuthVerificationCheckNavigation = StackNavigationProp<AppNavigatorParamList>;

export const AuthVerificationCheck: React.FC = () => {
    const { formatMessage } = useIntl();
    const { canGoBack, navigate, goBack } = useNavigation<AuthVerificationCheckNavigation>();
    const { remote } = useAuth();
    const handleError = useErrorHandler();

    const inputRef = useRef<TextInput | null>(null);
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useFocusEffect(() => {
        inputRef.current?.focus();
    });

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    const handleAction = useCallback(async () => {
        if (email && !loading) {
            try {
                setLoading(true);

                if (await remote.eidentVerificationRequired(email)) {
                    navigate('authenticate');
                } else {
                    if (await remote.verifyEmail(email)) {
                        navigate('verification-email-sent');
                    } else {
                        handleError(
                            new AppError(new Error('Email verification failed'), 'auth-verification-check.error', {
                                name: 'error-overlay.go_back',
                                onClose: () => (canGoBack() ? goBack() : navigate('main'))
                            })
                        );
                    }
                }
            } finally {
                setLoading(false);
            }
        }
    }, [canGoBack, email, goBack, handleError, loading, navigate, remote]);

    const nextButton = (
        <Button
            alignCenter
            rightIcon={ButtonForwardIcon}
            disabled={email === '' || loading}
            loading={loading}
            type="primary"
            onPress={handleAction}
            label={formatMessage('auth-intro.button.title')}
        />
    );

    const emailInputElement = (
        <Flex alignSelf="stretch" paddingLeft={{ md: '14' }} paddingRight={{ md: '14' }}>
            <Spacing.Vertical.LARGE />

            <InputEmailText
                ref={inputRef}
                placeholder={formatMessage('auth-verification-check.placeholder')}
                onChangeText={nextEmail => setEmail(nextEmail)}
                style={{ minWidth: 320 }}
                // NOTE: Don't call handleAction on onEndEditing as it causes duplicate requests when the submit
                //       button is pressed. Also, a request would be fired when navigating using the back button.
            />
        </Flex>
    );

    return (
        <>
            {web ? (
                <>
                    <AnimatedLogoHeader offset={offset} />
                    <AuthInstruction
                        devMenuAccess
                        title={formatMessage('auth-verification-check.title')}
                        description={formatMessage('auth-verification-check.description')}
                        secondaryBottomContent={emailInputElement}
                        actionButton={nextButton}
                        backButtonLabel={formatMessage('previous')}
                        backOnButtonPress={() => (canGoBack() ? goBack() : navigate('main'))}
                    />
                </>
            ) : (
                <KeyboardShift>
                    <ScreenContainerV2
                        style={{
                            paddingHorizontal: Spacing.MEDIUM,
                            backgroundColor: Color.BACKGROUND_SECONDARY
                        }}
                    >
                        <ScreenHeaderWithLogo devMenuAccess />

                        <Text.H1 before={Spacing.LARGE} after={Spacing.LARGE}>
                            <FormattedMessage id="auth-verification-check.title" />
                        </Text.H1>

                        <Text.LEAD_2>
                            <FormattedMessage id="auth-verification-check.description" />
                        </Text.LEAD_2>

                        {emailInputElement}

                        <Spacing.Vertical.Fill />
                        {nextButton}
                    </ScreenContainerV2>
                </KeyboardShift>
            )}
        </>
    );
};
