import { decode } from 'base-64';

/**
 * NOTE: Doesn't validate the signature of the token! Just reads the payload from it.
 *
 * @param token:    URL-safe, hmac signed base64 compressed JSON string / access token (not to be confused with JWT)
 */
export const getTokenPayload = (token: string) => {
    try {
        const payload = token.split(':')[0];

        return JSON.parse(decode(payload));
    } catch (error) {
        throw new Error('Invalid token');
    }
};
