import { differenceInMinutes } from 'date-fns';
import React from 'react';

import { AppointmentBubble } from '~/components/appointment/appointment-bubble';
import { useCalendarEvent } from '~/hooks/calendar-event';

export type AppointmentNotificationProps = {
    calendarEventId: ID;
};

export const AppointmentNotification = (props: AppointmentNotificationProps) => {
    const { calendarEventId } = props;
    const { calendarEvent } = useCalendarEvent(calendarEventId);

    return calendarEvent ? (
        <AppointmentBubble
            date={calendarEvent.start}
            contactType={calendarEvent.type}
            duration={differenceInMinutes(calendarEvent.end, calendarEvent.start)}
            staffName={
                calendarEvent.user
                    ? `${calendarEvent.user.firstName} ${calendarEvent.user.lastName}`
                    : calendarEvent.calendarName
            }
            staffTitle={calendarEvent.user?.title!}
            staffAvatarUrl={calendarEvent.user?.avatarSmallUrl!}
        />
    ) : null;
};
