import React from 'react';

import { ChevronRightIcon } from '~/components/icon';

import { Button } from './button';
import { OvalButton } from './oval-button';

type MessageHeaderButtonProps = {
    buttonText?: string;
    onPress: () => void;
};

export const MessageHeaderButton: React.FC<MessageHeaderButtonProps> = ({ buttonText, onPress }) => {
    return buttonText ? (
        <Button size="small" onPress={onPress} label={buttonText} />
    ) : (
        <OvalButton size="small" onPress={onPress} icon={ChevronRightIcon} />
    );
};
