import React from 'react';
import styled from 'styled-components/native';

import { BubbleLines } from '~/components/bubble-lines';
import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { Task, TaskColorScheme } from '~/components/task';
import { Text } from '~/components/text';

export type InteractionTheme = 'blue';

type InteractionThemeType = {
    bubbleBgColor: Color;
    textColor: Color;
    labelScheme: TaskColorScheme;
    linesColor: Color;
};

const interactionThemes: { [key in InteractionTheme]: InteractionThemeType } = {
    blue: {
        bubbleBgColor: Color.TASK_DEFAULT,
        textColor: Color.TEXT_TERTIARY,
        labelScheme: 'active',
        linesColor: Color.BACKGROUND_TERTIARY
    }
};

type InteractionMessageProps = {
    label?: string;
    title?: string;
    description?: string;
    actionLabel: string;
    theme: InteractionTheme;
    onPress: () => Promise<void>;
};

const InteractionMessageContainer = styled.View<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    flex-direction: column;
    border-radius: 16px;
    align-items: flex-start;
    padding: 16px;
    flex: 1 1 auto;
`;

const LinesContainer = styled.View`
    position: absolute;
    width: 200px;
    height: 24px;
    bottom: 0px;
    right: 0px;
`;

const ButtonContainer = styled.View`
    width: 60%;
`;

export const InteractionMessage = (props: InteractionMessageProps) => {
    const { label, title, description, actionLabel, onPress, theme } = props;
    const messageTheme = interactionThemes[theme];
    return (
        <InteractionMessageContainer backgroundColor={messageTheme.bubbleBgColor}>
            <LinesContainer>
                <BubbleLines fill={messageTheme.linesColor} />
            </LinesContainer>
            {label && <Task text={label} scheme={messageTheme.labelScheme} />}
            {title && (
                <Text.HEADER_3 style={{ color: messageTheme.textColor }} before={Spacing.SMALL} after={Spacing.SMALL}>
                    {title}
                </Text.HEADER_3>
            )}
            {description && (
                <Text.PARAGRAPH_2 style={{ color: messageTheme.textColor }} after={Spacing.LARGE}>
                    {description}
                </Text.PARAGRAPH_2>
            )}
            <ButtonContainer>
                <Button label={actionLabel} shape="rounded" size="regular" fillContainer onPress={onPress} />
            </ButtonContainer>
        </InteractionMessageContainer>
    );
};
