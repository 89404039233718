/*
 * Configuration for deep links
 *
 * Test with simulator by running `xcrun simctl openurl booted <url>`
 *
 * Open home tab
 *  heltti:///open/home
 *
 * Open services tab
 *  heltti:///open/services
 *
 * Open profile tab
 *  heltti:///open/profile
 *
 * Open profile edit
 *  heltti:///open/profile/edit
 *
 * Open appointment reservation (by calendar reservation access id)
 *  heltti:///open/appointment-reservations/Q2FsZW5kYXJSZXNlcnZhdGlvbkFjY2Vzc05vZGU6NA==
 *
 * Open appointment (by calendar event id)
 *  heltti:///open/appointments/Q2FsZW5kYXJSZXNlcnZhdGlvbkFjY2Vzc05vZGU6NA==
 *
 * Open article (by article id)
 *  heltti:///open/articles/QXJ0aWNsZU5vZGU6Mzc=
 *
 * Open chat (by chat id and optional message id to scroll to)
 *  heltti:///open/chats/Q2hhdE5vZGU6Mg==
 *  heltti:///open/chats/Q2hhdE5vZGU6Mg==/Q2hhdE1lc3NhZ2VOb2RlOjU=
 *
 * Unauthenticated
 *
 * Open authentication
 *  heltti:///open/authenticate
 *
 * Open email signup with token
 *  heltti:///open/email-signup/token
 *
 * Open PIN authentication
 *  heltti://open/pin
 *
 * Open SMS authentication
 *  heltti://open/sms-authentication
 *
 * Open strong authentication flow
 *  heltti://open/verify
 *
 * Open developer screen
 *  heltti://open/developer
 *
 * Download referral document
 *  heltti://open/referrals
 *
 */
export function getLinkingConfig(authenticated: boolean) {
    return authenticated
        ? {
              screens: {
                  main: {
                      path: 'open',
                      screens: {
                          tabs: {
                              path: '',
                              screens: {
                                  home: 'home',
                                  services: 'services',
                                  library: 'library',
                                  profile: {
                                      path: 'profile',
                                      screens: {
                                          'profile-edit': 'edit'
                                      }
                                  }
                              }
                          },
                          appointment: 'appointment/:calendarEventId',
                          'appointment-preview': 'appointment-preview/:appointment',
                          appointments: 'appointment-reservations/:calendarReservationAccessId',
                          'account-removal': 'account-removal',
                          'calendar-events': 'calendar-events',
                          'calendar-reservation-accesses': 'calendar-reservation-accesses',
                          documents: 'documents',
                          document: 'document/:documentId',
                          questionnaires: 'questionnaires',
                          'video-call': 'video-call/:url',
                          'staff-profile': 'staff-profile/:user',
                          chats: 'chats',
                          'chat-archive': 'chat-archive',
                          'contact-reason': 'contact-reason/:title/:actionType/:description',
                          'profile-edit': 'profile-edit',
                          'sick-leave': 'sick-leave',
                          'sick-leave-calendar': 'sick-leave-calendar',
                          'appointment-confirmed': 'appointments/:calendarEventId',
                          article: 'articles/:id',
                          faq: 'faq',
                          chat: 'chats/:chatId/:messageId?',
                          'new-contact': 'new-contact',
                          'questionnaire-response-old': 'questionnaire/:questionnaireResponseId', // old helmut analysis chat messages use this format
                          'questionnaire-response': 'questionnaire-response/:questionnaireResponseId'
                      }
                  },
                  'nps-company-event': '/company_event_nps/:npsUUID/:scoreDetail1?',
                  'nps-member': '/nps/:npsUUID/:scoreDetail1?',
                  'nps-mindy': '/mindyNPS/:npsUUID/:scoreDetail1?',
                  'external-page': '*'
              }
          }
        : {
              screens: {
                  'email-signup-intro': 'open/email-signup/:token',
                  'sms-authentication': 'open/sms-authentication/:token',
                  authenticate: 'open/authenticate',
                  'pin-authenticate': 'open/pin',
                  verify: 'open/verify',
                  developer: 'open/developer',

                  // Web support
                  'eident-verify': '/e-ident',
                  'eident-error': '/e-ident/error',
                  'nps-company-event': '/company_event_nps/:npsUUID/:scoreDetail1?',
                  'nps-member': '/nps/:npsUUID/:scoreDetail1?',
                  'nps-mindy': '/mindyNPS/:npsUUID/:scoreDetail1?',
                  'download-referral': '/referrals'
              }
          };
}
