import { Flex, Slider as NativeBaseSlider } from 'native-base';
import React from 'react';

import { Text } from '~/components/text';

export type SliderProps = {
    minValue: number;
    maxValue: number;
    initialValue: number;
    minValueDescription: string;
    maxValueDescription: string;
    onChange: (newValue: number) => void;
};

export const Slider = (props: SliderProps) => {
    const { minValue, maxValue, initialValue, minValueDescription, maxValueDescription, onChange } = props;

    return (
        <Flex direction="column">
            <Flex direction="row" justifyContent="space-between" alignItems="flex-end" mb="2">
                <Flex basis="0" grow="1">
                    <Text.INPUT_FIELD_TITLE>{minValueDescription}</Text.INPUT_FIELD_TITLE>
                </Flex>
                <Flex basis="0" grow="1" direction="column" alignItems="flex-end">
                    <Text.INPUT_FIELD_TITLE>{maxValueDescription}</Text.INPUT_FIELD_TITLE>
                </Flex>
            </Flex>
            <NativeBaseSlider
                minValue={minValue}
                maxValue={maxValue}
                defaultValue={initialValue}
                onChange={onChange}
                colorScheme="primaryDefault"
            >
                <NativeBaseSlider.Track>
                    <NativeBaseSlider.FilledTrack />
                </NativeBaseSlider.Track>
                <NativeBaseSlider.Thumb />
            </NativeBaseSlider>
        </Flex>
    );
};
