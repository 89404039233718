import React from 'react';

import { ErrorModal } from '~/components/error';
import { useIntl } from '~/contexts/intl';
import { NetworkServiceReachability } from '~/contexts/network-service-state';

import { BannerNotification } from '../banner-notification';

export type NetworkServiceNotificationProps = { reachability?: NetworkServiceReachability; latency?: number };

export function NetworkServiceNotification(props: NetworkServiceNotificationProps) {
    const { reachability, latency } = props;

    const { formatMessage } = useIntl();

    let message = formatMessage('network-state.poor-connection');

    if (__DEV__ && latency) {
        message += ` - ${Math.round(latency)} ms`;
    }

    return (
        <>
            {reachability === 'service-unavailable' && <ErrorModal errorType="connection" />}
            {reachability === 'service-degraded' && <BannerNotification type="warning" text={message} />}
        </>
    );
}
