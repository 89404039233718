import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/native';

import { BubbleLines } from '~/components/bubble-lines';
import { OvalButton } from '~/components/button';
import { Color } from '~/components/color';
import { CloseIcon } from '~/components/icon';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';

type NotificationFloatingType = 'primary' | 'secondary';

export type NotificationFloatingProps = {
    type: NotificationFloatingType;
    title?: string;
    closable?: boolean;
    messages: string | string[];
    button?: ReactNode;
};

const Container = styled.View`
    flex-direction: row;
    border-radius: 16px;
    min-height: 100px;
    margin-top: ${Spacing.SMALL}px;
`;

const Content = styled.View`
    flex: 1;
    padding: 16px;
`;

const CloseButton = styled(OvalButton).attrs({ icon: CloseIcon, size: 'small' })`
    margin-top: 8px;
    margin-right: 8px;
`;

const Decoration = styled.View`
    position: absolute;
    bottom: 0px;
    right: 0px;
`;

const BACKGROUND_COLOR = {
    primary: Color.BACKGROUND_DEFAULT,
    secondary: Color.BACKGROUND_PRIMARY
};

const FOREGROUND_COLOR = {
    primary: Color.BACKGROUND_SECONDARY,
    secondary: Color.BACKGROUND_DEFAULT
};

export function NotificationFloating(props: NotificationFloatingProps) {
    const { type, title, messages, button, closable = true } = props;
    const [visible, setVisible] = useState<boolean>(true);

    const renderContent = () => {
        switch (type) {
            case 'primary':
                throw new Error('Unimplemented');

            case 'secondary': {
                return (
                    <>
                        <Text.INPUT_FIELD_TITLE after={4}>{title}</Text.INPUT_FIELD_TITLE>
                        {Array.isArray(messages) ? (
                            messages.map((message, index) => (
                                <Text.CAPTION key={index} after={4}>
                                    {message}
                                </Text.CAPTION>
                            ))
                        ) : (
                            <Text.CAPTION after={4}>{messages}</Text.CAPTION>
                        )}
                        {button}
                    </>
                );
            }
        }
    };

    return visible ? (
        <Container style={[Shadow.styles.primary, { backgroundColor: BACKGROUND_COLOR[type] }]}>
            <Content>{renderContent()}</Content>
            <CloseButton disabled={!closable} onPress={() => setVisible(false)} />
            <Decoration>
                <BubbleLines fill={FOREGROUND_COLOR[type]} />
            </Decoration>
        </Container>
    ) : null;
}
