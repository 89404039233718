import { decode } from 'base-64';
import React from 'react';

import { AppointmentNotification } from '~/components/appointment/appointment-notification';

export type ChatIdAttachmentProps = {
    attachmentId: ID;
};

export const ChatIdAttachment = (props: ChatIdAttachmentProps) => {
    const { attachmentId } = props;
    const resourceName = decode(attachmentId).split(':')[0];

    switch (resourceName) {
        case 'CalendarEventNode':
            return <AppointmentNotification calendarEventId={attachmentId} />;
        default:
            throw new Error(`unknown resource ${resourceName}`);
    }
};
