import { useMutation } from '@apollo/client';
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Animated, View } from 'react-native';
import styled from 'styled-components/native';

import CommunicationPermissionsImage from '~/assets/images/communication-permissions.svg';
import { Button } from '~/components/button';
import { CheckBox, CheckGroup } from '~/components/check';
import { ButtonForwardIcon } from '~/components/icon';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { OnboardingNavigatorParamList } from '~/navigator/onboarding-navigator';
import { AcceptTermsDocument } from '~/types';

import { AnimatedLogoHeader } from '../home';

const Title = styled(Text.TITLE)`
    text-align: center;
`;

const Description = styled(Text.P1)`
    text-align: center;
`;

const PermissionTitle = styled(Text.H3)`
    text-align: left;
`;

type OnboardingAcceptTermsNavigation = CompositeNavigationProp<
    StackNavigationProp<OnboardingNavigatorParamList, 'communication-permissions'>,
    StackNavigationProp<AppNavigatorParamList>
>;

export const OnboardingCommunicationPermissions = () => {
    const { reset } = useNavigation<OnboardingAcceptTermsNavigation>();
    const { nextTopic } = useOnboardingTopics();
    const { formatMessage } = useIntl();
    const handleError = useErrorHandler();

    const [acceptTerms] = useMutation(AcceptTermsDocument);
    const [marketingAccepted, setMarketingAccepted] = useState<boolean>(true);
    const [memberCommunicationAccepted, setMemberCommunicationAccepted] = useState<boolean>(true);

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const offset = useRef(new Animated.Value(0)).current;

    const handleAcceptTerms = useCallback(async () => {
        try {
            const { data } = await acceptTerms({
                variables: {
                    input: {
                        termsAccepted: true,
                        marketingAccepted,
                        memberCommunicationAccepted
                    }
                }
            });

            if (data?.acceptTerms?.termsAccepted) {
                reset({ index: 0, routes: [{ name: nextTopic('communication-permissions') ?? 'tabs' }] });
            }
        } catch (error: unknown) {
            handleError(error);
        }
    }, [acceptTerms, handleError, marketingAccepted, memberCommunicationAccepted, nextTopic, reset]);

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <EllipticHeaderScreen
                renderEllipticMask
                renderTopContent={() => (
                    <>
                        {web ? (
                            <CommunicationPermissionsImage width="252" height="252" viewBox="0 0 350 350" />
                        ) : (
                            <CommunicationPermissionsImage />
                        )}
                    </>
                )}
                topContentHeight="240"
                renderBottomContent={() => (
                    <View>
                        <Spacing.Vertical.SMALL />
                        <Title>
                            <FormattedMessage id="onboarding-terms.title" />
                        </Title>

                        <Spacing.Vertical.LARGE />

                        <Description>
                            <FormattedMessage id="onboarding-terms.description" />
                        </Description>

                        <Spacing.Vertical.LARGE />

                        <PermissionTitle>
                            <FormattedMessage id="onboarding-terms.newsletters" />
                        </PermissionTitle>

                        <CheckGroup>
                            <CheckBox
                                selected={memberCommunicationAccepted}
                                onPress={() => setMemberCommunicationAccepted(!memberCommunicationAccepted)}
                                label={formatMessage('onboarding-terms.accept-communications.label')}
                                helper={formatMessage('onboarding-terms.accept-communications.helper')}
                            />
                            <CheckBox
                                selected={marketingAccepted}
                                onPress={() => setMarketingAccepted(!marketingAccepted)}
                                label={formatMessage('onboarding-terms.accept-marketing.label')}
                                helper={formatMessage('onboarding-terms.accept-marketing.helper')}
                            />
                        </CheckGroup>
                    </View>
                )}
                renderActions={() => (
                    <Flex flexBasis="256" marginBottom={{ base: '16', md: '0' }}>
                        <Button
                            fillContainer
                            alignCenter
                            type="primary"
                            onPress={handleAcceptTerms}
                            label={formatMessage('next')}
                            rightIcon={ButtonForwardIcon}
                        />
                    </Flex>
                )}
            />
        </>
    );
};
