import React from 'react';
import styled from 'styled-components/native';

import { Loader } from './loader';

const LoadingContentContainer = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const LoadingContent = () => {
    return (
        <LoadingContentContainer>
            <Loader size="medium" />
        </LoadingContentContainer>
    );
};
