import React from 'react';
import { View } from 'react-native';
import { Svg, Path, Rect } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const CalendarIcon = ({ fill = Color.ALMOST_BLACK, highlight = Color.CHERRY, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" fill="none" focusable={false} tabIndex="-1">
                <Rect x="7" y="2" width="2" height="5" rx="1" fill={fill} />
                <Rect x="15" y="2" width="2" height="5" rx="1" fill={fill} />
                <Rect x="11" y="2" width="2" height="5" rx="1" fill={fill} />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 3.95703C3.27477 4.40107 2 5.96717 2 7.83101V17.0486C2 19.2578 3.79086 21.0486 6 21.0486H18C20.2091 21.0486 22 19.2578 22 17.0486V7.83101C22 5.96717 20.7252 4.40107 19 3.95703V6.09858C19.5978 6.44439 20 7.09073 20 7.83101V17.0486C20 18.1532 19.1046 19.0486 18 19.0486H6C4.89543 19.0486 4 18.1532 4 17.0486V7.83101C4 7.09073 4.4022 6.44439 5 6.09858V3.95703Z"
                    fill={fill}
                />
                <Path d="M16 10L8 10" stroke={highlight} strokeWidth="2" strokeLinecap="round" />
            </Svg>
        </View>
    );
};
