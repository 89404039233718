import { useDimensions } from '@react-native-community/hooks';
import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Alert } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HeaderBackButton } from '~/components/header';
import { CalendarIcon } from '~/components/icon';
import { IconButton } from '~/components/icon-button';
import { InputDateText, InputSelectNumber, InputText } from '~/components/input';
import { KeyboardShift } from '~/components/keyboard-shift';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { AppError } from '~/error';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { useRoute } from '~/hooks/route/route';
import { useReportSickLeave } from '~/hooks/sick-leave';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { toISODateString } from '~/utils/date';

type SickLeaveNavigation = StackNavigationProp<MainNavigatorParamList, 'sick-leave'>;

export type SickLeaveRouteProp = RouteProp<MainNavigatorParamList, 'sick-leave'>;

export function SickLeave() {
    const scrollViewRef = useRef<Animated.ScrollView | null>(null);
    const { navigate, setOptions, canGoBack, goBack } = useNavigation<SickLeaveNavigation>();
    const { bottom } = useSafeAreaInsets();
    const {
        params: { date: initialDate }
    } = useRoute<SickLeaveRouteProp>();
    const headerHeight = useHeaderHeight();
    const { sickLeaveDays, reportSickLeave } = useReportSickLeave();
    const { formatMessage } = useIntl();
    const handleError = useErrorHandler();
    const { window } = useDimensions();

    const [date, setDate] = useState<ISODate>();
    const [days, setDays] = useState<number>();
    const [reason, setReason] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const { onScroll } = useExtendedNavigationHeader({
        offset: 0,
        range: 60,
        title: formatMessage('sick-leave.title'),
        headerLeft: <HeaderBackButton />
    });

    useLayoutEffect(() => {
        setOptions({
            title: '',
            headerShown: true,
            headerTransparent: true
        });
    });

    useEffect(() => {
        if (initialDate) {
            setDate(initialDate);
        } else {
            setDate(toISODateString(new Date()));
        }
    }, [initialDate]);

    const DateSelect = () => (
        <IconButton
            icon={CalendarIcon}
            onPress={() => navigate('sick-leave-calendar', { date })}
            layoutStyle={{ marginHorizontal: -2 }}
        />
    );

    const handleSubmit = useCallback(async () => {
        if (!loading && date && days) {
            try {
                setLoading(true);
                const { data } = await reportSickLeave({
                    variables: {
                        input: {
                            sickLeaveStart: date,
                            sickLeaveDays: days,
                            sickLeaveReason: reason
                        }
                    }
                });
                setLoading(false);
                if (data?.reportSickLeave?.sickLeave?.id) {
                    Alert.alert(
                        formatMessage('sick-leave.submit-confirmation.title'),
                        formatMessage('sick-leave.submit-confirmation.description'),
                        [
                            {
                                text: formatMessage('sick-leave.submit-confirmation.continue'),
                                onPress: () => (canGoBack() ? goBack() : navigate('services'))
                            }
                        ]
                    );
                }
            } catch (error: unknown) {
                if (error instanceof Error) {
                    handleError(
                        new AppError(error, 'sick-leave.error.cannot-report', {
                            name: 'error-overlay.go_back',
                            onClose: () => (canGoBack() ? goBack() : navigate('services'))
                        })
                    );
                } else {
                    handleError(error);
                }
            }
        }
    }, [loading, date, days, reportSickLeave, reason, formatMessage, canGoBack, goBack, navigate, handleError]);

    // @ts-ignore
    const handleScroll = useCallback(() => scrollViewRef?.current?.scrollToEnd(), []);

    const isDesktop: boolean = useBreakpointValue({ base: false, md: true });

    return (
        <ScrollableScreenContainer>
            <KeyboardShift>
                <ContentScrollView
                    // 🤬 Jest fails with `ref={scrollViewRef}`, assign via ref function
                    ref={(r: Animated.ScrollView) => (scrollViewRef.current = r)}
                    contentContainerStyle={{ height: window.height, paddingBottom: bottom }}
                    showsVerticalScrollIndicator={false}
                    onScroll={onScroll}
                    bounces
                    scrollEventThrottle={16}
                    overScrollMode="never"
                >
                    <ContentHorizontalMargins contentWidth="narrow" includePadding={isDesktop}>
                        <BackgroundContainer>
                            <Background />
                        </BackgroundContainer>
                        <Flex
                            flexGrow={1}
                            mx={4}
                            pt={{ base: `${headerHeight}px`, md: `${headerHeight + Spacing.LARGE}px` }}
                            pb={Spacing.MEDIUM}
                        >
                            <Text.TITLE>
                                <FormattedMessage id="sick-leave.title" />
                            </Text.TITLE>
                            <Spacing.Vertical.LARGE />
                            <Text.PARAGRAPH_1>
                                <FormattedMessage id="sick-leave.description" />
                            </Text.PARAGRAPH_1>
                            <Spacing.Vertical.LARGE />
                            <InputDateText
                                caption={formatMessage('sick-leave.first-date')}
                                value={date}
                                onChangeDate={setDate}
                                accessory={<DateSelect />}
                            />
                            <Spacing.Vertical.LARGE />
                            <NegativeMarginContainer>
                                <InputSelectNumber
                                    caption={formatMessage('sick-leave.day-count')}
                                    options={sickLeaveDays}
                                    selectedOption={days}
                                    onSelectOption={setDays}
                                />
                            </NegativeMarginContainer>
                            <Spacing.Vertical.LARGE />
                            <InputText
                                stretch
                                numberOfLines={3}
                                caption={formatMessage('sick-leave.reason')}
                                value={reason}
                                onChangeText={setReason}
                                onFocus={handleScroll}
                                style={{ height: 100 }}
                            />
                            <Spacing.Vertical.LARGE />
                            <Button
                                loading={loading}
                                disabled={!date || !days || loading}
                                type="primary"
                                label={formatMessage('sick-leave.submit')}
                                onPress={handleSubmit}
                            />
                        </Flex>
                    </ContentHorizontalMargins>
                </ContentScrollView>
            </KeyboardShift>
        </ScrollableScreenContainer>
    );
}

const ScrollableScreenContainer = styled.View`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const BackgroundContainer = styled(EllipticMaskView).attrs({ ellipseSize: 'regular' })`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60%;
`;

const Background = styled.View`
    background-color: ${Color.SUBMARINE_LIGHT};
    flex: 1;
    width: 100%;
`;

const NegativeMarginContainer = styled.View`
    margin-left: -16px;
    margin-right: -16px;
`;
