import * as Linking from 'expo-linking';
import React, { useCallback, useState } from 'react';
import { Platform, View } from 'react-native';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { HelperButton } from '~/components/button/helper-button';
import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { ArrowSwipeUpIcon } from '~/components/icon';
import { SCREEN_WIDTH_RATIO } from '~/components/screen';
import { Text } from '~/components/text';
import { useAnalyticsEventEffect } from '~/contexts/analytics';
import { useAppUpdate } from '~/contexts/app-update';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { isNative } from '~/utils/platform';

import AppUpdateImage from './assets/app-update.svg';

export const ScreenContainer = styled(View)`
    flex: 1;
    flex-direction: column-reverse;
    width: 100%;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const Content = styled.View<{ bottomInset: number }>`
    padding-bottom: ${({ bottomInset }) => 24 + bottomInset}px;
    padding-horizontal: 32px;
    align-items: center;
    justify-content: space-around;
`;

const ImageContent = styled.View`
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${Color.SMOOTHIE_LIGHT};
`;

const ButtonContent = styled.View`
    flex-direction: row;
`;

const SupportContent = styled.View`
    margin-top: 24px;
    width: 100%;
    justify-content: center;
`;

const Link = styled(Text.SMALL_BUTTON_LABEL)`
    text-decoration: underline;
`;

export function AppUpdateModal() {
    const { state, update } = useAppUpdate();
    const { formatMessage } = useIntl();
    const { bottom } = useSafeAreaInsets();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOpenSupportUrl = useCallback(async () => {
        const url = formatMessage('app-update.required.support.url');
        if (await Linking.canOpenURL(url)) {
            await Linking.openURL(url);
        }
    }, [formatMessage]);

    const handleUpdate = useCallback(async () => {
        try {
            setLoading(true);
            await update();
        } finally {
            setLoading(false);
        }
    }, [update]);

    useAnalyticsEventEffect({ type: 'app-update', action: 'available', detail1: `state=${state}` }, []);

    return state.match(/(app-store|ota)-(update|rollback)-required/) ? (
        <Modal
            isVisible
            hasBackdrop
            backdropColor={Color.BACKGROUND_TERTIARY}
            backdropOpacity={1}
            animationIn="slideInUp"
            animationOut="slideOutDown"
            useNativeDriverForBackdrop={isNative()}
            coverScreen
            presentationStyle="overFullScreen"
            style={{ margin: 0 }}
        >
            <ScreenContainer>
                <Content bottomInset={bottom}>
                    <Text.HEADER_2 before={24} style={{ textAlign: 'center' }}>
                        <FormattedMessage id="app-update.required.title" />
                    </Text.HEADER_2>
                    <Text.PARAGRAPH_1 before={24} after={24}>
                        <FormattedMessage id="app-update.required.description" />
                    </Text.PARAGRAPH_1>
                    <ButtonContent>
                        <Button
                            fillContainer
                            loading={loading}
                            type="primary"
                            label={formatMessage(
                                state === 'app-store-update-required'
                                    ? 'app-update.required.app-store'
                                    : 'app-update.required.ota',
                                { platform: Platform.OS }
                            )}
                            rightIcon={ArrowSwipeUpIcon}
                            onPress={handleUpdate}
                        />
                    </ButtonContent>

                    <SupportContent>
                        <HelperButton
                            title={
                                <FormattedMessage
                                    id="app-update.required.support"
                                    values={{
                                        link: (value: string) => <Link onPress={handleOpenSupportUrl}>{value}</Link>
                                    }}
                                />
                            }
                        />
                    </SupportContent>
                </Content>

                <EllipticMaskView style={{ flex: 1 }}>
                    <ImageContent>
                        <AppUpdateImage width={SCREEN_WIDTH_RATIO * 400} />
                    </ImageContent>
                </EllipticMaskView>
            </ScreenContainer>
        </Modal>
    ) : null;
}
