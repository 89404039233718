import { useQuery } from '@apollo/client';

import { CalendarReservationAccessListDocument, GlobalCalendarReservationAccessListDocument } from '~/types';

export function useCalendarReservationAccessList() {
    const { data, error, loading } = useQuery(CalendarReservationAccessListDocument, { fetchPolicy: 'network-only' });

    const calendarReservationAccesses = data?.root?.calendarReservationAccesses;

    return { loading, error, calendarReservationAccesses };
}

export function useGlobalCalendarReservationAccessList() {
    const { data, error, loading } = useQuery(GlobalCalendarReservationAccessListDocument, {
        fetchPolicy: 'network-only'
    });

    const globalCalendarReservationAccesses = data?.root?.globalCalendarReservationAccesses;

    return { loading, error, globalCalendarReservationAccesses };
}
