import { isString } from '~/utils';

export type EmbeddedYouTubeAttributes = {
    v: string; // YouTube video id
};

export type EmbeddedYouTubeVideoProps = {
    content?: string;
    attributes: EmbeddedYouTubeAttributes;
};

export function isEmbeddedYouTubeAttributes(attrs: Record<string, unknown>): attrs is EmbeddedYouTubeAttributes {
    return 'v' in attrs && isString(attrs.v);
}
