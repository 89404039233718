import React, { PropsWithChildren } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';

import { isWeb } from '~/utils';

// See https://chrisfrewin.medium.com/a-keyboard-avoiding-view-for-react-native-in-2021-196701ff0608

type KeyboardShiftProps = {
    headerHeight?: number;
} & PropsWithChildren<object>;

export function KeyboardShift(props: KeyboardShiftProps) {
    const { headerHeight = 0 } = props;

    const { children } = props;

    // Android: System support for soft input resize seems to work for our views
    if (Platform.OS === 'android' || isWeb()) {
        return <>{children}</>;
    }

    // iOS: React Native's KeyboardAvoidingView with header offset and
    // behavior 'padding' works fine on all ios devices (and keyboard types)
    return (
        <KeyboardAvoidingView keyboardVerticalOffset={headerHeight} style={styles.container} behavior="padding">
            {children}
        </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%'
    }
});
