import { PinCode } from '~/contexts/auth';

export const INPUT_EMPTY = '';

export const PIN_ENTRY_DELAY = 300;
export const PIN_CODE_LENGTH = 4;

export const REFERENCE_INVALID = 'zap!'; // Any string that fails to match a numeric pin code is OK here

export enum DisplayState {
    EMPTY = 'empty',
    VALID = 'valid',
    INVALID = 'invalid'
}

export function inputToDisplayStates(input: PinCode, length: number, reference?: string) {
    const states = input.split('').map((digit, index: number) => {
        const ref = reference?.[index];
        return ref === undefined ? DisplayState.VALID : ref === digit ? DisplayState.VALID : DisplayState.INVALID;
    });
    return Array.from({ length }, (_, i) => states?.[i] ?? DisplayState.EMPTY);
}
