import { useMutation } from '@apollo/client';
import * as Linking from 'expo-linking';
import { useState } from 'react';

import { useIntl } from '~/contexts/intl';
import { ChatListDocument, ChatNotificationActionFragment, EscalateToLiveChatMutationDocument } from '~/types';
import { valueForKey } from '~/utils/keyvalue';

const labelKeys = {
    CALL: 'chat-notification-action.label.call',
    ESCALATE: 'chat-notification-action.label.escalate'
} as const;

type ActionProps = {
    action: ChatNotificationActionFragment;
    chatId: ID;
};

export const useAction = (props: ActionProps) => {
    const { action, chatId } = props;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [escalateToLiveChat] = useMutation(EscalateToLiveChatMutationDocument);

    const escalateChat = async () => {
        setLoading(true);
        try {
            await escalateToLiveChat({
                variables: { input: { chatId } },
                refetchQueries: [{ query: ChatListDocument }]
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Error escalating to live chat ${err}`);
        }
        setLoading(false);
    };

    const onAction = () => {
        switch (action.type) {
            case 'CALL': {
                const phone = valueForKey<string>(action.params ?? null, 'phone');
                if (phone) {
                    Linking.openURL(`tel:${phone}`);
                }
                break;
            }
            case 'ESCALATE': {
                escalateChat();
                break;
            }
        }
    };

    const result = {
        onAction,
        loading,
        actionLabel: formatMessage(labelKeys[action.type])
    };

    return result;
};
