import { useLayout } from '@react-native-community/hooks';
import { Flex } from 'native-base';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleProp, ViewStyle } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type SegmentedControlProps = {
    values: string[];
    selectedIndex: number;
    onSetSelectedIndex: (index: number) => void;
    style?: StyleProp<ViewStyle>;
};

type SegmentedControlContainerProps = {
    onLayout?: (event: LayoutChangeEvent) => void;
    style?: StyleProp<ViewStyle>;
} & PropsWithChildren;

const SegmentedControlContainer: React.FC<SegmentedControlContainerProps> = ({ children, onLayout, style }) => (
    <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="8"
        borderRadius="4"
        borderWidth="1px"
        borderColor={Color.LINE_DEFAULT}
        backgroundColor={Color.BACKGROUND_PRIMARY}
        onLayout={onLayout}
    >
        {children}
    </Flex>
);

const Segment = styled.TouchableOpacity`
    z-index: 2;
    flex: 1;
    align-items: center;
    border-width: 0px;
    margin-horizontal: 2px;
`;

const Slider = styled(Animated.View)`
    z-index: 1;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 16px;
    background-color: ${Color.BASIC_DEFAULT};
    border-color: ${Color.LINE_DEFAULT};
    border-width: 1px;
    height: 32px;
`;

const SegmentTitle = styled(Text.SMALL_BUTTON_LABEL)<{ selected: boolean }>`
    color: ${({ selected }) => (selected ? Color.FOCUS_DEFAULT : Color.TEXT_SECONDARY)};
`;

export function SegmentedControl(props: SegmentedControlProps) {
    const { onLayout, width } = useLayout();
    const { values, selectedIndex, onSetSelectedIndex, style } = props;
    const [sliderWidth, setSliderWidth] = useState<number>();

    useEffect(() => {
        const newSliderWidth = width / values.length;
        if (width && newSliderWidth !== sliderWidth) {
            setSliderWidth(newSliderWidth);
        }
    }, [width, values.length, sliderWidth]);

    const position = useDerivedValue(
        () => width * (selectedIndex / values.length),
        [selectedIndex, width, values.length]
    );

    const sliderAnimatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: withTiming(position.value, { duration: 250, easing: Easing.inOut(Easing.ease) })
            }
        ]
    }));

    // Dirty state and default slider style is needed to properly initialize the component with initial
    // non-zero selected index. Otherwise, the initial selection will animate.

    const [dirty, setDirty] = useState<boolean>(false);
    const defaultSliderStyle = useMemo(
        () => ({
            transform: [
                {
                    translateX: width * (selectedIndex / values.length)
                }
            ]
        }),
        [selectedIndex, values.length, width]
    );

    const handleSelectIndex = (nextIndex: number) => {
        setDirty(true);
        onSetSelectedIndex(nextIndex);
    };

    return (
        <SegmentedControlContainer onLayout={onLayout} style={style}>
            <Slider style={[{ width: sliderWidth }, dirty ? sliderAnimatedStyle : defaultSliderStyle]} />
            {values.map((value: string, segmentIndex) => (
                <Segment
                    key={value}
                    accessibilityRole="button"
                    accessibilityLabel={value}
                    disabled={segmentIndex === selectedIndex}
                    onPress={() => handleSelectIndex(segmentIndex)}
                >
                    <SegmentTitle selected={segmentIndex === selectedIndex}>{value}</SegmentTitle>
                </Segment>
            ))}
        </SegmentedControlContainer>
    );
}
