export const fi = {
    ok: 'OK',
    cancel: 'Peruuta',
    close: 'Sulje',
    edit: 'Muokkaa',
    select: 'Valitse',
    start: 'Aloita',
    'start-again': 'Aloita alusta',
    next: 'Seuraava',
    previous: 'Takaisin',
    goBack: 'Takaisin',
    goBackToStart: 'Takaisin Alkuun',
    'continue-later': 'Jatka myöhemmin',
    error: 'Virhe',
    loading: 'Ladataan',
    show: 'Näytä',
    formattedDate: '{date, date, ::dd.MM.yyyy}',
    'time.duration': '{duration}min',
    'time.empty': '–',
    'time.at': 'klo',
    'time.now': 'nyt',
    'time.minutes_ago': '{minutes}min sitten',
    'time.hours_ago': '{hours}h sitten',
    'time.yesterday': 'eilen',
    'error.close.reset': 'Aloita alusta',
    'error.cannot-load-member-actions': 'Yhteydenottotapojen lataus epäonnistui',
    'error.cannot-add-message': 'Viestin lähetys epäonnistui',
    'error.cannot-load-messages': 'Viestien lataus epäonnistui',
    'error.cannot-download-attachment': 'Liitteen lataus epäonnistui',
    'error.cannot-create-chat': 'Chatin luonti epäonnistui',
    'error.chat-attachment-upload-failed': 'Tiedoston lataus epäonnistui',
    'error.make-appointment-failed': 'Ajanvaraus epäonnistui',
    'error.cancel-appointment-failed': 'Ajanvarauksen peruminen epäonnistui',
    'error.modify-appointment-failed': 'Ajanvarauksen muuttaminen epäonnistui',
    'error.creating-token-failed': 'Latauskoodien luonti epäonnistui',
    'error.deleting-token-failed': 'Latauskoodien poisto epäonnistui',
    'network-state.poor-connection': 'Hidas verkkoyhteys',
    'auth-intro.referrals': 'Lataa Lähete',
    'auth-intro.title': 'Tervetuloa MyHelttiin!',
    'auth-intro.description': 'Tervetuloa käyttämään MyHelttiä.',
    'auth-intro.button.title': 'Seuraava',
    'auth-verification-check.title': 'Hei!',
    'auth-verification-check.description': 'Kertoisitko työsähköpostiosoitteesi?',
    'auth-verification-check.placeholder': 'Syötä työsähköpostiosoite',
    'auth-verification-check.error': 'Sähköpostiosoitteen tarkistus epäonnistui',
    'auth-authenticate.title': 'Tunnistaudu',
    'auth-authenticate.description':
        'Turvallisen asioinnin takaamiseksi sinun tulee tunnistautua pankkitunnuksilla tai mobiilivarmenteella.',
    'auth-authenticate.button.title': 'Tunnistaudu',
    'auth-accept-terms-upon-continue':
        'Jatkaessasi hyväksyt MyHeltin <terms>käyttöehdot</terms> ja <privacy>tietosuojaselosteen</privacy>',
    'auth-accept-terms.terms': 'https://heltti.fi/tietosuoja',
    'auth-accept-terms.privacy': 'https://heltti.fi/tietosuoja',
    'auth-email-signup-intro.title': 'Tervetuloa!',
    'auth-email-signup-intro.description': 'Aloitetaan luomalla sinulle Heltti-tili.',
    'auth-email-signup-form.title': 'Perustiedot',
    'auth-email-signup-form.submit': 'Luo Heltti-tili',
    'auth-email-signup-form.error.description': 'Jokin meni nyt pieleen, yritäthän uudelleen.',
    'email-change-form.changed.description':
        'Saat hetken päästä vahvistusviestin sähköpostiisi. Sähköpostiosoitteen muutos tallennetaan, kun avaat viestissä olevan linkin',
    'auth-pin-authenticate.lead': 'Kirjaudu sisään PIN-koodilla',
    'auth-pin-authenticate.forgotten-pin': 'Olen unohtanut PIN-koodin',
    'auth-pin-authenticate.too-many-retries.title': 'Liian monta yritystä',
    'auth-pin-authenticate.too-many-retries.description':
        'Näyttää siltä että olet unohtanut PIN-koodisi. Haluatko kirjautua uudelleen?',
    'auth-pin-authenticate.authenticate': 'Tunnistaudu',
    'auth-pin-authenticate.other-error.title': 'Virhe',
    'auth-pin-authenticate.other-error.description':
        'Tunnistautuminen PIN-koodilla epäonnistui. Kirjaudu sovellukseen uudelleen.',
    'auth-pin-authenticate.recoverable-error.title': 'Virhe',
    'auth-pin-authenticate.recoverable-error.description':
        'PIN-koodilla tunnistautumisessa tapahtui virhe, yritäthän uudelleen.',
    'auth-pin-authenticate.biometric.prompt': 'Avaa MyHeltti sormenjäljentunnistuksella',
    'auth-pin-authenticate.biometric.cancel': 'Peruuta',
    'auth-pin-authenticate.biometric.fallback': 'Käytä pääsykoodia',
    'auth-pin-authenticate.other-error.authenticate': 'Tunnistaudu',
    'auth-sms-authenticate.enter-code': 'Syötä koodi tekstiviestistä',
    'auth-sms-authenticate.invalid-code-retry': 'Virheellinen koodi, yritäthän uudelleen!',
    'auth-sms-authenticate.invalid-code-restart.title': 'Liian monta yritystä',
    'auth-sms-authenticate.invalid-code-restart.description':
        'Vahvistuskoodit näyttäisi olevan virheellisiä. Yritäthän alusta uudelleen.',
    'auth-sms-authenticate.resend-code': 'Lähetä tekstiviesti uudelleen',
    'auth-sms-authenticate.resend-code.error': 'Virhe lähettäessä uutta koodia, yritäthän uudelleen.',
    'auth-sms-authenticate.resend-code.success': 'Uusi koodi on matkalla!',
    'auth-sms-authenticate.verifying-code': 'Pieni hetki, koodia tarkistetaan!',
    'auth-verify.title': 'Tunnistautuminen',
    'auth-verify.error.description': 'Tunnistautumispalvelu ei ole saatavilla. Yritä myöhemmin uudelleen.',
    'auth-verify.in-progress': 'Pieni hetki...',
    'auth-verify.success': 'Käyttäjä tunnistettu!',
    'auth-verify.failure': 'Tunnistautuminen epäonnistui!',
    'auth-verification-email-sent.title': 'Hei!',
    'auth-verification-email-sent.description':
        'Mikäli sähköpostiosoitteesi löytyi järjestelmästämme, lähetimme sinulle kirjautumislinkin sähköpostiisi. Käy kurkkaamassa!\n\nHuomaathan, että kirjautumislinkki toimii vain tällä laitteella.',
    'auth-verification-email-sent.helper':
        'Jos et saa sähköpostia ja tekstiviestiä, ota yhteyttä <link>Heltin tukeen</link>',
    'auth-verification-email-sent.support.url':
        'mailto:support@heltti.fi?subject=Virhe&body=Virhe:%20sähköpostin%20tarkistus%20epäonnistui',
    'auth-logout.failure': 'Uloskirjautuminen epäonnistui. Kokeile uudelleen.',
    'app-update.required.title': 'MyHeltti on parempi\nkuin koskaan!',
    'app-update.required.description':
        'Nykyisen version tuki on päättynyt ja MyHeltti on päivitettävä, jotta voit jatkaa sen käyttöä.',
    'app-update.required.ota': 'Päivitä tästä',
    'app-update.required.app-store':
        'Päivitä {platform, select, ios {AppStoressa} android {Google Play Kaupassa} other {}}',
    'app-update.required.support': 'Tarvitsetko apua? Ota yhteyttä <link>Heltin tukeen</link>',
    'app-update.required.support.url':
        'mailto:tuki@heltti.fi?subject=Virhe&body=Virhe:%20sovelluksen%20päivitys%20epäonnistui',
    'app-update.optional.title': 'Päivitys saatavilla MyHelttiin',
    'app-update.optional.description': 'Päivitä MyHeltti uusimpaan versioon painamalla Päivitä tästä -nappia.',
    'app-update.optional.submit': 'Päivitä tästä',
    'app-update.complete.title': 'MyHeltti on päivittynyt',
    'app-update.complete.description': 'MyHeltti on päivittynyt uusimpaan versioon. Sovellus käynnistetään uudelleen.',
    'onboarding.enable': 'Ota käyttöön',
    'onboarding.pass': 'Ohita',
    'onboarding.pin-setup-intro.title': 'Hei {callName}!',
    'onboarding.pin-setup-intro.description':
        'Sisäänkirjautuminen onnistui!\n\nSeuraavaksi luodaan PIN koodi sisäänkirjautumista varten.',
    'onboarding.pin-setup-intro.button.title': 'Seuraava',
    'onboarding-pin-setup.hello': 'Hei {name}!',
    'onboarding-pin-setup.instructions':
        'Luo PIN-koodi sisäänkirjautumista varten. PIN-koodin lisäksi voit aktivoida myös biometrisen tunnisteen, kuten sormenjäljen.',
    'onboarding-biometric-auth.hello': 'Hei {name}!',
    'onboarding-biometric-authenticate.description':
        'Voit kirjautua sisään myös käyttäen biometristä tunnistetta. Sen avulla sisäänkirjautuminen on nopeaa ja vaivatonta.\n\nVoit ottaa biometrisen kirjautumisen käyttöön myös myöhemmin puhelimen asetuksista.',
    'onboarding-biometric-auth.error.cannot_enable':
        '{type, select, 0 {Sormenjäljen} 1 {Kasvojen} 2 {Iris-} }tunnistuksen aktivointi epäonnistui',
    'onboarding-push-notifications.hello': 'Hei {name}!',
    'onboarding-push-notifications.description':
        'Seuraavaksi MyHeltti pyytää lupaa lähettää sinulle ilmoituksia. Suosittelemme, että sallit ilmoitukset, näin pysyt vaivattomasti ajantasalla hoitopolkusi tapahtumista.\n\nVoit ottaa ilmoitukset käyttöön myös myöhemmin puhelimen asetuksista.',
    'onboarding-push-notifications.error.cannot_enable': 'Activating push notifications failed',
    'onboarding-terms.title': 'Viestintä',
    'onboarding-terms.description':
        'Haluamme, että saat Heltin palveluista ja Heltistä kaiken ilon ja hyödyn irti. Varmista siis, että saat meiltä silloin tällöin postia!',
    'onboarding-terms.newsletters': 'Uutiskirjeet',
    'onboarding-terms.accept-communications.label': 'Palveluviestintä',
    'onboarding-terms.accept-communications.helper':
        'Sinulle oleelliset Heltin palveluihin liittyvät asiat ja tiedotteet.',
    'onboarding-terms.accept-marketing.label': 'Heltti-yhteisön viestintä',
    'onboarding-terms.accept-marketing.helper': 'Tietoa, inspiraatiota ja vinkkejä työhön ja muuhun arkeen.',
    'onboarding-terms.accept-feedback.label': 'Palautekysely',
    'onboarding-terms.accept-feedback.helper': 'Esimerkiksi tyytyväisyyskyselyt käytettyäsi Heltin palveluita.',
    'onboarding-profile.title': 'Tietojen tarkistus',
    'onboarding-profile.description': 'Voit muokata tietojasi myös myöhemmin Omat tiedot -näkymän kautta.',
    'onboarding-completed.hello': 'Hei {name}!',
    'onboarding-completed.description': 'Kaikki on valmista ja voit aloittaa MyHeltin käytön!',
    'onboarding-completed.completed': 'Valmista!',
    'pin-code-setup.enter-pin': 'Luo nelinumeroinen PIN-koodi\nsisäänkirjautumista varten.',
    'pin-code-setup.re-enter-pin': 'Syötä PIN-koodi uudelleen.',
    'pin-code-setup.pin-mismatch': 'PIN-koodit eivät vastaa toisiaan.\nSyötä PIN-koodi uudelleen.',
    'pin-code-setup.pin-set': 'PIN-koodi asetettu.',
    'pin-code-validate.enter-pin': 'Kirjaudu sisään PIN-koodilla',
    'pin-code-validate.biometric-auth-failed': 'Pääsy evätty, kokeile uudelleen.',
    'pin-code-validate.valid-pin': 'Oikea PIN-koodi.',
    'pin-code-validate.invalid-pin': 'Väärä PIN-koodi, ole hyvä ja yritä uudelleen.',
    'error.alert.title': 'Virhe',
    'error.alert.generic': 'Toimintoa ei voitu suorittaa. Kokeile uudelleen.',
    'error-overlay.title': 'Oho!',
    'error-overlay.description': 'Nyt jokin meni pieleen.',
    'error-overlay.support': 'Ota yhteyttä <link>Heltti tukeen</link>.',
    'error-overlay.support.url': 'mailto:tuki@heltti.fi?subject=Virhe&body=Virhe:%20{id}',
    'error-overlay.error_id': 'Virhetunniste {id}',
    'error-overlay.retry': 'Kokeile uudelleen',
    'error-overlay.close': 'Peruuta',
    'error-overlay.go_back': 'Takaisin',
    'modal-picker.back': 'Palaa takaisin',
    'user-profile.error.cannot_load': 'Käyttäjäprofiilin lataus epäonnistui',
    'tab-bar.home': 'Koti',
    'tab-bar.library': 'Kirjasto',
    'tab-bar.me': 'Minä',
    'tab-bar.services': 'Palvelusi',
    'home.ctas': 'Sinulle',
    'home.no-chats': 'Ei aktiivisia keskusteluita',
    'home.all-ctas': 'Kaikki palvelusi',
    'home.all-chats': 'Kaikki viestit',
    'home.all-appointments': 'Kaikki tapahtumasi',
    'appointments.title': 'Tulevat tapahtumasi',
    'appointments.past': 'Menneet tapahtumasi',
    'chats.title': 'Viestit',
    'chat.active_tasks': 'Aktiiviset tehtävät',
    'chat.load_more': 'Lataa lisää viestejä',
    'chat.participants': 'Osallistujat',
    'chat-input.placeholder': 'Kirjoita viesti',
    'chat-notification-action.label.call': 'Soita HelttiLinjaan',
    'chat-notification-action.label.escalate': 'Kutsu hoitaja',
    'chat-message.show_more': 'Lue lisää',
    'chat-message.show_less': 'Pienennä',
    'chat-message.joined_chat': 'Liittyi keskusteluun',
    'chat-message.left_chat': 'poistui keskustelusta',
    'chat-message.chat_closed': '{timestamp} keskustelu suljettu',
    'chat-message.chat_reopened': '{timestamp} keskustelu avattiin uudelleen',
    'chat-message.chat_escalated': '{timestamp} keskustelu on jonossa hoitajalle, pieni hetki!',
    'chat-message.new_messages': 'Uudet viestit',
    'chat-attachment.error.load-failed': 'Liitetiedoston lataaminen epäonnistui.',
    'nps.title': 'Kuinka hyvin onnistuimme?',
    'nps.detail1.company.title': 'Kuinka hyödylliseksi koit viimeisimmän tapaamisemme?',
    'nps.detail1.company.max-description': 'Erittäin hyödylliseksi',
    'nps.detail1.member.title': 'Koitko tulleesi kuulluksi?',
    'nps.detail1.mindy.title': 'Kuinka hyödylliseksi olet kokenut Mindy-valmennuksen?',
    'nps.detail1.mindy.max-description': 'Erittäin hyödylliseksi',
    'nps.detail1.min-description': 'En lainkaan',
    'nps.detail1.max-description': 'Oikein hyvin',
    'nps.detail2.company.title': 'Kuinka tyytyväinen olet yhteistyöhömme yleisesti?',
    'nps.detail2.company.max-description': 'Erittäin tyytyväinen',
    'nps.detail2.member.title': 'Saitko tarvitsemasi avun?',
    'nps.detail2.min-description': 'En lainkaan',
    'nps.detail2.max-description': 'Oikein hyvin',
    'nps.nps-question.title': 'Kuinka todennäköisesti suosittelisit Helttiä ystäville tai kollegoille?',
    'nps.nps-question.mindy.title': 'Kuinka todennäköisesti suosittelisit Mindyä ystäville tai kollegoille?',
    'nps.nps-question.min-description': 'En lainkaan todennäköisesti',
    'nps.nps-question.max-description': 'Erittäin todennäköisesti',
    'nps.comment.title': 'Miksi annoit tämän arvosanan?',
    'nps.contact-permission.title': 'Voitte olla minuun yhteydessä palautteeseeni liittyen',
    'nps.send': 'Lähetä',
    'nps.data-protection-description': 'Tietosuojaseloste',
    'nps.thank-you': 'Kiitos palautteestasi!',
    'member-action.faq.title': 'Usein kysyttyjä kysymyksiä',
    'member-action.faq.description': 'Vastaukset yleisimmin askarruttaviin kysymyksiin',
    'member-action.sick-leave.title': 'Sairauspoissaolo',
    'member-action.sick-leave.description': 'Ilmoita lyhytkestoisesta poissaolosta',
    'new-contact.title': 'Ota yhteyttä',
    'new-contact.non_busy_title': 'Kiireetön apu\nja hyvinvointiasiat',
    'new-contact.action.type': '{type, select, CALL {Soita} other {Aloita} }',
    'contact-reason.reason_placeholder': 'Kertoisitko lyhyesti miten voimme auttaa?',
    'contact-reason.title_placeholder': 'Aihe',
    'contact-reason.start_conversation': 'Aloita keskustelu',
    'sick-leave.title': 'Omailmoitteinen sairauspoissaolo',
    'sick-leave.description': 'Voit itse ilmoittaa lyhyet sairauspoissaolosi',
    'sick-leave.first-date': 'Ensimmäinen poissaolopäivä',
    'sick-leave.day-count': 'Päivien lukumäärä',
    'sick-leave.reason': 'Kuvaus',
    'sick-leave.submit-confirmation.title': 'Kiitos!',
    'sick-leave.submit-confirmation.description': 'Pikaista paranemista',
    'sick-leave.submit-confirmation.continue': 'Jatka',
    'sick-leave.submit': 'Lähetä',
    'sick-leave.error.cannot-report': 'Sairauspoissaolon kirjaus epäonnistui',
    'profile.title': 'Tietojen muokkaus',
    'profile.call-name': 'Kutsumanimi',
    'profile.first-name': 'Etunimi',
    'profile.last-name': 'Sukunimi',
    'profile.full-name': 'Nimi',
    'profile.ssn': 'Henkilötunnus',
    'profile.image.edit': 'Muokkaa profiilikuvaa',
    'profile.identification.helper': 'Tiedot on saatu tunnistautumisen kautta.',
    'profile.contact-info.helper': 'Käytämme ensisijaisesti näitä tietoja ollessamme sinuun yhteydessä.',
    'profile.email': 'Sähköpostiosoite',
    'profile.phone': 'Puhelinnumero',
    'profile.contact-details': 'Yhteystiedot',
    'profile.private-email': 'Sähköpostiosoite (yksityinen)',
    'profile.private-phone': 'Puhelinnumero (yksityinen)',
    'profile.address1': 'Osoite 1',
    'profile.address2': 'Osoite 2',
    'profile.zip': 'Postinumero',
    'profile.city': 'Postitoimipaikka',
    'profile.work-header': 'Työnantajan ilmoittamat tiedot',
    'profile.work-postfix': '(työ)',
    'profile.work-employer': 'Työnantaja',
    'profile.work-email': 'Työsähköpostiosoite',
    'profile.work-phone': 'Työpuhelinnumero',
    'profile.work-info':
        'Työnantajan ilmoittamia tietoja ei voi muokata. Ota yhteyttä työnantajaasi muuttaaksesi näitä tietoja.',
    'profile.permissions': 'Luvat',
    'profile.language': 'Sovelluksen & palvelun kieli',
    'profile.language.fi': 'Suomi',
    'profile.language.en': 'Englanti',
    'profile.verify': 'Nimen muuttaminen',
    'profile.verify.description': 'Tunnistaudu uudelleen päivittääksesi nimesi',
    'profile.verify.authenticate': 'Tunnistaudu',
    'profile.verify.go_back': 'Takaisin',
    'profile.account-removal.title': 'Pyydä tilin poistoa',
    'profile.account-removal.description':
        'Poistamme pyynnöstä tietojasi rekistereistämme. Otathan huomioon, että osa säilyttämistämme tiedoista on sellaisia, joita emme voi poistaa, esimerkiksi potilasrekisteriimme tallennetut tiedot. Voit tutustua tarkemmin tietosuojapolitiikkaamme osoitteessa https://heltti.fi/tietosuoja',
    'profile.account-removal.additional-information': 'Lisätietoja',
    'profile.account-removal.submit': 'Pyydä poistoa',
    'profile.account-removal.success': 'Pyyntö lähetetty',
    'profile.calendar-events.title': 'Ajanvaraus',
    'profile.calendar-events.description': 'Tarkastele ajanvarauksiasi',
    'profile.calendar-events.none': 'Ei ajanvarauksia',
    'profile.calendar-reservation-accesses.title': 'Avatut ajanvaraukset',
    'profile.calendar-reservation-accesses.description': 'Tarkastele sinulle avattuja ajanvarauksia ',
    'profile.calendar-reservation-accesses.item.title': 'Varaa aika',
    'profile.calendar-reservation-accesses.none': 'Ei avattuja ajanvarauksia',
    'profile.documents.title': 'Asiakirjat',
    'profile.documents.description': 'Tarkastele asiakirjojasi',
    'profile.documents.none': 'Ei asiakirjoja',
    'profile.document.added-date': 'Lisätty',
    'profile.document.export-tokens.title': 'Latauskoodit',
    'profile.document.export-tokens.code': 'Koodi',
    'profile.document.export-tokens.name': 'Sukunimi',
    'profile.document.export-tokens.valid-until': 'Voimassa',
    'profile.document.export-tokens.create-token': 'Luo latauskoodit',
    'profile.document.export-tokens.delete-token': 'Poista latauskoodit',
    'profile.document.export-tokens.help':
        'Latauskoodilla helpotat lähetteiden avaamista sairaanhoidon kumppanien vastaanotossa.\n\nAsiakaspalvelija saa ladattua tämän dokumentin osoitteesta **myheltti.fi/referrals** käyttämällä oheisia tietoja.',
    'profile.document.filename': 'Nimi',
    'profile.document.comment': 'Saateviesti',
    'profile.document.open': 'Avaa asiakirja',
    'profile.document.title': 'Asiakirja',
    'profile.questionnaires.title': 'Kyselyt',
    'profile.questionnaires.description': 'Tarkastele kyselyitäsi',
    'profile.questionnaires.none': 'Ei kyselyitä',
    'profile.sign-out': 'Kirjaudu ulos',
    'profile.error.image-upload-failed': 'Profiilikuvan lataaminen epäonnistui',
    'questionnaire-response-batch.title': 'Kyselyt {date, date, ::dd.MM.yyyy}',
    'questionnaire-response-batch.open-until': 'Vastausaikaa {date, date, ::dd.MM.yyyy} asti, vastaathan ennen sitä!',
    'questionnaire-response-batch.closed-at': 'Vastausaika päättyi {date, date, ::dd.MM.yyyy}.',
    'form.validate-phone.error.INVALID_COUNTRY': 'Virheellinen maakoodi',
    'form.validate-phone.error.NOT_A_NUMBER': 'Syötä pelkkiä numeroita',
    'form.validate-phone.error.TOO_SHORT': 'Puhelinnumero liian lyhyt',
    'form.validate-phone.error.TOO_LONG': 'Puhelinnumero liian pitkä',
    'form.validate-phone.error.INVALID_LENGTH': 'Virheellinen puhelinnumero',
    'form.validate-email.error': 'Virheellinen sähköpostiosoite',
    'form.validate-number.error': 'Syötä vain numeroita',
    'form.flash-saving': 'Tallennetaan...',
    'form.flash-success': 'Tallennettu',
    'form.flash-failure': 'Tallennus epäonnistui',
    'appointments.abort': 'Keskeytä ajanvaraus',
    'appointments.all-locations': 'Kaikki toimipisteet',
    'appointments.next-available-time': 'Seuraava vapaa aika {time}',
    'appointments.remote': 'etäajat',
    'appointments.ALL': 'Kaikki',
    'appointments.MORNING': 'Aamupäivä',
    'appointments.AFTERNOON': 'Iltapäivä',
    'appointments.select-location': 'Valitse toimipiste',
    'appointments.select-region': 'Valitse alue',
    'appointments.select-region.locate.title': 'Paikanna lähin palvelupiste',
    'appointments.select-region.locate.description':
        'Sallimalla MyHeltin käyttää sijaintiasi löydät\nlähimmän palvelupisteen helposti.',
    'appointments.select-region.remote-only': 'Vain etäajat',
    'appointments.select-region.choose.title': 'Valitse alue josta\nhaluat etsiä vapaita aikoja',
    'appointments.select-region.choose.description':
        'Voit myös sallia MyHeltin käyttää sijaintiasi.\nMuuta sijaintiasetuksia puhelimen\nyksityisyysasetuksista.',
    'appointments.confirmation.title': 'Vahvista ajanvaraus',
    'appointments.no-appointments-available': 'Vapaita aikoja ei löytynyt',
    'appointments.day.previous': 'Edellinen päivä',
    'appointments.day.next': 'Seuraava päivä',
    'appointments.error.not-available': 'Ajanvarausjärjestelmä ei ole saatavilla, kokeile myöhemmin uudelleen',
    'appointments.error.cannot-load-appointments':
        'Vapaiden aikojen lataaminen ei onnistunut.\nOle hyvä ja yritä uudelleen.',
    'appointments.error.try-again': 'Yritä uudelleen',
    'appointment.task':
        '{taskType, select, VIEW_APPOINTMENT {Varattu aika} VIEW_CANCELLATION {Peruttu aika} other {Ajanvaraus} }',
    'appointment.type':
        '{type, select, PHONE {Puhelu} APPOINTMENT_PHONE {Puhelu} VIDEO {Video} APPOINTMENT_VIDEO {Video} other {Tapaaminen} }',
    'appointments.make-appointment':
        'Varaa {type, select, phone {puhelinvastaanotto} video {videovastaanotto} other {tapaaminen} }',
    'appointments.change-appointment': 'Vaihda aikaa',
    'appointment.read-cancellation-terms': 'Lue peruutusehdot',
    'appointment.cancellation-terms': 'Lue peruutusehdot <u>täältä</u>',
    'appointment.contact-info.title':
        'Ohje {type, select, APPOINTMENT_PHONE {puhelinvastaanottoon} PHONE {puhelinvastaanottoon} VIDEO {videovastaanottoon} APPOINTMENT_VIDEO {videovastaanottoon} other {vastaanottoon} }',
    'appointment.contact-info.description':
        '{type, select, PHONE {Sinulle soitetaan sovittuna ajankohtana antamaasi numeroon.} APPOINTMENT_PHONE {Sinulle soitetaan sovittuna ajankohtana antamaasi numeroon.} VIDEO {Varmista että sinulla on toimiva kamera sekä mikrofoni videovastaanottoa varten.} APPOINTMENT_VIDEO {Varmista että sinulla on toimiva kamera sekä mikrofoni videovastaanottoa varten.} other {} }',
    'appointment.title': 'Tuleva tapahtumasi',
    'appointment-confirmed.title': 'Ajanvaraus vahvistettu',
    'appointment-confirmed.close': 'Selvä!',
    'appointment-cancelled.title': 'Tapahtuma peruttu',
    'appointment.join-video-call': 'Liity videopuheluun',
    'appointment.video-call-details': 'Linkki videopuheluun aukeaa 10 min ennen vastaanoton alkua',
    'appointment.add-calendar': 'Lisää aika kalenteriin',
    'appointment.add-calendar.title': 'Lisää aika kalenteriin',
    'appointment.add-calendar.description': 'Valitse kalenteri, johon tapahtuma lisätään',
    'appointment.add-calendar.event.title':
        '{calendarEventType, select, APPOINTMENT_PHONE {Puhelinvastaanotto} APPOINTMENT_VIDEO {Videovastaanotto} other {Vastaanotto}}',
    'appointment.add-calendar.no-calendars': 'Ei kalentereita',
    'appointment.select-type': 'Valitse vastaanoton tyyppi',
    'appointment.modify': 'Muuta ajanvarausta',
    'appointment.modify-or-cancel': 'Muuta tai peruuta ajanvaraus',
    'appointment.modify.title':
        '{modifiable, select, true {Oletko varma, että haluat muuttaa ajanvarausta?} other {Et voi enää muuttaa ajanvaraustasi}}',
    'appointment.modify.description':
        '{modifiable, select, true {Voit muuttaa ajanvaraustasi kun tapahtuman alkuun on yli 24 tuntia. Lue tarkemmat ehdot <link>täältä</link>.} other {Tapahtuman alkuun on alle 24 tuntia. Voit vielä perua ajan, mutta huomioithan <link>peruutusehdot</link>.}}',
    'appointment.modify.cancellation-hours': 'Voit muuttaa ajanvarausta 24h ennen varattua aikaasi.',
    'appointment.modify.new-appointment-after-cancellation':
        'Mikäli perut ajan ja tarvitset uuden ajan ole meihin uudestaan yhteydessä.',
    'appointment.cancel': 'Peruuta ajanvaraus',
    'appointment.cancel.title': 'Oletko varma, että haluat peruuttaa ajanvarauksen?',
    'appointment.cancel.description':
        'Varatun ajan muuttaminen tulee tehdä 24 tuntia ennen vastaanottoajan alkua. Lue tarkemmat ehdot <link>täältä</link>.',
    'appointment.filter': 'Rajaa hakua',
    'appointment.type.label': 'Vastaanoton tyyppi',
    'appointment.type.VISIT': 'Tapaaminen',
    'appointment.type.VIDEO': 'Videovastaanotto',
    'appointment.type.PHONE': 'Puhelinvastaanotto',
    'appointment.type.CHAT': 'Chat-keskustelu',
    'appointment.type.OTHER': 'Muu',
    'appointment.language.label': 'Asiantuntijan kielitaito',
    'appointment.language.FI': 'suomi',
    'appointment.language.SV': 'ruotsi',
    'appointment.language.EN': 'englanti',
    'appointment.gender.label': 'Asiantuntijan sukupuoli',
    'appointment.gender.OTHER': 'Muu',
    'appointment.gender.FEMALE': 'Nainen',
    'appointment.gender.MALE': 'Mies',
    'appointment.gender.ANY': 'Mikä tahansa',
    'appointment.age.label': 'Asiantuntijan ikä',
    'appointment.age.UNDER_30': 'Alle 30 vuotta',
    'appointment.age.BETWEEN_30_45': '30-45 vuotta',
    'appointment.age.BETWEEN_46_59': '46-59 vuotta',
    'appointment.age.OVER_60': 'Yli 60 vuotta',
    'appointment.age.ANY': 'Mikä tahansa',
    'video-call.title': 'Videovastaanotto',
    'calendar.month.january': 'Tammikuu',
    'calendar.month.february': 'Helmikuu',
    'calendar.month.march': 'Maaliskuu',
    'calendar.month.april': 'Huhtikuu',
    'calendar.month.may': 'Toukokuu',
    'calendar.month.june': 'Kesäkuu',
    'calendar.month.july': 'Heinäkuu',
    'calendar.month.august': 'Elokuu',
    'calendar.month.september': 'Syyskuu',
    'calendar.month.october': 'Lokakuu',
    'calendar.month.november': 'Marraskuu',
    'calendar.month.december': 'Joulukuu',
    'calendar.month.january.short': 'Tammi',
    'calendar.month.february.short': 'Helmi',
    'calendar.month.march.short': 'Maalis',
    'calendar.month.april.short': 'Huhti',
    'calendar.month.may.short': 'Touko',
    'calendar.month.june.short': 'Kesä',
    'calendar.month.july.short': 'Heinä',
    'calendar.month.august.short': 'Elo',
    'calendar.month.september.short': 'Syys',
    'calendar.month.october.short': 'Loka',
    'calendar.month.november.short': 'Marras',
    'calendar.month.december.short': 'Joulu',
    'calendar.day.monday': 'Maanantai',
    'calendar.day.tuesday': 'Tiistai',
    'calendar.day.wednesday': 'Keskiviikko',
    'calendar.day.thursday': 'Torstai',
    'calendar.day.friday': 'Perjantai',
    'calendar.day.saturday': 'Lauantai',
    'calendar.day.sunday': 'Sunnuntai',
    'calendar.day.monday.short': 'MA',
    'calendar.day.tuesday.short': 'TI',
    'calendar.day.wednesday.short': 'KE',
    'calendar.day.thursday.short': 'TO',
    'calendar.day.friday.short': 'PE',
    'calendar.day.saturday.short': 'LA',
    'calendar.day.sunday.short': 'SU',
    'calendar.day.today': 'Tänään',
    'calendar.pick.date': 'Valitse päivä',
    'attachment.type_description': '{type, select, IMAGE {Kuva} FILE {Liite} other {Liite} }',
    'chat-archive.title': 'Viestiarkisto',
    'chat-archive.notification.empty': 'Viestiarkistosi on tyhjä',
    'library.title': 'Kirjasto',
    'library.description':
        'Kokosimme tähän erilaisia mielen ja kehon hyvinvointia tukevia palveluita, artikkeleita ja työkaluja.',
    'services.noServicesAvailable': 'Ei palveluita',
    'services.title': 'Palvelusi',
    'services.description': 'Alta löydät kaikki tiliisi liitetyt palvelut',
    'services.article.read-more': 'Lue lisää',
    'services.article.loading': 'Sisältöä ladataan...',
    'services.error.article-not-available': 'Sisällön latauksessa tapahtui virhe',
    'error-modal.header.generic': 'Tapahtui virhe',
    'error-modal.header.unrecoverable': 'Tapahtui virhe',
    'error-modal.header.connection': 'Ei verkkoyhteyttä',
    'error-modal.header.auth': 'Tunnistautuminen',
    'error-modal.connection.title': 'Yhteys katkesi',
    'error-modal.connection.info': 'MyHeltti tarvitsee verkkoyhteyden.',
    'error-modal.connection.info2':
        'Tarkista verkkoyhteytesi ja yritä uudelleen.   Jos ongelma toistuu ota yhteyttä palveluntarjoajaan.',
    'error-modal.retry': 'Yritä uudelleen',
    'error-modal.generic.title': 'HUPS!',
    'error-modal.generic.info': 'Homma meni solmuun.',
    'error-modal.unrecoverable.title': 'Peruuttamaton virhe',
    'error-modal.unrecoverable.info': 'Sinut joudutaan kirjaamaan ulos.',
    'error-modal.auth.title': 'Tunnistautuminen epäonnistui',
    'error-modal.auth.info': 'Ole hyvä ja yritä uudelleen. Jos ongelma toistuu, ota yhteyttä Heltin tukeen.',
    'external-page.invalid-target': 'Sivun lataaminen epäonnistui',
    'email-verify.invalid-token': 'Sivun lataaminen epäonnistui',
    'email-verify-completed.hello': 'Hei {name}!',
    'email-verify-completed.closebutton': 'Sulje',
    'email-verify-completed.loading': 'Sähköpostia varmennetaan...',
    'email-verify-completed.success': 'Sähköposti  varmennettu.',
    'email-verify-failed.title': 'Virhe sähköpostin varmentamisessa',
    'external-referral.title': 'Lataa Lähete',
    'external-referral.form.last-name': 'Sukunimi',
    'external-referral.form.code': 'Turvakoodi',
    'external-referral.form.error': 'Hups, nyt ei löytynyt. Tarkistathan tiedot!',
    'external-referral.form.error.lenght': 'Liian vähän merkkejä',
    'external-referral.form.token-valid': 'Tiedot oikein! Klikkaa napista tallentaaksesi lähetteen.',
    'external-referral.button.open': 'Avaa lähete',
    'external-referral.button.download': 'Tallenna lähete',
    'external-referral.button.clear': 'Aloita alusta',
    'external-referral.instructions.clinic.title': 'Ohjeet asiakaspalvelijalle',
    'external-referral.instructions.clinic.description':
        'Avaa tämä sivu Internet-selaimella osoitteesta **myheltti.fi/referrals**\n\nSyötä jäsenen **sukunimi** ja **turvakoodi** ja paina **Avaa lähete**.\n\nMikäli tiedot ovat oikein ja turvakoodi on voimassa, saat ladattua lähetteen **Tallenna lähete** -napista. Lähetteen voi ladata yhdellä turvakoodilla **vain kerran**.',
    'external-referral.instructions.member.title': 'Ohjeet jäsenelle',
    'external-referral.instructions.member.description':
        'Kirjauduttuasi MyHelttiin sisään, voit luoda uuden turvakoodin **Asiakirjat** -sivulla. Koodin luodaksesi klikkaa lähetteen tiedot auki ja paina **Luo koodi** -nappia.',
    'breadcrumb.home': 'Koti',
    'breadcrumb.messages': 'Viestit'
};
