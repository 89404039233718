import React from 'react';

import AppointmentPhoneImage from '~/assets/images/appointment-phone.svg';
import AppointmentVideoImage from '~/assets/images/appointment-video.svg';
import AppointmentVisitImage from '~/assets/images/appointment-visit.svg';
import { CalendarEventType, AppointmentContactType } from '~/types';

export type AppointmentContactTypeImageProps = {
    type: AppointmentContactType | CalendarEventType;
};

export const AppointmentContactTypeImage = (props: AppointmentContactTypeImageProps) => {
    const { type } = props;

    switch (type) {
        case 'PHONE':
        case 'APPOINTMENT_PHONE':
            return <AppointmentPhoneImage />;
        case 'VIDEO':
        case 'APPOINTMENT_VIDEO':
            return <AppointmentVideoImage />;
        default:
            return <AppointmentVisitImage />;
    }
};
