import React from 'react';
import { View, ViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';

export const ScreenContainer = styled.View<{ bottomInset?: number }>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding-bottom: ${({ bottomInset = 0 }) => Math.max(bottomInset, Spacing.MEDIUM)}px;
`;

export function ScreenContainerV2(props: ViewProps) {
    const { bottom } = useSafeAreaInsets();

    const { children, style, ...rest } = props;
    return (
        <View
            {...rest}
            style={[
                {
                    flex: 1,
                    backgroundColor: Color.BACKGROUND_DEFAULT,
                    paddingBottom: Math.max(bottom, Spacing.MEDIUM)
                },
                style
            ]}
        >
            {children}
        </View>
    );
}
