import React, { useCallback, useState } from 'react';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { CloseIcon } from '~/components/icon';
import { ModalPicker } from '~/components/modal-picker';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { isWeb } from '~/utils';

import { AppointmentActionTitle, AppointmentActionDescription, AppointmentActionLink } from './appointment-actions';

export type AppointmentCancelProps = {
    visible: boolean;
    onCancelCalendarEvent: () => Promise<void>;
    onCancel: () => void;
};

export function AppointmentCancel(props: AppointmentCancelProps) {
    const { visible, onCancelCalendarEvent, onCancel } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { formatMessage } = useIntl();

    const handleCancelCalendarEvent = useCallback(async () => {
        try {
            setLoading(true);
            await onCancelCalendarEvent();
        } finally {
            setLoading(false);
        }
    }, [onCancelCalendarEvent]);

    return (
        <ModalPicker
            visible={visible}
            extraStyles={isWeb() ? { width: 343, marginLeft: 'auto', marginRight: 'auto' } : undefined}
            renderContent={() => (
                <>
                    <AppointmentActionTitle>
                        <FormattedMessage id="appointment.cancel.title" />
                    </AppointmentActionTitle>
                    <AppointmentActionDescription>
                        <FormattedMessage
                            id="appointment.cancel.description"
                            values={{
                                link: (value: string) => (
                                    <AppointmentActionLink onPress={() => {}}>{value}</AppointmentActionLink>
                                )
                            }}
                        />
                    </AppointmentActionDescription>
                </>
            )}
            renderActions={() => (
                <Button
                    alignCenter
                    fillContainer
                    loading={loading}
                    type="negative"
                    shape="rounded"
                    leftIcon={<CloseIcon fill={Color.ICON_NEGATIVE} />}
                    label={formatMessage('appointment.cancel')}
                    onPress={handleCancelCalendarEvent}
                />
            )}
            onCancel={onCancel}
        />
    );
}
