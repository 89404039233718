import { Box } from 'native-base';
import React from 'react';

import { Color } from '~/components/color';
import { Text } from '~/components/text';

export type SidebarHeaderProps = {
    title: string;
};

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ title }) => {
    return (
        <Box px="4" py="6">
            <Text.HEADER_3 style={{ color: Color.HEADER }}>{title}</Text.HEADER_3>
        </Box>
    );
};
