import { Flex, View } from 'native-base';
import React from 'react';
import { Pressable, Image, StyleProp, FlexStyle } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { isFunction, isString } from '~/utils/type-predicates';

import { MasonryList } from '../masonry-list';

type ButtonSheetItemKey = string;

export type ButtonSheetCompactItemProps = {
    key: string;
    title?: string;
    label: string;
    disabled?: boolean;
    loading?: boolean;
    image?: string | (() => React.ReactNode);
    onPress?: () => void | Promise<void>;
};

export type ButtonSheetCompactProps = {
    disabled?: boolean;
    title?: string;
    items: ButtonSheetCompactItemProps[];
    style?: StyleProp<FlexStyle>;
    onPressItem?: (key: ButtonSheetItemKey) => void | Promise<void>;
};

type ButtonState = 'disabled' | 'enabled' | 'hover' | 'select';

type ButtonVariant = `${ButtonState}`;
type ButtonVariantColors = {
    [key in ButtonVariant]: string;
};

const BACKGROUND_COLOR: ButtonVariantColors = {
    disabled: Color.BACKGROUND_DEFAULT,
    enabled: Color.BACKGROUND_DEFAULT,
    hover: Color.BASIC_HOVER,
    select: Color.BACKGROUND_DEFAULT
};

const OPACITY_SETTING = {
    select: 0.75,
    disabled: 0.5,
    enabled: 1.0,
    hover: 1.0
};

const ButtonSheetItemTitle = styled(Text.SMALL_BUTTON_LABEL).attrs({ numberOfLines: 3 })`
    color: ${Color.HEADER};
    margin-bottom: 8px;
`;

const state = (disabled: boolean, pressed: boolean) => (pressed ? 'select' : disabled ? 'disabled' : 'enabled');

function renderImage(image?: string | (() => React.ReactNode)) {
    if (image) {
        if (isString(image)) {
            return (
                <Image
                    accessibilityRole="image"
                    accessible
                    source={{ uri: image }}
                    style={{ width: 98, height: 98, borderRadius: 8 }}
                />
            );
        }
        if (isFunction(image)) {
            return image();
        }
    }
    return null;
}

export const CompactButtonSheet = (props: ButtonSheetCompactProps) => {
    const { title, items = [], disabled = false, style, onPressItem } = props;

    const renderItem = (item: ButtonSheetCompactItemProps) => {
        const { key, title: itemTitle, disabled: itemDisabled = false, image, onPress } = item;

        return (
            <Flex flexGrow={0} key={'box_' + key}>
                <Pressable
                    key={key}
                    accessibilityRole="button"
                    disabled={disabled || itemDisabled}
                    onPress={onPress ?? (() => onPressItem?.(key))}
                >
                    {({ pressed }) => {
                        const currentState: ButtonVariant = state(disabled || itemDisabled, pressed);
                        return (
                            <View
                                backgroundColor={BACKGROUND_COLOR[currentState]}
                                flexDir="column"
                                alignItems="flex-start"
                                opacity={OPACITY_SETTING[currentState]}
                                flexGrow={0}
                            >
                                <Flex justifyContent="flex-start" alignItems="center" flexDir="column">
                                    {image ? renderImage(image) : null}
                                    <Flex grow={0}>
                                        {itemTitle ? <ButtonSheetItemTitle>{itemTitle}</ButtonSheetItemTitle> : null}
                                    </Flex>
                                </Flex>
                            </View>
                        );
                    }}
                </Pressable>
            </Flex>
        );
    };

    return (
        <Flex style={style} grow={1} backgroundColor={Color.BACKGROUND_DEFAULT}>
            {title ? <Text.SUBTITLE_2 style={{ marginLeft: 16, marginTop: 16 }}>{title}</Text.SUBTITLE_2> : null}
            <Flex
                flexDir="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                width="100%"
                margin="0px 2px 0px 2px"
            >
                <MasonryList
                    items={items}
                    renderItem={renderItem}
                    customColumnCount={3}
                    minCardHeight={160}
                    minCardWidth={100}
                />
            </Flex>
        </Flex>
    );
};

CompactButtonSheet.displayName = 'CompactButtonSheet';
