import React from 'react';
import { View } from 'react-native';
import Svg, { Defs, ForeignObject, G } from 'react-native-svg';

import { MaskedViewProps } from './masked-view';

export const MaskedView: React.FC<MaskedViewProps> = ({ maskId, maskElement, children, style }) => {
    return (
        <View style={style}>
            <Svg height="100%" width="100%">
                <Defs>{maskElement}</Defs>
                <G mask={`url(#${maskId})`}>
                    <ForeignObject y="0" x="0" width="100%" height="100%">
                        {children}
                    </ForeignObject>
                </G>
            </Svg>
        </View>
    );
};
