import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

export const GradientScreenContainer = styled(LinearGradient).attrs({
    colors: [Color.BACKGROUND_DEFAULT, Color.BACKGROUND_TERTIARY],
    start: { x: 0.0, y: 0.0 },
    end: { x: 0.65, y: 0.65 }
})`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
`;
