import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex } from 'native-base';
import React, { useCallback } from 'react';

import { ArticleHighlight, ArticleMarkdown, ArticleSpacer, ArticleTitle } from '~/components/article/article';
import { Spacing } from '~/components/spacing';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { Article } from '~/types';

import { ArticleCategoryView } from './article-category';

export type ArticleRootProps = {
    article: Article;
    onlyShowChildCategories?: boolean;
};

type ArticleRootNavigation = StackNavigationProp<MainNavigatorParamList, 'article'>;

export function ArticleRoot(props: ArticleRootProps) {
    const { article, onlyShowChildCategories } = props;
    const { title, highlight, content, childCategories } = article;
    const { navigate } = useNavigation<ArticleRootNavigation>();

    const handleItemPressed = useCallback((id: ID) => navigate('article', { id }), [navigate]);

    return (
        <Flex>
            {!onlyShowChildCategories && (
                <>
                    <ArticleTitle after={Spacing.MEDIUM}>{title}</ArticleTitle>

                    {highlight ? (
                        <Flex style={{ marginBottom: Spacing.MEDIUM }}>
                            <ArticleHighlight>{highlight}</ArticleHighlight>
                        </Flex>
                    ) : null}

                    {content ? <ArticleMarkdown>{content}</ArticleMarkdown> : null}
                </>
            )}

            {childCategories.map((category, index) => (
                <Flex key={category.id}>
                    {onlyShowChildCategories || index > 0 ? <ArticleSpacer /> : null}
                    <ArticleCategoryView category={category} onItemPressed={handleItemPressed} />
                </Flex>
            ))}
        </Flex>
    );
}
