import React, { useCallback } from 'react';
import styled from 'styled-components/native';

import { NoContentContainer } from '~/components/no-content-container';
import { SystemMessage } from '~/components/system-message';
import { useIntl } from '~/contexts/intl';
import { ChatListItem } from '~/hooks/chat-list';

import { HomescreenSheetButton } from '../homescreen-sheet-button';
import { LoadingContent } from '../loader';
import { Spacing } from '../spacing';

type ChatListProps = {
    loading?: boolean;
    chats?: ChatListItem[];
};

const ListContainer = styled.View``;

const ChatContainer = styled.View`
    flex-direction: column;
    margin-bottom: ${Spacing.SMALL}px;
`;

export function ChatList(props: ChatListProps) {
    const { loading = false, chats = [] } = props;
    const { formatMessage } = useIntl();

    const renderChat = useCallback((chat: ChatListItem) => {
        const { id: chatId, users, title, last, interactions, badge, handleOpen } = chat;
        const { message, at, firstName: lastMessageSender } = last ?? {};

        return (
            <ChatContainer key={chatId}>
                <HomescreenSheetButton
                    onPress={handleOpen}
                    title={title}
                    lastMessage={message ?? ''}
                    lastMessageSender={lastMessageSender}
                    badge={badge}
                    timestamp={at}
                    users={users.map(user => ({
                        id: user.id,
                        title: user.title,
                        name: `${user.firstName} ${user.lastName}`,
                        avatarUrl: user.avatarSmallUrl
                    }))}
                    interactions={interactions}
                />
            </ChatContainer>
        );
    }, []);

    if (loading) {
        return <LoadingContent />;
    }

    return chats?.length ? (
        <ListContainer>{chats.map(renderChat)}</ListContainer>
    ) : (
        <NoContentContainer>
            <Spacing.Vertical.SMALL />
            <SystemMessage label={formatMessage('home.no-chats')} />
        </NoContentContainer>
    );
}
