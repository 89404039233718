import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ArrowForwardOutlineIcon: FunctionComponent<IconProps> = ({ fill, size = 24 }) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M10 19a1 1 0 01-.64-.23 1 1 0 01-.13-1.41L13.71 12 9.39 6.63a1 1 0 01.15-1.41 1 1 0 011.46.15l4.83 6a1 1 0 010 1.27l-5 6A1 1 0 0110 19z"
                    fill={fill ?? Color.ALMOST_BLACK}
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
