import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useLayoutEffect, useReducer, useState } from 'react';

import { Button } from '~/components/button';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { InputText } from '~/components/input';
import { Loader } from '~/components/loader';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useRequestAccountRemoval } from '~/hooks/account-removal';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { singleAsyncActionStateReducer } from '~/reducers/single-action-reducer';

type AccountRemovalNavigation = StackNavigationProp<MainNavigatorParamList, 'account-removal'>;

export const AccountRemoval: React.FC = props => {
    const { setOptions } = useNavigation<AccountRemovalNavigation>();
    const { formatMessage } = useIntl();

    const [{ state }, dispatch] = useReducer(singleAsyncActionStateReducer, { state: 'init' });
    const [additionalInformation, setAdditionalInformation] = useState('');
    const requestAccountRemoval = useRequestAccountRemoval();

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.account-removal.title') }));

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const handlePress = async () => {
        dispatch('request');
        await requestAccountRemoval(additionalInformation);
        dispatch('success');
    };

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="narrow">
                <Flex mt={4} grow="1" shrink="1">
                    {state === 'init' && (
                        <>
                            <Text.PARAGRAPH_1>
                                <FormattedMessage id="profile.account-removal.description" />
                            </Text.PARAGRAPH_1>
                            <Spacing.Vertical.MEDIUM />
                            <InputText
                                stretch
                                numberOfLines={5}
                                placeholder={formatMessage('profile.account-removal.additional-information')}
                                value={additionalInformation}
                                onChangeText={setAdditionalInformation}
                                style={webComponent ? { flexGrow: 1 } : { height: 50 }}
                            />
                            <Spacing.Vertical.MEDIUM />
                            <Button
                                type="negative"
                                label={formatMessage('profile.account-removal.submit')}
                                onPress={handlePress}
                                fillContainer
                            />
                        </>
                    )}
                    {state === 'loading' && <Loader size="large" />}
                    {state === 'done' && (
                        <Text.HEADER_1>
                            <FormattedMessage id="profile.account-removal.success" />
                        </Text.HEADER_1>
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
};
