import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useMemo } from 'react';

import { isSystemMessage, messageTypeToTranslationKey } from '~/components/chat/system-message-types';
import { IconProps, TickerIcon } from '~/components/icon';
import { useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { useLoadingQuery } from '~/hooks/loading-query';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import {
    ChatListChatFragment,
    ChatListDocument,
    ChatListMemberFragment,
    ChatListMembersFragment,
    ChatListMessageFragment,
    ChatListUserFragment,
    ChatListUsersFragment,
    ChatMessageAttachmentFragment,
    ChatMessageInteractionFragment,
    isFileAttachment,
    isInteractionAttachment
} from '~/types';
import { isSupportedImageFormat, parseFileExtension, relayConnectionReduce } from '~/utils';
import { fromISODateTimeString } from '~/utils/date';

type ChatListNavigator = StackNavigationProp<MainNavigatorParamList>;

export type ChatListUser = {
    id: ID;
    initials: string;
    title?: string;
    firstName: string;
    lastName: string;
    avatarSmallUrl?: string;
};

export type ChatListMessage = {
    id: ID;
    userId?: ID;
    firstName?: string;
    message: string;
    at: Date;
};

export type ChatListItem = {
    id: ID;
    users: ChatListUser[];
    title: string;
    badge: string;
    last: ChatListMessage;
    handleOpen: () => void;
    interactions: Interaction[];
};

export type Interaction = {
    id: ID;
    icon?: React.ComponentType<IconProps>;
    label: string;
    onPress: () => void;
};

const attachmentEmojis = { IMAGE: '📷', FILE: '📄' };

const mapUsersAndMembers = (users: ChatListUsersFragment, members: ChatListMembersFragment, currentUserId: ID) => {
    const reducedUsers = relayConnectionReduce<ChatListUserFragment>(users) ?? [];
    const mappedUsers = reducedUsers
        .filter(user => !user.leftAt)
        .map<ChatListUser>(({ user }) => ({
            id: user.id,
            initials: `${user.firstName[0]} ${user.lastName[0]}`,
            title: user.title ?? undefined,
            firstName: user.firstName,
            lastName: user.lastName,
            avatarSmallUrl: user.avatarSmallUrl!
        }));
    const reducedMembers = relayConnectionReduce<ChatListMemberFragment>(members) ?? [];
    const mappedMembers = reducedMembers
        .filter(member => !member.leftAt && member.member.id !== currentUserId)
        .map<ChatListUser>(({ member }) => ({
            id: member.id,
            initials: `${member.firstName[0]} ${member.lastName[0]}`,
            firstName: member.callName,
            lastName: member.lastName,
            avatarSmallUrl: member.avatarSmallUrl!
        }));
    return mappedUsers.concat(mappedMembers);
};

export type ChatListProps = {
    onlyOpen?: boolean;
};

export function useChatList(props: ChatListProps) {
    const { onlyOpen } = props;

    const { data, loading, error, refetch, loadingInitial } = useLoadingQuery(ChatListDocument, {
        variables: { onlyOpen }
    });
    const { formatMessage } = useIntl();

    const { chats } = data?.root ?? {};

    const { navigate } = useNavigation<ChatListNavigator>();
    const { id: currentUserId } = useUserProfile()!;

    const handleOpenChat = useCallback(
        (chatId: ID, messageId?: ID) => {
            navigate('chat', { chatId, messageId });
        },
        [navigate]
    );

    const mapMessageInteractions = useCallback(
        (chatId: ID, messageInteractions: readonly ChatMessageInteractionFragment[]): Interaction[] => {
            return messageInteractions.map(messageInteraction => {
                return {
                    id: messageInteraction.id,
                    icon: TickerIcon,
                    label: messageInteraction.uiInteraction.interaction.title!,
                    onPress: () => handleOpenChat(chatId, messageInteraction.chatMessage.id)
                };
            });
        },
        [handleOpenChat]
    );

    const messageToChatListMessage = useMemo(
        () => (message: string, type: number, attachments: readonly ChatMessageAttachmentFragment[]) => {
            if (attachments.length > 0) {
                const attachment = attachments[0];
                if (isFileAttachment(attachment)) {
                    const attachmentType = isSupportedImageFormat(parseFileExtension(attachment.filename))
                        ? 'IMAGE'
                        : 'FILE';
                    return `${attachmentEmojis[attachmentType]} ${
                        message ? message : formatMessage('attachment.type_description', { type: attachmentType })
                    }`;
                } else if (isInteractionAttachment(attachment)) {
                    return attachment.uiInteraction.interaction.label!;
                } else {
                    return attachment.title ?? '';
                }
            }
            if (isSystemMessage(type)) {
                // Timestamp not needed as it's shown elsewhere in the chat list
                return formatMessage(messageTypeToTranslationKey(type)!, { timestamp: '' });
            }
            return message;
        },
        [formatMessage]
    );

    const chatListChats = useMemo(
        () =>
            relayConnectionReduce<ChatListChatFragment>(chats)?.map<ChatListItem>(
                ({
                    id: chatId,
                    title,
                    messages,
                    users,
                    members,
                    messageInteractions,
                    unreadMessagesCountV2: unreadMessagesCount
                }) => ({
                    id: chatId,
                    title,
                    handleOpen: () => handleOpenChat(chatId),
                    badge: unreadMessagesCount > 0 ? '' + unreadMessagesCount : '',
                    interactions: mapMessageInteractions(chatId, messageInteractions ?? []),
                    users: mapUsersAndMembers(users, members, currentUserId),
                    last: relayConnectionReduce<ChatListMessageFragment>(messages)!.map<ChatListMessage>(
                        ({ id, message, createdAt, user, member, attachmentsV2, type }) => ({
                            id,
                            userId: user ? user.id : member?.id,
                            message: messageToChatListMessage(message, type, attachmentsV2),
                            firstName: user ? user.firstName : member?.callName,
                            at: fromISODateTimeString(createdAt)
                        })
                    )?.[0]
                })
            ),
        [currentUserId, chats, handleOpenChat, mapMessageInteractions, messageToChatListMessage]
    );

    return { loading, loadingInitial, error, chats: chatListChats, refetch };
}
