import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { ArrowForwardOutlineIcon, ChevronLeftIcon } from '~/components/icon';
import { List, ListItem, ListItemDivider } from '~/components/list';
import { LoadingContent } from '~/components/loader';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { SystemMessage } from '~/components/system-message';
import { useIntl } from '~/contexts/intl';
import { useDocumentList } from '~/hooks/document-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type DocumentsNavigation = StackNavigationProp<MainNavigatorParamList, 'documents'>;

const NoContentContainer = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export function Documents() {
    const { documents, loading, error } = useDocumentList({ unseenOnly: false });
    const { navigate, setOptions, goBack, canGoBack } = useNavigation<DocumentsNavigation>();
    const { formatMessage, formatDate } = useIntl();

    useErrorHandler(error);

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.documents.title') }));

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                <Flex flexDir="column" grow={1} paddingTop={4} paddingLeft={4} paddingRight={4}>
                    {webComponent && (
                        <Flex paddingBottom="4">
                            <Button
                                leftIcon={ChevronLeftIcon}
                                size="small"
                                type="text"
                                label={formatMessage('goBack').toUpperCase()}
                                style={{ maxWidth: 140 }}
                                onPress={() => (canGoBack() ? goBack() : navigate('profile'))}
                            />
                        </Flex>
                    )}
                    {loading ? (
                        <LoadingContent />
                    ) : (
                        <List
                            data={documents ?? []}
                            renderItem={({ item }) => (
                                <ListItem
                                    title={item.filename}
                                    accessory={() => <ArrowForwardOutlineIcon />}
                                    description={formatDate(item.addedDate, { dateStyle: 'long' })}
                                    onPress={() => navigate('document', { documentId: item.id })}
                                />
                            )}
                            ItemSeparatorComponent={ListItemDivider}
                            ListEmptyComponent={
                                <NoContentContainer>
                                    <Spacing.Vertical.MASSIVE />
                                    <SystemMessage label={formatMessage('profile.documents.none')} />
                                </NoContentContainer>
                            }
                        />
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
}
