import * as Linking from 'expo-linking';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';

import { LatLng, MapView, Marker, Region as MapRegion } from '~/components/map';

type Props = {
    name: string;
    region?: MapRegion;
    coordinates?: LatLng;
};

export const SingleMarkerMap: React.FC<Props> = props => {
    const { name, region, coordinates } = props;

    const handleMapPress = useCallback(async () => {
        if (coordinates) {
            const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'https:' });
            const latLng = `${coordinates.latitude},${coordinates.longitude}`;

            const url = Platform.select({
                ios: `${scheme}${name}@${latLng}`,
                android: `${scheme}${latLng}(${name})`,
                web: `${scheme}//www.google.com/maps/@${latLng},13z`
            });

            if (scheme && url) {
                await Linking.openURL(url);
            }
        }
    }, [coordinates, name]);

    if (!region) {
        return null;
    }

    return (
        <MapView
            testID="SingleMarkerMapView"
            scrollEnabled={false}
            zoomEnabled={false}
            zoomTapEnabled={false}
            style={{ width: 80, height: 80, borderRadius: 40 }}
            initialRegion={region}
            onPress={handleMapPress}
        >
            {coordinates ? <Marker coordinate={coordinates} /> : null}
        </MapView>
    );
};
