import { useHeaderHeight } from '@react-navigation/elements';
import { Flex } from 'native-base';
import React from 'react';
import { RefreshControl } from 'react-native';

import { ArticleRoot } from '~/components/article';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Loader } from '~/components/loader';
import { NoContentContainer } from '~/components/no-content-container';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { useIntl } from '~/contexts/intl';
import { REACHABILITY_THRESHOLD } from '~/contexts/network-service-state';
import { useArticle } from '~/hooks/article';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';

export const Library: React.FC = () => {
    const { formatMessage } = useIntl();
    const headerHeight = useHeaderHeight();

    const { article, loading, loadingInitial, refetch } = useArticle({ tag: 'root_explore' });
    const { title } = article ?? {};

    const { onScroll } = useExtendedNavigationHeader({
        title: title ?? formatMessage('library.title')
    });

    if (loadingInitial) {
        return (
            <NoContentContainer>
                <Loader delay={REACHABILITY_THRESHOLD} size="medium" />
            </NoContentContainer>
        );
    }

    return (
        <ContentScrollView
            onScroll={onScroll}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            overScrollMode="never"
            refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}
        >
            <ContentHorizontalMargins>
                <Flex grow="1" marginTop={{ base: headerHeight, md: headerHeight * 1.5 }} width="100%">
                    {article ? <ArticleRoot article={article} /> : null}
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
};
