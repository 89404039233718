import React, { PropsWithChildren, useContext, useMemo } from 'react';

import { getAuthIdentity, clearAuthIdentity, setAuthIdentity } from './user-identity-persist';

export type UserIdentityContextType = {
    set: (id: string) => Promise<void>;
    get: () => Promise<string | null>;
    clear: () => Promise<void>;
};

export const UserIdentityContext = React.createContext<UserIdentityContextType | undefined>(undefined);

function UserIdentityContextProvider(props: PropsWithChildren<object>) {
    const { children } = props;

    const identity = useMemo<UserIdentityContextType>(
        () => ({
            set: setAuthIdentity,
            get: getAuthIdentity,
            clear: clearAuthIdentity
        }),
        []
    );
    return <UserIdentityContext.Provider value={identity}>{children}</UserIdentityContext.Provider>;
}

export const UserIdentityConsumer = UserIdentityContext.Consumer;
export const UserIdentityProvider = UserIdentityContextProvider;

export function useUserIdentity() {
    const context = useContext<UserIdentityContextType | undefined>(UserIdentityContext);
    if (!context) {
        throw Error('Cannot use user identity context until it has been defined');
    }
    return context;
}
