import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Box, Flex, useBreakpointValue } from 'native-base';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, ViewToken } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ChatSidebar } from '~/components/chat';
import { ChatInputSection } from '~/components/chat/chat-input-section';
import { ChatMessages } from '~/components/chat/chat-messages';
import { ChatNotificationAlerts } from '~/components/chat/chat-notification-alerts';
import { ChatNotificationInlineMessages } from '~/components/chat/chat-notification-inline-messages';
import { ScrollToBottomButton } from '~/components/chat/scroll-to-bottom-button';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { ErrorModal } from '~/components/error';
import { KeyboardShift } from '~/components/keyboard-shift';
import { Loader, LoadingContent } from '~/components/loader';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { useUserProfile } from '~/contexts/user-profile';
import { ChatMessage, isSeenableChatMessage, useChat } from '~/hooks/chat';
import { useAddChatMessage } from '~/hooks/chat/add-chat-message';
import { useMarkAsSeen } from '~/hooks/chat/mark-as-seen';
import { useInteractionAction } from '~/hooks/interaction-action';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type ChatNavigationProp = StackNavigationProp<MainNavigatorParamList, 'chat'>;
type ChatRouteProp = RouteProp<MainNavigatorParamList, 'chat'>;

type ChatProps = {
    navigation: ChatNavigationProp;
    route: ChatRouteProp;
};

const ChatScreen: FunctionComponent<ChatProps> = ({ route, navigation }) => {
    const {
        params: { chatId, messageId }
    } = useRoute<ChatRouteProp>();
    const { canGoBack, goBack, setOptions, navigate } = navigation;

    const {
        loadingInitial,
        error,
        title,
        messages,
        loadMore,
        loadingMore,
        lastMessageSeenInfos,
        hasMoreMessages,
        alertNotifications,
        inlineNotifications,
        canSendMessages,
        lastMessageSeenId,
        unreadMessagesCount,
        messageInteractions,
        users
    } = useChat({ ensureMessagePresent: messageId, queryVariables: { chatId } });

    const { sendMessage } = useAddChatMessage(chatId);

    const { id } = useUserProfile()!;
    const headerHeight = useHeaderHeight();
    const { bottom } = useSafeAreaInsets();

    const showSidebar: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const chatContainerStyles = useBreakpointValue({
        base: {},
        md: {
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: Color.BORDER_DEFAULT,
            borderBottomRadius: 16,
            marginLeft: '32px',
            marginBottom: '32px'
        }
    });

    useEffect(() => {
        if (title) {
            setOptions({ title });
        }
    }, [setOptions, title]);

    const flatList = useRef<FlatList<ChatMessage>>(null);
    const [scrollToBottomY, setScrollToBottomY] = useState(0);
    const [showScrollToBottomButton, setShowScrollToBottomButton] = useState(false);

    const { onAction } = useInteractionAction();
    const markAsSeen = useMarkAsSeen(chatId, lastMessageSeenId);

    const onLayout = useCallback((event: LayoutChangeEvent) => {
        const { y, height } = event.nativeEvent.layout;
        setScrollToBottomY(y + height - Spacing.GIGANTIC);
    }, []);

    const onEndReached = useCallback(async () => {
        if (hasMoreMessages) {
            await loadMore();
        }
    }, [hasMoreMessages, loadMore]);

    const onViewableItemsChanged = useCallback(
        (items: { changed: ViewToken[]; viewableItems: ViewToken[] }) => {
            const viewableMessages: ChatMessage[] = items.viewableItems.map(item => item.item);
            if (viewableMessages.length > 0) {
                markAsSeen(viewableMessages.filter(isSeenableChatMessage).map(message => message.id));
            }
        },
        // onViewableItemsChanged should be never changed on the fly
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingInitial]
    );

    const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const {
            contentOffset: { y: contentOffset }
        } = event.nativeEvent;
        setShowScrollToBottomButton(contentOffset > 50);
    }, []);

    const scrollToMessageId = useMemo(() => messageId ?? lastMessageSeenId, [lastMessageSeenId, messageId]);

    const scrollToIndex = useCallback((index: number) => {
        flatList.current?.scrollToIndex({
            index,
            animated: true,
            viewPosition: 1
        });
    }, []);

    const scrollToEnd = useCallback(() => {
        scrollToIndex(0);
    }, [scrollToIndex]);

    // Needed if item we are trying to scroll to is not already rendered on the list, i.e.
    // is outside screen borders.
    const onScrollToIndexFailed = (info: { averageItemLength: number; index: number }) => {
        if (flatList.current) {
            flatList.current.scrollToOffset({
                offset: info.averageItemLength * info.index,
                animated: true
            });
        }

        setTimeout(() => {
            scrollToIndex(info.index);
        }, 100);
    };

    useEffect(() => {
        if (!loadingInitial) {
            setTimeout(() => {
                if (scrollToMessageId) {
                    const messageIndex = messages.findIndex(message => message.id === scrollToMessageId);
                    if (messageIndex > 0) {
                        scrollToIndex(messageIndex);
                    }
                }
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingInitial]);

    if (error) {
        return (
            <ErrorModal error={error} onRetry={() => {}} onClose={() => (canGoBack() ? goBack() : navigate('home'))} />
        );
    }

    return (
        <ScreenContainer style={{ paddingBottom: bottom }}>
            <KeyboardShift headerHeight={headerHeight}>
                {loadingInitial ? (
                    <LoadingContent />
                ) : (
                    <ContentHorizontalMargins contentWidth="default" includePadding={showSidebar}>
                        {showSidebar && (
                            <ChatSidebar users={users} messageInteractions={messageInteractions} title={title!} />
                        )}
                        <Flex direction="column" grow="1" shrink="1" pt="2" {...chatContainerStyles}>
                            {alertNotifications.length > 0 && (
                                <ChatNotificationAlerts alerts={alertNotifications} chatId={chatId} />
                            )}

                            {loadingMore && (
                                <Flex my={Spacing.LARGE}>
                                    <Loader size="small" />
                                </Flex>
                            )}

                            <Flex shrink="1" grow="1">
                                <ChatMessages
                                    ref={flatList}
                                    messages={messages}
                                    onAction={onAction}
                                    lastMessageSeenInfos={lastMessageSeenInfos}
                                    userId={id}
                                    onViewableItemsChanged={onViewableItemsChanged}
                                    onLayout={onLayout}
                                    onScroll={onScroll}
                                    onScrollToIndexFailed={onScrollToIndexFailed}
                                    onEndReached={onEndReached}
                                />
                            </Flex>
                            {inlineNotifications.length > 0 && (
                                <ChatNotificationInlineMessages inlines={inlineNotifications} />
                            )}
                            <ChatInputSection
                                chatId={chatId}
                                onSendMessage={sendMessage}
                                canSendMessages={canSendMessages}
                            />

                            <Box position="absolute" right={4} top={`${scrollToBottomY}px`}>
                                {showScrollToBottomButton && (
                                    <ScrollToBottomButton
                                        badge={
                                            unreadMessagesCount === undefined || unreadMessagesCount === 0
                                                ? undefined
                                                : '' + unreadMessagesCount
                                        }
                                        onPress={() => scrollToEnd()}
                                    />
                                )}
                            </Box>
                        </Flex>
                    </ContentHorizontalMargins>
                )}
            </KeyboardShift>
        </ScreenContainer>
    );
};

export { ChatScreen as Chat };
