import React from 'react';
import { View } from 'react-native';
import { Svg, Text } from 'react-native-svg';

import ChatAttachmentIconIcon from '~/assets/icons/chat-attachment.svg';
import { Color } from '~/components/color';

type ChatAttachmentIconProps = {
    text: string;
    size?: 'small' | 'regular';
};

export const ChatAttachmentIcon = (props: ChatAttachmentIconProps) => {
    const { size = 'regular', text } = props;

    return (
        <View>
            <ChatAttachmentIconIcon width={size === 'small' ? 32 : 44} />
            <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
                {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
                <Svg accessibilityRole="image" focusable={false} tabIndex="-1">
                    <Text
                        fill={Color.ALMOST_BLACK}
                        fontSize={size === 'small' ? 5 : 7}
                        x={size === 'small' ? 16 : 22}
                        y={size === 'small' ? 26 : 30}
                        strokeWidth="1"
                        textAnchor="middle"
                    >
                        {text.toUpperCase()}
                    </Text>
                </Svg>
            </View>
        </View>
    );
};
