import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Color } from '~/components/color';
import { SeenIcon } from '~/components/icon';

type Props = {
    imageUris: string[];
};

const MessageSeenContainer = styled.View`
    flex-direction: row;
    background-color: ${Color.BACKGROUND_PRIMARY};
    border-radius: 30px;
    align-items: center;
    padding: 3px;
`;

const AvatarContainer = styled.View`
    margin-left: 2px;
`;

export const MessageSeen = (props: Props) => {
    const { imageUris } = props;
    return (
        <View style={{ flexDirection: 'row' }}>
            <MessageSeenContainer>
                <SeenIcon fill={Color.ALMOST_BLACK} />
                {imageUris.map((imageUri, index) => (
                    // See https://shopify.github.io/flash-list/docs/fundamentals/performant-components#remove-key-prop
                    /* eslint-disable-next-line react/no-array-index-key */
                    <AvatarContainer key={index}>
                        <Avatar size="tiny" imageUri={imageUri} />
                    </AvatarContainer>
                ))}
            </MessageSeenContainer>
        </View>
    );
};
