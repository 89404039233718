import React, { useCallback, useState } from 'react';

import { Button } from '~/components/button';
import { ModalPicker } from '~/components/modal-picker';
import { FormattedMessage, useIntl } from '~/contexts/intl';

import { isWeb } from '~/utils';
import { AppointmentActionDescription, AppointmentActionLink, AppointmentActionTitle } from './appointment-actions';

export type AppointmentModifyProps = {
    visible: boolean;
    modifiable: boolean;
    onModifyCalendarEvent: () => Promise<void>;
    onCancel: () => void;
};

export function AppointmentModify(props: AppointmentModifyProps) {
    const { visible, modifiable, onModifyCalendarEvent, onCancel } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { formatMessage } = useIntl();

    const handleModifyCalendarEvent = useCallback(async () => {
        try {
            setLoading(true);
            await onModifyCalendarEvent();
        } finally {
            setLoading(false);
        }
    }, [onModifyCalendarEvent]);

    const handleLink = useCallback(() => {
        if (modifiable) {
            // TODO: navigate to terms
        } else {
            // TODO: navigate to cancellation terms
        }
    }, [modifiable]);

    return (
        <ModalPicker
            visible={visible}
            bottomPadding={modifiable}
            extraStyles={isWeb() ? { width: 343, marginLeft: 'auto', marginRight: 'auto' } : undefined}
            renderContent={() => (
                <>
                    <AppointmentActionTitle>
                        <FormattedMessage id="appointment.modify.title" values={{ modifiable }} />
                    </AppointmentActionTitle>
                    <AppointmentActionDescription>
                        <FormattedMessage
                            id="appointment.modify.description"
                            values={{
                                link: (value: string) => (
                                    <AppointmentActionLink onPress={handleLink}>{value}</AppointmentActionLink>
                                ),
                                modifiable
                            }}
                        />
                    </AppointmentActionDescription>
                </>
            )}
            renderActions={() =>
                modifiable ? (
                    <Button
                        alignCenter
                        fillContainer
                        loading={loading}
                        type="secondary"
                        shape="rounded"
                        label={formatMessage('appointment.modify')}
                        onPress={handleModifyCalendarEvent}
                    />
                ) : null
            }
            onCancel={onCancel}
        />
    );
}
