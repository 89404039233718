import React from 'react';
import { View } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export function TabMeIcon({ fill, size = 32 }: IconProps) {
    return (
        <View style={{ width: size, height: size }}>
            <Svg width="34" height="32" viewBox="0 0 34 32" fill="none">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8848 19.0292C14.6181 18.6695 8.32954 21.7532 3.00955 29.6939C2.98087 29.7367 2.97469 29.7691 2.97411 29.7929C2.97344 29.8204 2.98036 29.8535 2.99909 29.8873C3.03568 29.9534 3.10224 30.001 3.20198 30.001H31.4427C31.5249 30.001 31.5872 29.9648 31.6246 29.917C31.6592 29.8728 31.6686 29.827 31.6527 29.7732C29.7109 23.1778 25.1259 19.3871 19.8848 19.0292ZM20.0211 17.0338C26.2558 17.4596 31.4414 21.9744 33.5713 29.2083C33.9996 30.6629 32.8604 32.001 31.4427 32.001H3.20198C1.4749 32.001 0.339229 30.0863 1.34799 28.5807C6.91997 20.2639 13.8121 16.6098 20.0211 17.0338Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6105 2.12947C19.0289 1.51334 16.0933 3.18515 15.1569 5.9675C14.2384 8.69698 15.6424 11.2761 18.1414 11.8725C20.723 12.4887 23.6586 10.8169 24.595 8.03451C25.5135 5.30503 24.1095 2.72588 21.6105 2.12947ZM13.2614 5.32961C14.4974 1.65662 18.3971 -0.693636 22.0748 0.184105C25.8352 1.08157 27.7443 4.94653 26.4905 8.6724C25.2545 12.3454 21.3548 14.6956 17.6771 13.8179C13.9167 12.9204 12.0076 9.05548 13.2614 5.32961Z"
                    fill={fill ?? Color.ICON_DEFAULT}
                />
            </Svg>
        </View>
    );
}
