import { useMemo } from 'react';

export const useEllipticMaskValues = (rx: number, ry: number, minimumPointX: number, height: number = 1000) => {
    // Angle fixed for 45 degree tilt, if this is changed, need to check that axis shifts are calculated
    // correctly and the right solution to quadratic formulas are still chosen.
    const degAngle = -45;

    const [cx, cy] = useMemo(() => {
        // Angle converted to radians for Math functions
        const radAngle = (degAngle * 2 * Math.PI) / 360;

        // Some calculations needed to find how to position the clip path ellipse so that the minimum y value is at the
        // desired location, basically at the bottom of the visible canvas and minimumPointX from left

        // General equation for ellipse is (https://en.wikipedia.org/wiki/Ellipse#General_ellipse)
        // A * x^2 + B * x * y + C * y^2 + D * x + E * y + F = 0,
        //
        // where coefficients A-F can be calculated with semi-major axis rx, semi-minor axis ry, center coordinates (x0, y0)
        // and rotation angle. Center coordinates for this are always at origo so relevant coefficients A, B, C, F for this are
        // A = rx^2 * sin^2 angle + ry^2 * cos^2 angle
        // B = 2 * (ry^2 - rx^2) * sin angle * cos angle
        // C = rx^2 * cos^2 angle + ry^2 * sin^2 angle
        // F = -rx^2 * ry^2
        // Coefficients D and E are always 0 when center is at origo

        const A = Math.pow(rx, 2) * Math.pow(Math.sin(radAngle), 2) + Math.pow(ry, 2) * Math.pow(Math.cos(radAngle), 2);
        const B = 2 * (Math.pow(ry, 2) - Math.pow(rx, 2)) * Math.sin(radAngle) * Math.cos(radAngle);
        const C = Math.pow(rx, 2) * Math.pow(Math.cos(radAngle), 2) + Math.pow(ry, 2) * Math.pow(Math.sin(radAngle), 2);
        const F = -1 * Math.pow(rx, 2) * Math.pow(ry, 2);

        // To find the x for which the ellipse gets it minimum y value, partial derivative dy / dx for the equation is needed:
        //
        // dy / dx = (B * x + 2 * C * y) / (2 * A * x + B * y)
        //
        // Next, find where dy / dx = 0 is zero in terms of x (-> where numerator is zero)
        //
        // x = (-2 * C * y) / B
        //
        // then substitute x in the original equation with (-2 * C * y) / B and solve it
        //
        // A * ((-2 * C * y) / B)^2 + B * ((-2 * C * y) / B) * y + C * y^2 + F = 0
        // A * ((4 * C^2 * y^2) / B^2) + (-2 * C * y^2) + C * y^2 + F
        // ((4 * A * C^2) / B^2) * y^2 - C * y^2 + F = 0
        // ((4 * A * C^2) / B^2 + C) * y^2 = -F
        // y = +-sqrt(-F / ((4 * A * C^2) / B^2 + C))
        //
        // Now it is possible to calculate the position for minimum Y value

        const xCoordinateForMinimumYValue = Math.sqrt((-1 * F) / ((4 * A * C * C) / (B * B) - C));

        // Then we calculate the actual value in this position by just solving the quadratic equation with the x value
        // found in last step using the standard quadratic formula. We are looking for the minimum value so we can
        // skip the other solution.

        const a = C;
        const b = B * xCoordinateForMinimumYValue;
        const c = A * Math.pow(xCoordinateForMinimumYValue, 2) + F;
        const minimumYValue = (-1 * b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);

        // Now based on these values we get the desired place  for the center of the ellipse to get the minimum
        // position in coordinates (minimumPointX, -1 * height)

        const x = minimumPointX + xCoordinateForMinimumYValue;
        const y = -1 * height - minimumYValue; // 2 safety pixels for floating point errors

        // As a last step we need to calculate the center coordinates in rotated axis as svg ellipse rotates the
        // axis also for cx and cy. Luckily for -45 degree rotation that is simply using the pythagorean theorem
        // a^2 + b^2 = c^2

        const shiftForX = Math.sqrt((x * x) / 2);
        const shiftForY = Math.sqrt((y * y) / 2);
        const directionForY = y < 0 ? 1 : -1;

        const centerX = shiftForX - directionForY * shiftForY;
        const centerY = shiftForX + directionForY * shiftForY;

        return [centerX, centerY];
    }, [rx, ry, degAngle, minimumPointX, height]);

    return [cx, cy];
};
