import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const SeenIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M18.1374 12.4184C16.9451 15.9613 13.1988 18.1379 9.82157 17.3319C6.48563 16.5358 4.67925 13.0981 5.86264 9.58162C7.05493 6.03866 10.8012 3.86206 14.1784 4.66809C17.5144 5.46425 19.3208 8.90186 18.1374 12.4184Z"
                    strokeWidth={1}
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    // @ts-ignore
                    tabIndex="-1"
                />
                <Path
                    d="M10.7809 10.3753C10.4359 9.94404 9.80657 9.87412 9.3753 10.2191C8.94404 10.5641 8.87412 11.1934 9.21913 11.6247L10.7809 10.3753ZM11.6 13L10.8191 13.6247C11.034 13.8932 11.3717 14.0325 11.7134 13.9936C12.055 13.9546 12.3527 13.7428 12.5015 13.4327L11.6 13ZM9.21913 11.6247L10.8191 13.6247L12.3809 12.3753L10.7809 10.3753L9.21913 11.6247ZM12.5015 13.4327L14.9015 8.43273L13.0985 7.56727L10.6985 12.5673L12.5015 13.4327Z"
                    strokeWidth={1}
                    fill={fill ?? Color.ALMOST_BLACK}
                    stroke={fill ?? Color.ALMOST_BLACK}
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
