import { WatchQueryFetchPolicy } from '@apollo/client';

const keys = new Map<string, number>();

export function getFetchPolicyForKey(key: string, expiration: number): WatchQueryFetchPolicy {
    const lastFetchTimestamp = keys.get(key);
    const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;
    let fetchPolicy: WatchQueryFetchPolicy = 'cache-first';

    if (diffFromNow > expiration) {
        keys.set(key, Date.now());
        fetchPolicy = 'network-only';
    }
    return fetchPolicy;
}

export function resetFetchPolicyForKey(key: string) {
    keys.delete(key);
}
