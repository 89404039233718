import { AuthenticationType } from 'expo-local-authentication/src/LocalAuthentication.types';
import { useCallback, useMemo, useReducer } from 'react';
import useAsyncEffect from 'use-async-effect';

import { AuthActionResult, BiometricAuthActionResult, PinCode } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { timeout } from '~/utils';

import { INPUT_EMPTY, PIN_CODE_LENGTH, PIN_ENTRY_DELAY, REFERENCE_INVALID, inputToDisplayStates } from './pin-code';

type PinAuthenticateInputState =
    | 'clear-pin-code'
    | 'enter-pin-code'
    | 'authenticate-pin-code'
    | 'biometric-auth-failed'
    | 'valid-pin-code'
    | 'invalid-pin-code';

type PinCodeAuthenticateState = {
    inputState: PinAuthenticateInputState;
    input: PinCode;
    reference: PinCode | undefined;
};

type PinCodeAuthenticateAction =
    | { type: 'enter-digit'; digit: number }
    | { type: 'delete-digit' }
    | { type: 'set-pin-code'; pin: PinCode }
    | { type: 'set-pin-code-fail' }
    | { type: 'clear-pin-code' }
    | { type: 'auth-success' }
    | { type: 'auth-failure' };

const pinCodeAuthenticateReducer = (
    state: PinCodeAuthenticateState,
    action: PinCodeAuthenticateAction
): PinCodeAuthenticateState => {
    const { inputState, input, reference } = state;
    switch (action.type) {
        case 'enter-digit': {
            const { digit } = action;
            switch (inputState) {
                case 'clear-pin-code':
                case 'biometric-auth-failed':
                case 'enter-pin-code': {
                    const inputValue = `${input}${digit}`;
                    return {
                        inputState: inputValue.length < PIN_CODE_LENGTH ? 'enter-pin-code' : 'authenticate-pin-code',
                        input: inputValue,
                        reference: undefined
                    };
                }
                case 'valid-pin-code': {
                    return { inputState, input, reference: input };
                }
                case 'invalid-pin-code': {
                    return { inputState: 'clear-pin-code', input: `${digit}`, reference: undefined };
                }
            }
            return state;
        }
        case 'delete-digit': {
            switch (inputState) {
                case 'enter-pin-code': {
                    if (input.length > 0) {
                        const inputValue = input.slice(0, -1);
                        return { inputState, input: inputValue, reference };
                    }
                    return state;
                }
                case 'valid-pin-code':
                case 'invalid-pin-code': {
                    return { inputState: 'clear-pin-code', input: INPUT_EMPTY, reference: undefined };
                }
            }
            return state;
        }
        case 'set-pin-code': {
            const { pin } = action;
            switch (inputState) {
                case 'clear-pin-code':
                case 'biometric-auth-failed':
                case 'invalid-pin-code':
                case 'enter-pin-code': {
                    return {
                        inputState: 'authenticate-pin-code',
                        input: pin,
                        reference: undefined
                    };
                }
            }
            return state;
        }
        case 'set-pin-code-fail': {
            return { inputState: 'biometric-auth-failed', input: INPUT_EMPTY, reference: undefined };
        }
        case 'clear-pin-code': {
            return { inputState: 'clear-pin-code', input: INPUT_EMPTY, reference: undefined };
        }
        case 'auth-success': {
            if (inputState === 'authenticate-pin-code') {
                return { inputState: 'valid-pin-code', input, reference: input };
            }
            return state;
        }
        case 'auth-failure': {
            if (inputState === 'authenticate-pin-code') {
                return { inputState: 'invalid-pin-code', input, reference: REFERENCE_INVALID };
            }
            return state;
        }
    }
};

export type PinCodeAuthenticateHookProps = {
    onPinCodeAuthenticate: (pin: string) => Promise<AuthActionResult>;
    onPinCodeAuthenticateFailed: (reason?: string) => Promise<void>;
    onPinCodeClear?: () => void;
    biometricAuthType?: AuthenticationType;
    authenticateOnMount?: boolean;
    onBiometricAuthenticate?: () => Promise<BiometricAuthActionResult>;
};

export function usePinCodeAuthenticate(props: PinCodeAuthenticateHookProps) {
    const {
        onPinCodeAuthenticate,
        onPinCodeAuthenticateFailed,
        onPinCodeClear,
        biometricAuthType,
        authenticateOnMount,
        onBiometricAuthenticate
    } = props;

    const { formatMessage } = useIntl();
    const [{ inputState, input, reference }, dispatch] = useReducer(pinCodeAuthenticateReducer, {
        inputState: 'enter-pin-code',
        input: INPUT_EMPTY,
        reference: undefined
    });

    const states = inputToDisplayStates(input, PIN_CODE_LENGTH, reference);

    const handleEnter = useCallback((digit: number) => dispatch({ type: 'enter-digit', digit }), [dispatch]);
    const handleDelete = useCallback(() => dispatch({ type: 'delete-digit' }), [dispatch]);

    useAsyncEffect(
        async mounted => {
            if (mounted()) {
                if (inputState === 'authenticate-pin-code') {
                    const result = await onPinCodeAuthenticate(input);
                    if (result.success) {
                        dispatch({ type: 'auth-success' });
                        await timeout(PIN_ENTRY_DELAY);
                        result.commit();
                    } else {
                        if (result.reason === 'pin-auth-invalid-pin-code') {
                            dispatch({ type: 'auth-failure' });
                        } else {
                            dispatch({ type: 'clear-pin-code' });
                        }
                        await onPinCodeAuthenticateFailed(result.reason);
                    }
                }
                if (inputState === 'clear-pin-code') {
                    onPinCodeClear?.();
                }
            }
        },
        [inputState, input]
    );

    const handleBiometricAuthenticate = useCallback(async () => {
        if (onBiometricAuthenticate) {
            const result = await onBiometricAuthenticate();
            if (result.success) {
                dispatch({ type: 'set-pin-code', pin: result.pinCode });
            } else {
                dispatch({ type: 'set-pin-code-fail' });
                onPinCodeClear?.();
                if (result.reason !== 'biometric-auth-failed') {
                    await onPinCodeAuthenticateFailed(result.reason);
                }
            }
        }
    }, [onBiometricAuthenticate, onPinCodeAuthenticateFailed, onPinCodeClear]);

    useAsyncEffect(async () => {
        if (biometricAuthType && authenticateOnMount) {
            await handleBiometricAuthenticate();
        }
    }, [biometricAuthType, authenticateOnMount]);

    const messages: { [key in PinAuthenticateInputState]: string } = useMemo(
        () => ({
            'clear-pin-code': formatMessage('pin-code-validate.enter-pin'),
            'enter-pin-code': formatMessage('pin-code-validate.enter-pin'),
            'authenticate-pin-code': formatMessage('pin-code-validate.enter-pin'),
            'biometric-auth-failed': formatMessage('pin-code-validate.biometric-auth-failed'),
            'valid-pin-code': formatMessage('pin-code-validate.valid-pin'),
            'invalid-pin-code': formatMessage('pin-code-validate.invalid-pin')
        }),
        [formatMessage]
    );

    return {
        states,
        message: messages[inputState],
        input,
        handleEnter,
        handleDelete,
        biometricAuthenticate: handleBiometricAuthenticate
    };
}
