import React from 'react';

import { CalendarEventList } from '~/components/calendar-event-list';
import { Spacing } from '~/components/spacing';
import { CalendarEvent } from '~/types';

export type UpcomingAppointmentsProps = {
    handleOpenCalendarEvent?: (calendarEvent: CalendarEvent) => void;
    calendarEvents?: CalendarEvent[];
    isDisabled?: boolean;
};

export const UpcomingAppointments = (props: UpcomingAppointmentsProps) => {
    const { calendarEvents, handleOpenCalendarEvent, isDisabled = false } = props;

    if ((calendarEvents?.length ?? 0) === 0) {
        return null;
    }

    return (
        <CalendarEventList
            calendarEvents={calendarEvents}
            onOpenCalendarEvent={handleOpenCalendarEvent}
            layoutStyle={{ marginBottom: Spacing.MEDIUM }}
            isDisabled={isDisabled}
        />
    );
};
