import { encode } from 'base-64';

import { Maybe } from '~/types';

export type RelayNode<T> = { readonly __typename: string } & Partial<T>;
export type RelayEdge<T> = Maybe<{ readonly node?: Maybe<T> }>;
export type RelayConnection<T> = Maybe<{ readonly edges?: readonly Maybe<RelayEdge<T>>[] }>;

export const relayConnectionReduce = <T>(connection?: Maybe<RelayConnection<T>>): T[] | undefined =>
    connection?.edges?.reduce((acc, edge) => (edge?.node ? [...acc, edge.node] : acc), [] as T[]);

export const relayCursorForIndex = (index: number) => encode(`arrayconnection:${index}`);

export const relayCursorForLastEdge = <T>(edges: readonly RelayEdge<T>[]) => relayCursorForIndex(edges.length - 1);
export const relayCursorForFirstEdge = <T>(_edges: readonly RelayEdge<T>[]) => relayCursorForIndex(0);
