import React, { Ref } from 'react';
import { FlatList, FlatListProps } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

type ListStyleProps = {
    appearance?: 'default' | 'compact';
};

export type ListProps<T> = FlatListProps<T> & ListStyleProps;
export type ListElement<T> = React.ReactElement<ListProps<T>>;

const ListContainer = styled.View<ListStyleProps>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const List = <T extends any>({
    ref,
    keyExtractor,
    appearance,
    ...props
}: ListProps<T> & { ref?: Ref<FlatList<T>> }) => {
    const extractor = (_item: T, index: number): string => index.toString();
    return (
        <ListContainer>
            <FlatList<T> ref={ref} keyExtractor={keyExtractor ?? extractor} {...props} />
        </ListContainer>
    );
};
