import { useMediaQuery } from 'react-responsive';

export const useBreakpoints = () => {
    const desktop = useMediaQuery({ minDeviceWidth: 1024 });
    const tablet = useMediaQuery({ minDeviceWidth: 768, maxDeviceWidth: 1024 });
    const mobile = useMediaQuery({ maxDeviceWidth: 768 });

    return {
        desktop,
        tablet,
        mobile
    };
};
