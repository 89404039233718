import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { Color } from '~/components/color';

type IconProps = {
    color?: Color;
};

export const DeleteIcon: React.FC<IconProps> = props => {
    const color = props.color ?? Color.ALMOST_BLACK;

    return (
        <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <Path
                d="M9.08725 9.94182C9.65407 9.34072 10.4437 9 11.2699 9H25C26.6569 9 28 10.3431 28 12V20C28 21.6569 26.6569 23 25 23H11.2699C10.4437 23 9.65407 22.6593 9.08725 22.0582L5.31532 18.0582C4.22549 16.9025 4.22549 15.0975 5.31532 13.9418L9.08725 9.94182Z"
                stroke={color}
                strokeWidth="2"
            />
            <Path d="M14.375 18.9164L20.6251 13.083" stroke={color} strokeWidth="2" />
            <Path d="M14.3749 13.083L20.625 18.9164" stroke={color} strokeWidth="2" />
        </Svg>
    );
};

export const FaceIdIcon = () => (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
        <Path
            d="M24.7199 16.3505L24.7198 16.3506C24.0344 21.5155 19.6587 25.2667 15.2866 25.0803C10.9901 24.8971 7.71756 20.9326 8.39716 15.8105C9.08252 10.645 13.4586 6.89296 17.8307 7.08025C22.1276 7.26431 25.4 11.229 24.7199 16.3505Z"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
        />
        <Path
            d="M15.7117 15.0191C15.417 16.3444 14.4899 17.1702 13.641 16.8635C12.792 16.5568 12.3426 15.2338 12.6373 13.9084C12.9319 12.583 13.859 11.7573 14.708 12.064C15.5569 12.3707 16.0063 13.6937 15.7117 15.0191Z"
            fill={Color.ALMOST_BLACK}
        />
        <Rect x="16.6172" y="13.6426" width="4.06898" height="2.46374" rx="1.23187" fill="#2F2F2F" />
        <Path
            d="M21.1283 18.2604C21.4745 17.8151 21.3974 17.2016 20.9559 16.8902C20.5145 16.5787 19.876 16.6871 19.5298 17.1324L21.1283 18.2604ZM19.5298 17.1324C18.181 18.8672 15.2681 19.5749 13.062 18.5483L12.1025 20.3311C15.0806 21.7169 19.1044 20.8636 21.1283 18.2604L19.5298 17.1324Z"
            fill={Color.ALMOST_BLACK}
        />
        <Path
            d="M23.998 28H24.998C27.2072 28 28.998 26.2091 28.998 24V23"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M8.99805 28H7.99934C5.7902 28 3.99934 26.2091 3.99934 24V23"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M3.99934 9.00049V8.0001C3.99934 5.79096 5.7902 4.0001 7.99934 4.0001H8.99805"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M28.998 9.00049V8.0001C28.998 5.79096 27.2072 4.0001 24.998 4.0001H23.998"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export const TouchIdIcon = () => (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
        <Path
            d="M15.0659 17.0123C15.0659 17.0123 12.2378 19.0365 12.9739 21.7839C14.4705 27.3694 22.7397 24.0827 22.8056 17.1284C22.8924 7.95558 10.9885 4.71613 5.27202 18.1539"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M16.8361 20.7479C19.3465 18.5231 19.7375 15.4231 17.5686 13.8228C13.0167 11.1948 5.82738 18.6471 10.5726 25.4282C15.3178 32.2093 24.9463 27.0458 26.5311 20.2224C29.0739 9.27417 16.8439 -0.475496 6.51275 8.92777"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export const IrisIdIcon = () => (
    <Svg width="32" height="33" viewBox="0 0 32 33" fill="none">
        <Path
            d="M18.8703 15.4607L18.8703 15.4608C18.4804 17.3565 16.7662 18.5719 15.2494 18.4024C13.8093 18.2415 12.7185 16.8273 13.0991 14.9763C13.489 13.0803 15.2034 11.8647 16.72 12.0345C18.1604 12.1957 19.2511 13.6101 18.8703 15.4607Z"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
        />
        <Path
            d="M23.1998 28.4199H24C26.2091 28.4199 28 26.6291 28 24.4199V23.42"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M8.79883 28.4199H7.99982C5.79068 28.4199 3.99982 26.6291 3.99982 24.4199V23.42"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M3.99982 9.42041V8.4201C3.99982 6.21097 5.79068 4.4201 7.99982 4.4201H8.79883"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M28 9.42041V8.4201C28 6.21097 26.2091 4.4201 24 4.4201H23.1998"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M25.4016 15.3644C26.0962 15.9362 26.0962 16.9037 25.4016 17.4754C23.3081 19.1984 19.842 21.4199 16 21.4199C12.158 21.4199 8.69186 19.1984 6.5984 17.4754C5.90375 16.9037 5.90375 15.9362 6.5984 15.3644C8.69186 13.6415 12.158 11.4199 16 11.4199C19.842 11.4199 23.3081 13.6415 25.4016 15.3644Z"
            stroke={Color.ALMOST_BLACK}
            strokeWidth="2"
        />
    </Svg>
);
