import * as Sentry from '@sentry/react-native';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { ComponentType } from 'react';

import { config } from '~/app/config';
import { appSessionTimeout } from '~/app/constants';
import { AppEndpoint } from '~/contexts/app-config';
import { AppNavigationContainerRef } from '~/navigator/app-navigator';
import { getDebugMode, isNative } from '~/utils';

type ErrorTags = {
    [key: string]: ID | number | string | boolean | null;
};

const routingInstrumentation = new Sentry.ReactNavigationInstrumentation();

const profile: AppEndpoint = Constants.expoConfig?.extra?.profile;

if (['production', 'staging'].includes(profile)) {
    Sentry.init({
        environment: profile,
        dsn: config[profile]?.sentry?.dsn!,
        tracesSampleRate: 0.01,
        enableAutoSessionTracking: true,
        sessionTrackingIntervalMillis: appSessionTimeout,
        integrations: [
            new Sentry.ReactNativeTracing({
                routingInstrumentation,
                ignoreEmptyBackNavigationTransactions: true,
                enableAppStartTracking: true,
                enableNativeFramesTracking: true,
                tracingOrigins: ['localhost', /.*.heltti.fi/, /^\//]
            }),
            new Sentry.BrowserIntegrations.Breadcrumbs({
                console: false,
                fetch: true,
                xhr: true
            })
        ],
        ignoreErrors: ['GraphQL error'],
        beforeBreadcrumb: event => {
            if (event?.category === 'touch') {
                if (
                    event?.data?.componentTree?.find((val: string) => {
                        return val.startsWith('pin-code-keyboard');
                    })?.length > 0
                ) {
                    // message data for pin-code-keyboard identified users pin-code,
                    // thus it is removed from the sentry data
                    delete event.message;
                }
            }
            return event;
        }
    });
}

export function registerNavigationContainer(navigation: AppNavigationContainerRef | null) {
    routingInstrumentation?.registerNavigationContainer(navigation);
}

export function registerUser(userId: ID | null) {
    Sentry.setUser(userId ? { id: userId } : null);
}

export function setRequestId(requestId: UUID) {
    Sentry.setTag('request_id', requestId);
}

export function transaction(...args: Parameters<typeof Sentry.startTransaction>) {
    return Sentry.startTransaction(...args);
}

export function log(message: string, options: { notify: boolean } = { notify: false }) {
    Sentry.addBreadcrumb({ message, level: 'log' });

    if (isNative() && getDebugMode() && options?.notify) {
        Notifications.scheduleNotificationAsync({ trigger: null, content: { title: 'Debug', body: message } }).finally(
            () => {}
        );
    }

    if (__DEV__ && typeof jest === 'undefined') {
        // eslint-disable-next-line no-console
        console.log(message);
    }
}

export function info(message: string) {
    if (__DEV__ && typeof jest === 'undefined') {
        // eslint-disable-next-line no-console
        console.log(message);
    }
    Sentry.captureMessage(message, 'info');
}

export function warning(message: string) {
    if (__DEV__ && typeof jest === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(message);
    }
    Sentry.captureMessage(message, 'warning');
}

export function error(err: unknown, tags?: ErrorTags) {
    if (__DEV__ && typeof jest === 'undefined') {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    Sentry.captureException(err, { tags });
}

export function withErrorDiagnostics(component: ComponentType) {
    return Sentry.wrap(component, {
        touchEventBoundaryProps: { ignoreNames: ['View', 'Pressable', 'StyledNativeComponent', 'Svg', 'G', 'Path'] }
    });
}
