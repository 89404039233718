import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js/mobile';
import React, { ComponentType, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { NativeSyntheticEvent, TextInput, TextInputEndEditingEventData, TextInputProps } from 'react-native';
import { Circle, Path, Rect, Svg } from 'react-native-svg';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { AppMessageId, useIntl } from '~/contexts/intl';
import { isNative, isWeb, timeout } from '~/utils';
import { filter } from '~/utils/string';
import { isEmailValid } from '~/utils/validation';

export type FormInputProps<T extends string | boolean | number = string> = {
    caption?: string;
    disabled?: boolean;
    initialValue: T | null;
    onSetValue?: (value: T) => T;
    onSubmit?: (value: T) => Promise<void>;
    testID?: string;
};

const Container = styled.View``;
const Field = styled.View<{ focused: boolean; disabled: boolean }>`
    flex-direction: row;
    margin-top: 8px;
    padding: 14px 16px;
    margin-bottom: 16px;

    background-color: ${Color.BACKGROUND_DEFAULT};
    border-width: 2px;
    border-radius: 8px;
    border-color: ${({ focused, disabled }) =>
        !disabled ? (focused ? Color.FOCUS_DEFAULT : Color.LINE_DEFAULT) : Color.LINE_DISABLED};
`;

const SuccessIcon = () => (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <Circle cx="11" cy="11" r="11" fill={Color.PRIMARY_DEFAULT} />
        <Path
            d="M8.06066 9.86242C7.47487 9.27663 6.52513 9.27663 5.93934 9.86242C5.35355 10.4482 5.35355 11.398 5.93934 11.9837L8.06066 9.86242ZM10.0769 14L9.01626 15.0607C9.34079 15.3852 9.79602 15.5433 10.2518 15.4898C10.7076 15.4363 11.1139 15.177 11.3544 14.7861L10.0769 14ZM5.93934 11.9837L9.01626 15.0607L11.1376 12.9393L8.06066 9.86242L5.93934 11.9837ZM11.3544 14.7861L16.2775 6.78615L13.7225 5.21385L8.79944 13.2139L11.3544 14.7861Z"
            fill={Color.ICON_NEGATIVE}
        />
    </Svg>
);

const FailureIcon = () => (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <Circle cx="11" cy="11" r="11" fill={Color.CHERRY} />
        <Path
            d="M10.1318 5.53499C10.5157 4.86318 11.4844 4.86318 11.8682 5.53499L16.4219 13.5039C16.8028 14.1705 16.3215 15 15.5536 15H6.44636C5.67854 15 5.19717 14.1705 5.57812 13.5039L7.86824 9.49614L10.1318 5.53499Z"
            stroke="white"
            strokeWidth="2"
        />
    </Svg>
);

const LoadingIcon = () => (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <Rect width="22" height="22" rx="11" fill={Color.BUSY_DEFAULT} />
        <Path
            d="M10 1C10 1.55228 10.4477 2 11 2C11.5523 2 12 1.55228 12 1L10 1ZM12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5L12 5ZM10 9C10 9.55228 10.4477 10 11 10C11.5523 10 12 9.55228 12 9L10 9ZM12 13C12 12.4477 11.5523 12 11 12C10.4477 12 10 12.4477 10 13L12 13ZM10 17C10 17.5523 10.4477 18 11 18C11.5523 18 12 17.5523 12 17L10 17ZM12 21C12 20.4477 11.5523 20 11 20C10.4477 20 10 20.4477 10 21L12 21ZM10 -1L10 1L12 1L12 -1L10 -1ZM10 5L10 9L12 9L12 5L10 5ZM10 13L10 17L12 17L12 13L10 13ZM10 21L10 23L12 23L12 21L10 21Z"
            fill={Color.ICON_NEGATIVE}
        />
        <Path
            d="M21 10C20.4477 10 20 10.4477 20 11C20 11.5523 20.4477 12 21 12L21 10ZM17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10L17 12ZM13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12L13 10ZM9 12C9.55229 12 10 11.5523 10 11C10 10.4477 9.55229 10 9 10L9 12ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12L5 10ZM1 12C1.55228 12 2 11.5523 2 11C2 10.4477 1.55228 10 0.999999 10L1 12ZM23 10L21 10L21 12L23 12L23 10ZM17 10L13 10L13 12L17 12L17 10ZM9 10L5 10L5 12L9 12L9 10ZM0.999999 10L-1 10L-1 12L1 12L0.999999 10Z"
            fill={Color.ICON_NEGATIVE}
        />
        <Path
            d="M17.3631 3.22175C16.9725 3.61228 16.9725 4.24544 17.3631 4.63597C17.7536 5.02649 18.3867 5.02649 18.7773 4.63597L17.3631 3.22175ZM15.9488 7.4644C16.3394 7.07387 16.3394 6.44071 15.9488 6.05018C15.5583 5.65966 14.9252 5.65966 14.5346 6.05018L15.9488 7.4644ZM11.7062 8.87861C11.3157 9.26913 11.3157 9.9023 11.7062 10.2928C12.0967 10.6833 12.7299 10.6833 13.1204 10.2928L11.7062 8.87861ZM10.292 13.1212C10.6825 12.7307 10.6825 12.0976 10.292 11.707C9.90146 11.3165 9.2683 11.3165 8.87777 11.707L10.292 13.1212ZM6.04935 14.5355C5.65882 14.926 5.65882 15.5592 6.04935 15.9497C6.43987 16.3402 7.07304 16.3402 7.46356 15.9497L6.04935 14.5355ZM4.63513 18.7781C5.02566 18.3876 5.02566 17.7544 4.63513 17.3639C4.24461 16.9734 3.61144 16.9734 3.22092 17.3639L4.63513 18.7781ZM18.7773 1.80754L17.3631 3.22175L18.7773 4.63597L20.1915 3.22175L18.7773 1.80754ZM14.5346 6.05018L11.7062 8.87861L13.1204 10.2928L15.9488 7.4644L14.5346 6.05018ZM8.87777 11.707L6.04935 14.5355L7.46356 15.9497L10.292 13.1212L8.87777 11.707ZM3.22092 17.3639L1.80671 18.7781L3.22092 20.1923L4.63513 18.7781L3.22092 17.3639Z"
            fill={Color.ICON_NEGATIVE}
        />
        <Path
            d="M18.7773 17.364C18.3867 16.9735 17.7536 16.9735 17.3631 17.364C16.9725 17.7546 16.9725 18.3877 17.3631 18.7782L18.7773 17.364ZM14.5346 15.9498C14.9252 16.3403 15.5583 16.3403 15.9488 15.9498C16.3394 15.5593 16.3394 14.9261 15.9488 14.5356L14.5346 15.9498ZM13.1204 11.7072C12.7299 11.3167 12.0967 11.3167 11.7062 11.7072C11.3157 12.0977 11.3157 12.7309 11.7062 13.1214L13.1204 11.7072ZM8.87778 10.293C9.2683 10.6835 9.90146 10.6835 10.292 10.293C10.6825 9.90244 10.6825 9.26927 10.292 8.87875L8.87778 10.293ZM7.46356 6.05032C7.07304 5.6598 6.43987 5.6598 6.04935 6.05032C5.65882 6.44085 5.65882 7.07401 6.04935 7.46453L7.46356 6.05032ZM3.22092 4.63611C3.61144 5.02663 4.24461 5.02663 4.63513 4.63611C5.02566 4.24558 5.02566 3.61242 4.63513 3.22189L3.22092 4.63611ZM20.1915 18.7782L18.7773 17.364L17.3631 18.7782L18.7773 20.1925L20.1915 18.7782ZM15.9488 14.5356L13.1204 11.7072L11.7062 13.1214L14.5346 15.9498L15.9488 14.5356ZM10.292 8.87875L7.46356 6.05032L6.04935 7.46453L8.87778 10.293L10.292 8.87875ZM4.63513 3.22189L3.22092 1.80768L1.80671 3.22189L3.22092 4.63611L4.63513 3.22189Z"
            fill={Color.ICON_NEGATIVE}
        />
    </Svg>
);

type MessageProps = {
    message: string;
};

const MessageContainer = styled.View`
    position: absolute;
    top: 6px;
    right: 4px;
    padding: 0.2px;
    border-radius: 12px;
    flex-direction: row;
    align-items: center;
    background-color: ${Color.BACKGROUND_DEFAULT}

    z-index: 99;
`;

const Message = (props: MessageProps & { color: Color; icon: ComponentType<object> }) => {
    const { icon: Icon, color, message } = props;
    return (
        <MessageContainer style={Shadow.styles.primary}>
            <Icon />
            <Text.CAPTION numberOfLines={1} style={{ color, paddingLeft: 4, paddingRight: 8, maxWidth: 220 }}>
                {message}
            </Text.CAPTION>
        </MessageContainer>
    );
};

type FlashType =
    | {
          type: 'none';
      }
    | { type: 'saving' | 'success' | 'failure'; message?: string };

type FlashMessagesProps = {
    flash: FlashType;
    messages: {
        loading?: string;
        success?: string;
        failure?: string;
    };
};

const FlashMessages = (props: FlashMessagesProps) => {
    const { flash, messages } = props;
    const { formatMessage } = useIntl();

    switch (flash.type) {
        case 'saving': {
            return (
                <Message
                    message={flash.message ?? messages.loading ?? formatMessage('form.flash-saving')}
                    color={Color.BUSY_DEFAULT}
                    icon={LoadingIcon}
                />
            );
        }
        case 'success': {
            return (
                <Message
                    message={flash.message ?? messages.success ?? formatMessage('form.flash-success')}
                    color={Color.PRIMARY_DEFAULT}
                    icon={SuccessIcon}
                />
            );
        }
        case 'failure': {
            return (
                <Message
                    message={flash.message ?? messages.failure ?? formatMessage('form.flash-failure')}
                    color={Color.CHERRY}
                    icon={FailureIcon}
                />
            );
        }
        default:
            return null;
    }
};

export class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ValidationError';
    }
}

type FormTextInputProps = FormInputProps<string> & {
    onValidateValue?: (value: string | null) => Promise<void>;
    success?: string;
    failure?: string;
    loading?: string;
} & Pick<
        TextInputProps,
        | 'onSubmitEditing'
        | 'returnKeyType'
        | 'keyboardType'
        | 'autoCapitalize'
        | 'autoCorrect'
        | 'maxLength'
        | 'textContentType'
        | 'autoComplete'
        | 'placeholder'
        | 'placeholderTextColor'
        | 'multiline'
    >;

export const FormTextInput = forwardRef(function (props: FormTextInputProps, ref) {
    const [value, setValue] = useState<string | null>(null);
    const [flash, setFlash] = useState<FlashType>({ type: 'none' });
    const [focused, setFocused] = useState<boolean>(false);
    const dirty = useRef<boolean>(false);
    const localRef = useRef<TextInput>(null);
    const {
        caption,
        disabled = false,
        loading,
        success,
        failure,
        initialValue,
        onValidateValue,
        onSetValue,
        onSubmitEditing,
        onSubmit,
        ...rest
    } = props;
    const mounted = useRef(false);

    // We need to track component's mounted state in order to prevent updating `flash` if the user
    // navigates away from the view while the `onSubmit` async call is in progress.

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const setFlashIfMounted = (nextFlash: FlashType) => {
        if (mounted.current) {
            setFlash(nextFlash);
        }
    };

    const handleChangeText = useCallback(
        (text: string) => {
            const finalValue = onSetValue?.(text) ?? text;

            setValue(finalValue);

            if (flash.type === 'failure') {
                setFlashIfMounted({ type: 'none' });
            }

            dirty.current = initialValue !== finalValue;
        },
        [flash.type, initialValue, onSetValue]
    );

    const handleEndEditing = useCallback(
        async (text: string) => {
            if (initialValue !== text && text.length > 0) {
                let isError = false;
                let isValidationError = false;

                try {
                    await onValidateValue?.(text);

                    if (onSubmit) {
                        setFlash({ type: 'saving' });

                        await onSubmit?.(text);

                        setFlashIfMounted({ type: 'success' });
                        dirty.current = false;
                    }
                } catch (error) {
                    isError = true;
                    isValidationError = error instanceof ValidationError;

                    setFlashIfMounted({
                        type: 'failure',
                        message: error instanceof ValidationError ? error.message : undefined
                    });

                    localRef.current?.focus();
                } finally {
                    if (!isValidationError) {
                        await timeout(isError ? 5000 : 1500);

                        setFlashIfMounted({ type: 'none' });
                    }
                }
            }
        },
        [initialValue, onSubmit, onValidateValue]
    );

    return (
        <Container>
            <Text.INPUT_FIELD_TITLE
                style={{
                    marginLeft: Spacing.SMALL,
                    color: disabled ? Color.TEXT_DISABLED : Color.ALMOST_BLACK
                }}
            >
                {caption}
            </Text.INPUT_FIELD_TITLE>
            <Field focused={focused} disabled={disabled}>
                <TextInput
                    ref={mergeRefs([localRef, ref])}
                    style={[
                        {
                            width: '100%',
                            height: props.multiline ? 140 : 20,
                            ...Text.PARAGRAPH_1_STYLES,
                            lineHeight: 20,
                            color: disabled ? Color.TEXT_DISABLED : Color.ALMOST_BLACK
                        },
                        // Note: RN style types don't include outline, thus ignoring
                        // @ts-ignore
                        isWeb() ? { outline: 'none' } : {}
                    ]}
                    value={value ?? initialValue ?? undefined}
                    editable={!disabled}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                        if (isWeb()) {
                            handleEndEditing(value ?? '');
                        }
                    }}
                    onChangeText={handleChangeText}
                    onEndEditing={({ nativeEvent: { text } }: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
                        // onEndEditing appears to be called only with mobile environment, thus onBlur is used with Web environment
                        // isNative is here, just to make sure that if web starts calling this, it'll get the handleEndEditing only once
                        if (isNative()) {
                            handleEndEditing(text);
                        }
                    }}
                    onSubmitEditing={onSubmitEditing}
                    accessibilityLabel={caption}
                    {...rest}
                />
            </Field>
            <FlashMessages flash={flash} messages={{ loading, success, failure }} />
        </Container>
    );
});

FormTextInput.displayName = 'FormTextInput';

type FormPhoneNumberInputProps = Omit<FormTextInputProps, 'autoComplete'> & {
    countryCode?: CountryCode;
};

export const FormPhoneNumberInput = forwardRef(function (props: FormPhoneNumberInputProps, ref) {
    const localRef = useRef<TextInput>(null);
    const { formatMessage } = useIntl();

    const { onValidateValue, onSetValue, countryCode = 'FI', ...rest } = props;

    const handleValidateValue = useCallback(
        async (text: string | null) => {
            try {
                if (text) {
                    parsePhoneNumber(text);
                }
                await onValidateValue?.(text);
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throw new ValidationError(
                        formatMessage(`form.validate-phone.error.${error.message}` as AppMessageId)
                    );
                } else {
                    throw error;
                }
            }
        },
        [formatMessage, onValidateValue]
    );

    const handleSetValue = useCallback(
        (text: string) => {
            const textWithPrefix = text.length === 1 && text[0] !== '+' ? `+${text}` : text;

            const value = new AsYouType(countryCode).input(textWithPrefix);

            return onSetValue?.(value) ?? value;
        },
        [countryCode, onSetValue]
    );

    return (
        <FormTextInput
            ref={mergeRefs([localRef, ref])}
            {...rest}
            keyboardType="phone-pad"
            textContentType="telephoneNumber"
            autoComplete="tel"
            onValidateValue={handleValidateValue}
            onSetValue={handleSetValue}
        />
    );
});

FormPhoneNumberInput.displayName = 'FormPhoneNumberInput';

type FormEmailInputProps = Omit<FormTextInputProps, 'autoComplete'>;

export const FormEmailInput = forwardRef(function (props: FormEmailInputProps, ref) {
    const { formatMessage } = useIntl();

    const { onValidateValue } = props;

    const handleValidateValue = useCallback(
        async (text: string | null) => {
            if (!isEmailValid(text, true)) {
                throw new ValidationError(formatMessage(`form.validate-email.error`));
            }

            await onValidateValue?.(text);
        },
        [formatMessage, onValidateValue]
    );

    return (
        <FormTextInput
            ref={ref}
            {...props}
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="emailAddress"
            autoComplete="email"
            onValidateValue={handleValidateValue}
        />
    );
});

FormEmailInput.displayName = 'FormEmailInput';

export const FormNumericTextInput = forwardRef(function (props: FormTextInputProps, ref) {
    const { formatMessage } = useIntl();
    const { onValidateValue, onSetValue } = props;

    const handleValidateValue = useCallback(
        async (text: string | null) => {
            if (text?.length && Number.isNaN(Number.parseInt(text, 10))) {
                throw new ValidationError(formatMessage(`form.validate-number.error`));
            }
            await onValidateValue?.(text);
        },
        [formatMessage, onValidateValue]
    );

    const handleSetValue = useCallback(
        (text: string) => {
            const value = filter(text, /\d+/);
            return onSetValue?.(value) ?? value;
        },
        [onSetValue]
    );

    return (
        <FormTextInput
            ref={ref}
            {...props}
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="number-pad"
            onSetValue={handleSetValue}
            onValidateValue={handleValidateValue}
        />
    );
});

FormNumericTextInput.displayName = 'FormNumericTextInput';
