import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import * as WebBrowser from 'expo-web-browser';
import { Box, Flex, useBreakpointValue } from 'native-base';
import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { AppointmentContactInfo, AppointmentHeader, AppointmentLocation } from '~/components/appointment';
import { AppointmentContactTypeSelect } from '~/components/appointment/appointment-contact-type-select';
import { Avatar } from '~/components/avatar';
import { Button, TextButton as CustomLinkButton } from '~/components/button';
import { Card } from '~/components/card';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HeaderBackButton } from '~/components/header';
import { HeaderTextButton } from '~/components/header/header-text-button';
import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { ButtonBackIcon, InfoIcon } from '~/components/icon';
import { Image } from '~/components/image';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { UserInfo } from '~/components/user';
import { useIntl } from '~/contexts/intl';
import { useAppointment } from '~/hooks/appointment';
import { useDynamicHeaderColor } from '~/hooks/dynamic-header-color/dynamic-header-color';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { AppointmentContactType } from '~/types';

type AppointmentPreviewNavigation = StackNavigationProp<MainNavigatorParamList, 'appointment-preview'>;

export type AppointmentPreviewRouteProp = RouteProp<MainNavigatorParamList, 'appointment-preview'>;

const ContentScrollView = styled(Animated.ScrollView)``;

const ActionContainer = styled.View<{ bottomInset: number }>`
    margin: 16px 32px ${({ bottomInset }) => Math.max(8, bottomInset)}px;
`;

const UserAvatar = styled.View`
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
`;

const CardDivider = styled.View`
    border-top-width: 1px;
    border-top-color: ${Color.LINE_DEFAULT};
    align-self: stretch;
    margin-left: 16px;
`;

const InfoButton = styled.View`
    margin-top: ${Spacing.HUGE}px;
    margin-bottom: ${Spacing.HUGE}px;
`;

type CardWrapperProps = { wrap: boolean; shadow?: boolean; style?: StyleProp<ViewStyle> } & PropsWithChildren;

const CardWrapper: React.FC<CardWrapperProps> = props => {
    const { style = {}, shadow = true } = props;

    const styles = [style];

    if (shadow) {
        styles.push(Shadow.styles.primary);
    } else {
        styles.push({ borderWidth: 1, borderStyle: 'solid', borderColor: Color.LINE_DEFAULT });
    }

    if (props.wrap) {
        return <Card style={styles}>{props.children}</Card>;
    } else {
        return <>{props.children}</>;
    }
};

export function AppointmentPreview() {
    const { canGoBack, goBack, setOptions, navigate } = useNavigation<AppointmentPreviewNavigation>();
    const { params } = useRoute<AppointmentPreviewRouteProp>();
    const { bottom } = useSafeAreaInsets();
    const { formatMessage } = useIntl();

    const [contactType, setContactType] = useState<AppointmentContactType>();
    const [reservingAppointment, setReservingAppointment] = useState<boolean>(false);

    const isMobileSize = useBreakpointValue({
        base: true,
        sm: false
    });

    const handleAbort = useCallback(() => {
        navigate('calendar-reservation-accesses');
    }, [navigate]);

    useLayoutEffect(() => {
        if (isMobileSize) {
            setOptions({
                headerLeft: () => <HeaderBackButton onPress={handleAbort} />,
                headerTitle: formatMessage('appointments.confirmation.title')
            });
        } else {
            setOptions({
                header: (props: StackHeaderProps) => (
                    <HelttiHeaderStackHeaderWrapper
                        {...props}
                        headerRight={
                            <HeaderTextButton onPress={handleAbort} label={formatMessage('appointments.abort')} />
                        }
                    />
                )
            });
        }
    });

    const { onScroll } = useDynamicHeaderColor({
        range: 20,
        color: {
            from: Color.BACKGROUND_SECONDARY,
            to: Color.BACKGROUND_DEFAULT
        }
    });

    // Existence of `params.calendarEventId` determines whether this screen is presented to reserve a normal
    // appointment, or presented to reserve a new appointment as a modification for an existing appointment
    // (calendar event)
    const { appointment, reserveAppointment, modifyCalendarEvent } = useAppointment(
        params.appointment,
        params.calendarEventId
    );
    const { location } = appointment;

    const { contactTypes } = appointment;
    useEffect(() => setContactType(contactTypes[0]), [contactTypes]);

    const handleReserveAppointment = useCallback(async () => {
        try {
            if (contactType) {
                setReservingAppointment(true);

                // Modify existing appointment if its calendar event id was specified in route params, otherwise reserve
                // a new appointment (default use case)

                const calendarEventId = await (params.calendarEventId
                    ? modifyCalendarEvent(contactType)
                    : reserveAppointment(contactType));
                if (calendarEventId) {
                    navigate('appointment-confirmed', { calendarEventId });
                }
            }
        } finally {
            setReservingAppointment(false);
        }
    }, [contactType, params.calendarEventId, modifyCalendarEvent, reserveAppointment, navigate]);

    const handleOpenUrl = useCallback(async (url: string) => {
        await WebBrowser.openBrowserAsync(url);
    }, []);

    const backgroundColor = useBreakpointValue({
        base: Color.BACKGROUND_SECONDARY,
        sm: Color.BACKGROUND_DEFAULT
    });

    const headerAndUserDirection = useBreakpointValue({
        base: 'column',
        sm: 'row'
    });

    return (
        <ContentScrollView
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            overScrollMode="never"
            style={{ backgroundColor }}
        >
            <ContentHorizontalMargins contentWidth="narrow">
                <Box flexDirection="column" flexGrow={1} flexShrink={1} mt={{ base: 0, sm: 16 }}>
                    <CardWrapper wrap={isMobileSize} shadow={isMobileSize}>
                        {isMobileSize && (
                            <UserAvatar>
                                {appointment?.user?.avatarBigUrl ? (
                                    <EllipticMaskView style={{ height: 400 }}>
                                        <Image
                                            source={{ uri: appointment.user.avatarBigUrl }}
                                            resizeMode="cover"
                                            style={{ width: '100%', height: 400 }}
                                        />
                                    </EllipticMaskView>
                                ) : null}
                            </UserAvatar>
                        )}

                        <Flex direction={headerAndUserDirection} alignSelf="stretch">
                            <CardWrapper
                                wrap={!isMobileSize}
                                shadow={isMobileSize}
                                style={{ flex: 2, marginRight: Spacing.MEDIUM }}
                            >
                                <AppointmentHeader
                                    task="make-appointment"
                                    type={contactTypes.length > 1 ? undefined : contactType}
                                    date={appointment.start}
                                    duration={appointment.duration}
                                    style={{ marginTop: isMobileSize ? -8 : Spacing.MEDIUM }}
                                />
                            </CardWrapper>

                            {appointment?.user ? (
                                <>
                                    {isMobileSize && <CardDivider />}

                                    <CardWrapper wrap={!isMobileSize} shadow={isMobileSize} style={{ flex: 1 }}>
                                        {!isMobileSize && (
                                            <>
                                                <Box p={Spacing.TINY}>
                                                    <Avatar
                                                        size="large"
                                                        imageUri={appointment?.user?.avatarBigUrl ?? ''}
                                                    />
                                                </Box>

                                                <CardDivider />
                                            </>
                                        )}
                                        <UserInfo image={false} user={appointment.user} />
                                    </CardWrapper>
                                </>
                            ) : null}
                        </Flex>

                        <CardWrapper
                            wrap={!isMobileSize}
                            shadow={isMobileSize}
                            style={{ marginTop: isMobileSize ? 0 : 16 }}
                        >
                            {contactTypes?.length > 1 && contactType ? (
                                <>
                                    {isMobileSize && <CardDivider />}

                                    <AppointmentContactTypeSelect
                                        contactTypes={contactTypes}
                                        selectedContactType={contactType}
                                        onSelectedContactTypeChange={setContactType}
                                    />
                                </>
                            ) : null}

                            <CardDivider />

                            {location && contactType === 'VISIT' ? <AppointmentLocation location={location} /> : null}

                            {contactType ? <AppointmentContactInfo type={contactType} /> : null}
                        </CardWrapper>
                    </CardWrapper>

                    <ActionContainer bottomInset={bottom}>
                        <Flex flexDirection="row" marginTop={Spacing.TINY}>
                            {!isMobileSize && (
                                <>
                                    <Box marginRight={Spacing.TINY} flex={1}>
                                        <Button
                                            fillContainer
                                            type="secondary"
                                            shape="rounded"
                                            label={formatMessage('appointments.change-appointment')}
                                            onPress={() =>
                                                canGoBack() ? goBack() : navigate('calendar-reservation-accesses')
                                            }
                                            leftIcon={<ButtonBackIcon />}
                                        />
                                    </Box>
                                </>
                            )}

                            <Box marginLeft={isMobileSize ? 0 : Spacing.TINY} flex={1}>
                                <Button
                                    fillContainer
                                    type="primary"
                                    shape="rounded"
                                    label={formatMessage('appointments.make-appointment', { type: contactType })}
                                    onPress={handleReserveAppointment}
                                    loading={reservingAppointment}
                                />
                            </Box>
                        </Flex>
                        <InfoButton>
                            <CustomLinkButton
                                label={formatMessage('appointment.cancellation-terms', {
                                    u: (value: string) => (
                                        <Text.BUTTON_LABEL style={{ textDecorationLine: 'underline' }}>
                                            {value}
                                        </Text.BUTTON_LABEL>
                                    )
                                })}
                                leftIcon={InfoIcon}
                                onPress={() =>
                                    handleOpenUrl(
                                        'https://heltti.fi/wp-content/uploads/2022/06/Liite-5_-Heltin-yleiset-palveluehdot-2022.pdf'
                                    )
                                }
                            />
                        </InfoButton>
                    </ActionContainer>
                </Box>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
}
