import { RouteProp, useRoute } from '@react-navigation/native';
import React from 'react';

import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { Nps } from './nps';

type NpsCompanyEventRoute = RouteProp<AppNavigatorParamList, 'nps-company-event'>;

export const NpsCompanyEvent = () => {
    const {
        params: { npsUUID, scoreDetail1 }
    } = useRoute<NpsCompanyEventRoute>();

    return (
        <Nps
            npsUUID={npsUUID}
            initialScoreDetail1={scoreDetail1 ? parseInt(scoreDetail1, 10) : undefined}
            detailQuestion1={{
                titleKey: 'nps.detail1.company.title',
                minDescriptionKey: 'nps.detail1.min-description',
                maxDescriptionKey: 'nps.detail1.company.max-description'
            }}
            detailQuestion2={{
                titleKey: 'nps.detail2.company.title',
                minDescriptionKey: 'nps.detail2.min-description',
                maxDescriptionKey: 'nps.detail2.company.max-description'
            }}
            npsQuestionTitleKey="nps.nps-question.title"
        />
    );
};
