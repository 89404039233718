import { constantCase } from 'change-case';
import { Flex } from 'native-base';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import AppointmentMakeImage from '~/assets/images/appointment-make.svg';
import { Color } from '~/components/color';
import { CalendarIcon } from '~/components/icon/calendar';
import { Spacing } from '~/components/spacing';
import { Task, TaskColorScheme } from '~/components/task';
import { Text } from '~/components/text';
import { Time } from '~/components/time';
import { FormattedDate, FormattedMessage, useIntl } from '~/contexts/intl';
import { AppointmentContactType, CalendarEventType } from '~/types';

import { AppointmentContactTypeImage } from './appointment-contact-type-image';
import { AppointmentTask } from './appointment-types';

export type AppointmentHeaderType = 'default' | 'condensed';

export type AppointmentHeaderProps = {
    task: AppointmentTask;
    date: Date;
    duration: number;
    title?: string;
    type?: AppointmentContactType | CalendarEventType;
    headerType?: AppointmentHeaderType;
    style?: StyleProp<ViewStyle>;
};

function taskScheme(task: string): TaskColorScheme {
    return (
        ({
            MAKE_APPOINTMENT: 'primary',
            VIEW_APPOINTMENT: 'primary',
            VIEW_CANCELLATION: 'negative'
        }[task] as TaskColorScheme) ?? 'primary'
    );
}

function taskImage(task: string, type?: AppointmentContactType | CalendarEventType) {
    if (task === 'MAKE_APPOINTMENT') {
        return <AppointmentMakeImage />;
    }
    if (type) {
        return <AppointmentContactTypeImage type={type} />;
    }
    return null;
}

const AppointmentInfo = styled.View`
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: -8px;
`;

export function AppointmentHeader(props: AppointmentHeaderProps) {
    const { date, duration, task, type, style, headerType = 'default' } = props;
    const { formatMessage } = useIntl();
    const taskType = constantCase(task);

    const TitleComponent = headerType === 'default' ? Text.TITLE : Text.HEADER_3;

    return (
        <Flex
            direction="row"
            alignSelf="stretch"
            justifyContent="space-between"
            alignItems="center"
            margin="4"
            style={style}
        >
            <AppointmentInfo>
                <Task
                    text={formatMessage('appointment.task', { taskType })}
                    icon={CalendarIcon}
                    scheme={taskScheme(taskType)}
                />
                <TitleComponent style={{ color: Color.SMOOTHIE }} before={Spacing.SMALL} after={Spacing.SMALL}>
                    <FormattedDate weekday="short" day="numeric" month="numeric" value={date} />
                    {'\n'}
                    <FormattedMessage id="time.at" /> <Time value={date} />
                </TitleComponent>
                <Text.PARAGRAPH_1 after={Spacing.SMALL}>
                    <FormattedMessage id="time.duration" values={{ duration }} />
                    {type && ` • ${formatMessage('appointment.type', { type })}`}
                </Text.PARAGRAPH_1>
            </AppointmentInfo>
            {taskImage(taskType, type)}
        </Flex>
    );
}
