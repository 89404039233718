import React from 'react';
import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';

import { Avatar } from '~/components/avatar';
import { Badge } from '~/components/badge';
import { ButtonSheet, ButtonSheetItemProps } from '~/components/button';
import { Color } from '~/components/color';
import { ChevronRightIcon } from '~/components/icon';
import { Shadow } from '~/components/shadow';
import { Text } from '~/components/text';
import { ChatMessageUser } from '~/hooks/chat';
import { Interaction } from '~/hooks/chat-list';
import { useChatTime } from '~/hooks/chat/chat-time';
import { strip } from '~/utils/markdown';
import { cutoffString } from '~/utils/string';

export type HomescreenSheetButtonProps = {
    title: string;
    lastMessage: string;
    lastMessageSender?: string;
    timestamp: Date;
    badge?: string;
    users: ChatMessageUser[];
    interactions: Interaction[];
    onPress: () => void;
};

const SheetButtonOuterContainer = styled.View`
    flex: 1 1 auto;
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const SheetButtonInnerContainer = styled.View`
    border-radius: 16px;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
`;

const InformationContainer = styled.View<{ showBorder: boolean }>`
    padding: 16px 16px 16px 0;
    margin-left: 16px;
    ${({ showBorder }) =>
        showBorder
            ? `
        border-bottom-width: 1px;
        border-color: ${Color.LINE_DEFAULT};
    `
            : ''}
`;

const HeaderLine = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

const CenteringRowContainer = styled.View`
    flex-direction: row;
    align-items: center;
`;

const ContentContainer = styled.View`
    margin-vertical: 10px;
`;

const AvatarContainer = styled.View`
    margin-right: 10px;
`;

const TitleContainer = styled.View`
    flex: 0 0 67%;
`;

const BadgeContainer = styled.View`
    margin-left: 5px;
`;

const renderSingleUser = (user: ChatMessageUser) => {
    return (
        <CenteringRowContainer>
            <AvatarContainer>
                <Avatar size="small" imageUri={user.avatarUrl} />
            </AvatarContainer>
            <View>
                <Text.PARAGRAPH_3>{user.name}</Text.PARAGRAPH_3>
                {user.title ? <Text.PARAGRAPH_1>{user.title}</Text.PARAGRAPH_1> : null}
            </View>
        </CenteringRowContainer>
    );
};

export const HomescreenSheetButton = (props: HomescreenSheetButtonProps) => {
    const { title, lastMessage, lastMessageSender, timestamp, badge, users, interactions, onPress } = props;
    const { dateToRelativeTime } = useChatTime();

    const interactionItems: ButtonSheetItemProps[] = interactions.map(interaction => {
        return {
            key: `item${interaction.id}`,
            label: interaction.label,
            left: interaction.icon,
            right: ChevronRightIcon,
            onPress: interaction.onPress
        };
    });

    const messagePreview = (sender: string | undefined, message: string) => {
        return `${sender ? sender + ': ' : ''}${cutoffString(strip(message), 80)}`;
    };

    return (
        <SheetButtonOuterContainer style={Shadow.styles.primary}>
            <SheetButtonInnerContainer>
                <Pressable onPress={onPress}>
                    <InformationContainer showBorder={interactions.length > 0}>
                        <HeaderLine>
                            <TitleContainer>
                                <Text.HEADER_3 style={{ color: Color.HEADER }} numberOfLines={1}>
                                    {title}
                                </Text.HEADER_3>
                            </TitleContainer>
                            <CenteringRowContainer>
                                <Text.CAPTION style={{ color: Color.TEXT_SECONDARY }}>
                                    {dateToRelativeTime(timestamp)}
                                </Text.CAPTION>
                                <BadgeContainer>{!!badge && <Badge badge={badge} />}</BadgeContainer>
                            </CenteringRowContainer>
                        </HeaderLine>
                        <ContentContainer>
                            <Text.PARAGRAPH_1>{messagePreview(lastMessageSender, lastMessage)}</Text.PARAGRAPH_1>
                        </ContentContainer>
                        {users.length === 1 && renderSingleUser(users[0])}
                        {users.length > 1 && (
                            <CenteringRowContainer>
                                {users.map(user => (
                                    <AvatarContainer key={user.id}>
                                        <Avatar size="small" imageUri={user.avatarUrl} />
                                    </AvatarContainer>
                                ))}
                            </CenteringRowContainer>
                        )}
                    </InformationContainer>
                </Pressable>
                <ButtonSheet carets items={interactionItems} />
            </SheetButtonInnerContainer>
        </SheetButtonOuterContainer>
    );
};
