import * as Linking from 'expo-linking';
import { useBreakpointValue } from 'native-base';
import React from 'react';

import HeroImage from '~/assets/images/code-envelope.svg';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { AuthInstruction } from '~/screens/auth/auth-instruction';

const Info = () => {
    const { formatMessage } = useIntl();

    return (
        <FormattedMessage
            id="auth-verification-email-sent.helper"
            values={{
                link: (value: string) => (
                    <Text.SMALL_BUTTON_LABEL
                        style={{ textDecorationLine: 'underline' }}
                        onPress={async () => {
                            await Linking.openURL(formatMessage('auth-verification-email-sent.support.url'));
                        }}
                    >
                        {value}
                    </Text.SMALL_BUTTON_LABEL>
                )
            }}
        />
    );
};

export const AuthVerificationEmailSent: React.FC = () => {
    const { formatMessage } = useIntl();

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    return (
        <AuthInstruction
            renderEllipticMask
            image={web ? <HeroImage width="272" height="272" viewBox="0 0 350 350" /> : <HeroImage />}
            title={formatMessage('auth-verification-email-sent.title')}
            description={formatMessage('auth-verification-email-sent.description')}
            info={<Info />}
        />
    );
};
