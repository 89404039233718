import * as Localization from 'expo-localization';
import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import {
    FormattedMessage as ReactFormattedMessage,
    FormattedDate,
    FormattedTime,
    FormattedNumber,
    IntlProvider as ReactIntlProvider,
    useIntl as useReactIntl
} from 'react-intl';
import useAsyncEffect from 'use-async-effect';

import { getLocale, setLocale } from './intl-persist';
import { en as EN } from './lang_en';
import { fi as FI } from './lang_fi';

export type AppLocale = 'EN' | 'FI'; // Should match LanguageCode
export type AppMessageId = keyof typeof EN & keyof typeof FI;

export type AppMessageMap = Record<AppMessageId, string>;
export type AppLocalesMap = Record<AppLocale, AppMessageMap>;

const LOCALES: AppLocalesMap = { EN, FI };

type AppIntlState = {
    locale: AppLocale;
    messages: AppMessageMap;
};

type AppIntlContextType = AppIntlState & { setCurrentLocale: (locale: AppLocale) => Promise<void> };

export const AppIntlContext = React.createContext<AppIntlContextType | undefined>(undefined);

function AppIntlContextProvider(props: PropsWithChildren<object>) {
    const { children } = props;
    const [intlState, setIntlState] = useState<AppIntlState>();

    useAsyncEffect(async () => {
        for (const loc of [await getLocale(), Localization.locale, ...Localization.locales]) {
            if (loc) {
                const [locale] = loc.toUpperCase().split('-') as AppLocale[];
                if (locale in LOCALES) {
                    setIntlState({ locale, messages: LOCALES[locale] });
                    return;
                }
            }
        }
        setIntlState({ locale: 'EN', messages: LOCALES.EN });
    }, []);

    const setCurrentLocale = useCallback(async function (locale: AppLocale) {
        if (locale in LOCALES) {
            await setLocale(locale);
            setIntlState({ locale, messages: LOCALES[locale] });
        }
    }, []);

    if (intlState) {
        return (
            <AppIntlContext.Provider value={{ ...intlState, setCurrentLocale }}>
                <ReactIntlProvider locale={intlState.locale} messages={intlState.messages} defaultLocale="en-US">
                    {children}
                </ReactIntlProvider>
            </AppIntlContext.Provider>
        );
    }
    return null;
}

export const AppIntlConsumer = AppIntlContext.Consumer;
export const AppIntlProvider = AppIntlContextProvider;

function useAppIntl() {
    const context = useContext<AppIntlContextType | undefined>(AppIntlContext);
    if (!context) {
        throw Error('Cannot use app intl context until it has been defined');
    }
    return context;
}

export function useIntl() {
    const intl = useReactIntl();
    const appIntl = useAppIntl();

    return useMemo(
        () => ({
            ...intl,
            ...appIntl,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatMessage: (id: AppMessageId, ...rest: any) => intl.formatMessage({ id, defaultMessage: id }, ...rest)
        }),
        [appIntl, intl]
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FormattedMessageProps<V extends Record<string, any> = Record<string, React.ReactNode>> {
    id: AppMessageId;
    values?: V;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tagName?: React.ElementType<any>;
    children?(...nodes: React.ReactNodeArray): React.ReactNode;
}

// TODO: Fix TS error-overlay or remove wrapper and use TS template literals to provide auto-completion
// @ts-ignore
const FormattedMessage: FunctionComponent<FormattedMessageProps> = props => <ReactFormattedMessage {...props} />;

export { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber };
