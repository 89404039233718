import 'expo-dev-client';

// eslint-disable-next-line import/order
import { registerRootComponent } from 'expo';

// See https://stackoverflow.com/questions/56943813/using-intl-properly-in-android-react-native-app
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fi';

// See https://github.com/LinusU/react-native-get-random-values#readme
import 'react-native-get-random-values';

// See https://github.com/apollographql/apollo-client/issues/6381
import 'core-js/features/promise';

// Supports Array.at
import 'core-js/proposals/relative-indexing-method';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { LogBox, Platform } from 'react-native';
// See https://github.com/charpeni/react-native-url-polyfill#option-1-simple
import { setupURLPolyfill } from 'react-native-url-polyfill';

// See https://www.npmjs.com/package/abort-controller
import 'abort-controller/polyfill';

import { App } from '~/app/app-component';
import { isWeb } from '~/utils/platform';

if (Platform.OS !== 'web') {
    setupURLPolyfill();
}

LogBox.ignoreLogs([
    'Unknown query named "GetArticle" requested', // caused by performing refetchQueries: ['GetArticle', ... before any such query has been performed
    'Constants.deviceYearClass has been deprecated', // Caused by expo-sentry, remove when upgraded,
    'Failed to create a new MMKV instance'
]);

// Patching
// @see https://github.com/expo/expo/issues/18485
if (isWeb()) {
    const rootTag = createRoot(document.getElementById('root'));

    rootTag.render(<App />);
} else {
    registerRootComponent(App);
}
