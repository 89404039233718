import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';

export const Divider = styled.View<{ before?: number; after?: number; leftInset?: number }>`
    height: 1px;
    width: 100%;
    ${({ before, after }) => (before ? `margin-top: ${before}px;` : '') + (after ? `margin-bottom: ${after}px;` : '')}
    margin-left: ${({ leftInset }) => leftInset ?? Spacing.MEDIUM}px;
    background-color: ${Color.LINE_DEFAULT};
`;

export const RoundedDivider = styled.View<{ before?: number; after?: number }>`
    height: 2px;
    ${({ before, after }) => (before ? `margin-top: ${before}px;` : '') + (after ? `margin-bottom: ${after}px;` : '')}
    margin-left: ${Spacing.MEDIUM}px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-color: ${Color.LINE_DEFAULT};
`;
