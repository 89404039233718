import { ApolloClient } from '@apollo/client';
import { Mutex } from 'async-mutex';
import {
    AuthenticationType,
    LocalAuthenticationOptions,
    SecurityLevel
} from 'expo-local-authentication/src/LocalAuthentication.types';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useAnalytics } from '~/contexts/analytics';
import { useAppConfig } from '~/contexts/app-config';
import { AppLocale } from '~/contexts/intl';
import { UserIdentityContextType, useUserIdentity } from '~/contexts/user-identity';
import * as ErrorDiagnostics from '~/error/app-error-diagnostics';
import { isNative } from '~/utils';

import {
    authenticateAsync,
    getEnrolledLevelAsync,
    hasHardwareAsync,
    isEnrolledAsync,
    supportedAuthenticationTypesAsync
} from './auth-biometric';
import {
    createAuthClient,
    eidentInit,
    eidentVerificationRequired,
    eidentVerify,
    RecoverableError,
    resendSMSToken,
    signUpWithEmail,
    TokenAuthResult,
    tokenObtain,
    tokenRefresh,
    tokenRevoke,
    verifyEmail,
    verifySMSToken
} from './auth-client';
import {
    clearAuthToken,
    clearBiometricAuthEnabled,
    clearPinCode,
    clearRefreshExpiresIn,
    clearRefreshToken,
    getAuthToken,
    getBiometricAuthEnabled,
    getPinCode,
    getRefreshExpiresIn,
    getRefreshToken,
    setAuthToken,
    setBiometricAuthEnabled,
    setPinCode,
    setRefreshExpiresIn,
    setRefreshToken
} from './auth-persist';

export type AuthState = 'initializing' | 'unauthenticated' | 'authenticated';

export type BiometricAuthFailureReason =
    | 'biometric-auth-not-available'
    | 'biometric-auth-failed'
    | 'biometric-auth-other-error';

export type PinAuthFailureReason =
    | 'pin-auth-invalid-user-identity'
    | 'pin-auth-invalid-pin-code'
    | 'pin-auth-invalid-refresh-token'
    | 'pin-auth-other-error';

export type TokenAuthFailureReason = string;

export type AuthFailureReason = TokenAuthFailureReason | PinAuthFailureReason;

export type AuthActionResult = { success: false; reason?: AuthFailureReason } | { success: true; commit: () => void };

export type BiometricAuthActionResult =
    | { success: true; pinCode: PinCode }
    | { success: false; reason: BiometricAuthFailureReason };

type BiometricAuthState = {
    available: boolean | null;
    enabled: boolean | null;
    level: SecurityLevel;
    types: AuthenticationType[];
};

export type PinCode = string;

type PinAuthState = {
    available: boolean;
    enabled: boolean | null;
};

type RemoteAuthContext = {
    login: (username: string, password: string) => Promise<AuthActionResult>;
    logout: () => Promise<AuthActionResult>;
    getAuthToken: () => string;

    eidentVerificationRequired: (email: string) => Promise<boolean>;
    eidentInit: () => Promise<string | null>;
    eidentVerify: (params: string) => Promise<AuthActionResult>;

    verifyEmail: (email: string) => Promise<boolean>;
    resendSMSCode: (tokenFromEmail: string) => Promise<boolean>;
    verifySMSCode: (tokenFromEmail: string, tokenFromSMS: string) => Promise<AuthActionResult>;

    signUpWithEmail: (
        token: string,
        firstName: string,
        lastName: string,
        phone: string,
        language: AppLocale
    ) => Promise<AuthActionResult>;
};

type BiometricAuthContext = BiometricAuthState & {
    canAuthenticate: () => Promise<boolean>;
    authenticate: (options?: LocalAuthenticationOptions) => Promise<BiometricAuthActionResult>;
    enable: (options?: LocalAuthenticationOptions) => Promise<boolean>;
    disable: () => Promise<void>;
    reset: () => Promise<void>;
};

type PinAuthContext = PinAuthState & {
    canAuthenticate: () => Promise<boolean>;
    authenticate: (pinCode: string) => Promise<AuthActionResult>;
    setPinCode: (pinCode: string) => Promise<void>;
    reset: () => Promise<boolean>;
};

export type AuthContextType = {
    state: AuthState;
    client: ApolloClient<object>;
    reset: () => void;
    refreshAuthenticationToken: () => Promise<boolean>;
    identity: Omit<UserIdentityContextType, 'set'>;
    remote: RemoteAuthContext;
    biometric: BiometricAuthContext;
    pin: PinAuthContext;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
const mutex = new Mutex();

const AuthContextProvider = ({ children }: PropsWithChildren<object>) => {
    const { config } = useAppConfig();
    const identity = useUserIdentity();
    const { track } = useAnalytics();

    const client = useMemo(() => createAuthClient(config), [config]);

    // This context stores cached values of PIN auth state (`enabled`) and biometric auth state (`available`, `enabled`,
    // `level` and `types`). This is needed because obtaining their current values is async task and consuming
    // these values via async API increases the overall complexity of views (as well as the context itself).
    // Caching is implemented by storing these values to references using `useRef`. This way, we can easily store
    // the current values and mutate them without updating the context (in cae of e.g. `setPinCode`). On the other
    // hand, in some situations (like `reset`) context update is desired. For this use case, `cache` state variable
    // is available so that it can be mutated to force context update.

    const [authState, setAuthState] = useState<AuthState>('initializing');
    const [, clearCache] = useState<number>(0);
    const pinAuthState = useRef<PinAuthState>({ available: isNative(), enabled: false });
    const biometricAuthState = useRef<BiometricAuthState>({
        available: null,
        enabled: null,
        level: SecurityLevel.NONE,
        types: []
    });

    /**
     * Utility method for checking if a valid refresh token exists
     */
    const canAuthenticateWithToken = useCallback(async () => {
        const refreshExpiresIn = await getRefreshExpiresIn();

        return !!refreshExpiresIn && refreshExpiresIn > new Date(Date.now());
    }, []);

    const resetAuthenticationState = useCallback(() => {
        try {
            if (authState === 'authenticated') {
                ErrorDiagnostics.log('Resetting auth state');
                // Unset auth token and set local state to unauthenticated
                clearAuthToken();
                setAuthState('unauthenticated');
            }
        } catch (error) {
            ErrorDiagnostics.error(error);
        }
    }, [authState]);

    const clearUserIdentity = useCallback(async () => {
        ErrorDiagnostics.log('Clear user identity');

        clearAuthToken();

        pinAuthState.current = { available: isNative(), enabled: null };

        await Promise.all([
            identity.clear(),
            clearRefreshToken(),
            clearRefreshExpiresIn(),
            clearPinCode(),
            clearBiometricAuthEnabled()
        ]);
    }, [identity]);

    const doRefreshAuthenticationToken = async () => {
        const refreshToken = await getRefreshToken();
        if (refreshToken) {
            const result = await tokenRefresh(client, refreshToken);
            await setToken(result);
            return result;
        } else {
            throw new Error('Authentication error: refresh token not available');
        }
    };

    const refreshAuthenticationToken = async () => {
        return mutex.runExclusive(async () => {
            ErrorDiagnostics.log('Refreshing authentication token');
            const result = await doRefreshAuthenticationToken();
            ErrorDiagnostics.log('Authentication token refreshed');
            return result;
        });
    };

    const refreshAuthenticationTokenWithLogoutOnError = async (): Promise<boolean> => {
        try {
            await refreshAuthenticationToken();
            return true;
        } catch (error: unknown) {
            await clearUserIdentity();
            setAuthState('unauthenticated');
            throw new Error('Authentication error: refresh token expired');
        }
    };

    /**
     * Handle auth state initialisation
     */
    useAsyncEffect(async mounted => {
        if (mounted()) {
            try {
                ErrorDiagnostics.log('Initializing auth state...');

                if (isNative()) {
                    const [hardware, enrolled, level, types, biometricAuthEnabled, pinCode] = await Promise.all([
                        hasHardwareAsync(),
                        isEnrolledAsync(),
                        getEnrolledLevelAsync(),
                        supportedAuthenticationTypesAsync(),
                        getBiometricAuthEnabled(),
                        getPinCode()
                    ]);

                    biometricAuthState.current = {
                        available: hardware && enrolled,
                        enabled: biometricAuthEnabled,
                        level,
                        types
                    };

                    pinAuthState.current = { available: isNative(), enabled: pinCode !== null ? true : null };

                    /**
                     * Ensure that no identity exists if pin code doesn't exist
                     *
                     * Otherwise, we might end up in a situation where identity and API tokens are for the previous
                     * session/user. This might i.e. cause the client to authenticate with mismatching identities between
                     * eIdent and the API tokens causing all related accounts to be locked.
                     */
                    if (!pinAuthState.current.enabled) {
                        await clearUserIdentity();
                    }

                    setAuthState('unauthenticated');

                    track({
                        type: 'auth',
                        action: 'init',
                        detail1: `pin=${pinAuthState.current}`,
                        detail2: `biometric=${biometricAuthState.current}`,
                        detail3: `types=${types}`,
                        detail4: `level=${level}`
                    });
                } else {
                    // In web we don't want to reset auth state when context is mounted as that happens on refreshs.
                    // Instead try to refresh authentication token, and if that succeeds, set state as authenticated.

                    const refreshTokenValid = await canAuthenticateWithToken();
                    const currentRefreshToken = await getRefreshToken();

                    if (!currentRefreshToken || !refreshTokenValid) {
                        await clearUserIdentity();
                        setAuthState('unauthenticated');
                    } else {
                        try {
                            const result = await refreshAuthenticationToken();
                            const commitResult = await commitRemoteAuthLogin(result, false);
                            if (commitResult.success) {
                                commitResult.commit();
                            } else {
                                await clearUserIdentity();
                            }
                        } catch (error: unknown) {
                            await clearUserIdentity();
                            setAuthState('unauthenticated');
                        }
                    }
                }
            } catch (error) {
                pinAuthState.current = { available: isNative(), enabled: null };
                setAuthState('unauthenticated');
                ErrorDiagnostics.error(error);
            }
        }
    }, []);

    const assertIdentity = useCallback(
        async (nextIdentity: string): Promise<void> => {
            const currentIdentity = await identity.get();

            // Reset the currently stored user identity (i.e. to prevent logging in with pin code/face id)
            // if the previous user identity differs from the identity of the user logged in.
            if (currentIdentity !== null && currentIdentity !== nextIdentity) {
                ErrorDiagnostics.log(
                    `Clearing current user identity (${currentIdentity}) because of ${nextIdentity} logged in`
                );

                await clearUserIdentity();
            }
        },
        [clearUserIdentity, identity]
    );

    const setToken = useCallback(async (result: TokenAuthResult): Promise<void> => {
        setAuthToken(result.token);
        await setRefreshToken(result.refreshToken);
        await setRefreshExpiresIn(result.refreshExpiresIn);
    }, []);

    /**
     * Remote auth context
     */
    const commitRemoteAuthLogin = useCallback(
        async (result: TokenAuthResult, saveTokenValues = true): Promise<AuthActionResult> => {
            await assertIdentity(result.id);
            await identity.set(result.id);

            if (saveTokenValues) {
                await setToken(result);
            }

            return {
                success: true,
                commit: () => setAuthState('authenticated')
            };
        },
        [assertIdentity, identity, setToken]
    );

    const remote = useMemo<RemoteAuthContext>(
        () => ({
            login: async (username: string, password: string): Promise<AuthActionResult> => {
                try {
                    const result = await tokenObtain(client, username, password);
                    track({ type: 'auth-remote-login', action: 'success' });

                    return commitRemoteAuthLogin(result);
                } catch (error: unknown) {
                    track({ type: 'auth-remote-login', action: 'failure' });
                    return { success: false };
                }
            },
            logout: async (): Promise<AuthActionResult> => {
                const currentRefreshToken = await getRefreshToken();

                if (!currentRefreshToken) {
                    track({ type: 'auth-remote-logout', action: 'failure' });
                    throw new Error(`Cannot revoke remote auth token without refresh token`);
                }

                try {
                    if (await tokenRevoke(client, currentRefreshToken)) {
                        track({ type: 'auth-remote-logout', action: 'success' });
                        return {
                            success: true,
                            commit: async () => {
                                // Logout will reset biometric and PIN code authentication
                                await clearBiometricAuthEnabled();
                                biometricAuthState.current = { ...biometricAuthState.current, enabled: null };
                                await clearUserIdentity();
                                setAuthState('unauthenticated');
                            }
                        };
                    }
                } catch (error: unknown) {}

                track({ type: 'auth-remote-logout', action: 'failure' });
                return { success: false };
            },
            getAuthToken: (): string => {
                if (authState === 'authenticated') {
                    const authToken = getAuthToken();

                    if (authToken) {
                        return authToken;
                    }

                    ErrorDiagnostics.error(`Failed to read auth token even if in authenticated state`);
                }
                throw new Error('Cannot access auth token in unauthenticated state');
            },

            eidentVerificationRequired: async (email: string): Promise<boolean> => {
                return eidentVerificationRequired(client, email);
            },
            eidentInit: async (): Promise<string> => {
                return eidentInit(client);
            },
            eidentVerify: async (params: string): Promise<AuthActionResult> => {
                try {
                    const result = await eidentVerify(client, params);
                    track({ type: 'auth-eident', action: 'success' });

                    return commitRemoteAuthLogin(result);
                } catch (error: unknown) {
                    track({ type: 'auth-eident', action: 'failure' });
                    return { success: false };
                }
            },

            signUpWithEmail: async (
                token: string,
                firstName: string,
                lastName: string,
                phone: string,
                language: AppLocale
            ): Promise<AuthActionResult> => {
                try {
                    const result = await signUpWithEmail(client, token, firstName, lastName, phone, language);

                    track({ type: 'auth-signup', action: 'success' });
                    return commitRemoteAuthLogin(result);
                } catch (error: unknown) {
                    track({ type: 'auth-signup', action: 'failure' });
                    return { success: false };
                }
            },
            verifyEmail: (email: string): Promise<boolean> => {
                return verifyEmail(client, email);
            },
            verifySMSCode: async (tokenFromEmail: string, tokenFromSMS: string): Promise<AuthActionResult> => {
                try {
                    const result = await verifySMSToken(client, tokenFromEmail, tokenFromSMS);

                    track({ type: 'auth-sms', action: 'success' });
                    return commitRemoteAuthLogin(result);
                } catch (error: unknown) {
                    track({ type: 'auth-sms', action: 'failure' });
                    return { success: false };
                }
            },
            resendSMSCode: async (tokenFromEmail: string): Promise<boolean> => {
                return resendSMSToken(client, tokenFromEmail);
            }
        }),
        [client, track, commitRemoteAuthLogin, clearUserIdentity, authState]
    );

    /**
     * Biometric auth context
     */
    const biometric = useMemo<BiometricAuthContext>(
        () => ({
            ...biometricAuthState.current,
            canAuthenticate: async (): Promise<boolean> => {
                const { enabled } = biometricAuthState.current;
                const tokenValid = await canAuthenticateWithToken();
                const canAuthenticate = enabled === true && tokenValid;
                track(
                    enabled === true && tokenValid
                        ? { type: 'auth-biometric', action: 'can-authenticate' }
                        : { type: 'auth-biometric', action: 'cannot-authenticate', detail1: `tokenValid=${tokenValid}` }
                );
                return canAuthenticate;
            },
            authenticate: async (options?: LocalAuthenticationOptions): Promise<BiometricAuthActionResult> => {
                const { available } = biometricAuthState.current;

                if (available) {
                    const result = await authenticateAsync(options);

                    if (result.success) {
                        track({ type: 'auth-biometric', action: 'success' });
                        return { success: true, pinCode: (await getPinCode())! };
                    }

                    track({ type: 'auth-biometric', action: 'failure' });
                    return { success: false, reason: 'biometric-auth-failed' };
                }
                const reason: BiometricAuthFailureReason = 'biometric-auth-not-available';
                track({ type: 'auth-biometric', action: 'failure', detail1: `reason=${reason}` });
                return { success: false, reason };
            },
            enable: async (options?: LocalAuthenticationOptions): Promise<boolean> => {
                const result = await authenticateAsync(options);
                if (result.success) {
                    const enabled = true;
                    await setBiometricAuthEnabled(enabled);
                    biometricAuthState.current = { ...biometricAuthState.current, enabled };
                    track({ type: 'auth-biometric', action: 'enable-success' });
                    return true;
                }
                track({ type: 'auth-biometric', action: 'enable-failure' });
                return false;
            },
            disable: async (): Promise<void> => {
                const enabled = false;
                await setBiometricAuthEnabled(enabled);
                biometricAuthState.current = { ...biometricAuthState.current, enabled };
                track({ type: 'auth-biometric', action: 'disable' });
            },
            reset: async (): Promise<void> => {
                await clearBiometricAuthEnabled();
                biometricAuthState.current = { ...biometricAuthState.current, enabled: null };
                clearCache(state => state + 1);
                track({ type: 'auth-biometric', action: 'reset' });
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [track, biometricAuthState, canAuthenticateWithToken, biometricAuthState.current]
    );

    /**
     * Pin auth context
     */
    const pin = useMemo<PinAuthContext>(
        () => ({
            ...pinAuthState.current,
            canAuthenticate: async (): Promise<boolean> => {
                const { available, enabled } = pinAuthState.current;
                const tokenValid = await canAuthenticateWithToken();
                const canAuthenticate = available && enabled === true && tokenValid;
                track(
                    canAuthenticate
                        ? { type: 'auth-pin', action: 'can-authenticate' }
                        : { type: 'auth-pin', action: 'cannot-authenticate', detail1: `tokenValid=${tokenValid}` }
                );
                return canAuthenticate;
            },
            authenticate: async (pinCode: PinCode): Promise<AuthActionResult> => {
                const currentRefreshToken = await getRefreshToken();
                const currentPinCode = await getPinCode();

                if (pinCode !== currentPinCode) {
                    const reason: PinAuthFailureReason = 'pin-auth-invalid-pin-code';
                    track({ type: 'auth-pin', action: 'failure', detail1: `reason=${reason}` });
                    return { success: false, reason };
                }
                if (!currentRefreshToken) {
                    const reason: PinAuthFailureReason = 'pin-auth-invalid-refresh-token';
                    track({ type: 'auth-pin', action: 'failure', detail1: `reason=${reason}` });
                    return { success: false, reason };
                }

                try {
                    const result = await refreshAuthenticationToken();

                    track({ type: 'auth-pin', action: 'success' });
                    return commitRemoteAuthLogin(result, false);
                } catch (error: unknown) {
                    track({ type: 'auth-pin', action: 'failure', detail1: `reason=${error}` });
                    if (error instanceof RecoverableError) {
                        return { success: false, reason: 'pin-auth-recoverable-error' };
                    } else {
                        await clearUserIdentity();
                        return { success: false };
                    }
                }
            },
            setPinCode: async (pinCode: PinCode): Promise<void> => {
                await setPinCode(pinCode);
                track({ type: 'auth-pin', action: 'set-pin' });
                pinAuthState.current = { available: isNative(), enabled: true };
            },
            reset: async (): Promise<boolean> => {
                try {
                    await clearPinCode();
                    pinAuthState.current = { available: isNative(), enabled: null };
                    clearCache(state => state + 1);
                    track({ type: 'auth-pin', action: 'clear-pin' });
                    return true;
                } catch (error) {
                    ErrorDiagnostics.error(error);
                    return false;
                }
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [track, client, canAuthenticateWithToken, pinAuthState.current]
    );

    return (
        <AuthContext.Provider
            value={
                remote && biometric && pin
                    ? {
                          state: authState,
                          client,
                          reset: resetAuthenticationState,
                          refreshAuthenticationToken: refreshAuthenticationTokenWithLogoutOnError,
                          identity: { ...identity, clear: clearUserIdentity },
                          remote,
                          biometric,
                          pin
                      }
                    : undefined
            }
        >
            {authState !== 'initializing' && remote && biometric && pin ? children : null}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;
export const AuthProvider = AuthContextProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw Error('Cannot use context until it defined');
    }
    return context;
};
