import styled from 'styled-components/native';

import { Color } from '~/components/color';

export const NoContentContainer = styled.View`
    flex: 1;
    flex-grow: 0;
    min-height: 128px;
    border: 0.3px solid ${Color.LINE_DEFAULT};
    border-radius: 16px;
    height: 128px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px;
`;
