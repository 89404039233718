import { useQuery } from '@apollo/client';

import { MemberActionFragment, GetMemberActionsDocument, MemberActionType } from '~/types';

export type Action = {
    type: MemberActionType;
    title: string;
    descriptionShort: string;
    actionUrl?: string;
    description: string;
};

export const chatActions: MemberActionType[] = ['HELTTI_CHAT', 'LIVE_CHAT', 'OWN_NURSE', 'FEEDBACK', 'SUPPORT'];

const contactCategoryActions: MemberActionType[] = ['HELTTI_CHAT', 'LIVE_CHAT', 'CALL', 'REPORT_SICK_LEAVE'];
const nonBusyCategoryActions: MemberActionType[] = ['FEEDBACK', 'OWN_NURSE', 'SUPPORT', 'FAQ'];

export const useMemberActions = () => {
    const { data, loading, error } = useQuery(GetMemberActionsDocument);

    const availableActions = data?.root?.availableActions?.actions ?? [];

    const fragmentToAction = (fragment: MemberActionFragment): Action => {
        return {
            title: fragment.title!,
            descriptionShort: fragment.descriptionShort!,
            description: fragment.description!,
            type: fragment.typeNew!,
            actionUrl: fragment.actionUrl!
        };
    };

    const availableContactCategoryActions = [
        ...availableActions
            .filter(action => action.typeNew && contactCategoryActions.includes(action.typeNew))
            .map(fragmentToAction)
    ];

    const availableNonBusyCategoryActions = [
        ...availableActions
            .filter(action => action.typeNew && nonBusyCategoryActions.includes(action.typeNew))
            .map(fragmentToAction)
    ];

    return {
        loading,
        error,
        contactCategoryActions: availableContactCategoryActions,
        nonBusyCategoryActions: availableNonBusyCategoryActions
    };
};
