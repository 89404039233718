import { AuthenticationType } from 'expo-local-authentication/src/LocalAuthentication.types';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { Platform, Pressable } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { SCREEN_HEIGHT_RATIO } from '~/components/screen';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { isWeb } from '~/utils/platform';

import { DeleteIcon, FaceIdIcon, IrisIdIcon, TouchIdIcon } from './pin-code-icons';

const KeyboardButtons = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    ${Platform.select({
        android: `
    background-color: rgba(0, 0, 0, 0.18);
    border-radius: 16px;
    `,
        ios: '',
        web: `
    background-color: rgba(0, 0, 0, 0.18);
    border-radius: 16px;
    `
    })}
    margin-top: ${Spacing.SMALL}px;
`;

const ACTION_ICON = {
    [AuthenticationType.FACIAL_RECOGNITION]: () => <FaceIdIcon />,
    [AuthenticationType.FINGERPRINT]: () => <TouchIdIcon />,
    [AuthenticationType.IRIS]: () => <IrisIdIcon />
};

type PinCodeKeyboardProps = {
    value: string;
    onKeyPress: (value: number) => void;
    onDelete: () => void;
    biometricAuthType?: AuthenticationType;
    onBioMetricAuthenticate?: () => void;
    disabled?: boolean;
};

export const PinCodeKeyboard: React.FC<PinCodeKeyboardProps> = props => {
    const { biometricAuthType, onKeyPress, onDelete, onBioMetricAuthenticate, disabled = false } = props;

    const enter = useCallback((num: number) => () => onKeyPress(num), [onKeyPress]);

    const action = biometricAuthType ? ACTION_ICON[biometricAuthType] : undefined;

    /**
     * Register keyboard listeners on web
     */
    useEffect(() => {
        if (isWeb()) {
            const handleKeyDown = (e: KeyboardEvent) => {
                if (e.code.startsWith('Digit')) {
                    onKeyPress(parseInt(e.key, 10));
                } else if (e.code === 'Backspace') {
                    onDelete();
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            // Don't forget to clean up
            return function cleanup() {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [onDelete, onKeyPress]);

    return (
        <KeyboardButtons style={Shadow.styles.primary}>
            <KeyboardButton
                key="1"
                label="1"
                accessibilityLabel="1"
                onPress={enter(1)}
                corner="tl"
                disabled={disabled}
            />
            <KeyboardButton key="2" label="2" accessibilityLabel="2" onPress={enter(2)} disabled={disabled} />
            <KeyboardButton
                key="3"
                label="3"
                accessibilityLabel="3"
                onPress={enter(3)}
                corner="tr"
                disabled={disabled}
            />
            {[4, 5, 6, 7, 8, 9].map(num => (
                <KeyboardButton
                    key={num.toString()}
                    label={num}
                    accessibilityLabel={num.toString()}
                    onPress={enter(num)}
                    disabled={disabled}
                />
            ))}
            {action ? (
                <>
                    <KeyboardButton
                        key="extra"
                        label={action}
                        onPress={onBioMetricAuthenticate}
                        testID="biometricAuthenticationButton"
                        corner="bl"
                        disabled={disabled}
                    />
                    <KeyboardButton
                        key="0"
                        maxi={!action}
                        label="0"
                        accessibilityLabel="0"
                        onPress={enter(0)}
                        disabled={disabled}
                    />
                </>
            ) : (
                <KeyboardButton
                    key="0"
                    maxi={!action}
                    label="0"
                    accessibilityLabel="0"
                    onPress={enter(0)}
                    corner="bl"
                    disabled={disabled}
                />
            )}
            <KeyboardButton
                key="del"
                label={() => <DeleteIcon color={!disabled ? Color.TEXT_DEFAULT : Color.TEXT_DISABLED} />}
                accessibilityLabel="delete"
                onPress={onDelete}
                corner="br"
                disabled={disabled}
            />
        </KeyboardButtons>
    );
};

type KeyboardButtonRenderFunc = () => ReactElement;

type KeyboardButtonProps = {
    key: string;
    label: string | number | KeyboardButtonRenderFunc;
    onPress?: () => void;
    corner?: 'tl' | 'tr' | 'bl' | 'br';
    maxi?: boolean;
    disabled: boolean;
    accessibilityLabel?: string;
    testID?: string;
};

const KeyboardButtonInset = styled.View<{ maxi: boolean }>`
    width: ${({ maxi }) => (maxi ? 66.66 : 33.33)}%;
    height: ${SCREEN_HEIGHT_RATIO * 60}px;
    padding: 0.5px;
`;

const BORDER_RADIUS_MAP: { [key: string]: string } = {
    tl: `border-top-left-radius: 16px;`,
    tr: `border-top-right-radius: 16px;`,
    bl: `border-bottom-left-radius: 16px;`,
    br: `border-bottom-right-radius: 16px;`
};

const KeyboardButtonFrame = styled.View<{
    corner?: keyof typeof BORDER_RADIUS_MAP;
    pressed: boolean;
    disabled: boolean;
}>`
    height: 100%;
    align-items: center;
    justify-content: center;
    ${({ corner }) => BORDER_RADIUS_MAP[corner ?? '']};
    background-color: ${({ pressed }) => (pressed ? Color.BACKGROUND_PRIMARY : Color.BACKGROUND_DEFAULT)};
`;

const KeyboardButton: React.FC<KeyboardButtonProps> = props => {
    const { label, onPress, maxi = false, corner, disabled, accessibilityLabel, testID } = props;

    return (
        <KeyboardButtonInset maxi={maxi}>
            <Pressable
                accessible
                accessibilityRole="button"
                onPress={onPress}
                disabled={disabled}
                testID={testID}
                accessibilityLabel={accessibilityLabel}
                style={{ flexGrow: 1 }}
            >
                {({ pressed }) => (
                    <KeyboardButtonFrame corner={corner} pressed={pressed} disabled={disabled}>
                        {typeof label === 'function' ? (
                            label()
                        ) : (
                            <Text.P1 style={{ color: !disabled ? Color.TEXT_DEFAULT : Color.TEXT_DISABLED }}>
                                {label}
                            </Text.P1>
                        )}
                    </KeyboardButtonFrame>
                )}
            </Pressable>
        </KeyboardButtonInset>
    );
};
