import { useAppState } from '@react-native-community/hooks';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { NetworkServiceNotification } from '~/components/network-service-notification';
import { useAppConfig } from '~/contexts/app-config';

export type NetworkServiceReachability = 'service-available' | 'service-degraded' | 'service-unavailable';

export type NetworkServiceStateContextType = {
    reachability: NetworkServiceReachability | undefined;
    getAverageLatency: () => number | undefined;
};

export const NetworkServiceStateContext = React.createContext<NetworkServiceStateContextType | undefined>(undefined);

export const REACHABILITY_THRESHOLD = 750; // ms
export const ERROR_COUNT_THRESHOLD = 3;

const CHECK_INTERVAL_SHORT = 5000; // Check interval if service was _not_ previously available
const CHECK_INTERVAL_LONG = 10000; // Check interval if service was previously available

const CHECK_INTERVAL_TIMEOUT = 5000;

type NetworkServiceReachabilityState = {
    latency: [number, number]; // tuple of [latency cumulative moving average, number of samples]
    errors: number; // successive error count
    interval: number;
};

function NetworkServiceStateContextProvider(props: PropsWithChildren<object>) {
    const { children } = props;
    const { endpoint, config } = useAppConfig();
    const status = useAppState();

    const reachabilityCheckTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [reachability, setReachability] = useState<NetworkServiceReachability>();

    const state = useRef<NetworkServiceReachabilityState>({
        latency: [0, 0],
        errors: 0,
        interval: CHECK_INTERVAL_SHORT
    });

    const handleCheckReachability = useCallback(async () => {
        function success(startTime: number) {
            const [value, n] = state.current.latency;
            const cma = (Date.now() - startTime + n * value) / (n + 1);

            state.current.latency = [cma, n + 1];
            state.current.errors = 0;

            if (cma > REACHABILITY_THRESHOLD && n > 5) {
                setReachability('service-degraded');
                state.current.interval = CHECK_INTERVAL_SHORT;
            } else {
                setReachability('service-available');
                state.current.interval = CHECK_INTERVAL_LONG;
            }
        }

        function failure() {
            state.current.errors += 1;
            state.current.interval = CHECK_INTERVAL_SHORT;

            if (state.current.errors > ERROR_COUNT_THRESHOLD) {
                setReachability('service-unavailable');
            } else {
                setReachability('service-degraded');
            }
        }

        if (status === 'active') {
            const controller = new AbortController();
            const id = setTimeout(() => controller.abort(), CHECK_INTERVAL_TIMEOUT);

            try {
                const then = Date.now();
                const result = await fetch(`${config.http}://${config.host}/ping`, { signal: controller.signal });

                if (result.ok) {
                    success(then);
                } else {
                    failure();
                }
            } catch (error: unknown) {
                failure(); // Network error or `AbortError`
            } finally {
                reachabilityCheckTimer.current = setTimeout(handleCheckReachability, state.current.interval);
                clearTimeout(id);
            }
        }
    }, [config, status]);

    const getAverageLatency = useCallback(() => state.current.latency[0], []);

    useEffect(() => {
        setReachability(undefined);
        state.current = {
            latency: [0, 0],
            errors: 0,
            interval: CHECK_INTERVAL_SHORT
        };
    }, [endpoint, config]);

    useEffect(() => {
        reachabilityCheckTimer.current = setTimeout(handleCheckReachability, state.current.interval);
        return () => {
            if (reachabilityCheckTimer.current !== null) {
                clearTimeout(reachabilityCheckTimer.current);
            }
        };
    }, [handleCheckReachability]);

    const value: NetworkServiceStateContextType = {
        reachability,
        getAverageLatency
    };

    return (
        <NetworkServiceStateContext.Provider value={value}>
            <NetworkServiceNotification reachability={value.reachability} latency={state.current.latency[0]} />
            {children}
        </NetworkServiceStateContext.Provider>
    );
}

export const NetworkServiceStateConsumer = NetworkServiceStateContext.Consumer;
export const NetworkServiceStateProvider = NetworkServiceStateContextProvider;

export function useNetworkServiceState() {
    const context = useContext<NetworkServiceStateContextType | undefined>(NetworkServiceStateContext);
    if (!context) {
        throw Error('Cannot use app network service state context until it has been defined');
    }
    return context;
}
