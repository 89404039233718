import React, { ComponentType, PropsWithChildren, ReactNode } from 'react';
import { Pressable, Text as NativeText, TextProps as NativeTextProps, ViewStyle, StyleProp } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';
import { Loader } from '~/components/loader';
import { Shadow } from '~/components/shadow';
import { Text } from '~/components/text';

export type ButtonShape = 'square' | 'rounded';
export type ButtonType = 'basic' | 'primary' | 'secondary' | 'negative' | 'text';
export type ButtonSize = 'tiny' | 'small' | 'regular' | 'large' | 'huge';

type ButtonState = 'disabled' | 'enabled' | 'hover' | 'select';

type ButtonVariant = `${ButtonType}-${ButtonState}`;
type ButtonVariantColors = {
    [key in ButtonVariant]: string;
};

export type ButtonProps = {
    label: string;
    helper?: string;
    fillContainer?: boolean;
    alignCenter?: boolean;
    loading?: boolean;
    disabled?: boolean;
    disabledStateRendered?: boolean;
    shape?: ButtonShape;
    type?: ButtonType;
    size?: ButtonSize;
    leftIcon?: React.ComponentType<IconProps> | ReactNode;
    rightIcon?: React.ComponentType<IconProps> | ReactNode;
    onPress?: () => void | Promise<void>;
    style?: StyleProp<ViewStyle>;
};

const isString = (obj: unknown): obj is string => {
    return typeof obj === 'string';
};

const isNumber = (obj: unknown): obj is number => {
    return typeof obj === 'number';
};

const isBoolean = (obj: unknown): obj is boolean => {
    return typeof obj === 'boolean';
};

const isChild = (icon: React.ComponentType<IconProps> | ReactNode): icon is ReactNode => {
    return (
        icon === null || icon === undefined || isString(icon) || isNumber(icon) || isBoolean(icon) || 'props' in icon
    );
};

const BACKGROUND_COLOR: ButtonVariantColors = {
    'basic-disabled': Color.BASIC_DEFAULT,
    'basic-enabled': Color.BACKGROUND_DEFAULT,
    'basic-hover': Color.BASIC_HOVER,
    'basic-select': Color.BASIC_PRESSED,

    'primary-disabled': Color.BASIC_DEFAULT,
    'primary-enabled': Color.PRIMARY_DEFAULT,
    'primary-hover': Color.PRIMARY_HOVER,
    'primary-select': Color.PRIMARY_PRESSED,

    'secondary-disabled': Color.BASIC_DEFAULT,
    'secondary-enabled': Color.BACKGROUND_DEFAULT,
    'secondary-hover': Color.BASIC_HOVER,
    'secondary-select': Color.BASIC_PRESSED,

    'negative-disabled': Color.BASIC_DEFAULT,
    'negative-enabled': Color.CHERRY,
    'negative-hover': Color.WARNING_HOVER,
    'negative-select': Color.WARNING_PRESSED,

    'text-disabled': Color.BASIC_DEFAULT,
    'text-enabled': Color.BACKGROUND_DEFAULT,
    'text-hover': Color.BASIC_HOVER,
    'text-select': Color.BASIC_PRESSED
};

const FOREGROUND_COLOR: ButtonVariantColors = {
    'basic-disabled': Color.TEXT_DISABLED,
    'basic-enabled': Color.ALMOST_BLACK,
    'basic-hover': Color.BASIC_HOVER,
    'basic-select': Color.ALMOST_BLACK,

    'primary-disabled': Color.TEXT_DISABLED,
    'primary-enabled': Color.BACKGROUND_DEFAULT,
    'primary-hover': Color.BACKGROUND_DEFAULT,
    'primary-select': Color.BACKGROUND_DEFAULT,

    'secondary-disabled': Color.TEXT_DISABLED,
    'secondary-enabled': Color.PRIMARY_DEFAULT,
    'secondary-hover': Color.PRIMARY_DEFAULT,
    'secondary-select': Color.PRIMARY_DEFAULT,

    'negative-disabled': Color.TEXT_DISABLED,
    'negative-enabled': Color.BACKGROUND_DEFAULT,
    'negative-hover': Color.BACKGROUND_DEFAULT,
    'negative-select': Color.BACKGROUND_DEFAULT,

    'text-disabled': Color.TEXT_DISABLED,
    'text-enabled': Color.ALMOST_BLACK,
    'text-hover': Color.BASIC_HOVER,
    'text-select': Color.ALMOST_BLACK
};

const ButtonContainer = styled.View<{
    alignCenter: boolean;
    shape: ButtonShape;
    buttonState: ButtonState;
    type: ButtonType;
    size: ButtonSize;
}>`
    flex-direction: row;

    height: ${({ size }) => ({ tiny: '24px', small: '32px', regular: '48px', large: '60px', huge: '80px' }[size])};
    padding-horizontal: ${({ shape, size }) =>
        ({
            tiny: { square: '10px', rounded: '10px' },
            small: { square: '14px', rounded: '14px' },
            regular: { square: '16px', rounded: '18px' },
            large: { square: '16px', rounded: '22px' },
            huge: { square: '18px', rounded: '26px' }
        }[size][shape])};
    background-color: ${({ type, buttonState }) => BACKGROUND_COLOR[`${type}-${buttonState}` as ButtonVariant]};
    border-radius: ${({ shape, size }) =>
        ({
            tiny: { square: '8px', rounded: '12px' },
            small: { square: '12px', rounded: '16px' },
            regular: { square: '14px', rounded: '24px' },
            large: { square: '16px', rounded: '28px' },
            huge: { square: '18px', rounded: '32px' }
        }[size][shape])};
    justify-content: ${({ alignCenter }) => (alignCenter ? 'center' : 'space-between')};
    align-items: center;
    user-select: none;
`;

const ButtonLabelContainer = styled.View<{ alignCenter: boolean }>`
    flex: 1 1 auto;
    flex-direction: column;
    align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
    justify-content: space-between;
    margin-horizontal: 8px;
`;

type ButtonSubviewProps = PropsWithChildren<{ buttonState: ButtonState; type: ButtonType; size: ButtonSize }>;

const ButtonLabel = (props: NativeTextProps & ButtonSubviewProps) => {
    const { buttonState, type, size, children, ...rest } = props;
    const styles = ['tiny', 'small'].includes(size) ? Text.SMALL_BUTTON_LABEL_STYLES : Text.BUTTON_LABEL_STYLES;
    const color = FOREGROUND_COLOR[`${type}-${buttonState}` as ButtonVariant];
    const fontSize = { tiny: 12, small: 14, regular: 16, large: 16, huge: 16 }[size];
    const paddingVertical = size === 'huge' ? 3 : 0;

    return (
        <NativeText style={{ flexShrink: 1, ...styles, fontSize, color, paddingVertical }} {...rest}>
            {children}
        </NativeText>
    );
};

const ButtonLabelHelper = (props: NativeTextProps & ButtonSubviewProps) => {
    const { buttonState, size, children } = props;
    const styles = ['tiny', 'small'].includes(size) ? Text.SMALL_BUTTON_LABEL_STYLES : Text.BUTTON_LABEL_STYLES;
    const color = buttonState !== 'disabled' ? Color.TEXT_SECONDARY : Color.TEXT_DISABLED;
    const paddingVertical = size === 'huge' ? 3 : 0;

    return (
        <NativeText style={{ ...styles, fontSize: 14, marginTop: 1, color, paddingVertical }}>{children}</NativeText>
    );
};

const state = (disabled: boolean, pressed: boolean) => (pressed ? 'select' : disabled ? 'disabled' : 'enabled');

export const Button = (props: ButtonProps) => {
    const {
        label,
        helper,
        loading,
        fillContainer = false,
        alignCenter = true,
        disabled = false,
        disabledStateRendered = true,
        shape = 'square',
        type = 'basic',
        size = 'regular',
        onPress,
        style
    } = props;
    const { leftIcon: LeftIcon, rightIcon: RightIcon } = props;

    const renderLoaderOrIcon = (Icon: ComponentType<IconProps> | ReactNode, fill: string) => {
        if (loading) {
            return <Loader size="small" fill={fill} />;
        }
        if (!isChild(Icon)) {
            return <Icon fill={fill} />;
        } else {
            return Icon;
        }
    };

    const renderLoader = (fill: string) => {
        if (loading) {
            return <Loader size="small" fill={fill} />;
        }
    };

    return (
        <Pressable
            accessibilityRole="button"
            accessibilityLabel="Button"
            disabled={disabled || loading}
            onPress={onPress}
            style={[style, fillContainer ? { flex: 1 } : {}]}
        >
            {({ pressed }) => {
                const fill = FOREGROUND_COLOR[`${type}-${state(disabled, pressed)}` as ButtonVariant];
                return (
                    <ButtonContainer
                        alignCenter={alignCenter}
                        buttonState={state(disabled && disabledStateRendered, pressed)}
                        shape={shape}
                        type={type}
                        size={size}
                        style={type !== 'text' && Shadow.styles.primary}
                    >
                        {LeftIcon ? renderLoaderOrIcon(LeftIcon, fill) : null}
                        <ButtonLabelContainer alignCenter={alignCenter}>
                            <ButtonLabel
                                buttonState={state(disabled && disabledStateRendered, pressed)}
                                type={type}
                                size={size}
                                numberOfLines={1}
                                ellipsizeMode="middle"
                            >
                                {label}
                            </ButtonLabel>
                            {['large', 'huge'].includes(size) && helper ? (
                                <ButtonLabelHelper
                                    buttonState={state(disabled && disabledStateRendered, pressed)}
                                    type={type}
                                    size={size}
                                    numberOfLines={1}
                                >
                                    {helper}
                                </ButtonLabelHelper>
                            ) : null}
                        </ButtonLabelContainer>
                        {RightIcon ? renderLoaderOrIcon(RightIcon, fill) : !LeftIcon ? renderLoader(fill) : null}
                    </ButtonContainer>
                );
            }}
        </Pressable>
    );
};

Button.displayName = 'Button';
