import { differenceInMinutes, isSameDay, isToday, isYesterday } from 'date-fns';

import { useIntl } from '~/contexts/intl';

export const useChatTime = () => {
    const { formatDate, formatMessage, formatTime } = useIntl();

    const dateToChatTime = (date: Date) => {
        // Only time if sent within a day
        if (isSameDay(date, new Date())) {
            return formatTime(date, { timeStyle: 'short' });
        }
        return formatDate(date, { dateStyle: 'short', timeStyle: 'short' });
    };

    const dateToRelativeTime = (date: Date) => {
        const minDiff = differenceInMinutes(new Date(), date);
        if (minDiff < 1) {
            return formatMessage('time.now');
        } else if (minDiff < 60) {
            return formatMessage('time.minutes_ago', { minutes: minDiff });
        } else if (isToday(date)) {
            return formatMessage('time.hours_ago', { hours: Math.floor(minDiff / 60) });
        } else if (isYesterday(date)) {
            return formatMessage('time.yesterday');
        } else {
            return formatDate(date, { dateStyle: 'short' });
        }
    };

    return {
        dateToChatTime,
        dateToRelativeTime
    };
};
