import React from 'react';
import { Text, View } from 'react-native';

import { Button } from '~/components/button';

export type ErrorAuthResetProps = {
    error: Error;
    reset: () => void;
};

export const ErrorAuthReset = ({ error, reset }: ErrorAuthResetProps) => {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Text>Logged out ({error.message})</Text>
            <Button label="Back to Login" onPress={reset} />
        </View>
    );
};
