import { Pressable } from 'native-base';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { IconProps } from '~/components/icon';
import { Shadow } from '~/components/shadow';

export type OvalButtonSize = 'small' | 'regular' | 'large';

type OvalButtonState = 'disabled' | 'enabled' | 'hover' | 'select';

export type OvalButtonProps = {
    disabled?: boolean;
    disabledStateRendered?: boolean;
    size?: OvalButtonSize;
    icon: React.ComponentType<IconProps>;
    onPress?: () => void | Promise<void>;
    style?: StyleProp<ViewStyle>;
    fill?: Color;
};

const FOREGROUND_COLOR = {
    disabled: Color.BACKGROUND_DEFAULT,
    enabled: Color.BACKGROUND_DEFAULT,
    hover: Color.BASIC_HOVER,
    select: Color.BASIC_PRESSED
};

const BACKGROUND_SIZE = {
    small: 32,
    regular: 48,
    large: 60
};

const ICON_SIZE = {
    small: 22,
    regular: 32,
    large: 42
};

const OvalButtonBackground = styled.View<{ buttonState: OvalButtonState; size: OvalButtonSize }>`
    width: ${({ size }) => BACKGROUND_SIZE[size]}px;
    height: ${({ size }) => BACKGROUND_SIZE[size]}px;
    border-radius: 50px;
    background-color: ${({ buttonState }) => FOREGROUND_COLOR[buttonState]};
    transform: scale(0.92, 1) rotate(45deg);
`;

const OvalButtonIcon = styled.View<{ size: OvalButtonSize }>`
    position: absolute;
    top: ${({ size }) => (BACKGROUND_SIZE[size] + 6 - ICON_SIZE[size]) / 2}px;
    left: ${({ size }) => (BACKGROUND_SIZE[size] + 6 - ICON_SIZE[size]) / 2}px;
`;

const state = (disabled: boolean, pressed: boolean) => (pressed ? 'select' : disabled ? 'disabled' : 'enabled');

export const OvalButton = (props: OvalButtonProps) => {
    const { disabled = false, disabledStateRendered = true, size = 'regular', onPress, style, fill } = props;
    const { icon: Icon } = props;

    return (
        <Pressable accessibilityRole="button" disabled={disabled} onPress={onPress} style={style} padding="3px">
            {({ isPressed }: { isPressed: boolean }) => {
                const iconFill = disabled && disabledStateRendered ? Color.ICON_DISABLED : fill;
                return (
                    <>
                        <OvalButtonBackground
                            buttonState={state(disabled && disabledStateRendered, isPressed)}
                            size={size}
                            style={Shadow.styles.primary}
                        />
                        <OvalButtonIcon size={size} style={{ elevation: 10 }}>
                            <Icon size={ICON_SIZE[size]} fill={iconFill} />
                        </OvalButtonIcon>
                    </>
                );
            }}
        </Pressable>
    );
};

OvalButton.displayName = 'OvalButton';
