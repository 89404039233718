import { PermissionStatus } from 'expo-modules-core/src/PermissionsInterface';
import { NotificationPermissionsStatus } from 'expo-notifications';
import React, { useContext, useMemo } from 'react';

import { PushPermissionStatus } from '~/contexts/push-notifications/push-notifications-persist';
import {
    PushNotificationsContext,
    PushNotificationContextProviderProps
} from '~/contexts/push-notifications/push-notifications-types';

const PushNotificationsContextProvider = (props: PushNotificationContextProviderProps) => {
    const { children } = props;

    const status: PushPermissionStatus | null = PermissionStatus.UNDETERMINED;
    const value = useMemo(
        () => ({
            requestPermissions: async () =>
                Promise.resolve<NotificationPermissionsStatus>({
                    status: PermissionStatus.UNDETERMINED
                } as NotificationPermissionsStatus)
        }),
        []
    );
    return (
        <PushNotificationsContext.Provider value={{ status, ...value }}>{children}</PushNotificationsContext.Provider>
    );
};

export const PushNotificationsConsumer = PushNotificationsContext.Consumer;
export const PushNotificationsProvider = PushNotificationsContextProvider;

export const usePushNotifications = () => {
    const context = useContext(PushNotificationsContext);
    if (!context) {
        throw Error('Cannot use context until it defined');
    }
    return context;
};
