type PrimitiveType = string | number | boolean;

export const findNextItem = <T extends PrimitiveType>(array: readonly T[], item: T): T | undefined => {
    const index = array.findIndex(i => i === item);
    if (index !== -1 && index < array.length) {
        return array[index + 1];
    }
    return undefined;
};

export function range(start: number, end: number): number[] {
    return end >= start
        ? [...Array(end - start + 1).keys()].map(x => x + start)
        : [...Array(start - end + 1).keys()].map(x => start - x);
}
