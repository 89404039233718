import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useAnalytics } from '~/contexts/analytics';
import { AppError } from '~/error';
import { ChatListDocument, InvokeMemberActionDocument, MemberActionType } from '~/types';

import { chatActions } from './member-actions';

export const useMemberActionMutation = () => {
    const errorHandler = useErrorHandler();
    const [memberActionMutation] = useMutation(InvokeMemberActionDocument);
    const [loading, setLoading] = useState(false);
    const { track } = useAnalytics();

    const onAction = useCallback(
        async (action: MemberActionType, title?: string, reasonForContact?: string) => {
            if (chatActions.includes(action)) {
                setLoading(true);

                try {
                    const result = await memberActionMutation({
                        variables: {
                            input: {
                                typeNew: action,
                                title,
                                reasonForContact
                            }
                        },
                        refetchQueries: [{ query: ChatListDocument }]
                    });

                    track({ type: 'member-action', action, detail1: `title=${title}` });

                    return result.data?.memberAction?.appUrl;
                } catch (err: unknown) {
                    if (err instanceof Error) {
                        errorHandler(
                            new AppError(err, 'error.cannot-create-chat', {
                                onClose: () => {},
                                name: 'error-overlay.go_back'
                            })
                        );
                    } else {
                        errorHandler(err);
                    }
                }
                setLoading(false);
            }
        },
        [errorHandler, memberActionMutation, track]
    );

    return {
        loading,
        onAction
    };
};
