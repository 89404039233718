import { useMutation } from '@apollo/client';

import { useUserProfile } from '~/contexts/user-profile';
import { ReportSickLeaveDocument } from '~/types';
import { range } from '~/utils/array';

export function useReportSickLeave() {
    const { employment } = useUserProfile();
    const { sickLeaveMinDays = 0, sickLeaveMaxDays = 0 } = employment?.company ?? {};
    const sickLeaveDays = range(sickLeaveMinDays, sickLeaveMaxDays);

    const [reportSickLeave] = useMutation(ReportSickLeaveDocument);

    return {
        sickLeaveDays,
        reportSickLeave
    };
}
