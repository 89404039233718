import { useMutation } from '@apollo/client';
import { decode } from 'base-64';
import * as Linking from 'expo-linking';
import { useCallback, useState } from 'react';

import * as ErrorDiagnostics from '~/error';
import { GetInteractionsDocument, InteractionTriggerDocument } from '~/types';

export function useInteractionTrigger() {
    const [mutate] = useMutation(InteractionTriggerDocument);
    const [loading, setLoading] = useState<boolean>(false);

    const getInteractionHandlesFromURL = useCallback((linkUrl: string): [string, string] | null => {
        try {
            const url = new URL(linkUrl);
            if (url.pathname.includes('triggerInteraction')) {
                const pathParts = url.pathname
                    .split('/')
                    .filter(item => item.length !== 0 && item !== 'triggerInteraction');

                const [handle, actionHandle] = pathParts;

                if (handle && actionHandle) {
                    return [decode(handle), decode(actionHandle)];
                }
            }
        } catch (error) {
            ErrorDiagnostics.error(error);
        }
        return null;
    }, []);

    const triggerInteraction = useCallback(
        async (handle: string, actionHandle: string) => {
            try {
                setLoading(true);
                const result = await mutate({
                    variables: { input: { handle, actionHandle } },
                    // See https://github.com/apollographql/apollo-client/issues/8900#issuecomment-1013529904
                    refetchQueries: [{ query: GetInteractionsDocument }]
                });

                const { redirectTo } = result.data?.interactionTrigger?.interactionResult ?? {};
                if (redirectTo) {
                    await Linking.openURL(redirectTo);
                }
                setLoading(false);
            } catch (error: unknown) {
                ErrorDiagnostics.error(error);
            }
        },
        [mutate]
    );

    return {
        loading,
        getInteractionHandlesFromURL,
        triggerInteraction
    };
}
