import { useMutation } from '@apollo/client';
import { isFuture } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useUserProfile } from '~/contexts/user-profile';
import { AppError } from '~/error';
import {
    CreateMemberDocumentExportTokenDocument,
    DeleteMemberDocumentExportTokeDocument,
    DocumentDocument,
    DocumentFragment,
    User
} from '~/types';
import { relayConnectionReduce } from '~/utils';

import { useLoadingQuery } from '../loading-query';

type DocumentProps = {
    documentId: ID;
};

export type DocumentItem = {
    filename: string;
    addedDate: Date;
    url?: string | null;
    createdBy?: User | null;
    comment?: string | null;
    exportToken?: ExportToken;
};

export type ExportToken = {
    id: ID;
    token: string;
    name: string;
    validUntil: Date;
};

const documentFragmentToDocumentItem = (documentFragment: DocumentFragment, lastName: string): DocumentItem => {
    const { filename, createdBy, memberVisibleComment: comment, addedDate, url } = documentFragment;
    const validToken = relayConnectionReduce(documentFragment.exportTokens)?.find(
        token => !token.deletedAt && token.validUntil && isFuture(new Date(token.validUntil))
    );

    const exportToken = validToken
        ? {
              id: validToken.id,
              token: validToken.token,
              validUntil: new Date(validToken.validUntil!),
              name: lastName.toUpperCase()
          }
        : undefined;

    return {
        filename,
        createdBy,
        addedDate: new Date(addedDate),
        url,
        comment,
        exportToken
    };
};

export const useDocument = (props: DocumentProps) => {
    const { documentId } = props;
    const { lastName } = useUserProfile();
    const errorHandler = useErrorHandler();

    const [createTokenMutation] = useMutation(CreateMemberDocumentExportTokenDocument);
    const [deleteTokenMutation] = useMutation(DeleteMemberDocumentExportTokeDocument);

    const { data, loading, error } = useLoadingQuery(DocumentDocument, {
        variables: { documentId }
    });

    const createToken = useCallback(async () => {
        try {
            await createTokenMutation({ variables: { input: { documentId } } });
        } catch (err) {
            if (err instanceof Error) {
                errorHandler(
                    new AppError(err, 'error.creating-token-failed', {
                        onClose: () => {},
                        name: 'error-overlay.go_back'
                    })
                );
            } else {
                errorHandler(err);
            }
        }
    }, [createTokenMutation, documentId, errorHandler]);

    const deleteToken = useCallback(
        async (tokenId: ID) => {
            try {
                await deleteTokenMutation({ variables: { input: { tokenId } } });
            } catch (err) {
                if (err instanceof Error) {
                    errorHandler(
                        new AppError(err, 'error.deleting-token-failed', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    errorHandler(err);
                }
            }
        },
        [deleteTokenMutation, errorHandler]
    );

    return useMemo(
        () => ({
            loading,
            error,
            document: data?.root?.document ? documentFragmentToDocumentItem(data?.root?.document, lastName) : null,
            createToken,
            deleteToken
        }),
        [createToken, data?.root?.document, deleteToken, error, lastName, loading]
    );
};
