import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect } from 'react';
import { SafeAreaView, ScrollView, Text } from 'react-native';

import { ScreenContainer } from '~/components/screen';
import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

type DeveloperJsonNavigationProp = StackNavigationProp<AppNavigatorParamList, 'developer-json'>;
export type DeveloperJsonRouteProp = RouteProp<AppNavigatorParamList, 'developer-json'>;

export function DeveloperJson() {
    const { setOptions } = useNavigation<DeveloperJsonNavigationProp>();
    const { params } = useRoute<DeveloperJsonRouteProp>();

    useLayoutEffect(() => {
        setOptions({
            headerTitle: params.title
        });
    }, [params.title, setOptions]);

    return (
        <ScreenContainer>
            <SafeAreaView>
                <ScrollView contentContainerStyle={{ width: 600, padding: 8 }}>
                    <Text style={{ fontSize: 12 }}>{params.json}</Text>
                </ScrollView>
            </SafeAreaView>
        </ScreenContainer>
    );
}
