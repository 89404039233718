import { endOfMonth } from 'date-fns';
import React, { useMemo } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { DayState } from 'react-native-calendars/src/types';
import styled from 'styled-components/native';

import { useIntl } from '~/contexts/intl';
import { toISODateString } from '~/utils/date';
import { isNative, isWeb } from '~/utils/platform';

import { Color } from '../color';
import { ChevronLeftIcon, ChevronRightIcon } from '../icon';
import { FontFamily, Text } from '../text';

import { CalendarProps } from './calendar-types';

const DayContainer = styled.View<{ selected: boolean; selectable?: boolean }>`
    display: flex;
    padding: 8px;
    width: 36px;
    align-items: center;
    justify-content: center;
    border-radius: ${({ selected, selectable }) => (selected || selectable ? '50px' : 0)};
    border-color: ${({ selectable }) => {
        return selectable && isWeb() ? Color.FOCUS_DEFAULT : 'transparent';
    }};
    border-width: ${({ selectable }) => (selectable && isWeb() ? '1px' : 0)};
    background-color: ${({ selected }) => (selected ? Color.FOCUS_DEFAULT : Color.TRANSPARENT)};
    color: ${({ selectable }) => (selectable && isWeb() ? Color.FOCUS_DEFAULT : Color.TEXT_TERTIARY)};
`;

export const CustomCalendar: React.FC<CalendarProps> = props => {
    const { loading, selectedDate, selectableDates, onSelectDate, month, minDate, maxDate, setAppointmentDaysRange } =
        props;
    const { formatMessage, locale } = useIntl();

    // These are values for react-native-calendars months and days, see https://github.com/wix/react-native-calendars
    const calendarLocaleConfig = useMemo(() => {
        const monthNames = [
            formatMessage('calendar.month.january'),
            formatMessage('calendar.month.february'),
            formatMessage('calendar.month.march'),
            formatMessage('calendar.month.april'),
            formatMessage('calendar.month.may'),
            formatMessage('calendar.month.june'),
            formatMessage('calendar.month.july'),
            formatMessage('calendar.month.august'),
            formatMessage('calendar.month.september'),
            formatMessage('calendar.month.october'),
            formatMessage('calendar.month.november'),
            formatMessage('calendar.month.december')
        ];

        const monthNamesShort = [
            formatMessage('calendar.month.january.short'),
            formatMessage('calendar.month.february.short'),
            formatMessage('calendar.month.march.short'),
            formatMessage('calendar.month.april.short'),
            formatMessage('calendar.month.may.short'),
            formatMessage('calendar.month.june.short'),
            formatMessage('calendar.month.july.short'),
            formatMessage('calendar.month.august.short'),
            formatMessage('calendar.month.september.short'),
            formatMessage('calendar.month.october.short'),
            formatMessage('calendar.month.november.short'),
            formatMessage('calendar.month.december.short')
        ];

        const dayNames = [
            formatMessage('calendar.day.sunday'),
            formatMessage('calendar.day.monday'),
            formatMessage('calendar.day.tuesday'),
            formatMessage('calendar.day.wednesday'),
            formatMessage('calendar.day.thursday'),
            formatMessage('calendar.day.friday'),
            formatMessage('calendar.day.saturday')
        ];

        const dayNamesShort = [
            formatMessage('calendar.day.sunday.short'),
            formatMessage('calendar.day.monday.short'),
            formatMessage('calendar.day.tuesday.short'),
            formatMessage('calendar.day.wednesday.short'),
            formatMessage('calendar.day.thursday.short'),
            formatMessage('calendar.day.friday.short'),
            formatMessage('calendar.day.saturday.short')
        ];

        const today = formatMessage('calendar.day.today');

        return { monthNames, monthNamesShort, dayNames, dayNamesShort, today };
    }, [formatMessage]);

    if (LocaleConfig?.locales) {
        LocaleConfig.locales[locale.toLowerCase()] = calendarLocaleConfig;
        LocaleConfig.defaultLocale = locale.toLowerCase();
    }

    const renderDayComponent = (dayProps: { date?: DateData; state?: DayState }) => {
        const { date, state } = dayProps;
        if (!date) {
            return <DayContainer selected={false} />;
        }

        const isDisabled = state === 'disabled' || (selectableDates && !selectableDates?.includes(date.dateString));
        const isSelectable = state !== 'selected' && (selectableDates ?? [])?.includes(date.dateString);
        const isSelected = state === 'selected' || date.dateString === selectedDate;
        const isToday = state === 'today';

        let style = { color: Color.ALMOST_BLACK };

        if (isToday && !isSelected) {
            style = { color: Color.FOCUS_DEFAULT };
        } else if (isDisabled && !isSelected && !isToday) {
            style = { color: Color.TEXT_DISABLED };
        } else if (isSelected) {
            style = { color: Color.TEXT_TERTIARY };
        }

        return (
            <DayContainer selected={isSelected} selectable={isSelectable}>
                <TouchableOpacity disabled={isDisabled} onPress={() => onSelectDate(date.dateString)}>
                    <Text.PARAGRAPH_1 style={style}>{date.day || ''}</Text.PARAGRAPH_1>
                </TouchableOpacity>
            </DayContainer>
        );
    };

    const renderArrow = (direction: 'right' | 'left') => {
        return <span>{direction === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</span>;
    };

    const onMonthChange = (_month: { dateString: string | number | Date }) => {
        const beginning = new Date(_month.dateString);
        const end = endOfMonth(beginning);
        if (setAppointmentDaysRange) {
            setAppointmentDaysRange([toISODateString(beginning), toISODateString(end)]);
        }
    };

    return (
        <Calendar
            scrollEnabled
            disableAllTouchEventsForDisabledDays
            hideArrows={isNative()}
            hideExtraDays
            initialDate={month}
            displayLoadingIndicator={loading}
            minDate={minDate}
            maxDate={maxDate}
            renderArrow={renderArrow}
            theme={{
                monthTextColor: Color.ALMOST_BLACK,
                textMonthFontFamily: Platform.OS === 'ios' ? FontFamily.SYSTEM : FontFamily.ROBOTO_REGULAR,
                textMonthFontWeight: '500',
                textMonthFontSize: 14,
                textSectionTitleColor: Color.ALMOST_BLACK
            }}
            onMonthChange={onMonthChange}
            dayComponent={renderDayComponent}
            pastScrollRange={0}
            futureScrollRange={4}
            current={selectedDate}
            firstDay={1}
        />
    );
};
