import React from 'react';
import styled from 'styled-components/native';

import PhoneAppointmentImage from '~/assets/images/appointment-phone.svg';
import VideoAppointmentImage from '~/assets/images/appointment-video.svg';
import ClinicAppointmentImage from '~/assets/images/appointment-visit.svg';
import { RadioGroup } from '~/components/check';
import { RadioImageButton } from '~/components/check/radio-image-button';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { AppointmentContactType } from '~/types';

export type AppointmentContactTypeSelectProps = {
    contactTypes: readonly AppointmentContactType[];
    selectedContactType: AppointmentContactType;
    onSelectedContactTypeChange: (contactType: AppointmentContactType) => void;
};

export const AppointmentContactTypeSelectContainer = styled.View`
    flex-direction: column;
    align-self: stretch;
    padding: 16px;
`;

export function AppointmentContactTypeSelect(props: AppointmentContactTypeSelectProps) {
    const { contactTypes, selectedContactType, onSelectedContactTypeChange } = props;
    const { formatMessage } = useIntl();
    const types: AppointmentContactType[] = ['VISIT', 'VIDEO', 'PHONE'];
    const images = [ClinicAppointmentImage, VideoAppointmentImage, PhoneAppointmentImage];

    return (
        <AppointmentContactTypeSelectContainer>
            <Text.SUBTITLE_1 after={16}>
                <FormattedMessage id="appointment.select-type" />
            </Text.SUBTITLE_1>
            <RadioGroup
                horizontal
                selectedIndex={types.findIndex(contactType => contactType === selectedContactType)}
                onChange={index => onSelectedContactTypeChange(types[index])}
            >
                {types.map((type, index) => {
                    const ImageComponent = images[index];
                    return (
                        <RadioImageButton
                            key={type}
                            imageLabel={formatMessage('appointment.type', { type })}
                            image={<ImageComponent height={60} width={60} />}
                            disabled={!contactTypes.includes(type)}
                        />
                    );
                })}
            </RadioGroup>
        </AppointmentContactTypeSelectContainer>
    );
}
