import { useMutation } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import { useState, useMemo, useCallback } from 'react';

import { useLoadingQuery } from '~/hooks/loading-query';
import {
    QuestionnaireQuestionFragment,
    QuestionnaireQuestionResponseFragment,
    QuestionnaireQuestionType,
    QuestionnaireResponseMutationDocument,
    QuestionnaireResponseQueryDocument
} from '~/types';
import { Extends, Mutable, relayConnectionReduce } from '~/utils';
import { objectMap } from '~/utils/object';

const radioQuestions = ['RADIO', 'RADIO_ICONS', 'BOOLEAN'] as const;
type RadioQuestion = (typeof radioQuestions)[number];
const multipleValueOptionQuestions = ['SELECT', 'SELECT_ICONS'] as const;
type MultipleValueOptionsQuestion = (typeof multipleValueOptionQuestions)[number];
const sliderQuestions = ['SLIDER'] as const;
type SliderQuestion = (typeof sliderQuestions)[number];

export const isRadioQuestion = (questionType: QuestionnaireQuestionType): questionType is RadioQuestion => {
    return radioQuestions.includes(questionType as RadioQuestion);
};

export const isMultipleValueOptionsQuestion = (
    questionType: QuestionnaireQuestionType
): questionType is MultipleValueOptionsQuestion => {
    return multipleValueOptionQuestions.includes(questionType as MultipleValueOptionsQuestion);
};

export const isSliderQuestion = (questionType: QuestionnaireQuestionType): questionType is SliderQuestion => {
    return sliderQuestions.includes(questionType as SliderQuestion);
};

export type QuestionnaireNumberValueQuestion = {
    questionType: 'RADIO' | 'SLIDER';
    question: QuestionnaireQuestionFragment;
    responseValue: number | null;
};

export type QuestionnaireMultipleOptionsQuestion = {
    questionType: 'SELECT';
    question: QuestionnaireQuestionFragment;
    responseValue: number[];
};

export type QuestionnaireStringValueQuestion = {
    questionType: Extends<QuestionnaireQuestionType, 'TEXT_LONG' | 'TEXT_SHORT' | 'INT'>;
    question: QuestionnaireQuestionFragment;
    responseValue: string | null;
};

export type QuestionnaireQuestionWithResponse =
    | QuestionnaireNumberValueQuestion
    | QuestionnaireMultipleOptionsQuestion
    | QuestionnaireStringValueQuestion;

export type QuestionResponse = string | number[] | null;

type ResponsesWithQuestionId = {
    [key: ID]: QuestionResponse;
};

export const useQuestionnaireResponse = (questionnaireResponseId: ID) => {
    const { loading, error, data, loadingInitial } = useLoadingQuery(QuestionnaireResponseQueryDocument, {
        variables: { id: questionnaireResponseId }
    });
    const [updateQuestionnaireResponse] = useMutation(QuestionnaireResponseMutationDocument);
    const [responses, setResponses] = useState<ResponsesWithQuestionId>({});
    const responseSummary = data?.root?.questionnaireResponse.responseSummaryMd;
    const { title, description, outro, questions } = data?.root?.questionnaireResponse.questionnaire ?? {};

    const savedResponses = useMemo(() => {
        return (
            relayConnectionReduce<QuestionnaireQuestionResponseFragment>(data?.root?.questionnaireResponse.responses) ??
            []
        );
    }, [data?.root?.questionnaireResponse.responses]);

    const reducedQuestions = useMemo(() => {
        return relayConnectionReduce<QuestionnaireQuestionFragment>(questions) ?? [];
    }, [questions]);

    const pages = useMemo(() => groupBy(reducedQuestions, 'orderNumber'), [reducedQuestions]);
    const questionPageCount = Object.keys(pages).length;

    const mappedPages: Record<string, QuestionnaireQuestionWithResponse[]> = useMemo(() => {
        return objectMap(pages, page => {
            return page.map(question => {
                const questionResponse = savedResponses.find(
                    response => response.questionnaireQuestion.id === question.id
                );
                if (isRadioQuestion(question.type) || isSliderQuestion(question.type)) {
                    return {
                        question,
                        questionType: question.type === 'SLIDER' ? 'SLIDER' : 'RADIO',
                        responseValue: questionResponse?.listValue?.[0] ?? null
                    };
                } else if (isMultipleValueOptionsQuestion(question.type)) {
                    return {
                        question,
                        questionType: 'SELECT',
                        responseValue: (questionResponse?.listValue ?? []) as Mutable<number[]>
                    };
                } else {
                    return {
                        question,
                        questionType: question.type,
                        responseValue: questionResponse?.strValue ?? null
                    };
                }
            });
        });
    }, [pages, savedResponses]);

    const initialPage = useMemo(() => {
        if (loadingInitial) {
            return undefined;
        }

        const respondedQuestionIds = savedResponses.map(response => response.questionnaireQuestion.id);
        if (respondedQuestionIds.length === 0) {
            return 0;
        }
        const firstUnansweredQuestion = reducedQuestions.find(question => !respondedQuestionIds.includes(question.id));
        return firstUnansweredQuestion ? firstUnansweredQuestion.orderNumber : questionPageCount + 1;
    }, [loadingInitial, questionPageCount, reducedQuestions, savedResponses]);

    const responsesToMutationResponses = useCallback(
        (pageNumber: number) => {
            return mappedPages[pageNumber].map(page => {
                const questionId = page.question.id;
                const responseValue = responses[questionId] ?? null;

                if (typeof responseValue === 'string') {
                    return { questionId, strValue: responseValue };
                }

                return { questionId, listValue: responseValue };
            });
        },
        [mappedPages, responses]
    );

    const saveResponses = useCallback(
        async (page: number) => {
            const questionResponses = responsesToMutationResponses(page);
            await updateQuestionnaireResponse({
                variables: {
                    data: {
                        id: questionnaireResponseId,
                        questionResponses
                    }
                }
            }).then(() => {
                setResponses({});
            });
        },
        [questionnaireResponseId, responsesToMutationResponses, updateQuestionnaireResponse]
    );

    const onValueChanged = useCallback(
        (questionId: ID, value: QuestionResponse) => {
            setResponses({
                ...responses,
                [questionId]: value
            });
        },
        [responses]
    );

    return useMemo(
        () => ({
            loading,
            error,
            intro: description,
            summary: outro,
            responseSummary,
            title,
            pages: mappedPages,
            questionPageCount,
            initialPage,
            saveResponses,
            onValueChanged
        }),
        [
            description,
            error,
            initialPage,
            loading,
            responseSummary,
            mappedPages,
            onValueChanged,
            outro,
            questionPageCount,
            saveResponses,
            title
        ]
    );
};
