import React, { ReactChild } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { InfoIcon } from '~/components/icon';

import { Button } from './button';

export type CTAButtonProps = {
    label: string;
    helper?: string;
    showIcon?: boolean;
    icon?: ReactChild;
    loading?: boolean;
    disabled?: boolean;
    onPress?: () => void | Promise<void>;
};

type ButtonState = 'disabled' | 'enabled';

const ButtonContainer = styled.View<{ state: ButtonState }>`
    align-self: stretch;
    flex-direction: column;
    padding: 8px 8px 8px;
    border-radius: 24px;
    background-color: ${({ state }) => (state !== 'disabled' ? Color.BACKGROUND_TERTIARY : Color.BASIC_PRESSED)};
    justify-content: center;
`;

const HelperTextContainer = styled.View`
    flex-direction: row;
    margin-top: 16px;
    padding: 0px 8px 8px 8px;
    align-items: flex-start;
    justify-content: center;
`;

const HelperText = styled.Text`
    margin-left: 8px;
    margin-right: 8px;
`;

/**
 * @deprecated Use CallToAction component
 */
export const CTAButton = (props: CTAButtonProps) => {
    const { label, helper, showIcon = false, disabled = false, onPress } = props;
    const state = disabled ? 'disabled' : 'enabled';
    return (
        <ButtonContainer state={state}>
            <Button fillContainer size="large" shape="square" disabled={disabled} label={label} onPress={onPress} />
            {helper && (
                <HelperTextContainer>
                    {showIcon && <InfoIcon />}
                    <HelperText>{helper}</HelperText>
                </HelperTextContainer>
            )}
        </ButtonContainer>
    );
};

export type CTAButtonGroupProps = {
    buttons: CTAButtonProps[];
};

export const CTAButtonGroup = (props: CTAButtonGroupProps) => {
    const { buttons } = props;

    return (
        <ButtonContainer state="enabled">
            {buttons.map(({ label, helper, icon, onPress }, index) => {
                return (
                    <View style={{ marginTop: index === 0 ? 0 : 7 }} key={label}>
                        <Button
                            key={`button-${label}`}
                            fillContainer
                            size="huge"
                            shape="square"
                            disabled={false}
                            label={label}
                            helper={helper}
                            rightIcon={icon}
                            onPress={onPress}
                        />
                    </View>
                );
            })}
        </ButtonContainer>
    );
};

CTAButton.displayName = 'CTAButton';
