import { ApolloClient, ApolloLink, from, fromPromise, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 as uuid } from 'uuid';

import { AppEndpointConfig } from '~/contexts/app-config';
import { getAuthToken } from '~/contexts/auth';
import * as ErrorDiagnostics from '~/error/app-error-diagnostics';

import { cacheConfig } from './apollo-cache';
import { customFetch } from './app-fetch';

let refreshing = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let pendingRequests: [any, any][] = [];

const resolvePendingRequests = (error?: Error) => {
    pendingRequests.forEach(([resolve, reject]) => (error ? reject(error) : resolve()));
    pendingRequests = [];
};

export const createAppClient = (
    { http, ws, host }: AppEndpointConfig,
    refreshAuthenticationToken: () => Promise<boolean>
) => {
    const uri = `${http}://${host}/member-api/graphql/`;

    const httpLink = createUploadLink({
        uri,
        credentials: 'same-origin',
        headers: {
            'Accept-Encoding': 'gzip'
        },
        fetch: customFetch
    });

    // Note! Websocket client always sends the current auth token on WS `connection_init`. The backend will verify
    // auth token and if not successful, immediately close the connection with close code 4004. The client will
    // receive the code and reconnect with new token.

    // Typically, any normal GraphQL query or mutation operation (over HTTPS) will renew the auth token using the
    // renewal mechanism implemented in `errorLink` below. In typical situations queries and mutations will happen
    // alongside subscription messages, so trying to re-connect with expired token is not a common scenario. However,
    // this can happen if the client closes the socket due to inactivity and never executes any other operations
    // before trying to re-open the connection. This would lead to a failure.

    // To experiment with this edge case, 1) reduce subscription client `inactivityTimeout` to e.g. 3 seconds,
    // 2) open a view with a subscription, e.g. chat, 3) close the view and wait > `inactivityTimeout` -> the connection
    // will close, 4) observe connection close with code 1000, 5) wait > JWT expiration + leeway 5) navigate back to
    // the view, 5) subscription tries to open the web socket with expired JWT token which will cause the socket to
    // close (with 4004 code)

    // In order to catch this 4004 on the client side, we must use a custom fork of (unmaintained)
    // `subscriptions-transport-ws` that will correctly report the close code to the subscription client `onDisconnect`
    // event handler. See https://github.com/Traktormaster/subscriptions-transport-ws/commit/63a689e9487c228d01ae60d1cc78204072a2fba0

    const client = new SubscriptionClient(`${ws}://${host}/member-api/ws/graphql`, {
        reconnect: true,
        lazy: true,
        connectionParams: () => ({
            authToken: getAuthToken()
        }),
        inactivityTimeout: 30 * 1000
    });

    client.onDisconnected(code => {
        if (code === 4004) {
            if (!refreshing) {
                refreshing = true;
                ErrorDiagnostics.log('Token expired in subscription client, refreshing.');
                refreshAuthenticationToken()
                    .catch(err => ErrorDiagnostics.error(err))
                    .finally(() => {
                        refreshing = false;
                    });
            }
        }
        ErrorDiagnostics.log(`Subscription disconnected (${code})`, { notify: true });
    });

    ['error', 'connecting', 'reconnecting', 'connected', 'reconnected'].forEach(event =>
        client.on(event, async () => ErrorDiagnostics.log(`Subscription ${event}`, { notify: true }))
    );

    const wsLink: ApolloLink = new WebSocketLink(client);

    const authLink = setContext(() => {
        const requestId = uuid();
        ErrorDiagnostics.setRequestId(requestId);

        return {
            headers: {
                'X-Request-ID': requestId,
                Authorization: `JWT ${getAuthToken()}`
            }
        };
    });

    const errorLink = onError(error => {
        // Inspired by https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
        const { networkError, graphQLErrors, operation, forward } = error;
        const tokenExpiredError = graphQLErrors?.find(({ message }) =>
            ['You do not have permission to perform this action', 'Signature has expired'].includes(message)
        );
        if (networkError?.message?.includes('403') || tokenExpiredError) {
            let forward$; // Observable
            if (!refreshing) {
                refreshing = true;
                ErrorDiagnostics.log('Token expired in apollo error link, refreshing.');
                forward$ = fromPromise<boolean>(
                    refreshAuthenticationToken()
                        .then(() => {
                            resolvePendingRequests();
                            return true;
                        })
                        .catch(err => {
                            ErrorDiagnostics.error(err);
                            return false;
                        })
                        .finally(() => {
                            refreshing = false;
                        })
                );
            } else {
                forward$ = fromPromise<void>(new Promise((resolve, reject) => pendingRequests.push([resolve, reject])));
            }
            return forward$.flatMap(() => forward(operation));
        }

        if (graphQLErrors?.length) {
            graphQLErrors.map(graphQLError => ErrorDiagnostics.log(`GraphQL error: ${graphQLError.message}`));
        }
    });

    const sentryLink = new SentryLink({
        uri,
        setTransaction: true,
        setFingerprint: true
    });

    const apolloClient = new ApolloClient({
        connectToDevTools: true,
        link: ApolloLink.from([
            ApolloLink.split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                from([errorLink, authLink, sentryLink, httpLink])
            )
        ]),
        cache: new InMemoryCache(cacheConfig)
    });

    if (__DEV__) {
        apolloClient.__actionHookForDevTools(
            // @ts-ignore
            (arg: { dataWithOptimisticResults: object; state: { queries: object } }) => {
                if (arg.state?.queries) {
                    // eslint-disable-next-line no-console
                    // console.log(arg.state.queries);
                }
            }
        );
    }

    return apolloClient;
};
