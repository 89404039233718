import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Circle, Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const InfoIcon: FunctionComponent<IconProps> = ({ fill, size = 20 }) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 26 26" focusable={false} tabIndex="-1">
                <Circle cx="13" cy="12" r="10" fill={fill ?? Color.ALMOST_BLACK} />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9643 5.70821C13.179 5.83023 12.5103 6.72997 12.4706 7.71783C12.431 8.70569 13.0356 9.4076 13.8209 9.28558C14.6062 9.16356 15.275 8.26382 15.3146 7.27596C15.3542 6.2881 14.7497 5.58619 13.9643 5.70821Z"
                    fill={Color.ICON_NEGATIVE}
                    // @ts-ignore
                    tabIndex="-1"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.062 11.6169C11.2029 11.5384 11.3759 11.5669 11.4912 11.6811C11.6129 11.7952 11.6513 11.9807 11.6001 12.152C11.3438 12.9939 10.9595 14.2355 10.6264 15.3129C10.4535 15.8694 10.5111 16.4902 10.7866 16.9968C11.062 17.5034 11.5232 17.853 12.0484 17.9457C12.0805 17.9529 12.1125 17.96 12.1445 17.96C13.227 18.1527 14.3095 17.6389 14.9372 16.64C15.0269 16.4973 15.0205 16.3047 14.918 16.1691C14.8155 16.0335 14.6426 15.9978 14.5017 16.0763C14.3095 16.1834 14.1173 16.2833 13.938 16.3831C13.7971 16.4616 13.6241 16.4331 13.5088 16.3189C13.3871 16.2048 13.3487 16.0193 13.4 15.848C13.6562 15.0061 14.0405 13.7645 14.3736 12.6871C14.5465 12.1306 14.4889 11.5098 14.2134 11.0032C13.938 10.4966 13.4768 10.147 12.9516 10.0543C12.9196 10.0471 12.8875 10.04 12.8555 10.04C11.773 9.84734 10.6905 10.3611 10.0628 11.36C9.9731 11.5027 9.9795 11.6953 10.082 11.8309C10.1845 11.9665 10.3574 12.0022 10.4983 11.9237C10.6905 11.8238 10.8827 11.7167 11.062 11.6169Z"
                    fill={Color.ICON_NEGATIVE}
                    // @ts-ignore
                    tabIndex="-1"
                />
            </Svg>
        </View>
    );
};
