import React from 'react';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';

export type AppointmentVideoMeetingDetailsProps = {
    videoAppointmentUrl?: string;
    onJoinVideoAppointment: () => void;
};

const VideoMeetingDetailsContainer = styled.View`
    height: 112px;
    flex-direction: row;
    align-items: center;
    padding-horizontal: 24px;
`;

const VideoMeetingDetailsDescription = styled(Text.PARAGRAPH_1)`
    text-align: center;
`;

export function AppointmentVideoMeetingDetails(props: AppointmentVideoMeetingDetailsProps) {
    const { videoAppointmentUrl, onJoinVideoAppointment } = props;
    const { formatMessage } = useIntl();

    return (
        <VideoMeetingDetailsContainer>
            {!videoAppointmentUrl ? (
                <VideoMeetingDetailsDescription>
                    <FormattedMessage id="appointment.video-call-details" />
                </VideoMeetingDetailsDescription>
            ) : (
                <Button
                    fillContainer
                    label={formatMessage('appointment.join-video-call')}
                    type="primary"
                    shape="rounded"
                    size="large"
                    onPress={onJoinVideoAppointment}
                />
            )}
        </VideoMeetingDetailsContainer>
    );
}
