import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import addDays from 'date-fns/addDays';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import useAsyncEffect from 'use-async-effect';

import { CustomCalendar } from '~/components/calendar';
import { AppointmentDay } from '~/types';
import { fromISODateString, toISODateString } from '~/utils/date';
import { isNative } from '~/utils/platform';

export type AppointmentsCalendarProps = {
    appointmentDaysRange: [ISODate, ISODate];
    date: ISODate;
    setSelectedDate: (date: ISODate) => void;
    appointmentDays?: AppointmentDay[];
    fetchMoreAppointmentDays: (from: string, to: string) => Promise<void>;
    setAppointmentDaysRange?: (range: [ISODate, ISODate]) => void;
};

export const AppointmentsCalendar: React.FC<AppointmentsCalendarProps> = props => {
    const {
        appointmentDaysRange,
        setAppointmentDaysRange,
        date,
        setSelectedDate,
        appointmentDays,
        fetchMoreAppointmentDays
    } = props;

    const [months, setMonths] = useState<[ISODate, ISODate][]>(
        Array.from(Array(1).keys()).map(i => {
            const first = addMonths(startOfMonth(fromISODateString(appointmentDaysRange[0])), i);
            const last = endOfMonth(first);
            return [toISODateString(first), toISODateString(last)];
        })
    );

    const fetchNextMonthAppointmentDays = async () => {
        const prevFirst = months[months.length - 1][0];
        const first = addMonths(fromISODateString(prevFirst), 1);
        const last = endOfMonth(first);
        const range: [ISODate, ISODate] = [toISODateString(first), toISODateString(last)];
        setMonths([...months, range]);
        await fetchMoreAppointmentDays(...range);
    };

    // On init, fetch more appointment days for the current month and the next one to populate the screen
    useAsyncEffect(
        async mounted => {
            if (mounted()) {
                const prevEnd = fromISODateString(appointmentDaysRange[1]);
                const start = addDays(prevEnd, 1);
                const end = addMonths(endOfMonth(start), 1);
                await fetchMoreAppointmentDays(toISODateString(start), toISODateString(end));
            }
        },
        [fetchMoreAppointmentDays, appointmentDaysRange[1]]
    );

    const selectableDates = useMemo(
        () => appointmentDays?.map(appointmentDay => toISODateString(appointmentDay.date)) ?? [],
        [appointmentDays]
    );

    const renderItem = useCallback(
        (info: ListRenderItemInfo<[ISODate, ISODate]>) => {
            return (
                <CustomCalendar
                    month={info.item[0]}
                    selectableDates={selectableDates}
                    onSelectDate={setSelectedDate}
                    selectedDate={date}
                    minDate={toISODateString(new Date())}
                    setAppointmentDaysRange={setAppointmentDaysRange}
                />
            );
        },
        [selectableDates, setSelectedDate, date, setAppointmentDaysRange]
    );

    const monthData = isNative() ? months : [months[0]];
    const onEndReached = isNative() ? fetchNextMonthAppointmentDays : undefined;
    const onEndReachedThreshold = isNative() ? 0.4 : undefined;

    return (
        <FlatList<[ISODate, ISODate]>
            showsVerticalScrollIndicator={false}
            initialNumToRender={2}
            data={monthData}
            keyExtractor={range => range[0]}
            renderItem={renderItem}
            // getItemLayout={(data, index) => ({ length: 325, offset: 325 * index, index })}
            onEndReached={onEndReached}
            onEndReachedThreshold={onEndReachedThreshold}
        />
    );
};
