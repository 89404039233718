import React from 'react';
import { Circle, Mask } from 'react-native-svg';

import { CircleMaskProps } from './circle-mask';

export const CircleMask = (props: CircleMaskProps) => {
    const { maskId } = props;

    return (
        <Mask id={maskId} maskContentUnits="objectBoundingBox">
            <Circle cx={0.5} cy={0.5} r={0.5} fill="#ffffff" />
        </Mask>
    );
};
