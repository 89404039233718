import React, { useState } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { FilePreviewModal } from '~/components/file-preview';
import { ChatAttachmentIcon } from '~/components/icon/chat-attachment';
import { Image } from '~/components/image';
import { OvalLoader } from '~/components/loader';
import { ScaledImage } from '~/components/scaled-image';
import { useAuth } from '~/contexts/auth';
import { useDownload } from '~/hooks/download';
import { isNative, isPdfFormat, isSupportedImageFormat, parseFileExtension } from '~/utils';

export type ChatAttachmentProps = {
    filename: string;
    description?: string;
    url: string;
};

export function ChatAttachment(props: ChatAttachmentProps) {
    const { filename, description, url } = props;
    const [loading, setLoading] = useState(false);
    const [previewed, setPreviewed] = useState(false);
    const { bytesDownloaded, bytesTotal, download } = useDownload();

    const onDownload = async () => {
        setLoading(true);
        try {
            await download(url, filename);
        } finally {
            setLoading(false);
        }
    };

    return (
        <AttachmentContainer>
            {isSupportedImageFormat(filename) || (isNative() && isPdfFormat(filename)) ? (
                <>
                    {previewed && (
                        <FilePreviewModal
                            url={url}
                            title={filename}
                            filename={filename}
                            description={description}
                            onClose={() => setPreviewed(false)}
                        />
                    )}
                    {isSupportedImageFormat(filename) && (
                        <Pressable onPress={() => setPreviewed(true)}>
                            <ChatAttachmentImage url={url} form="rounded" />
                        </Pressable>
                    )}
                    {isPdfFormat(filename) && (
                        <ChatAttachmentButton
                            label={filename}
                            extension={parseFileExtension(filename)}
                            onPress={() => setPreviewed(true)}
                        />
                    )}
                </>
            ) : (
                <ChatAttachmentButton
                    label={filename}
                    extension={parseFileExtension(filename)}
                    onPress={onDownload}
                    loading={loading}
                    progress={bytesDownloaded}
                    total={bytesTotal}
                />
            )}
        </AttachmentContainer>
    );
}

type ChatAttachmentButtonProps = {
    label: string;
    extension: FileMimeType;
    onPress: () => void;
    loading?: boolean;
    progress?: number;
    total?: number;
};

function ChatAttachmentButton(props: ChatAttachmentButtonProps) {
    const { label, extension, onPress, loading, progress = 0, total = 0 } = props;
    return (
        <Button
            onPress={onPress}
            leftIcon={
                loading ? <OvalLoader progress={progress} total={total} /> : <ChatAttachmentIcon text={extension} />
            }
            label={label}
            fillContainer
        />
    );
}

const ImageAttachmentBubble = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    flex: 0 1 auto;
    border-radius: 15px;
`;

const AttachmentContainer = styled.View`
    flex: 1 1 auto;
    margin-bottom: 2px;
    padding: 2px;
`;

type ChatAttachmentImageForm = 'original' | 'rounded' | 'square';

type ChatAttachmentImageProps = {
    url: string;
    form: ChatAttachmentImageForm;
};

export const ChatAttachmentImage = (props: ChatAttachmentImageProps) => {
    const { form, url } = props;
    const { remote } = useAuth();

    return (
        <ImageAttachmentBubble>
            {form === 'square' ? (
                <Image
                    source={{ uri: url }}
                    resizeMode="cover"
                    style={{ height: 50, aspectRatio: 1, borderRadius: 8 }}
                />
            ) : (
                <ScaledImage
                    uri={url}
                    headers={{ Authorization: `JWT ${remote.getAuthToken()}` }}
                    additionalStyles={{ borderRadius: form === 'rounded' ? 15 : 0 }}
                />
            )}
        </ImageAttachmentBubble>
    );
};
