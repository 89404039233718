import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { CryptoDigestAlgorithm, digestStringAsync } from 'expo-crypto';
import { AlertDialog, Input } from 'native-base';
import React, { useCallback, useRef, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import { HandlerStateChangeEvent, LongPressGestureHandler, State } from 'react-native-gesture-handler';

import { DEVELOPER_PASSWORD_DIGEST } from '~/app/constants';
import HorizontalLogoSvg from '~/assets/images/horizontal-logo.svg';
import { Button } from '~/components/button';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

type HorizontalLogoNavigation = StackNavigationProp<AppNavigatorParamList>;

type Props = {
    devMenuAccess?: boolean;
};

export const HorizontalLogo: React.FC<Props> = props => {
    const { devMenuAccess = false } = props;

    const { navigate } = useNavigation<HorizontalLogoNavigation>();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');

    const closeRef = useRef(null);

    const onGestureEvent = useCallback(
        (event: HandlerStateChangeEvent) => {
            if (event.nativeEvent.state === State.ACTIVE) {
                if (__DEV__) {
                    navigate('developer');
                } else {
                    setShowDialog(true);
                }
            }
        },
        [navigate]
    );

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    const onSubmit = useCallback(async () => {
        const digest = await digestStringAsync(CryptoDigestAlgorithm.SHA256, password);

        if (digest === DEVELOPER_PASSWORD_DIGEST) {
            navigate('developer');
        }

        setShowDialog(false);
    }, [navigate, password]);

    if (!devMenuAccess) {
        return <HorizontalLogoSvg />;
    }

    return (
        <>
            {showDialog ? (
                <AlertDialog leastDestructiveRef={closeRef} isOpen={showDialog} onClose={closeDialog}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header>Enter password</AlertDialog.Header>
                        <AlertDialog.Body>
                            <Input
                                autoFocus
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder="Password"
                                secureTextEntry
                                onChangeText={setPassword}
                                marginBottom={4}
                            />

                            <Button type="primary" label="Open" disabled={!password} onPress={onSubmit} />
                        </AlertDialog.Body>
                    </AlertDialog.Content>
                </AlertDialog>
            ) : null}

            <LongPressGestureHandler testID="horizontal-logo-gesture-handler" onHandlerStateChange={onGestureEvent}>
                <Pressable>
                    <HorizontalLogoSvg />
                </Pressable>
            </LongPressGestureHandler>
        </>
    );
};
