import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/native';

import { Text } from '~/components/text';

type Props = PropsWithChildren<{
    caption?: string;
}>;

const Container = styled.View`
    flex-direction: column;
    width: 100%;
    padding: 24px 0px 0px;
`;

export function FormGroup(props: Props) {
    const { caption, children } = props;
    return (
        <Container>
            {caption ? (
                <Text.SUBTITLE_1 numberOfLines={1} style={{ marginBottom: 16 }}>
                    {caption}
                </Text.SUBTITLE_1>
            ) : null}
            {children}
        </Container>
    );
}

FormGroup.displayName = 'FormGroup';
