// Note! These must match the PostScript name of the font

import { FontSource } from 'expo-font/src/Font.types';
import { Platform } from 'react-native';

export enum FontFamily {
    SYSTEM = 'System', // San Francisco on iOS, Robot on Android

    NOI_GROTESK_REGULAR = 'NoiGrotesk-Regular',
    NOI_GROTESK_MEDIUM = 'NoiGrotesk-Medium',

    SF_COMPACT_REGULAR = 'SFCompactText-Regular',
    SF_COMPACT_MEDIUM = 'SFCompactText-Medium',
    SF_COMPACT_BOLD = 'SFCompactText-Bold',

    ROBOTO_REGULAR = 'Roboto-Regular',
    ROBOTO_MEDIUM = 'Roboto-Medium',
    ROBOTO_BOLD = 'Roboto-Bold'
}

export const fonts = Platform.select<{ [fontFamily: string]: FontSource }>({
    ios: {
        [FontFamily.NOI_GROTESK_REGULAR]: require('~/assets/fonts/NoiGrotesk-Regular.otf'),
        [FontFamily.NOI_GROTESK_MEDIUM]: require('~/assets/fonts/NoiGrotesk-Medium.otf'),

        [FontFamily.SF_COMPACT_REGULAR]: require('~/assets/fonts/SF-Compact-Text-Regular.otf'),
        [FontFamily.SF_COMPACT_MEDIUM]: require('~/assets/fonts/SF-Compact-Text-Medium.otf'),
        [FontFamily.SF_COMPACT_BOLD]: require('~/assets/fonts/SF-Compact-Text-Bold.otf')
    },
    android: {
        [FontFamily.NOI_GROTESK_REGULAR]: require('~/assets/fonts/NoiGrotesk-Regular.otf'),
        [FontFamily.NOI_GROTESK_MEDIUM]: require('~/assets/fonts/NoiGrotesk-Medium.otf'),

        [FontFamily.ROBOTO_REGULAR]: require('~/assets/fonts/Roboto-Regular.ttf'),
        [FontFamily.ROBOTO_MEDIUM]: require('~/assets/fonts/Roboto-Medium.ttf'),
        [FontFamily.ROBOTO_BOLD]: require('~/assets/fonts/Roboto-Bold.ttf')
    },
    web: {
        [FontFamily.NOI_GROTESK_REGULAR]: require('~/assets/fonts/NoiGrotesk-Regular.otf'),
        [FontFamily.NOI_GROTESK_MEDIUM]: require('~/assets/fonts/NoiGrotesk-Medium.otf'),

        [FontFamily.ROBOTO_REGULAR]: require('~/assets/fonts/Roboto-Regular.ttf'),
        [FontFamily.ROBOTO_MEDIUM]: require('~/assets/fonts/Roboto-Medium.ttf'),
        [FontFamily.ROBOTO_BOLD]: require('~/assets/fonts/Roboto-Bold.ttf')
    }
})!;
