// list from https://reactnative.dev/docs/image#source

export const isSupportedImageFormat = (filename: string) => {
    return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp'].includes(parseFileExtension(filename.toLowerCase()));
};

export const isPdfFormat = (filename: string) => {
    return parseFileExtension(filename.toLowerCase()) === 'pdf';
};

export const parseFileExtension = (filename: string) => {
    return filename.slice(filename.lastIndexOf('.') + 1);
};
