import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useState } from 'react';
import { Alert } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import useAsyncEffect from 'use-async-effect';

import { LinkButton } from '~/components/button';
import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { PinCodeDisplay, PinCodeKeyboard } from '~/components/pin-code';
import { SCREEN_WIDTH_RATIO } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useAnalytics } from '~/contexts/analytics';
import { AuthActionResult, BiometricAuthActionResult, PinCode, useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import * as ErrorDiagnostics from '~/error';
import { usePinCodeAuthenticate } from '~/hooks/pin-code';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

type AuthPinAuthenticateNavigation = StackScreenProps<AppNavigatorParamList, 'pin-authenticate'>['navigation'];

const PIN_CODE_MAX_RETRY_COUNT = 2;

const ScreenContainer = styled.View<{ bottomInset: number }>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
`;

const HeaderContainer = styled.View<{ topInset: number }>`
    align-items: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
    padding-top: ${({ topInset }) => topInset + Spacing.SMALL}px;
    padding-bottom: ${Spacing.SMALL}px;
`;

const TopContainer = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
`;

const BottomContainer = styled.View`
    align-items: center;
    padding: 0 ${Spacing.MEDIUM}px ${Spacing.MEDIUM}px;
`;

const ForgottenLinkContainer = styled.View`
    padding: ${Spacing.MEDIUM}px 0 ${Spacing.HUGE}px;
`;

export const AuthPinAuthenticate = () => {
    const { top, bottom } = useSafeAreaInsets();
    const { reset } = useNavigation<AuthPinAuthenticateNavigation>();
    const { pin, biometric, remote, identity } = useAuth();
    const { formatMessage } = useIntl();
    const [canAuthenticate, setCanAuthenticate] = useState<boolean>();
    const { track } = useAnalytics();

    const [retryCount, setRetryCount] = useState<number>(0);

    const navigateToAuthentication = useCallback(async () => {
        try {
            const email = await identity.get();
            if (email) {
                if (await remote.eidentVerificationRequired(email)) {
                    reset({ index: 1, routes: [{ name: 'intro' }, { name: 'authenticate' }] });
                } else if (await remote.verifyEmail(email)) {
                    reset({
                        index: 2,
                        routes: [{ name: 'intro' }, { name: 'verification-check' }, { name: 'verification-email-sent' }]
                    });
                } else {
                    reset({ index: 1, routes: [{ name: 'intro' }, { name: 'verification-check' }] });
                }
            } else {
                reset({ index: 0, routes: [{ name: 'intro' }] });
            }
        } catch {
            reset({ index: 0, routes: [{ name: 'intro' }] });
        }
    }, [identity, remote, reset]);

    useAsyncEffect(async () => {
        try {
            setCanAuthenticate(await biometric.canAuthenticate());
        } catch (error) {
            ErrorDiagnostics.error(error);
            setCanAuthenticate(false);
        }
    }, [biometric]);

    const handlePinCodeAuthenticateFailed = useCallback(
        async (reason?: string) => {
            return new Promise<void>(resolve => {
                if (reason === 'pin-auth-recoverable-error') {
                    // Try again
                    Alert.alert(
                        formatMessage('auth-pin-authenticate.recoverable-error.title'),
                        formatMessage('auth-pin-authenticate.recoverable-error.description'),
                        [
                            {
                                text: formatMessage('ok'),
                                onPress: () => {
                                    resolve();
                                }
                            }
                        ]
                    );
                } else if (reason === 'pin-auth-invalid-pin-code') {
                    if (retryCount < PIN_CODE_MAX_RETRY_COUNT) {
                        setRetryCount(count => count + 1);
                    } else {
                        Alert.alert(
                            formatMessage('auth-pin-authenticate.too-many-retries.title'),
                            formatMessage('auth-pin-authenticate.too-many-retries.description'),
                            [
                                {
                                    text: formatMessage('cancel'),
                                    onPress: () => {
                                        setRetryCount(0);
                                        resolve();
                                    }
                                },
                                {
                                    text: formatMessage('auth-pin-authenticate.authenticate'),
                                    onPress: async () => {
                                        await navigateToAuthentication();
                                        resolve();
                                    }
                                }
                            ]
                        );
                    }
                } else {
                    ErrorDiagnostics.info(`PIN code authentication failed: ${reason}`);
                    Alert.alert(
                        formatMessage('auth-pin-authenticate.other-error.title'),
                        formatMessage('auth-pin-authenticate.other-error.description'),
                        [
                            {
                                text: formatMessage('auth-pin-authenticate.authenticate'),
                                onPress: async () => {
                                    await navigateToAuthentication();
                                    resolve();
                                }
                            }
                        ]
                    );
                }
            });
        },
        [retryCount, formatMessage, navigateToAuthentication]
    );

    const handleBiometricAuthenticate = useCallback(async () => {
        try {
            ErrorDiagnostics.log('Signing in with biometric id');

            track({ type: 'auth-biometric', action: 'init' });

            return biometric.authenticate({
                promptMessage: formatMessage('auth-pin-authenticate.biometric.prompt', {
                    type: biometric.types?.[0]
                }),
                cancelLabel: formatMessage('auth-pin-authenticate.biometric.cancel'),
                fallbackLabel: formatMessage('auth-pin-authenticate.biometric.fallback'),
                disableDeviceFallback: false
            });
        } catch (error) {
            ErrorDiagnostics.error(error);
            return Promise.resolve({
                success: false,
                reason: 'biometric-auth-other-error'
            } as BiometricAuthActionResult);
        }
    }, [biometric, formatMessage, track]);

    const handlePinAuthenticate = useCallback(
        async (pinCode: PinCode) => {
            try {
                ErrorDiagnostics.log('Signing in with pin');
                track({ type: 'auth-pin', action: 'init' });
                return await pin.authenticate(pinCode);
            } catch (error) {
                ErrorDiagnostics.log(`Sign in with pin failed: ${error}`);
                return Promise.resolve({
                    success: false,
                    reason: 'pin-auth-other-error'
                } as AuthActionResult);
            }
        },
        [pin, track]
    );

    const handleForgottenPin = useCallback(async () => {
        await pin.reset();
        await navigateToAuthentication();
        track({ type: 'auth-pin', action: 'forgotten-pin' });
    }, [navigateToAuthentication, pin, track]);

    const { states, message, input, handleEnter, handleDelete, biometricAuthenticate } = usePinCodeAuthenticate({
        onPinCodeAuthenticate: handlePinAuthenticate,
        onPinCodeAuthenticateFailed: handlePinCodeAuthenticateFailed,
        onBiometricAuthenticate: handleBiometricAuthenticate,
        biometricAuthType:
            biometric.available && biometric.enabled && canAuthenticate ? biometric.types?.[0] : undefined,
        authenticateOnMount: true
    });

    return (
        <>
            <StatusBar style="dark" />
            <ScreenContainer bottomInset={bottom}>
                <HeaderContainer topInset={top}>
                    <HorizontalLogo devMenuAccess />
                </HeaderContainer>

                <EllipticMaskView ellipseSize="large" style={{ flex: 1 }}>
                    <TopContainer>
                        <PinCodeDisplay size={SCREEN_WIDTH_RATIO < 1.0 ? 'small' : 'standard'} states={states} />
                        <Spacing.Vertical.MEDIUM />
                        <Text.P1 style={{ paddingHorizontal: Spacing.MEDIUM }}>{message}</Text.P1>
                    </TopContainer>
                </EllipticMaskView>

                <Spacing.Vertical.MEDIUM />

                <BottomContainer>
                    <ForgottenLinkContainer>
                        <LinkButton
                            size="small"
                            label={formatMessage('auth-pin-authenticate.forgotten-pin')}
                            onPress={handleForgottenPin}
                        />
                    </ForgottenLinkContainer>

                    <PinCodeKeyboard
                        value={input}
                        onKeyPress={handleEnter}
                        onDelete={handleDelete}
                        biometricAuthType={
                            biometric.available && biometric.enabled && canAuthenticate
                                ? biometric.types?.[0]
                                : undefined
                        }
                        onBioMetricAuthenticate={biometricAuthenticate}
                    />
                </BottomContainer>
            </ScreenContainer>
        </>
    );
};
