import React from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

import { CheckButton, CheckButtonProps } from './check-button';

export type RadioButtonProps = Omit<CheckButtonProps, 'type'>;

const ActiveBackground = styled.View<{ selected: boolean }>`
    border-radius: 8px;
    ${({ selected }) => (selected ? `background-color: ${Color.BASIC_PRESSED};` : '')}
    margin-bottom: 4px;
`;

export const RadioButton = (props: RadioButtonProps) => {
    const { selected = false } = props;
    return (
        <ActiveBackground selected={selected}>
            <CheckButton type="radio" {...props} />
        </ActiveBackground>
    );
};

RadioButton.displayName = 'RadioButton';
