// See https://stackoverflow.com/questions/54954091/how-to-use-callback-with-usestate-hook-in-react

import { useCallback, useEffect, useRef, useState } from 'react';

type SetStateCallback<T> = (value: T) => void;

export function useStateCallback<T>(initialState: T): [T, (value: T, cb: SetStateCallback<T>) => void] {
    const [state, setState] = useState<T>(initialState);
    const callback = useRef<SetStateCallback<T> | null>(null);

    const setStateCallback = useCallback((newState: T, cb: SetStateCallback<T>) => {
        callback.current = cb;
        setState(newState);
    }, []);

    useEffect(() => {
        if (callback.current) {
            callback.current(state);
            callback.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
}
