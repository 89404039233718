import { Flex } from 'native-base';
import React, { PropsWithChildren } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Color } from '~/components/color';

export type CardProps = {
    isDisabled?: boolean;
    style?: StyleProp<ViewStyle>;
} & PropsWithChildren;

export const Card: React.FC<CardProps> = ({ children, isDisabled, style }) => {
    return (
        <Flex
            alignItems="center"
            backgroundColor={isDisabled ? Color.BACKGROUND_DISABLED : Color.BACKGROUND_DEFAULT}
            borderRadius={4}
            style={style}
        >
            {children}
        </Flex>
    );
};

export const CardDivider: React.FC = () => {
    return <Flex borderTopWidth="1px" border-top-color={Color.LINE_DEFAULT} alignSelf="stretch" marginLeft="4" />;
};
