import { useDimensions } from '@react-native-community/hooks';
import React from 'react';
import { SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

import { EllipticMaskView } from '../elliptic-mask';
import { Shadow } from '../shadow';
import { Spacing } from '../spacing';
import { Task } from '../task';
import { Text } from '../text';

import { Button } from './button';

export type HighlightButtonProps = {
    buttonLabel?: string;
    buttonDisabled?: boolean;
    description?: string;
    heroImage: React.ComponentType<SvgProps>;
    imageSubtitle?: string;
    imageTitle?: string;
    loading?: boolean;
    onPress?: () => void | Promise<void>;
    title?: string;
};

const ButtonContainer = styled.View`
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    padding-top: 0;
    padding-horizontal: 0;
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const HelperAndButtonContainer = styled.View`
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    padding: 12px 16px 16px 16px;
`;

const HeroImageContainer = styled.View`
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
`;

const ImageContent = styled.View<{ size?: 'small' | 'medium' | 'large' }>`
    background-color: ${Color.BACKGROUND_TERTIARY};
    border-radius: ${({ size }) => (size === 'small' ? 16 : 0)}px;
    width: 100%;
`;

const Kicker = styled.View`
    position: absolute;
    left: 16px;
    top: 6.5%;
`;

const ImageTitle = styled.View`
    position: absolute;
    width: 170px;
    left: 16px;
    top: 19.51%;
`;

const TextContainer = styled.View`
    flex: 2;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
`;

const LabelButtonContainer = styled.View`
    flex: 1;
`;

export const HighlightButton = (props: HighlightButtonProps) => {
    const {
        buttonLabel,
        buttonDisabled = false,
        description,
        onPress,
        heroImage: HeroImage,
        title,
        imageSubtitle,
        imageTitle
    } = props;
    const { window } = useDimensions();

    return (
        <ButtonContainer style={[Shadow.styles.primary, { maxWidth: window.width }]}>
            <HeroImageContainer>
                <EllipticMaskView ellipseSize="large">
                    <ImageContent>
                        {imageSubtitle ? (
                            <Kicker>
                                <Task text={imageSubtitle} scheme="active" />
                            </Kicker>
                        ) : null}
                        {imageTitle ? (
                            <ImageTitle>
                                <Text.HEADER_2>{imageTitle}</Text.HEADER_2>
                            </ImageTitle>
                        ) : null}
                        <HeroImage width="100%" height={246} />
                    </ImageContent>
                </EllipticMaskView>
            </HeroImageContainer>

            <HelperAndButtonContainer>
                <TextContainer>
                    {title ? <Text.SUBTITLE_1>{title}</Text.SUBTITLE_1> : null}
                    {description ? <Text.PARAGRAPH_1 numberOfLines={5}>{description}</Text.PARAGRAPH_1> : null}
                </TextContainer>
                {buttonLabel && (
                    <>
                        <Spacing.Horizontal.SMALL />
                        <LabelButtonContainer>
                            <Button
                                size="regular"
                                shape="rounded"
                                type="primary"
                                alignCenter
                                disabled={buttonDisabled}
                                label={buttonLabel}
                                fillContainer
                                onPress={onPress}
                            />
                        </LabelButtonContainer>
                    </>
                )}
            </HelperAndButtonContainer>
        </ButtonContainer>
    );
};

HighlightButton.displayName = 'HighlightButton';
