import { useDimensions } from '@react-native-community/hooks';
import React from 'react';
import { Image, ImageSourcePropType, Pressable } from 'react-native';
import { SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { Color } from '~/components/color';

import { Shadow } from '../shadow';
import { Spacing } from '../spacing';
import { Text } from '../text';

const Container = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: ${Spacing.LARGE}px ${Spacing.MEDIUM}px;
    border-radius: 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

const ImageContainer = styled.View`
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 16px;
    overflow: hidden;
`;

const TextContainer = styled.View`
    margin-left: ${Spacing.MEDIUM}px;
    flex: 1;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
`;

export type BannerButtonProps = {
    heroImage?: React.ComponentType<SvgProps>;
    imageSrc?: ImageSourcePropType;

    title?: string;
    description?: string;

    onPress?: () => void | Promise<void>;
};

export const BannerButton: React.FC<BannerButtonProps> = props => {
    const { description, onPress, heroImage: HeroImage, imageSrc, title } = props;
    const { window } = useDimensions();

    return (
        <Pressable accessibilityRole="button" onPress={onPress}>
            <Container style={[Shadow.styles.primary, { maxWidth: window.width }]}>
                <ImageContainer>
                    {HeroImage ? <HeroImage width={80} height={80} /> : null}
                    {imageSrc ? <Image resizeMode="cover" source={imageSrc} style={{ width: 80, height: 80 }} /> : null}
                </ImageContainer>

                <TextContainer>
                    {title ? <Text.H3 style={{ color: Color.HEADER }}>{title}</Text.H3> : null}
                    {description ? (
                        <Text.PARAGRAPH_1 before={title && description ? Spacing.SMALL : undefined} numberOfLines={5}>
                            {description}
                        </Text.PARAGRAPH_1>
                    ) : null}
                </TextContainer>
            </Container>
        </Pressable>
    );
};

BannerButton.displayName = 'BannerButton';
