import React from 'react';
import styled from 'styled-components/native';

import { SingleMarkerMap } from '~/components/map/single-marker-map';
import { Text } from '~/components/text';
import { Location } from '~/types';
import { geoJsonPointToLatLng, geoJsonPointToMapRegion } from '~/utils/geo-json';

export type AppointmentLocationProps = {
    location: Location;
};

const AppointmentLocationContainer = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
`;

const AddressContainer = styled.View`
    flex-direction: column;
    flex: 1;
`;

export const AppointmentLocation: React.FC<AppointmentLocationProps> = props => {
    const { location } = props;
    const { name, address1, address2, zip, city, geometry } = location;
    const region = geometry ? geoJsonPointToMapRegion(geometry, 200) : undefined;
    const coordinates = geometry ? geoJsonPointToLatLng(geometry) : undefined;

    return (
        <AppointmentLocationContainer>
            <AddressContainer>
                <Text.PARAGRAPH_2>{name}</Text.PARAGRAPH_2>
                <Text.PARAGRAPH_1>
                    {address1}, {address2}
                </Text.PARAGRAPH_1>
                <Text.PARAGRAPH_1>
                    {zip} {city}
                </Text.PARAGRAPH_1>
            </AddressContainer>

            <SingleMarkerMap name={name} region={region} coordinates={coordinates} />
        </AppointmentLocationContainer>
    );
};
