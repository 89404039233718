import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { CustomCalendar } from '~/components/calendar';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { HeaderCloseButton } from '~/components/header';
import { ScreenContainer } from '~/components/screen';
import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { toISODateString } from '~/utils/date';

export type DateSelectNavigationProp = StackNavigationProp<MainNavigatorParamList, 'sick-leave-calendar'>;
export type DateSelectRouteProp = RouteProp<MainNavigatorParamList, 'sick-leave-calendar'>;

export function SickLeaveCalendar() {
    const { setOptions, navigate } = useNavigation<DateSelectNavigationProp>();
    const { params } = useRoute<DateSelectRouteProp>();
    const [selectedDate, setSelectedDate] = useState(params?.date ?? toISODateString(new Date()));
    const { formatMessage } = useIntl();

    useLayoutEffect(() => {
        setOptions({
            headerLeft: () => <HeaderCloseButton />,
            headerTitle: formatMessage('sick-leave.first-date'),
            headerTitleAlign: 'left',
            headerTransparent: false,
            headerShadowVisible: false
        });
    }, [formatMessage, setOptions]);

    const renderItem = useCallback(
        (info: ListRenderItemInfo<ISODate>) => {
            return <CustomCalendar month={info.item} onSelectDate={setSelectedDate} selectedDate={selectedDate} />;
        },
        [selectedDate]
    );
    const month = startOfMonth(new Date());
    const isDesktop: boolean = useBreakpointValue({ base: false, md: true });

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="narrow" includePadding={isDesktop}>
                <Flex flexDirection="column" grow={1}>
                    <FlashList<ISODate>
                        showsVerticalScrollIndicator={false}
                        estimatedItemSize={360}
                        data={[
                            toISODateString(subMonths(month, 1)),
                            toISODateString(month),
                            toISODateString(addMonths(month, 1))
                        ]}
                        keyExtractor={id => id}
                        renderItem={renderItem}
                    />
                    <BottomContainer>
                        <Button
                            label={formatMessage('select')}
                            alignCenter
                            type="primary"
                            onPress={() =>
                                navigate('sick-leave', {
                                    date: selectedDate
                                })
                            }
                        />
                    </BottomContainer>
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
}

const BottomContainer = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};
    border-top-width: 1px;
    border-top-color: ${Color.LINE_DEFAULT};
    height: 112px;
    align-self: center;
    padding-horizontal: 32px;
    padding-vertical: 24px;
    width: 100%;
`;
