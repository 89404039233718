import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { encode } from 'base-64';
import { Flex } from 'native-base';
import React, { useCallback } from 'react';

import { Avatar, AvatarSize } from '~/components/avatar';
import { OvalButton } from '~/components/button';
import { ChevronRightIcon } from '~/components/icon';
import { Text } from '~/components/text';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { User } from '~/types';

type UserInfoNavigation = StackNavigationProp<MainNavigatorParamList, 'appointment'>;

export type UserInfoProps = {
    user: User;
    image: boolean;
    avatarSize?: AvatarSize;
};

export const UserInfo: React.FC<UserInfoProps> = props => {
    const { user, image, avatarSize = 'regular' } = props;
    const { firstName, lastName, title, profile, avatarSmallUrl } = user;
    const { navigate } = useNavigation<UserInfoNavigation>();

    const handleProfileOpen = useCallback(() => {
        if (user) {
            navigate('staff-profile', { user: encode(JSON.stringify(user)) });
        }
    }, [user, navigate]);

    return (
        <Flex direction="row" alignItems="center" p="4">
            {image && avatarSmallUrl ? <Avatar size={avatarSize} imageUri={avatarSmallUrl} /> : null}
            <Flex direction="column" ml={image ? 4 : 0}>
                <Text.PARAGRAPH_2>
                    {firstName} {lastName}
                </Text.PARAGRAPH_2>
                <Text.PARAGRAPH_1>{title}</Text.PARAGRAPH_1>
            </Flex>
            {profile ? <OvalButton size="small" icon={ChevronRightIcon} onPress={handleProfileOpen} /> : null}
        </Flex>
    );
};
