import { Flex, Card, useBreakpointValue } from 'native-base';
import React, { useCallback } from 'react';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { Image } from '~/components/image';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { ArticleCategoryArticle, LayoutStyleProps } from '~/types';

import { MasonryList } from '../masonry-list';

import { ArticleCompactChildCategory } from './article-compact-child-category';

export type ArticleListProps = {
    title?: string | null;
    description?: string | null;
    articles: readonly ArticleCategoryArticle[];
    onItemPressed: (item: ID) => void;
    layoutStyle?: LayoutStyleProps;
};

export function ArticleList(props: ArticleListProps) {
    const { title, description, articles = [], onItemPressed, layoutStyle } = props;
    const { formatMessage } = useIntl();

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    const IMAGE_HEIGHT = webComponent ? 140 : 220;

    const renderItem = useCallback(
        (item: ArticleCategoryArticle) => {
            const { id, title: articleTitle, highlight, coverPhotoUrl, childCategories = [] } = item;
            const ellipseStyle = webComponent ? { maxHeight: IMAGE_HEIGHT - 10 } : {};

            return (
                <Flex key={'card-' + id} borderRadius="16" style={Shadow.styles.primary}>
                    <Card backgroundColor={Color.BACKGROUND_DEFAULT} borderRadius="16" padding={0}>
                        <EllipticMaskView ellipseSize="large" style={ellipseStyle}>
                            {coverPhotoUrl ? (
                                <Image
                                    source={{ uri: coverPhotoUrl }}
                                    resizeMode="cover"
                                    style={[{ width: '100%', height: IMAGE_HEIGHT }]}
                                />
                            ) : null}
                        </EllipticMaskView>
                        <Flex margin="8px 16px 16px" alignItems="flex-start">
                            <Text.HEADER_3 style={{ color: Color.HEADER }}>{articleTitle}</Text.HEADER_3>
                            <Text.PARAGRAPH_1 before={8} after={16} numberOfLines={3}>
                                {highlight}
                            </Text.PARAGRAPH_1>
                            <Button
                                size="small"
                                shape="rounded"
                                label={formatMessage('services.article.read-more')}
                                onPress={() => onItemPressed(id)}
                            />
                        </Flex>
                        <Spacing.Horizontal.LARGE />
                        {childCategories.map(category => (
                            <ArticleCompactChildCategory
                                key={category.id}
                                title={category.title}
                                articles={category.articles}
                            />
                        ))}
                    </Card>
                </Flex>
            );
        },
        [IMAGE_HEIGHT, formatMessage, onItemPressed, webComponent]
    );

    return (
        <Flex>
            {articles.length ? (
                <Flex marginTop={Spacing.SMALL} marginBottom={Spacing.LARGE} style={layoutStyle}>
                    {title ? <Text.HEADER_3 after={Spacing.LARGE}>{title}</Text.HEADER_3> : null}
                    {description ? <Text.PARAGRAPH_1 after={Spacing.SMALL}>{description}</Text.PARAGRAPH_1> : null}
                    <MasonryList<ArticleCategoryArticle> items={articles} renderItem={renderItem} />
                </Flex>
            ) : null}
        </Flex>
    );
}
